export const typeOperations = [
    {
        id: 7,
        name: "Reserva de Numeração",
        type: "reserveNumber"
        // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
    },
    {
        id: 8,
        name: "Suspensão Temporária",
        type: "suspTemporary"
        // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
    },
    {
        id: 9,
        name: "Suspensão a Pedido",
        type: "suspClient"
        // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
    },
    {
        id: 10,
        name: "Suspensão Perda/Roubo",
        type: "suspRob"
        // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
    },
    {
        id: 11,
        name: "Suspensão Quarentena",
        type: "suspQuarentine"
        // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
    },
    {
        id: 12,
        name: "Reativação",
        type: "react"
        // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
    }
]

import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
const placement = "bottomRight"

//http://localhost:3333/api/customers?name=Li
export const getCustomerByName = name => dispatch => {
  // dispatch(fetchStart());
  dispatch(fetchStart(actionsType.GET_CUSTOMERS_START));

  axios
    .get(API_ROOT + `customers?name=${name}`)
    .then(res => {
      // dispatch(getCustomerSuccess(res.data));
      dispatch(fetchSuccess(actionsType.GET_CUSTOMERS_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_CUSTOMERS_FAIL, error));
    });
};

export const getEmployeesByName = name => dispatch => {
  // dispatch(fetchStart());
  dispatch(fetchStart(actionsType.GET_CUSTOMERS_START));

  axios
    .get(API_ROOT + `employees?name=${name}`)
    .then(res => {
      // dispatch(getCustomerSuccess(res.data));
      dispatch(fetchSuccess(actionsType.GET_CUSTOMERS_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_CUSTOMERS_FAIL, error));
    });
};

export const getCustomers = (customerId,
  size,
  page) => dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchStart(actionsType.GET_LIST_CUSTOMERS_START));

    axios
      .get(
        API_ROOT +
        `customers-list?customerId=${customerId}&size=${size}&page=${page}`
      )
      .then(res => {
        dispatch(fetchSuccess(actionsType.GET_LIST_CUSTOMERS_SUCCESS, res.data));
      })
      .catch(error => {
        dispatch(fetchError(actionsType.GET_LIST_CUSTOMERS_FAIL, error));
      });
  };

export const insertCustomer = (customer) => dispatch => {
  // dispatch(fetchStart());
  dispatch(fetchStart(actionsType.GET_CUSTOMERS_START));
  notification.open({
    key: 'loadingInsertCustomer',
    message: 'Realizando a inserção do cliente!',
    description:
      'Em instantes você receberá a resposta',
    icon: <LoadingOutlined />,
    duration: 1,
    placement

  });
  let data = {
    "name": customer.name,
    "account_type": customer.account_type,
    "industry": customer.industry,
    "billing_address_street": customer.street+", "+customer.number,
    "billing_address_district": customer.district,
    "billing_address_city": customer.city,
    "billing_address_state": customer.state,
    "billing_address_postalcode": customer.postalcode,
    "billing_address_country": customer.country,
    "phone_office": customer.phone,
    "phone_alternate": customer.phone_alternate,
    "shipping_address_street": customer.street,
    "shipping_address_district": customer.district,
    "shipping_address_city": customer.city,
    "shipping_address_state": customer.state,
    "shipping_address_postalcode": customer.postalcode,
    "shipping_address_country": customer.country,
    "cpfcnpj_c": customer.cnpj,
    "nome_fantasia_c": customer.nome_fantasia,
    "tipopessoa_c": customer.tipo_pessoa,
    "status_c": customer.status,
    "due_day": customer.due_day,
    "branch__id": customer.branch__id,
    "email": customer.email,
    "email_support": customer.email_support,
    "seller__id": customer.seller__id,
    "type__id": customer.type__id,
    "notes": customer.notes
  }

  axios
    .post(API_ROOT + `customers`, data)
    .then(res => {
      notification.close('loadingInsertCustomer')
      // dispatch(getCustomerSuccess(res.data));
      console.log(res.data)
      dispatch(fetchSuccess(actionsType.INSERT_CUSTOMERS_SUCCESS, res.data));
      dispatch(fetchStart(actionsType.INSERT_CUSTOMERS_CLEAR));
    })
    .catch(error => {
      notification.close('loadingInsertCustomer')
      dispatch(fetchError(actionsType.GET_CUSTOMERS_FAIL, error));
    });
};


export const editCustomer = (id,customer) => dispatch => {
  // dispatch(fetchStart());
  dispatch(fetchStart(actionsType.GET_CUSTOMERS_START));
  notification.open({
    key: 'loadingUpdateCustomer',
    message: 'Realizando a atualização do cliente!',
    description:
      'Em instantes você receberá a resposta',
    icon: <LoadingOutlined />,
    duration: 1,
    placement

  });
  let data = {
    "name": customer.name,
    "account_type": customer.account_type,
    "industry": customer.industry,
    "billing_address_street": customer.street+", "+customer.number,
    "billing_address_district": customer.district,
    "billing_address_city": customer.city,
    "billing_address_state": customer.state,
    "billing_address_postalcode": customer.postalcode,
    "billing_address_country": customer.country,
    "phone_office": customer.phone,
    "phone_alternate": customer.phone_alternate,
    "shipping_address_street": customer.street,
    "shipping_address_district": customer.district,
    "shipping_address_city": customer.city,
    "shipping_address_state": customer.state,
    "shipping_address_postalcode": customer.postalcode,
    "shipping_address_country": customer.country,
    "cpfcnpj_c": customer.cnpj,
    "nome_fantasia_c": customer.nome_fantasia,
    "tipopessoa_c": customer.tipo_pessoa,
    "status_c": customer.status,
    "due_day": customer.due_day,
    "branch__id": customer.branch__id,
    "email": customer.email,
    "email_support": customer.email_support,
    "seller__id": customer.seller__id,
    "type__id": customer.type__id,
    "notes": customer.notes
  }

  axios
    .put(API_ROOT + `customers/${id}`, data)
    .then(res => {
      notification.close('loadingUpdateCustomer')
      // dispatch(getCustomerSuccess(res.data));
      console.log(res.data)
      dispatch(fetchSuccess(actionsType.INSERT_CUSTOMERS_SUCCESS, res.data));
      dispatch(fetchStart(actionsType.INSERT_CUSTOMERS_CLEAR));
    })
    .catch(error => {
      notification.close('loadingUpdateCustomer')
      dispatch(fetchError(actionsType.GET_CUSTOMERS_FAIL, error));
    });
};

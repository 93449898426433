import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Input, AutoComplete } from "antd";
import { getCustomerByName } from "../../../store/actions/customerAction";
import DeviceStatusInfo from "../../container/Dashborad/DeviceStatusInfo";
import DeviceConsumptionInfo from "../../container/Dashborad/DeviceConsumptionInfo";
import Loading from "../../../components/Loading/Loading";
import CardInfo from "../../container/Dashborad/CardInfo";
import HeliosCardChart from "../../../components/HeliosCardChart/HeliosCardChart";
import DeviceConsumption from "../../../model/DeviceConsumption";
import moment from "moment";
import { getDevicesDash, getDevicesNLT } from "../../../store/actions/deviceAction";
import { countDevicesConsumptionNLT, countDevicesConsumption } from "../../../store/actions/deviceConsumptionAction";
import { getPhaseSimcard } from "../../../store/actions/phaseSimcardsAction";


const dateStart = moment()
  .subtract(12, "months")
  .format("YYYY-MM-DD");

const dateNow = moment().format('lll');

const DashboardLora = props => {
  const [customerId,] = useState(1);
  const [customerName, setCustomerName] = useState();
  const [query, setQuery] = useState();
  const [limit,] = useState(5);
  const [offset,] = useState(0);
  const [customerSelected, setCustomerSelected] = useState("Nenhum cliente selecionado");
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [height, setHeight] = useState(window.innerHeight);
  const [optionsValue, setOptionsValue] = useState("");
  const [customerSelectedPhase, setCustomerSelectedPhase] = useState("Operação NLT");
  const dispatch = useDispatch();


  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (customerName) {
      dispatch(getCustomerByName(customerName));
    }
  }, [dispatch, customerName]);

  const customers = useSelector(state => state.customers);

  useEffect(() => {
    if (customers.customers) {
      let options = [];
      customers.customers.data.map(obj => {
        return options.push({
          value: obj.cpfcnpj_c.toString(),
          label: obj.name
        });
      });
      setOptionsCustomer(options);
    }
  }, [customers]);
  useEffect(() => {
    dispatch(countDevicesConsumptionNLT());
    dispatch(getDevicesDash(query, offset, limit));
    dispatch(getDevicesNLT());
    dispatch(countDevicesConsumption(query, offset, limit));
    dispatch(getPhaseSimcard(dateStart, dateNow, 1, customerId));
  }, [dispatch, customerId, query, offset, limit]);
  const status = useSelector(state => state.devices);
  const statusNLT = useSelector(state => state.devicesNLT);

  const consumptions = useSelector(state => state.devicesConsumption);
  const consumptionsNLT = useSelector(state => state.devicesConsumptionNLT);

  //mocado
  const phaseSimcards = useSelector(state => state.phaseSimcards);

  let totalActive = 0
  let totalInactive = 0
  let totalCreate = 0

  if (status.devices) {

    let list = status.devices.devices
    for (var i = 0; i < list.length; i++) {
      if (list[i].activation === "ABP" && list[i].block_downlink === false && list[i].block_uplink === false) { totalActive++; }//Ativados
      else if (list[i].activation === "OTTA" && list[i].block_downlink === false && list[i].block_uplink === false) { totalCreate++; }//Criados
      else { totalInactive++ }//Inativos
    }
  }

  const statusDevice = [
    { name: 'Ativos', value: totalActive, color: "green" },
    { name: 'Inativos', value: totalInactive, color: "red" },
    { name: 'Criados', value: totalCreate, color: "orange" }

  ];
  let totalActiveNLT = 0
  let totalInactiveNLT = 0
  let totalCreateNLT = 0

  if (statusNLT.devicesNLT) {

    let list = statusNLT.devicesNLT.devices
    for (var j = 0; j < list.length; j++) {
      if (list[j].activation === "ABP" && list[j].block_downlink === false && list[j].block_uplink === false) { totalActiveNLT++; }//Ativados
      else if (list[j].activation === "OTTA" && list[j].block_downlink === false && list[j].block_uplink === false) { totalCreateNLT++; }//Criados
      else { totalInactiveNLT++ }//Inativos
    }
  }

  const statusDeviceNLT = [
    { name: 'Ativos', value: totalActiveNLT, color: "green" },
    { name: 'Inativos', value: totalInactiveNLT, color: "red" },
    { name: 'Criados', value: totalCreateNLT, color: "orange" }

  ];

  const columnsStatus = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
    {
      title: 'value',
      dataIndex: 'value',
      key: 'value',
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
  ];
  const columnsConsumptionNLT = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
  ];
  const columnsConsumption = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
  ];
  let consumptionDeviceNLT = [];

  if (consumptionsNLT.devicesConsumptionNLT) {
    const {
      downlinkNLT,
      uplinkNLT
    } = consumptionsNLT.devicesConsumptionNLT;
    consumptionDeviceNLT.push(
      new DeviceConsumption(
        "Uplink",
        uplinkNLT,
        "green"
      )
    );
    consumptionDeviceNLT.push(
      new DeviceConsumption(
        "Downlink",
        downlinkNLT,
        "orange"
      )
    );
  }

  let consumptionDevice = [];

  if (consumptions.devicesConsumption) {
    const {
      downlink,
      uplink
    } = consumptions.devicesConsumption;
    consumptionDevice.push(
      new DeviceConsumption(
        "Uplink",
        uplink,
        "green"
      )
    );
    consumptionDevice.push(
      new DeviceConsumption(
        "Downlink",
        downlink,
        "orange"
      )
    );
  }

  return (
    <div>
      <Card
        title="Dashboard Lora"
        extra={
          <div>

            <AutoComplete
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerName();
                  setCustomerSelected("Nenhum cliente selecionado");
                  setCustomerSelectedPhase("Operação NLT");
                }
                if (value === "") {
                  setQuery();
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                setOptionsValue(option.label);
                setQuery(`tags:${value}`);
                setCustomerSelected(option.label);
                setCustomerSelectedPhase(option.label);
              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
            </AutoComplete>
          </div>
        }
      ></Card>
      <Row gutter={16}>
        <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
          <CardInfo
            title="Base total de Devices"
            subTitle="Operação NLT"
            lastUpdate={statusNLT.lastUpdate}
            onUpdate={() => dispatch(getDevicesNLT())}
          >
            {statusNLT.loading ? (
              <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
            ) : (
                <DeviceStatusInfo
                  data={statusDeviceNLT}
                  total={statusNLT.devicesNLT ? statusNLT.devicesNLT.total : 0}
                  columns={columnsStatus}
                />)}

          </CardInfo>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
          <CardInfo
            title={customerSelected}
            subTitle=""
            lastUpdate={status.lastUpdate}
            onUpdate={() => dispatch(getDevicesDash())}
          >
            {status.loading ? (
              <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
            ) : (
                <DeviceStatusInfo
                  data={statusDevice}
                  total={status.devices ? status.devices.total : 0}
                  columns={columnsStatus}
                />)}

          </CardInfo>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
          <CardInfo
            title="Base total de consumo de Devices"
            subTitle="Operação NLT"
            lastUpdate={consumptionsNLT.lastUpdate}
            onUpdate={() => dispatch(countDevicesConsumptionNLT())}
          >
            {consumptionsNLT.loading ? (
              <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
            ) : (
                <DeviceConsumptionInfo
                  columns={columnsConsumptionNLT}
                  data={consumptionDeviceNLT}
                  total={consumptionsNLT.devicesConsumptionNLT ? consumptionsNLT.devicesConsumptionNLT.totalNLT : 0}
                />)}

          </CardInfo>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
          <CardInfo
            title={customerSelected}
            subTitle=""
            lastUpdate={consumptions.lastUpdate}
            onUpdate={() => dispatch(countDevicesConsumption())}
          >
            {consumptions.loading ? (
              <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
            ) : (
                <DeviceConsumptionInfo
                  columns={columnsConsumption}
                  data={consumptionDevice}
                  total={consumptions.devicesConsumption ? consumptions.devicesConsumption.total : 0}
                />)}

          </CardInfo>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
          <CardInfo
            title="Total de Devices ativos"
            subTitle={customerSelectedPhase}
            lastUpdate={phaseSimcards.lastUpdate}
            onUpdate={() =>
              dispatch(getPhaseSimcard(dateStart, dateNow, 1, customerId))
            }
          >
            {phaseSimcards.loading ? (
              <Loading heightContainer={height <= 650 ? "47vh" : "30vh"} />
            ) : (
                <HeliosCardChart color="green" dataChart={phaseSimcards.simcardActivated} />
              )}
          </CardInfo>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
          <CardInfo
            title="Total de Devices inativos"
            subTitle={customerSelectedPhase}
            lastUpdate={phaseSimcards.lastUpdate}
            onUpdate={() =>
              dispatch(getPhaseSimcard(dateStart, dateNow, 1, customerId))
            }
          >
            {phaseSimcards.loading ? (
              <Loading heightContainer={height <= 650 ? "47vh" : "30vh"} />
            ) : (
                <HeliosCardChart color="red" dataChart={phaseSimcards.simcardActivated} />
              )}
          </CardInfo>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
          <CardInfo
            title="Total de Devices criados"
            subTitle={customerSelectedPhase}
            lastUpdate={phaseSimcards.lastUpdate}
            onUpdate={() =>
              dispatch(getPhaseSimcard(dateStart, dateNow, 1, customerId))
            }
          >
            {phaseSimcards.loading ? (
              <Loading heightContainer={height <= 650 ? "47vh" : "30vh"} />
            ) : (
                <HeliosCardChart color="yellow" dataChart={phaseSimcards.simcardActivated} />
              )}
          </CardInfo>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardLora;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "antd/dist/antd.css";

import RouterRedirect from "./routeRedirect";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <RouterRedirect />
      </Router>
    </Provider>
  );
};

export default App;

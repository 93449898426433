import React from "react";
import { Route, Switch } from "react-router-dom";

import LayoutDefault from "../LayoutDefault";
import MenuCustomer from "./MenuCustomer";
import Dashboard from "../../views/Customers/Dashboard/Dashboard";
import Page404 from "../../views/PagesInfo/Page404";

const LayoutCustomer = props => {
  return (
    <LayoutDefault menu={<MenuCustomer />}>
      <Switch>
        <Route path="/" exact>
          <Dashboard />
        </Route>
        <Route>
          <Page404 />
        </Route>
      </Switch>
    </LayoutDefault>
  );
};

export default LayoutCustomer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCustomerByName, getCustomers } from "../../../store/actions/customerAction";


import HeliosTable from "../../../components/HeliosTable/HeliosTable";
import {
    Tooltip,
    Drawer,
    Form,
    Input,
    Button,

    AutoComplete,

} from "antd";
import {  EditOutlined,EyeOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";

import SkeletonTable from "../../../components/Skeleton/SkeletonTable";

// import { columns } from "./columnsTable";

const Customers = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [optionsCustomer, setOptionsCustomer] = useState([]);
    const [optionsValue, setOptionsValue] = useState("");

    const [showFilter, setShowFilter] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getCustomers(customerId, pageSize, page-1));

    }, [dispatch, customerId, pageSize, page]);

    useEffect(() => {
        if (customerName) {
            dispatch(getCustomerByName(customerName));
        }


    }, [dispatch, customerName]);

    const customers = useSelector(state => state.customers);
    useEffect(() => {
        if (customers.customers) {
            let options = [];
            customers.customers.data.map(obj => {
                return options.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsCustomer(options);
        }

    }, [customers]);



    const { allCustomerList, loading } = useSelector(state => state.allCustomerList);
    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(current);
    };

    const handlePaginationChange = (current) => {
        setPage(current);
    };

    const onClearFilter = () => {
        setCustomerId();
        setCustomerName();
        setOptionsValue("");
    };

    const onShowFilter = () => {
        setShowFilter(true);
    };

    const onCloseFilter = () => {
        setShowFilter(false);
    };


    const subTitle = (
        <div>
            <Tooltip placement="bottom" title="Filtros">
                <i
                    style={styles.subTitle}
                    className="fas fa-filter"
                    onClick={() => {
                        onShowFilter();
                    }}
                />
            </Tooltip>
            <Tooltip placement="bottom" title="Adicionar Clientes">
                    <i
                    style={styles.subTitle}
                    className="fas fa-user-plus"
                    onClick={() => {
                        history.push("/add-customer-operation");
                      }}
                    />
                    </Tooltip>
        </div>
    );
    const columns = [
        {
            title: "Razão Social",
            dataIndex: "name",
            
        },
        {
            title: "CNPJ",
            dataIndex: "cpfcnpj_c",
            
        },
        {
            title: "Email",
            dataIndex: "email",
            
        },

        {
            title: "Telefone",
            dataIndex: "phone_office",
            
        },
        // {
        //     title: "Gerente",
        //     dataIndex: "seller__name",
            
        // },
        {
            title: "Ações",
            key: "action",
            render: (text, record) => (
                <>
              <Button
                icon={<EditOutlined  style={{fontSize:18}}/>}
                style={{borderColor: "orange",color:"orange"}}
                type="default" 
                onClick={() => {
                  history.push({
                    pathname: `/edit-customer-operation/${record.id}`,
                    state: {
                        id:record.id,
                        name:record.name,
                        account_type:record.account_type,
                        industry:record.industry,
                        billing_address_street:record.billing_address_street,
                        billing_address_district:record.billing_address_district,
                        billing_address_city:record.billing_address_city,
                        billing_address_state:record.billing_address_state,
                        billing_address_postalcode:record.billing_address_postalcode,
                        billing_address_country:record.billing_address_country,
                        phone_office:record.phone_office,
                        phone_alternate:record.phone_alternate,
                        shipping_address_street:record.shipping_address_street,
                        shipping_address_district:record.shipping_address_district,
                        shipping_address_city:record.shipping_address_city,
                        shipping_address_state:record.shipping_address_state,
                        shipping_address_postalcode:record.shipping_address_postalcode,
                        shipping_address_country:record.shipping_address_country,
                        cpfcnpj_c:record.cpfcnpj_c,
                        nome_fantasia_c:record.nome_fantasia_c,
                        tipopessoa_c:record.tipopessoa_c,
                        status_c:record.status_c,
                        due_day:record.due_day,
                        branch__id:record.branch__id,
                        branch__name:record.branch__name,
                        email:record.email,
                        email_support:record.email_support,
                        seller__id:record.seller__id,
                        seller__name:record.seller__name,
                        type__id:record.type__id,
                        type__name:record.type__name,
                        notes:record.notes
                    }
                  });
                }}
                size="small"
                >
              Editar
            </Button>
            <Button
            icon={<EyeOutlined  style={{fontSize:18}}/>}
            style={{borderColor: "green",color:"green",marginLeft:3}}
            type="default" 
            onClick={() => {
              history.push({
                pathname: `/view-customer-operation/${record.id}`,
                state: {
                    id:record.id,
                    name:record.name,
                    account_type:record.account_type,
                    industry:record.industry,
                    billing_address_street:record.billing_address_street,
                    billing_address_district:record.billing_address_district,
                    billing_address_city:record.billing_address_city,
                    billing_address_state:record.billing_address_state,
                    billing_address_postalcode:record.billing_address_postalcode,
                    billing_address_country:record.billing_address_country,
                    phone_office:record.phone_office,
                    phone_alternate:record.phone_alternate,
                    shipping_address_street:record.shipping_address_street,
                    shipping_address_district:record.shipping_address_district,
                    shipping_address_city:record.shipping_address_city,
                    shipping_address_state:record.shipping_address_state,
                    shipping_address_postalcode:record.shipping_address_postalcode,
                    shipping_address_country:record.shipping_address_country,
                    cpfcnpj_c:record.cpfcnpj_c,
                    nome_fantasia_c:record.nome_fantasia_c,
                    tipopessoa_c:record.tipopessoa_c,
                    status_c:record.status_c,
                    due_day:record.due_day,
                    branch__id:record.branch__id,
                    branch__name:record.branch__name,
                    email:record.email,
                    email_support:record.email_support,
                    seller__id:record.seller__id,
                    seller__name:record.seller__name,
                    type__id:record.type__id,
                    type__name:record.type__name,
                    notes:record.notes
                }
              });
            }}
            size="small"
            >
          Visualizar
        </Button>
        </>
             
            )
          }

    ]
    return (
        <div>
            <SkeletonTable show={loading} title="Clientes" icon="fas fa-user-tie" pageSize={pageSize}/>

            {allCustomerList && !loading?(
                <HeliosTable
                    title="Clientes"
                    subTitle={subTitle}
                    columns={columns}
                    icon="fas fa-user-tie"
                    dataSource={allCustomerList.data || []}
                    rowKey="id"
                    total={allCustomerList.total}
                    pageSize={pageSize}
                    page={page}
                    onChange={handlePaginationChange}
                    onShowSizeChange={onShowSizeChange}
                // rowSelection={rowSelection}
                />
            ):""}

            <Drawer
                title="Filtros"
                headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
                placement="right"
                onClose={onCloseFilter}
                visible={showFilter}
                width={500}
                style={styles.containerDrawer}
            >
                <Form layout="vertical">
                    <Form.Item label="CLIENTE">
                        <AutoComplete
                            options={optionsCustomer}
                            value={optionsValue}
                            onChange={value => {
                                if (!value) {
                                    setCustomerId();
                                    setCustomerName();
                                }
                            }}
                            onSearch={value => {
                                setOptionsValue(value);
                                const timer = setTimeout(() => {
                                    if (value.length >= 2) {
                                        setCustomerName(value);
                                    }
                                }, 1000);
                                return () => clearTimeout(timer);
                            }}
                            onSelect={(value, option) => {
                                setOptionsValue(option.label);
                                setCustomerId(value);
                            }}
                        >
                            <Input.Search
                                size="middle"
                                placeholder="Pesquisar Cliente..."
                                allowClear
                                loading={customers.loading}
                            />
                        </AutoComplete>
                    </Form.Item>
                </Form>
                <div style={styles.btnFilter}>
                    <Button
                        onClick={onClearFilter}
                        type="danger"
                        ghost
                        style={styles.btnCancel}
                    >
                        LIMPAR
          </Button>
                    <Button onClick={onCloseFilter} type="default">
                        FECHAR
          </Button>
                </div>
            </Drawer>

        </div>
    );
};

const styles = {
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
    containerDrawer: {
        //width: 30
    },
    btnFilter: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
    },
    btnCancel: {
        marginRight: 8
    },
    online: {
        color: "green"
    }
};

export default Customers;

import React, { useState } from "react";

import {
    Card,
    Steps,
    Button,
    Descriptions,
    notification
} from "antd";
import {RollbackOutlined,RightOutlined} from "@ant-design/icons";
import CardsOperation from "./CardsOperation";
import UploadOperation from "./UploadOperation";
import FormOperation from "./FormOperation";
import { getManyHardResetAndChangeApnClient, getManyHardResetClient } from "../../../store/actions/hardResetAction";
import { getManyChangeApnClient } from "../../../store/actions/changeApnAction";
import { getManySmsAndChangeAPNClient,  getManySmsAWS } from "../../../store/actions/smsMoAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const { Step } = Steps;

const OperationsMassive = () => {

    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [protocol,setProtocol]=useState("");
    const [motive,setMotive]=useState("")
    const [apnName,setApnName]=useState("")


    const [current, setCurrent] = useState(0);
    const [typeResource,setTypeResource]=useState("")
    const [fileInput,setFileInput]=useState()
    const [isVerified, setIsVerified] = useState(false)
    const [email,setEmail]=useState("");

    const dispatch = useDispatch();
    const hardReset = useSelector(state => state.hardReset);
    const smsMo = useSelector(state => state.smsMo);
    const changeApn = useSelector(state => state.changeApn);

    const history = useHistory();
    const placement = "bottomRight"
    let clientId = localStorage.getItem("clientId")
    const proccessOperation=()=>{
        

        if (typeResource === "Hard Reset") {

            // dispatch(getManyHardReset(fileInput))
            dispatch(getManyHardResetClient(customerId, customerName, fileInput,motive,protocol,email))
            
        }
        if (typeResource === "Alterar APN") {
            // dispatch(getManyChangeApn(fileInput, apnName))
            dispatch(getManyChangeApnClient(customerId, customerName,fileInput, apnName,motive,protocol,email))

        }
        if (typeResource === "Ativar SMS") {
            dispatch(getManySmsAWS(customerId, customerName,"enable",fileInput,motive,protocol,email))
        }
        if (typeResource === "Ativar SMS com APN") {
            dispatch(getManySmsAndChangeAPNClient(customerId, customerName,"enable",apnName,fileInput,motive,protocol,email))
        }
        if (typeResource === "Desativar SMS com APN") {
            dispatch(getManySmsAndChangeAPNClient(customerId, customerName,"disable",apnName,fileInput,motive,protocol,email))
        }
        if (typeResource === "Hard Reset com APN") {
            dispatch(getManyHardResetAndChangeApnClient(customerId, customerName,apnName,fileInput,motive,protocol,email))
        }
        if (typeResource === "Desativar SMS") {
            dispatch(getManySmsAWS(customerId, customerName,"disable",fileInput,motive,protocol,email))
        }
   
    }

    if (typeResource === "Hard Reset") {
        if(hardReset.hardReset){
            notification.success({
                message: hardReset.hardReset.message,
                description:
                    'Linhas para Reset estão na fila de operação massiva',
                duration: 60,
                placement
            });
        hardReset.hardReset = null
        history.push("/resource-activities")
        
        }
    }
    if (typeResource === "Ativar SMS" || typeResource === "Desativar SMS") {
        
        if(smsMo.smsMo){
            notification.success({
                message: smsMo.smsMo.message,
                description:
                    'Linhas para mudança do status de sms estão na fila de operação massiva',
                    
                duration: 60,
                placement
            });
            history.push("/resource-activities")
        }
    }
    if (typeResource === "Ativar SMS com APN" || typeResource === "Desativar SMS com APN") {
        
        if(smsMo.smsMo){
            notification.success({
                message: smsMo.smsMo.message,
                description:
                    'Linhas para mudança do status de SMS e alterar APN estão na fila de operação massiva',
                    
                duration: 60,
                placement
            });
            history.push("/resource-activities")
        }
    }
    if (typeResource === "Hard Reset com APN") {
        
        if(hardReset.hardReset){
            notification.success({
                message: hardReset.hardReset.message,
                description:
                    'Linhas para resetar e alterar APN estão na fila de operação massiva',
                
                duration: 60,
                placement
            });
            history.push("/resource-activities")
        }
    }
    if (typeResource === "Alterar APN") {
        if(changeApn.changeApn){
            notification.success({
                message: changeApn.changeApn.message,
                description:
                    'Linhas para mudança de APN estão na fila de operação massiva',
                duration: 60,
                placement
            });
        history.push("/resource-activities")

        }

    }


    const next = () => {
      setCurrent(current + 1);
    };
  
    const prev = () => {
      setCurrent(current - 1);
      setFileInput()
    };
    const steps = [
        {
          title: 'Operação',
          content: <CardsOperation onChange={next} onType={setTypeResource}/>,
        },
        {
          title: 'Upload',
          content: <UploadOperation setFileInput={setFileInput}/>,
        },
        {
          title: 'Ocorrência',
          content: <FormOperation 
            setIsVerified={setIsVerified} 
            typeResource={typeResource} 
            setCustomerId={setCustomerId}
            setCustomerName={setCustomerName}
            setProtocol={setProtocol}
            setMotive={setMotive}
            setEmail={setEmail}
            setApnName={setApnName}
            />,
        },{
          title: 'Resumo',
          content:
          <> 
          {/* <Animate transitionName="fade" transitionAppear> */}
            <Card styles={styles.container}>
                <Descriptions title="Resumo da Operação" bordered layout="vertical">
                    <Descriptions.Item label="Recurso solicitado:" span={3}>{typeResource}</Descriptions.Item>
                    {clientId ===null &&( <Descriptions.Item label="Cliente:">{customerName}</Descriptions.Item>)}
                    <Descriptions.Item label="Número do protocolo:">{protocol}</Descriptions.Item>
                    <Descriptions.Item label="Email do requerente:">{email}</Descriptions.Item>
                    <Descriptions.Item label="Motivo da operação:" span={3}>
                    {motive}
                    </Descriptions.Item>
                    {apnName?(
                        <Descriptions.Item label="Access Point Name (APN):" span={3}>
                        {apnName}
                        </Descriptions.Item>
                    ):""}
                </Descriptions>

            </Card>
            {/* </Animate> */}
            </>,
        },
      ];

    return (
        <Card
            title={<h1 style={styles.title}>
                <i
                    style={styles.icon}
                    className="fa fa-industry"
                />
      Criar Operação em Massa
  </h1>}
            extra={ <div>

                </div>}
        >
            <div>
                    <Card>
                    <Steps current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div style={styles.steps_content}>{steps[current].content}</div>
                    <div style={styles.steps_action}>
              
                        {current > 0 && (
                            <Button style={{ marginTop: 10, borderColor: "orange", color: "orange" }} icon={<RollbackOutlined style={{ fontSize: 18 }}/>} onClick={() => prev()}>
                                Voltar
                            </Button>
                        )}
                        {current === 1 && fileInput &&  (
                            <Button style={{ marginTop: 10,marginLeft:3}} type="primary" onClick={() => next()} icon={<RightOutlined style={{ fontSize: 18 }}/>}>
                                Ocorrência
                            </Button>
                        )}
                        {current === 2 && isVerified && fileInput && (
                            <Button style={{ marginTop: 10,marginLeft:3}} type="primary" onClick={() => next()} icon={<RightOutlined style={{ fontSize: 18 }}/>}>
                                Resumo
                            </Button>
                        )}
                        {current === 3 && isVerified && fileInput&&(
                            <Button style={{ marginTop: 10,marginLeft:3}} type="primary" onClick={() => proccessOperation()} icon={<RightOutlined style={{ fontSize: 18 }} />}>
                                Processar
                            </Button>
                        )}
                        
                    </div> 
                    </Card>
                </div>
        </Card>

    )
}
const styles = {
    steps_content: {
        minHeight: 200,
        marginTop: 20,
        padding: 10,
        textAlign: "center",
        backgroundColor: "#fafafa",
        border: "1px dashed #e9e9e9",
        borderRadius: 2,
      },
      steps_action: {
        marginTop: 24,
        textAlign:"right"

      },
      container: {
        borderRadius: "10px",
        background: "#fff",
        boxShadow:
          "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
      },

    icon: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 17,
        marginRight: 10,
        fontSize: 22,
        color: "#ffffff"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    title: {
        fontWeight: 900,
        // marginLeft: 20
    },
    
    btnCancel: {
        marginRight: 8
    },
    
};

export default OperationsMassive;

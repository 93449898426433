import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Tooltip,Drawer,Form,Select,AutoComplete,Input,Button,Progress} from "antd";
import { getCustomerByName } from "../../../../store/actions/customerAction";

import {  EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { getResourcesClients} from "../../../../store/actions/resourceLogsAction";
import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import { AllUsers } from "../../../../store/actions/userAction";

const { Option } = Select;
const ResourceLogsClients = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const history = useHistory();
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState();
  const [typeResource, setTypeResource] = useState("");
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [optionsValue, setOptionsValue] = useState("");
  
  const [email, setEmail] = useState();
    const [userId, setUserId] = useState();

    const [optionsValueUsers, setOptionsValueUsers] = useState()
    const [optionsUsers, setOptionsUsers] = useState([]);
    const perPage = 100

  
  const users = useSelector(state => state.user);

  useEffect(() => {
      if (users.users) {
          let options = [];
          users.users.data.map(obj => {
              return options.push({ value: obj.id.toString(), label: obj.email });
          })
          setOptionsUsers(options);
      }
      

  }, [users]);

  useEffect(() => {
    if (email) {
      dispatch(AllUsers(email, perPage))
    }
    dispatch(getResourcesClients(userId,typeResource,customerId, page, pageSize));
    const interval =setInterval(() => {
      dispatch(getResourcesClients(userId,typeResource,customerId, page, pageSize));
    }, 5000);

    
    return () => clearInterval(interval);
    
  }, [dispatch,email, perPage,userId,typeResource,page, customerId, pageSize]);


  useEffect(() => {
    if (customerName) {
      dispatch(getCustomerByName(customerName));
    }
  }, [dispatch, customerName]);

  const customers = useSelector(state => state.customers);

    useEffect(() => {
        if (customers.customers) {
            let options = [];
            customers.customers.data.map(obj => {
                return options.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsCustomer(options);
        }
    }, [customers]);

  const { resourceLogs } = useSelector(
    state => state.resourceLogs
  );

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(1);
  };
  function handleChange(value) {
    setTypeResource(value)
  }
  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
  };

  const onShowFilter = () => {
    setShowFilter(true);
  };
  const onCloseFilter = () => {
    setShowFilter(false);
  };
  const onClearFilter = () => {
    setTypeResource("");
    setCustomerName();
    setCustomerId();
};

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => {
            onShowFilter();
          }}
        />
      </Tooltip>

    </div>
  );

  const columns = [
    
    {
      title: "Transação",
      dataIndex: "transactionId"
    },
    {
      title: "Cliente",
      dataIndex: "customerName"
    },
    {
      title: "Total de Linhas",
      dataIndex: "quantTotal"
    },
    {
      title: "Status do Processo",
      dataIndex: "quantLines",
      render: (text, record) => (
        <>
        <Tag color={record.quantLines === record.quantTotal ? "orange" : record.quantLines === 0 ? "green" : "blue"}>
          {record.quantLines === record.quantTotal ? "Em espera" : record.quantLines === 0 ? "Finalizado" : "Processando - "+(record.quantLines)+" Linhas"}
        </Tag>
        <Progress percent={((record.quantTotal-record.quantLines)/record.quantTotal*100).toFixed(2)} size="small" />
        </>
      )

    },
    {
      title: "Recurso",
      dataIndex: "typeResource",
      render: (text, record) => (
       <>         
        {text ==='many-hard-reset'?"Reset":
        text ==='many-enable-sms'?"Ativar SMS":
        text ==='many-disable-sms'?"Desativar SMS":
        text ==='many-change-apn'?"Atribuir APN":
        text ==="many-reset-persist"?"Reset+APN":""
        }
        </>
      )
    },
    
    {
      title: "Data e Hora",
      dataIndex: "created_at",
      render: (text, record) => (
        moment(text).format("DD/MM/YYYY HH:mm:ss")
      )
    },
    {
      title: "Ações",
      key: "action",
      render: (text, record) => (
        <Button
          icon={<EyeOutlined  style={{fontSize:18}}/>}
          style={{borderColor: "green",color:"green"}}
          type="default" 
          onClick={() => {
            history.push({
              pathname: `/line-activities-clients/${record.transactionId}`,
              state: {
                transactionId: record.transactionId,
                email: record.email,
                typeResource: record.typeResource,
                customerName:record.customerName,
                apn:record.apn,
              }
            });
          }}
          size="small"
          >
        Ver processo
      </Button>
       
      )
    }
  ];

  return (
    <div>
     {/* <SkeletonTable show={!loading} title="Operações em Massa" icon="fa fa-industry" pageSize={pageSize}/> */}
      {resourceLogs  &&(
        <HeliosTable
          title="Lista de Operações em Massa - Clientes"
          subTitle={subTitle}
          columns={columns}
          icon="fa fa-industry"
          dataSource={resourceLogs.data}
          rowKey="_id"
          total={resourceLogs.total}
          pageSize={pageSize}
          page={page}
          onChange={handlePaginationChange}
          onShowSizeChange={onShowSizeChange}
        />
      )}

      <Drawer
        title="Filtros"
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        placement="right"
        onClose={onCloseFilter}
        visible={showFilter}
        width={500}
        style={styles.containerDrawer}
      >
        <Form layout="vertical">
          <Form.Item label="CLIENTE">
            <AutoComplete
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerId();
                  setCustomerName();
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                setOptionsValue(option.label);
                setCustomerId(value);
              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item label="Tipo do Recurso">
            <Select defaultValue="Todos" style={{ width: "100%" }} onChange={handleChange}>
              <Option value="many-hard-reset">Resetar Linha em Massa</Option>
              <Option value="many-enable-sms">Ativar SMS em Massa</Option>
              <Option value="many-disable-sms">Desativar SMS em Massa</Option>
              <Option value="many-change-apn">Trocar APN em Massa</Option>
              <Option value="many-reset+change-apn">Resetar e Trocar APN em Massa</Option>
              <Option value="">Todos</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Email do cliente">
                        <AutoComplete
                            options={optionsUsers}
                            value={optionsValueUsers}
                            onChange={value => {
                                if (!value) {
                                    setUserId()
                                    setEmail();
                                }
                            }}
                            onSearch={value => {
                                setOptionsValueUsers(value);
                                const timer = setTimeout(() => {
                                    if (value.length >= 2) {
                                        setEmail(value);
                                    }
                                }, 1000);
                                return () => clearTimeout(timer);
                            }}
                            onSelect={(value, option) => {
                                console.log(value)
                                setOptionsValueUsers(option.label);
                                setUserId(value)
                            }}
                        >
                            <Input.Search
                                size="middle"
                                placeholder="Pesquisar email..."
                                allowClear
                                loading={users.loading}
                            />
                        </AutoComplete>
                    </Form.Item>
        </Form>
        <div style={styles.btnFilter}>
          <Button
            onClick={onClearFilter}
            type="danger"
            ghost
            style={styles.btnCancel}
          >
            LIMPAR
          </Button>
          <Button onClick={onCloseFilter} type="default">
            FECHAR
          </Button>
        </div>
      </Drawer>


    </div>
  );
};

const styles = {
  subTitle: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },
  btnFilter: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    borderTop: "1px solid #e9e9e9",
    padding: "10px 16px",
    background: "#fff",
    textAlign: "right"
},
  icon:{
    marginRight: 5,
    fontSize: 18,
  },
  profile: {
    marginRight: 10,
    //fontWeight: 600,
    fontStyle: "italic"
  }
};

export default ResourceLogsClients;

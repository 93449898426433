import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";


export const getResources = (userId,typeResource,customerId, page, limit,existOs) => dispatch => {
    dispatch(fetchStart(actionType.GET_RESOURCE_LOGS_START));
    // const user_id = localStorage.getItem("history");
    existOs = existOs ===true?1:0
    userId = userId===undefined?"":userId
    console.log("Teset OS: ",existOs)
    axios
        .get(API_ROOT + `resource_logs?userId=${userId}&customerId=${customerId}&page=${page}&typeResource=${typeResource}&limit=${limit}&osId=${existOs}`)

        .then(res => {
            dispatch(fetchSuccess(actionType.GET_RESOURCE_LOGS_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_RESOURCE_LOGS_FAIL, err));

        });

};

export const getResourcesClients = (userId,typeResource,customerId, page, limit) => dispatch => {
    dispatch(fetchStart(actionType.GET_RESOURCE_LOGS_START));
    // const user_id = localStorage.getItem("history");
    userId = userId===undefined?"":userId
    axios
        .get(API_ROOT + `resource_logs_clients?userId=${userId}&customerId=${customerId}&page=${page}&typeResource=${typeResource}&limit=${limit}`)

        .then(res => {
            dispatch(fetchSuccess(actionType.GET_RESOURCE_LOGS_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_RESOURCE_LOGS_FAIL, err));

        });

};


export const getLineLogs = (_id,line,status,page, limit) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));
    
    // console.log(_id,line)
    let nameLine;
    if(line.length>14){
        nameLine="iccid"
    }else{
        nameLine="msisdn"
    }
    axios
        .get(API_ROOT + `line_logs?_id=${_id}&${nameLine}=${line}&status=${status}&page=${page}&limit=${limit}`)

        .then(res => {
            console.log(res.data)
            dispatch(fetchSuccess(actionType.GET_LOGS_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};

export const getLineLogsClients = (transactionId,line,status,page, limit) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));
    
    console.log(transactionId,line)
    let nameLine;
    if(line.length>14){
        nameLine="iccid"
    }else{
        nameLine="msisdn"
    }
    axios
        .get(API_ROOT + `line_logs_clients?transactionId=${transactionId}&${nameLine}=${line}&status=${status}&page=${page}&limit=${limit}`)

        .then(res => {
            console.log(res.data)
            dispatch(fetchSuccess(actionType.GET_LOGS_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};

export const getLineLogsSummarized = (resourceId) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));
    
    
    axios
        .get(API_ROOT + `line_logs/summarized?resourceId=${resourceId}`)

        .then(res => {
            console.log(res.data)
            dispatch(fetchSuccess(actionType.GET_LOGS_SUMMARIZED, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};

export const getLineLogsSummarizedClients = (transactionId) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));
    
    
    axios
        .get(API_ROOT + `line_logs_clients/summarized?transactionId=${transactionId}`)

        .then(res => {

            dispatch(fetchSuccess(actionType.GET_LOGS_SUMMARIZED, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};

export const setLineController = (lote,numberControl,_id,) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));

    axios
        .get(API_ROOT + `resource-controller?lote=${lote}&control=${numberControl}&resourceId=${_id}`)

        .then(res => {
            console.log(res.data)
            dispatch(fetchSuccess(actionType.GET_LINE_CONTROLLER_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};



import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    changePasswordUser,
    clearErrors,
    clearMessage
} from "../../../../store/actions/userAction";

import { Card, Form, Input, Row, Col, Button, notification } from "antd";

import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import Backdrop from "../../../../components/Backdrop/Backdrop";
import styles from "./styles";

// Schema for yup
const validationSchema = Yup.object().shape({

    password: Yup.string()
        .required("A senha é obrigatória.")
        .min(6, "A senha deve ter pelo menos 6 caracteres.")
});

const UpdatePassword = () => {
    const history = useHistory();
    const { state } = history.location;
    const [showBackdrop, setShowBackdrop] = useState(false);
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const { message, errors, loading } = user;
    let userUpdate = null;
    if (state) {
        userUpdate = state;
        console.log(userUpdate)
    }
    useEffect(() => {
        if (errors) {
            errors.map(error => {
                return notification.error({
                    message: error.detail,
                    placement: "topRight"
                });
            });
            dispatch(clearErrors());
        }
        if (message) {
            notification.success({
                message: message,
                placement: "topRight"
            });
            dispatch(clearMessage());
        }
        setShowBackdrop(loading);
    }, [message, errors, loading, dispatch]);

    return (
        <Row justify="center" type="flex">
            <Backdrop show={showBackdrop} loading={true} />
            <Col xs={24} sm={18} xl={16} xxl={10}>
                <Card
                    title=
                    {< h1 style={styles.title}>
                        <i
                            style={styles.subTitle}
                            className="fas fa-key"
                        />
                        Alterar Senha
                    </h1>}

                >
                    <Form layout="vertical">
                        <Formik
                            initialValues={{
                                password: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                console.log("values: ", values);
                                if (state) {
                                    const user = {
                                        password: values.password,

                                    };
                                    dispatch(changePasswordUser(userUpdate.id, user));
                                }
                                // When button submits form and form is in the process of submitting, submit button is disabled
                                setSubmitting(true);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid
                            }) => (
                                    <div>
                                        <Form.Item label="Senha">
                                            <Input.Password
                                                size="large"
                                                type="password"
                                                value={values.password}
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={touched.name && errors.name ? "error" : null}
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                style={{ color: "red" }}
                                            />
                                        </Form.Item>
                                        <Row>
                                            <Col span={24} style={{ textAlign: "right" }}>
                                                <Button
                                                    type="primary"
                                                    ghost
                                                    style={{ marginLeft: 8 }}
                                                    onClick={() => {
                                                        handleSubmit()
                                                    }}
                                                >
                                                    ALTERAR
                      </Button>
                                                <Button
                                                    type="danger"
                                                    ghost
                                                    style={{ marginLeft: 8 }}
                                                    onClick={() => history.push("/user-operation")}
                                                >
                                                    CANCELAR
                      </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                        </Formik>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default UpdatePassword;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../store/actions/authAction";

import { Row, Col, Form, Input, Button } from "antd";
import { UserOutlined } from '@ant-design/icons';
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import Recaptcha from "react-recaptcha";
import logo from "../../assets/img/logo.png";
import { useHistory } from "react-router-dom";


// Schema for yup
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("O e-mail não é válido.")
        .required("E-mail é obrigatório.")
});

const ForgotPassword = () => {
    const [isVerified, setIsVerified] = useState(false);
    const history = useHistory();

    const dispatch = useDispatch();

    const verifyCallback = response => {
        // Here you will get the final recaptchaToken!!!
        if (response) {
            setIsVerified(true);
        }
    };

    const callback = () => { };

    return (
        <div style={styles.container}>
            <Row>

                <Col xs={24} className="login-container">
                    <div style={styles.containerForm}>
                        <Form
                            className="login-form"
                            style={styles.form}
                            autoComplete="off"
                            autoSave="off"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 15
                                }}
                            >
                                <img src={logo} alt="Logo" />
                            </div>
                            <Formik
                                initialValues={{ email: "" }}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    dispatch(forgotPassword(values));
                                    // When button submits form and form is in the process of submitting, submit button is disabled
                                    setSubmitting(true);
                                    resetForm();
                                    // Simulate submitting to database, shows us values submitted, resets form
                                    // setTimeout(() => {
                                    //   alert(JSON.stringify(values, null, 2));
                                    //   resetForm();
                                    //   setSubmitting(false);
                                    // }, 500);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    isValid
                                }) => (
                                        <div>
                                            <Form.Item>
                                                <Input
                                                    size="large"
                                                    prefix={
                                                        <UserOutlined
                                                            style={{ color: "rgba(0,0,0,.25)" }}
                                                        />
                                                    }
                                                    type="email"
                                                    value={values.email}
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={
                                                        touched.email && errors.email ? "error" : null
                                                    }
                                                    placeholder="Email"
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                            <Recaptcha
                                                //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //# key localhost
						sitekey="6Le7ixUiAAAAAH1ZsHxNblEteX81wdp4QdVFHQyu" //# key nlt-iot.com
                                                render="explicit"
                                                type="image"
                                                hl="pt-BR"
                                                onloadCallback={callback}
                                                verifyCallback={verifyCallback}
                                                theme="light"
                                                size="normal"
                                                badge="bottomleft"
                                            />

                                            <Form.Item>
                                                <Button
                                                    style={styles.button}
                                                    size="default"
                                                    type="primary"
                                                    ghost
                                                    htmlType="submit"
                                                    className="login-form-button"
                                                    disabled={!isVerified || !isValid}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isSubmitting ? "Loading..." : "REDEFINIR SENHA"}
                                                </Button>
                                                <Button
                                                    style={styles.button}
                                                    size="default"
                                                    type="default"
                                                    className="login-form-button"
                                                    onClick={() => history.push("/login")}
                                                >
                                                    Voltar
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    )}
                            </Formik>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const styles = {
    container: {
        marginLeft: "-15px",
        background: "rgb(245, 242, 242)"
    },
    containerForm: {
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center"
    },
    form: {
        width: "30%",
        padding: 30,
        borderRadius: "10px",
        background: "#fff",
        //boxShadow:"3px 4px 4px 7px rgba(0,0,0,0.39)"
        boxShadow:
            "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },
    image: {
        height: "100vh",
        width: "100%",
        opacity: "0.5"
    },
    backdropImage: {
        backgroundColor: "#000"
    },
    button: {
        marginTop: 20,
        marginLeft: 10
    }
};

export default ForgotPassword;

import axios from "axios";
import * as type from "../types";
import { API_ROOT } from "../../config/api-config";

import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";

export const getDevices = (query, offset, limit) => dispatch => {
  dispatch(fetchStart(type.GET_DEVICES_START));
  axios
    .get(API_ROOT + `devices`, {
      params: {
        query: query,
        offset: offset,
        limit: limit
      }
    })
    .then(res => {
      dispatch(fetchSuccess(type.GET_DEVICES_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(type.GET_DEVICES_FAIL, error));
    });
};
export const getDevicesDash = (query, offset, limit) => dispatch => {
  dispatch(fetchStart(type.GET_DEVICES_START));
  axios
    .get(API_ROOT + `devices`, {
      params: {
        query: query,
        offset: offset,
        limit: limit
      }
    })
    .then(res => {
      let list
      if (query) { list = res.data }
      dispatch(fetchSuccess(type.GET_DEVICES_SUCCESS, list));
    })
    .catch(error => {
      dispatch(fetchError(type.GET_DEVICES_FAIL, error));
    });
};

export const getDevicesNLT = () => dispatch => {
  dispatch(fetchStart(type.GET_DEVICES_START_NLT));
  axios
    .get(API_ROOT + `devices`, {
      params: {

      }
    })
    .then(res => {
      console.log(res.data)
      dispatch(fetchSuccess(type.GET_DEVICES_SUCCESS_NLT, res.data));
    })
    .catch(error => {
      dispatch(fetchError(type.GET_DEVICES_FAIL_NLT, error));
    });
};

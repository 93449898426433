import React from 'react'
export const columns = [
  {
    title: "Razão Social",
    dataIndex: "customerName",
    // render: (text, obj) => <span style={{ min: 100 }}> {text}</span>,
    width: 200,
    ellipsis: true,
  },
  {
    title: "MSISDN",
    dataIndex: "lineMsisdn",
    width: 180,
    ellipsis: true,
  },
  {

    title: "Compartilhado",
    dataIndex: "planAggregate",
    ellipsis: true,
    width: 130,
    render: (text, obj) => <span>{text === true ? "Sim" : "Não"}</span>

  },
  {

    title: "Upload",
    dataIndex: "lineUploadF",
    ellipsis: true,
    width: 130,
  },
  {

    title: "Download",
    dataIndex: "lineDownloadF",
    ellipsis: true,
    width: 130,
  },
  {

    title: "Total",
    dataIndex: "lineTotalF",
    ellipsis: true,
    width: 130,
  },
  {

    title: "Upload Compartilhado",
    dataIndex: "itemUploadF",
    ellipsis: true,
    width: 130,
  },
  {
    title: "Download Compartilhado",
    dataIndex: "itemDownloadF",
    ellipsis: true,
    width: 130,
  },
  {

    title: "Total Compartilhado",
    dataIndex: "itemTotalF",
    ellipsis: true,
    width: 130,
  }

];

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button} from "antd";

import {  EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import { getTransactionResource } from "../../../../store/actions/transactionLogsAction";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";

const TransactionLogs = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const history = useHistory();


 
  useEffect(() => {
    
    dispatch(getTransactionResource(page-1, pageSize));
   
    
  }, [dispatch,page, pageSize]);



  const { transactionLogs,loading } = useSelector(
    state => state.transactionLogs
  );

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(current);
  };

  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);

  };




  const subTitle = (
    <div>
     

    </div>
  );

  const columns = [
    
    {
      title: "Transação",
      dataIndex: "transaction_id"
    },
    {
      title: "Cliente",
      dataIndex: "client"
    },
    
    {
      title: "Recurso",
      dataIndex: "queue_type",
      render: (text, record) => (
       <>         
        {text ==='recicle'?"Troca de Simcard":""}
        </>
      )
    },
    
    {
      title: "Início",
      dataIndex: "initial_date",
      render: (text, record) => (
        moment(text).format("DD/MM/YYYY HH:mm:ss")
      )
    },
   
    {
      title: "Ações",
      key: "action",
      render: (text, record) => (
        <Button
          icon={<EyeOutlined  style={{fontSize:18}}/>}
          style={{borderColor: "green",color:"green"}}
          type="default" 
          onClick={() => {
            history.push({
              pathname: `/transaction-line-activities/${record.transaction_id}`,
              state: {
                transaction_id: record.transaction_id,
                client: record.client,
                queue_type:record.queue_type,
                // os_id: record.queue_type
              }
            });
          }}
          size="small"
          >
        Ver processo
      </Button>
       
      )
    }
  ];

  return (
    <div>
    <SkeletonTable show={loading} title="Lista de Reciclagens de Simcards" icon="fab fa-phabricator" pageSize={pageSize}/>
      {transactionLogs  && !loading ? (
        <HeliosTable
          title="Lista de Reciclagens de Simcards"
          subTitle={subTitle}
          columns={columns}
          icon="fab fa-phabricator"
          dataSource={transactionLogs.data}
          rowKey="transaction_id"
          total={transactionLogs.total}
          pageSize={pageSize}
          page={page}
          onChange={handlePaginationChange}
          onShowSizeChange={onShowSizeChange}
        />
      ):""}


    </div>
  );
};

// const styles = {
//   subTitle: {
//     marginRight: 10,
//     fontSize: 18,
//     cursor: "pointer"
//   },
//   btnFilter: {
//     position: "absolute",
//     right: 0,
//     bottom: 0,
//     width: "100%",
//     borderTop: "1px solid #e9e9e9",
//     padding: "10px 16px",
//     background: "#fff",
//     textAlign: "right"
// },
//   icon:{
//     marginRight: 5,
//     fontSize: 18,
//   },
//   profile: {
//     marginRight: 10,
//     //fontWeight: 600,
//     fontStyle: "italic"
//   }
// };

export default TransactionLogs;

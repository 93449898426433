import * as actionType from "../types";

const initialState = {
  users: null,
  errors: null,
  message: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.GET_USERS_START:
      return {
        ...state,
        loading: true
      };
    case actionType.GET_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
        errors: null,
        loading: false
      };
    case actionType.GET_USERS_FAIL:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };
    case actionType.CREATE_USERS_START:
      return {
        ...state,
        loading: false,
        message: action.payload
      };
    case actionType.DELETE_USERS_SUCCESS:
      return {
        ...state,
        message: action.message,
        loading: false,
        users: {
          ...state.users,
          data: state.users.data.filter(user => user.id !== action.userId)
        }
      };
    case actionType.UPDATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload
      };
    case actionType.ENABLE_DISABLE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload
      };
    case actionType.CLEAR_ERRORS_USER:
      return {
        ...state,
        errors: null
      };
    case actionType.CLEAR_MESSAGE_USER:
      return {
        ...state,
        message: null
      };
    default:
      return state;
  }
}

import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
import { notification, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import HeliosTableAtr from "../../components/HeliosTable/HeliosTableAtr";

const { info } = Modal;
const placement = "bottomRight"

export const columns = [
    {
        title: "MSISDN",
        dataIndex: "line"
    },
    {
        title: "Resultado",
        dataIndex: "result"
    }]

export const getSmsActive = object => dispatch => {
    dispatch(fetchStart(actionType.SMS_LINE_START));
    notification.open({
        key: 'loadingSmsMo',
        message: 'Efetuando operação de Habilitar o SMS da linha ',
        description:
            'Em instantes você receberá a resposta do pedido da linha ' + object.msisdn,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/sms", { "status": "enable", "msisdn": object.msisdn })
        .then(res => {
            if (res.data) {
                console.log(res.data)
                notification.close('loadingSmsMo')
                if (res.data.response.length === 0) {
                    notification.error({
                        message: 'Erro no pedido!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                        duration: 60,
                        placement
                    });
                }
                if (res.data.response[0].description === "OK" || res.data.response[0].description === "Ok") {
                    notification.success({
                        message: 'Operação realizada com sucesso!',
                        description:
                            'A linha ' + object.msisdn + ' está com o SMS Habilitado',
                        duration: 60,
                        placement
                    });
                }
                else if (res.data.response[0].description !== "OK") {
                    notification.warning({
                        message: 'A linha está com falhas na Operadora!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia',
                        duration: 60,
                        placement
                    });
                }
            }
            dispatch(fetchSuccess(actionType.SMS_LINE_SUCCESS, res.data));
        })
        .catch(err => {
            notification.close('loadingSmsMo')
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.SMS_LINE_ERROR, err));
        });
};
export const getManySmsActive = file => dispatch => {
    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.SMS_LINE_START));
    notification.open({
        key: 'loadingManySmsMo',
        message: 'Efetuando operação massiva de Habilitar o SMS das linhas ',
        description:
            'Em instantes você receberá a resposta do pedido da linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/many-enable-sms", formData, config)
        .then(res => {
            console.log(res.data)
            notification.close('loadingManySmsMo')
            if (res.data.response.length === 0) {
                notification.error({
                    message: 'Erro no pedido!',
                    description:
                        'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                    duration: 60,
                    placement
                });
            }
            if (res.data.response.length !== 0) {

                info({
                    title: "",
                    width: 600,
                    content: <HeliosTableAtr
                        title="Resultado das linhas"
                        columns={columns}
                        dataSource={res.data.response[0] || []}
                        rowKey="line"
                    />,
                    okText: "Fechar",
                    onOk() {
                    }
                })
            }
            dispatch(fetchSuccess(actionType.SMS_LINE_SUCCESS, res.data));
        })
        .catch(err => {
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.SMS_LINE_ERROR, err));
        });
};

export const getSmsInactive = object => dispatch => {
    dispatch(fetchStart(actionType.SMS_LINE_START));
    notification.open({
        key: 'loadingSmsMo',
        message: 'Efetuando operação de Desabilitar o SMS da Linha ',
        description:
            'Em instantes você receberá a resposta do pedido da linha ' + object.msisdn,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/sms", { "status": "disable", "msisdn": object.msisdn })
        .then(res => {
            if (res.data) {
                console.log(res.data)
                notification.close('loadingSmsMo')
                if (res.data.response.length === 0) {
                    notification.error({
                        message: 'Erro no pedido!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                        duration: 60,
                        placement
                    });
                }
                if (res.data.response[0].description === "OK" || res.data.response[0].description === "Ok") {
                    notification.success({
                        message: 'Operação realizada com sucesso!',
                        description:
                            'A linha ' + object.msisdn + ' está com o SMS Desabilitado',
                        duration: 60,
                        placement
                    });
                }
                else if (res.data.response[0].description !== "OK") {
                    notification.warning({
                        message: 'A linha está com falhas na Operadora!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia',
                        duration: 60,
                        placement
                    });
                }
            }
            dispatch(fetchSuccess(actionType.SMS_LINE_SUCCESS, res.data));
        })
        .catch(err => {
            notification.close('loadingSmsMo')
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!' + err,
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.SMS_LINE_ERROR, err));
        });
};
export const getManySmsInactive = file => dispatch => {
    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.SMS_LINE_START));
    notification.open({
        key: 'loadingManySmsMo',
        message: 'Efetuando operação massiva de Desabilitar o SMS das Linhas ',
        description:
            'Em instantes você receberá a resposta do pedido das linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/many-disable-sms", formData, config)
        .then(res => {
            console.log(res.data)
            notification.close('loadingManySmsMo')
            if (res.data.response.length === 0) {
                notification.error({
                    message: 'Erro no pedido!',
                    description:
                        'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                    duration: 60,
                    placement
                });
            }
            if (res.data.response.length !== 0) {

                info({
                    title: "",
                    width: 600,
                    content: <HeliosTableAtr
                        title="Resultado das linhas"
                        columns={columns}
                        dataSource={res.data.response[0] || []}
                        rowKey="line"
                    />,
                    okText: "Fechar",
                    onOk() {
                    }
                })
            }

            dispatch(fetchSuccess(actionType.SMS_LINE_SUCCESS, res.data));
        })
        .catch(err => {
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.SMS_LINE_ERROR, err));
        });
};

export const getManySmsAWS = (customerId, customerName, sms, file,motive,protocol,email) => dispatch => {
    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    formData.append("customerId", customerId)
    formData.append("customerName", customerName)
    formData.append("status", sms)
    formData.append("motive", motive)
    formData.append("protocol", protocol)
    formData.append("email", email)


    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.SMS_LINE_START));
    notification.open({
        key: 'loadingManySmsMo',
        message: 'Efetuando operação massiva de Habilitar o SMS das linhas ',
        description:
            'Em instantes você receberá a resposta do pedido da linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/many-sms", formData, config)
        .then(res => {
            notification.close('loadingManySmsMo')
            dispatch(fetchSuccess(actionType.SMS_LINE_SUCCESS, res.data));
            dispatch(fetchStart(actionType.SMS_LINE_CLEAR));

        })
        .catch(err => {
            notification.error({
                message: err.response.data.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.SMS_LINE_ERROR, err));

        });
};

export const getSmsAWS = (object, sms) => dispatch => {
    dispatch(fetchStart(actionType.SMS_LINE_START));
    let status = sms === "enable" ? "Habilitar" : "Desabilitar"
    notification.open({
        key: 'loadingSmsMo',
        message: "Efetuando operação de " + status + " o SMS da linha ",
        description:
            'Em instantes você receberá a resposta do pedido da linha ' + object.msisdn,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/sms-aws", { "status": sms, "msisdn": [object.msisdn] })
        .then(res => {
            if (res.data) {
                console.log(res.data)
                notification.close('loadingSmsMo')
                if (res.data.response.length === 0) {
                    notification.error({
                        message: 'Erro no pedido!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                        duration: 60,
                        placement
                    });
                }
                if (res.data.response[0][0].description === "OK" || res.data.response[0][0].description === "Ok") {
                    notification.success({
                        message: 'Operação realizada com sucesso!',
                        description:
                            'A linha ' + object.msisdn + ' teve resultado OK',
                        duration: 60,
                        placement
                    });
                }

                else {
                    notification.warning({
                        message: 'A linha está com falhas na Operadora!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia',
                        duration: 60,
                        placement
                    });
                }
            }
            dispatch(fetchSuccess(actionType.SMS_LINE_SUCCESS, res.data));
        })
        .catch(err => {
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.SMS_LINE_ERROR, err));
        });
};


export const getSMSAndChangeAPN = (object, apn, sms) => dispatch => {
    dispatch(fetchStart(actionType.CHANGE_APN_LINE_START));
    const status = sms==="enable"?"Ativar":"Desativar"
    notification.open({
        key: 'loadingSmsMo',
        message: "Efetuando operação de " + status + " o SMS da linha e Mudança de APN",
        description:
            'Em instantes você receberá a resposta do pedido da linha ' + object.msisdn,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/sms", { "apn": apn, "status": sms, "msisdn": object.msisdn })
        .then(res => {
            if (res.data) {
                console.log(res.data)
                if (res.data.response.length === 0) {
                    notification.close('loadingSmsMo')
                    notification.error({
                        message: 'Erro no pedido!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                        duration: 60,
                        placement
                    });

                }
                if (res.data.response[0].description === "OK" || res.data.response[0].description === "Ok") {

                    notification.close('loadingSmsMo')
                    notification.success({
                        message: 'Operação realizada com sucesso!',
                        description:
                            'A linha ' + object.msisdn + ' está com a operação de ' + status + ' SMS concluída e a APN ' + apn,
                        duration: 30,
                        placement
                    });
                    dispatch(fetchSuccess(actionType.CHANGE_APN_LINE_SUCCESS, res.data));
                    dispatch(fetchStart(actionType.CHANGE_APN_LINE_CLEAR));
                }
                else {
                    notification.close('loadingSmsMo')
                    notification.warning({
                        message: 'A linha está com falhas na Operadora!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia',
                        duration: 30,
                        placement
                    });
                }
            }
        })
        .catch(err => {
            notification.close('loadingChangeApn')
            notification.error({
                message: err.response.data.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.CHANGE_APN_LINE_ERROR, err));
        });

};

export const getManySmsAndChangeAPNClient = (customerId, customerName, sms, apn, file,motive,protocol,email) => dispatch => {
    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    formData.append("customerId", customerId)
    formData.append("customerName", customerName)
    formData.append("status", sms)
    formData.append("apn", apn)

    formData.append("email", email)
    formData.append("protocol", protocol)
    formData.append("motive", motive)
    


    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.SMS_LINE_START));
    notification.open({
        key: 'loadingManySmsMo',
        message: 'Efetuando operação massiva de SMS e adicionar a APN das linhas ',
        description:
            'Em instantes você receberá a resposta do pedido da linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/many-sms", formData, config)
        .then(res => {
            notification.close('loadingManySmsMo')
            dispatch(fetchSuccess(actionType.SMS_LINE_SUCCESS, res.data));
            dispatch(fetchStart(actionType.SMS_LINE_CLEAR));

        })
        .catch(err => {
            notification.error({
                message: err.response.data.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.SMS_LINE_ERROR, err));

        });
};
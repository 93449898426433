import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/actions/authAction";

import { Row, Col, Carousel, Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import Recaptcha from "react-recaptcha";

import agro from "../../assets/img/agrobusiness.jpg";
import health from "../../assets/img/healthcare.jpg";
import indu1 from "../../assets/img/industry4_1.jpg";
import smart from "../../assets/img/smart_cities.jpg";
import logo from "../../assets/img/logo.png";
import { useHistory } from "react-router-dom";


const settings = {
  dots: true,
  effect: "fade",
  infinite: true,
  speed: 900,
  slidesToShow: 1,
  slidesToScroll: 1
};

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("O e-mail não é válido.")
    .required("E-mail é obrigatório."),
  password: Yup.string()
    .required("A senha é obrigatória.")
    .min(6, "A senha deve ter mais de 6 caracteres.")
});

const Login = () => {
  const history = useHistory();

  const [isVerified, setIsVerified] = useState(false);

  const dispatch = useDispatch();

  const verifyCallback = response => {
    // Here you will get the final recaptchaToken!!!
    if (response) {
      setIsVerified(true);
    }
  };

  const callback = () => { };

  return (
    <div style={styles.container}>
      <Row>
        <Col xs={10} className="carousel-container">
          <Carousel autoplay {...settings} className="carousel-inner">
            <div className="carousel-item" style={styles.backdropImage}>
              <img src={agro} alt="Agro Business" />
              <div className="carousel-caption">
                <h3>Agro Business</h3>
                <p>
                  Um dos setores da economia que mais cresce atualmente, exige
                  cada vez mais eficiência. A implantação de soluções IoT para o
                  agrobusiness têm se mostrado a melhor alternativa para obter
                  ganhos de produtividade.
                </p>
              </div>
            </div>

            <div className="carousel-item" style={styles.backdropImage}>
              <img src={health} alt="Telemedicina" />
              <div className="carousel-caption">
                <h3>Health Care</h3>
                <p>
                  Muito além das fronteiras. As soluções IoT voltadas para a
                  telemedicina são disruptivas e capazes de levar alto padrão de
                  medicina a lugares antes remotos e inacessíveis
                </p>
              </div>
            </div>

            <div className="carousel-item" style={styles.backdropImage}>
              <img src={indu1} alt="Industria 4.0" />
              <div className="carousel-caption">
                <h3>Industry 4.0</h3>
                <p>
                  As soluções IoT podem ser o diferencial no ganho de
                  produtividade e também a porta de entrada na indústria 4.0.
                </p>
              </div>
            </div>

            <div className="carousel-item" style={styles.backdropImage}>
              <img src={smart} alt="Smartcities" />
              <div className="carousel-caption">
                <h3>Smartcities</h3>
                <p>
                  Com a popularização de facilidades de mobilidade urbana,
                  sistemas de segurança e medição remota de energia, água e gás,
                  a integração e automação através de soluções IoT tornaram-se
                  indispensáveis para as cidades inteligentes.
                </p>
              </div>
            </div>
          </Carousel>
        </Col>
        <Col xs={14} className="login-container">
          <div style={styles.containerForm}>
            <Form
              className="login-form"
              style={styles.form}
              autoComplete="off"
              autoSave="off"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 15
                }}
              >
                <img src={logo} alt="Logo" />
              </div>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  dispatch(loginUser(values));
                  // When button submits form and form is in the process of submitting, submit button is disabled
                  setSubmitting(true);
                  resetForm();
                  // Simulate submitting to database, shows us values submitted, resets form
                  // setTimeout(() => {
                  //   alert(JSON.stringify(values, null, 2));
                  //   resetForm();
                  //   setSubmitting(false);
                  // }, 500);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid
                }) => (
                    <div>
                      <Form.Item>
                        <Input
                          size="large"
                          prefix={
                            <UserOutlined
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          type="email"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.email && errors.email ? "error" : null
                          }
                          placeholder="Username"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input.Password
                          size="large"
                          prefix={
                            <LockOutlined
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.password && errors.password ? "error" : null
                          }
                          placeholder="Password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Form.Item>
                      <Recaptcha
                        //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" //# key localhost
			sitekey="6Le7ixUiAAAAAH1ZsHxNblEteX81wdp4QdVFHQyu" //# key nlt-iot.com
                        render="explicit"
                        type="image"
                        hl="pt-BR"
                        onloadCallback={callback}
                        verifyCallback={verifyCallback}
                        theme="light"
                        size="normal"
                        badge="bottomleft"
                      />

                      <div style={{ marginTop: 15 }}>
                        <span>
                          {/* Esqueci minha senha{" "}
                           */}
                          <Button type="link" onClick={() => history.push("/forgot-password")}>
                            Esqueci minha senha
                          </Button>
                          {/* <Menu.Item key="teste" onClick={() => history.push("/forgot-password")}>
                            <QuestionOutlined />
        Esqueci minha senha
      </Menu.Item> */}
                        </span>
                      </div>

                      <Form.Item>
                        <Button
                          style={styles.button}
                          size="default"
                          type="primary"
                          ghost
                          htmlType="submit"
                          className="login-form-button"
                          disabled={!isVerified || !isValid}
                          onClick={() => handleSubmit()}
                        >
                          {isSubmitting ? "Loading..." : "ACESSAR"}
                        </Button>
                      </Form.Item>
                    </div>
                  )}
              </Formik>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  container: {
    marginLeft: "-15px",
    background: "rgb(245, 242, 242)"
  },
  containerForm: {
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center"
  },
  form: {
    width: "70%",
    padding: 30,
    borderRadius: "10px",
    background: "#fff",
    //boxShadow:"3px 4px 4px 7px rgba(0,0,0,0.39)"
    boxShadow:
      "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  },
  image: {
    height: "100vh",
    width: "100%",
    opacity: "0.5"
  },
  backdropImage: {
    backgroundColor: "#000"
  },
  button: {
    marginTop: 20
  }
};

export default Login;

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSVLink,  } from "react-csv";
import moment from "moment";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { 
  Tag, 
  Tooltip, 
  Drawer, 
  Form, 
  Select, 
  AutoComplete, 
  Input, 
  Button, 
  Progress,
  notification
} from "antd";

import HeliosTable from "../../../../components/HeliosTable/HeliosTable";

// Actions
import { getCustomerByName } from "../../../../store/actions/customerAction";
import { getResources } from "../../../../store/actions/resourceLogsAction";
import { AllUsers } from "../../../../store/actions/userAction";

// Hooks & Services
import {useAsyncFunc} from '../../../../hooks';
import { exportOrderServiceReport } from '../../../../services/helios/orderServices';
import {
  getOrderServiceReportHeaders,
  getOrderServiceType,
  getOrderServicesReportName,
} from '../../../../helpers/get-reports-config';

const resourceTypes = {
  stock_os_with_booking_numbering: 'stock',
  stock_os_with_msisdn: 'stock',
  on_demand_os: 'on-demand',
  active_stock_os_with_booking_numbering: 'stock'
};

const styles = {
  subTitle: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },

  btnFilter: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    borderTop: "1px solid #e9e9e9",
    padding: "10px 16px",
    background: "#fff",
    textAlign: "right"
  },

  icon: {
    marginRight: 5,
    fontSize: 18,
  },

  profile: {
    marginRight: 10,
    fontStyle: "italic"
  }
};

// #startregion [PRIVATE COMPONENTS]
const DownloadReportButton = ({record}) => {
  // Hooks & Estoaos
  const [exportDataState, exportDataRequest] = useAsyncFunc(exportOrderServiceReport);
  const [data, setData] = useState([]);

  const {
    _id: resourceId, 
    typeResource = '', 
    operationCategory, 
    rootOs,
    customerId
  } = record;
  
  const fileHeaders = getOrderServiceReportHeaders(typeResource);
  const reportSubType = getOrderServiceType(typeResource);
  const reportName = getOrderServicesReportName(typeResource);
  
  // Funções
  const downloadOperationReport = async () => {
    await exportDataRequest({
      rootOs,
      reportType: operationCategory,
      reportSubType,
      customerId
    });
  };

  // Efeitos
  useEffect(() => {
    setData(exportDataState.data?.data);

    if (exportDataState.error) {
      notification.error({
        message: 'Erro no download',
        duration: 20,
        placement: 'bottomRight'
      });
    }
  }, [exportDataState]);

  return (
    <>
      {data?.length > 0 ? 
      (
        <CSVLink
          key={resourceId}
          headers={fileHeaders}
          data={data}
          filename={`relatorio_${reportName}_${Date.now()}.csv`}
          separator=";"
        >
          Baixar relatório completo
        </CSVLink>
      ):
      (
        <Tooltip placement="top" title="Baixar resultado completo da operação">
          <Button
            key={resourceId}
            icon={<DownloadOutlined 
            style={{ fontSize: 18 }} />}
            style={{ borderColor: "orange", color: "orange", marginLeft: 3, marginBottom: 5 }}
            type="default"
            size="small"
            loading={exportDataState.loading}
            onClick={downloadOperationReport}
          />
        </Tooltip>
      )}
    </>
  );
};

DownloadReportButton.prototype = {
  rowData: PropTypes.object
};
// #endregion [PRIVATE COMPONENTS]

const ResourceLogsOs = () => {
  const customers = useSelector(state => state.customers);
  const users = useSelector(state => state.user);
  const { resourceLogs } = useSelector(state => state.resourceLogs);

  const dispatch = useDispatch();
  const history = useHistory();
  
  const { Option } = Select;

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState();
  const [typeResource, setTypeResource] = useState("");
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [optionsValue, setOptionsValue] = useState("");

  const [email, setEmail] = useState();
  const [userId, setUserId] = useState();

  const [optionsValueUsers, setOptionsValueUsers] = useState()
  const [optionsUsers, setOptionsUsers] = useState([]);

  // Efeitos
  useEffect(() => {
    if (users.users) {
      const options = [];
      
      users.users.data.map(obj => {
        return options.push({ value: obj.id.toString(), label: obj.email });
      })
      setOptionsUsers(options);
    }
  }, [users]);

  useEffect(() => {
    if (email) {
      dispatch(AllUsers(email, 100));
    }

    dispatch(getResources(userId, typeResource, customerId, page, pageSize, true));
    
    const interval = setInterval(() => {
      dispatch(getResources(userId, typeResource, customerId, page, pageSize, true));
    }, 5000);

    return () => clearInterval(interval);

  }, [dispatch, email, userId, typeResource, page, customerId, pageSize]);

  useEffect(() => {
    if (customerName) {
      dispatch(getCustomerByName(customerName));
    }
  }, [dispatch, customerName]);

  useEffect(() => {
    if (customers.customers) {
      const options = [];
      
      customers.customers.data.map(obj => {
        return options.push({ value: obj.id.toString(), label: obj.name });
      });

      setOptionsCustomer(options);
    }
  }, [customers]);

  
  // Funções
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const handleChange = (value) => {
    setTypeResource(value);
  }

  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
  };

  const onShowFilter = () => {
    setShowFilter(true);
  };

  const onCloseFilter = () => {
    setShowFilter(false);
  };

  const onClearFilter = () => {
    setTypeResource("");
    setCustomerName();
    setCustomerId();
  };

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={onShowFilter}
        />
      </Tooltip>
    </div>
  );

  const columns = [
    {
      title: "Lote",
      dataIndex: "lote"
    },
    {
      title: "Cliente",
      dataIndex: "customerName"
    },
    {
      title: "Total de Linhas",
      dataIndex: "quantTotal"
    },
    {
      title: "Status do Processo",
      dataIndex: "quantLines",
      render: (text, record) => {
        const color = record.statusProgress === "In Progress" ? "purple" : record.quantLines === record.quantTotal ? "orange" : record.quantLines === 0 ? "green" : record.quantLines === -1 ? "orange" : "blue";
        const status = record.statusProgress === "In Progress" ? "Em progresso" : record.quantLines === record.quantTotal ? "Em espera" : record.quantLines === 0 ? "Finalizado" : record.quantLines === -1 ? "Pausado" : "Processando - " + (record.quantLines) + " Linhas"; 

        return (
          <>
            <Tag color={color}>
              {status}
            </Tag>
            <Progress percent={((record.quantTotal - record.quantLines) / record.quantTotal * 100).toFixed(2)} size="small" />
          </>
        );
      }
    },
    {
      title: "Recurso",
      dataIndex: "typeResource",
      render: (text) => {
        const resourceTypes = {
          'many-suspension-client': 'Suspensão a Pedido',
          'many-suspension-rob': 'Suspensão por Perda/Roubo',
          'many-suspension-quarentine': 'Suspensão Quarentena',
          'reserve-numeration': 'Reserva de Numeração',
          'many-suspension-temporary': 'Suspensão Temporária',
          'advanced-stock': 'Estoque Avançado',
          reactivation: 'Reativação',
          on_demand_os: 'Os Sob Demanda',
          stock_os_with_booking_numbering: 'Os com Estoque Avançado', // estoque com Reserva de Numeração
          stock_os_with_msisdn: 'Os com Estoque Avançado',
          active_stock_os_with_booking_numbering: 'Os com Estoque Ativado',
        };
        
        return (
        <>
          {resourceTypes[`${text}`] || ""}
        </>
      )}
    },
    {
      title: "Data e Hora",
      dataIndex: "created_at",
      render: (text) => (moment(text).format("DD/MM/YYYY HH:mm:ss"))
    },
    {
      title: 'OS Pai',
      dataIndex: 'rootOs'
    },
    {
      title: "Arquivo",
      dataIndex: "nameFile"
    },
    {
      title: "Ações",
      key: "action",
      render: (record) => {
        const {typeResource = ''} = record;
        const showDowloadReportButton = Object.keys(resourceTypes).includes(typeResource);
        
        return (
          <>
            {showDowloadReportButton && (
              <DownloadReportButton
                key={record?._id}
                record={record}
              />
            )}

            <Button
              icon={<EyeOutlined style={{ fontSize: 18 }} />}
              style={{ borderColor: "green", color: "green", marginLeft: 3 }}
              type="default"
              onClick={() => {
                history.push({
                  pathname: `/line-activities-os/${record._id}`,
                  state: {
                    _id: record._id,
                    nameFile: record.nameFile,
                    email: record.email,
                    lote: record.lote,
                    typeResource: record.typeResource,
                    customerName: record.customerName,
                    apn: record.apn,
                    motive: record.motive,
                    osId: record.osId,
                    emailClient: record.emailClient,
                    protocol: record.protocol,
                    statusProgress: record.statusProgress,
                    quantTotal: record.quantTotal,
                    rootOs: record?.rootOs
                  }
                });
              }}
              size="small"
            >
              Ver processo
            </Button >
          </>
      );
    }
    }
  ];

  return (
    <div>
      {resourceLogs && (
        <HeliosTable
          title="Lista de Operações em Massa com OS"
          subTitle={subTitle}
          columns={columns}
          icon="fa fa-industry"
          dataSource={resourceLogs.data}
          rowKey="_id"
          total={resourceLogs.total}
          pageSize={pageSize}
          page={page}
          onChange={handlePaginationChange}
          onShowSizeChange={onShowSizeChange}
        />
      )}

      <Drawer
        title="Filtros"
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        placement="right"
        onClose={onCloseFilter}
        visible={showFilter}
        width={500}
        style={styles.containerDrawer}
      >
        <Form layout="vertical">
          <Form.Item label="CLIENTE">
            <AutoComplete
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerId();
                  setCustomerName();
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                setOptionsValue(option.label);
                setCustomerId(value);
              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item label="Tipo do Recurso">
            <Select defaultValue="Todos" style={{ width: "100%" }} onChange={handleChange}>
              <Option value="many-suspension-client">Suspensão a Pedido do Cliente</Option>
              <Option value="many-suspension-rob">Suspensão por Perda/Roubo</Option>
              <Option value="many-suspension-quarentine">Suspensão por Quarentena</Option>
              <Option value="many-suspension-temporary">Suspensão Temporária</Option>
              <Option value="reserve-numeration">Reserva de Numeração</Option>
              <Option value="reactivation">Reativação</Option>

              <Option value="on_demand_os">Estoque sob demanda</Option>
              <Option value="stock_os_with_booking_numbering">Estoque avançado</Option>
              <Option value="active_stock_os_with_booking_numbering">Estoque ativado</Option>

              <Option value="">Todos</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Email do Operador">
            <AutoComplete
              options={optionsUsers}
              value={optionsValueUsers}
              onChange={value => {
                if (!value) {
                  setUserId()
                  setEmail();
                }
              }}
              onSearch={value => {
                setOptionsValueUsers(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setEmail(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                console.log(value)
                setOptionsValueUsers(option.label);
                setUserId(value)
              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar email..."
                allowClear
                loading={users.loading}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
        <div style={styles.btnFilter}>
          <Button
            onClick={onClearFilter}
            type="danger"
            ghost
            style={styles.btnCancel}
          >
            LIMPAR
          </Button>
          <Button onClick={onCloseFilter} type="default">
            FECHAR
          </Button>
        </div>
      </Drawer>
    </div>
  );
};



export default ResourceLogsOs;

import axios from "axios";
import * as type from "../types";
import { API_ROOT } from "../../config/api-config";

import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";


export const countDevicesConsumptionNLT = () => dispatch => {
    dispatch(fetchStart(type.COUNT_DEVICES_START_NLT));
    axios
        .get(API_ROOT + `devices`, {
            params: {
            }
        })
        .then(res => {
            let uplinkNLT = 0;
            let downlinkNLT = 0;
            let totalConsumptionNLT = 0;
            if (res.data) {
                const listDevicesNLT = res.data.devices

                for (var i = 0; i < listDevicesNLT.length; i++) {
                    downlinkNLT = downlinkNLT + listDevicesNLT[i].counter_down
                    uplinkNLT = uplinkNLT + listDevicesNLT[i].counter_up
                    totalConsumptionNLT = downlinkNLT + uplinkNLT
                }
            }
            let consumptionNLT = {
                uplinkNLT: uplinkNLT,
                downlinkNLT: downlinkNLT,
                totalNLT: totalConsumptionNLT,

            };
            dispatch(fetchSuccess(type.COUNT_DEVICES_SUCCESS_NLT, consumptionNLT));
        })
        .catch(error => {
            dispatch(fetchError(type.COUNT_DEVICES_FAIL_NLT, error));
        });
};
export const countDevicesConsumption = (query, offset, limit) => dispatch => {
    dispatch(fetchStart(type.COUNT_DEVICES_START));
    axios
        .get(API_ROOT + `devices`, {
            params: {
                query: query,
                offset: offset,
                limit: limit
            }
        })
        .then(res => {
            let uplink = 0;
            let downlink = 0;
            let totalConsumption = 0;
            if (query) {
                console.log(query)
                const listDevices = res.data.devices

                for (var i = 0; i < listDevices.length; i++) {
                    downlink = downlink + listDevices[i].counter_down
                    uplink = uplink + listDevices[i].counter_up
                    totalConsumption = downlink + uplink
                }
            }
            let consumptionNLT = {
                uplink: uplink,
                downlink: downlink,
                total: totalConsumption,

            };
            dispatch(fetchSuccess(type.COUNT_DEVICES_SUCCESS, consumptionNLT));
        })
        .catch(error => {
            dispatch(fetchError(type.COUNT_DEVICES_FAIL, error));
        });
};

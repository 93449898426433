import React from 'react'
import HeliosTable from "../HeliosTable/HeliosTable"
import './skeleton.scss';

const SkeletonTable = props => {
    const skeleton = [{
        title: <b className='skeleton' style={{ width: 1500 }}></b>,
        dataIndex: "name"
    }]

    const dados = [];
    for (let i = 1; i <= props.pageSize; i++) {
        dados.push({
            key: i,
            name: <b className='skeleton' style={{ width: 1500 }}></b>,
        });
    }
    return (
        props.show ?
            <HeliosTable
                title={props.title}
                subTitle=""
                icon={props.icon}
                columns={skeleton}
                dataSource={dados || []}
                rowKey="key"
                total={0}
                pageSize={props.pageSize}
                defaultCurrent={0}
            // onChange={onPaginationChange}
            // onShowSizeChange={onShowSizeChange}
            /> : null
    )
}
export default SkeletonTable;
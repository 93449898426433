import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Tooltip, Drawer, Form, Select, Input, Button, Row, Card, Col, Descriptions, Progress } from "antd";


import { RollbackOutlined, PauseCircleOutlined, PlayCircleOutlined, RedoOutlined, UndoOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { getLineLogs, getLineLogsSummarized, setLineController } from "../../../../store/actions/resourceLogsAction";
import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import CardInfo from "../../../container/Dashborad/CardInfo";
import HeliosCardChartOperation from "../../../../components/HeliosCardChart/HeliosCardChartOperation";


const { Option } = Select;
const LineLogs = () => {
  const history = useHistory();
  const { state } = history.location;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  const [typeStatus, setTypeStatus] = useState("");
  const [line, setLine] = useState("");

  const [showFilter, setShowFilter] = useState(false);
  const [resource, setResource] = useState("")
  const { logs, loading } = useSelector(state => state.logs);
  const { lineController } = useSelector(state => state.lineController);
  const { logsSummarized, lastUpdate } = useSelector(state => state.logsSummarized);

  const [notHasError, setNotHasError] = useState(true);
  const [notHasCreated, setNotHasCreated] = useState(true);

  
  console.log(lineController)

  let resourceInfo = null;
  if (state) {
    resourceInfo = state;

  }
  const setResourceControl = (event) => {
    console.log(event)
    dispatch(setLineController(resourceInfo.lote,event,resourceInfo._id))

  }

  useEffect(() => {
    dispatch(getLineLogs(resourceInfo._id, line, typeStatus, page, pageSize));
    dispatch(getLineLogsSummarized(resourceInfo._id));
    logsSummarized && logsSummarized[2].total > 0 ? setNotHasError(false) : setNotHasError(true)//error
    logsSummarized && logsSummarized[1].total > 0 ? setNotHasCreated(false) : setNotHasCreated(true)//created


    let typeResource = resourceInfo.typeResource
    setResource(typeResource === 'many-hard-reset' ? "Reset" : typeResource === 'many-enable-sms' ? "Ativar SMS" : typeResource === 'many-disable-sms' ? "Desativar SMS" : typeResource === 'many-change-apn' ? "Atribuir APN" : typeResource === "many-reset-persist+change-apn" ? "Reset+APN" : typeResource === "many-enable-sms+change-apn" ? "Ativar SMS+APN" :
      typeResource === "many-disable-sms+change-apn" ? "Desativar SMS+APN" : "")


    const interval = setInterval(() => {
      dispatch(getLineLogs(resourceInfo._id, line, typeStatus, page, pageSize));
    }, 5000);
    const intervalSummarized = setInterval(() => {
      dispatch(getLineLogsSummarized(resourceInfo._id));

    }, 5000);
    return () => {
      clearInterval(interval)
      clearInterval(intervalSummarized)
    };


  }, [dispatch, resourceInfo, line, typeStatus, page, pageSize, logsSummarized]);

  // console.log(logsSummarized)


  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(1);
  };
  function handleChange(value) {
    setTypeStatus(value)
  }
  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
  };

  const onShowFilter = () => {
    setShowFilter(true);
  };
  const onCloseFilter = () => {
    setShowFilter(false);
  };
  const onClearFilter = () => {
    setTypeStatus("");
    setLine("")
  };
  const onChangeInput = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "msisdnIccid" && e.target.value.length <= 20) {
        setLine(e.target.value);
        if (e.target.value.length <= 20) {
          setLine(e.target.value);
        }
      }
    }
  };

  

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => {
            onShowFilter();
          }}
        />
      </Tooltip>

    </div>
  );

  const columns = [

    {
      title: "Linha(Msisdn/Iccid)",
      dataIndex: "msisdn",
      render: (text, record) => (
        <>
          {record.msisdn ? record.msisdn : record.iccid}
        </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <>
          <Tag color={record.status === "Created" ? "orange" : record.status === "Success" ? "green" : record.status === "Error" ? "red" : "blue"}>
            {record.status === "Created" ? "Em espera" : record.status === "Success" ? "Sucesso" : record.status === "Error" ? "Erro" : "Processando"}
          </Tag>
        </>
      )
    },
    {
      title: "Data e Hora",
      dataIndex: "updated_at",
      render: (text, record) => (
        moment(text).format("DD/MM/YYYY HH:mm:ss")
      )
    }
  ];

  return (
    <div>

      {logs ? (
        <div>

          <Row gutter={16}>
            <Col lg={14} md={24} sm={24} xs={24}>
              <HeliosTable
                title="Detalhes da Operação"
                subTitle={subTitle}
                columns={columns}
                icon="fa fa-industry"
                dataSource={logs.data || []}
                rowKey="_id"
                total={logs.total}
                pageSize={pageSize}
                page={page}
                onChange={handlePaginationChange}
                onShowSizeChange={onShowSizeChange}
              />
            </Col>
            <Col lg={10} md={24} sm={24} xs={24}>
              <Card
                title={<h1 style={styles.titleInfo}>
                  <i
                    style={styles.subTitleInfo}
                    className="fas fa-info-circle"
                  />
                  Informações
                </h1>}
                extra={<div></div>}
              >
                <Row gutter={16}>
                  <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10, width: "300px" }}>
                    <Card

                      title={
                        <Row justify="space-between" style={styles.containerTitle}>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <span>{resourceInfo.customerName}</span>
                          </Col>
                        </Row>
                      }
                      style={styles.container}
                    >
                      <Descriptions
                        bordered
                        size="small"
                        column={{ xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                      >
                        <Descriptions.Item label="Lote:">{resourceInfo.lote}</Descriptions.Item>
                        <Descriptions.Item label="Recurso:">{resource}</Descriptions.Item>
                        <Descriptions.Item label="Email:" span={2}>{resourceInfo.email}</Descriptions.Item>
                        <Descriptions.Item label="Arquivo:" span={2}>{resourceInfo.nameFile}</Descriptions.Item>
                        {resourceInfo.apn && <Descriptions.Item label="APN:">{resourceInfo.apn}</Descriptions.Item>}
                        {resourceInfo.emailClient && <Descriptions.Item label="Email do Cliente:" span={2}>{resourceInfo.emailClient}</Descriptions.Item>}
                        {resourceInfo.osId && <Descriptions.Item label="Os WS:">{resourceInfo.osId} </Descriptions.Item>}
                        {resourceInfo.protocol && <Descriptions.Item label="Protocolo:">{resourceInfo.protocol} </Descriptions.Item>}
                        {resourceInfo.motive && <Descriptions.Item label="Motivo:" span={2}>{resourceInfo.motive} </Descriptions.Item>}



                      </Descriptions>


                    </Card>
                    <Row gutter={16}>
                      <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 10, width: "300px" }}>
                        <Card title={
                          <Row justify="space-between" style={styles.containerTitle}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <span>Controle da Operação</span>
                            </Col>
                          </Row>
                        }
                          style={styles.container}>
                          <Row gutter={16}>
                            {/* logsSummarized[0] = Processando
                          logsSummarized[1] = Em espera
                          logsSummarized[2] = Erro
                          logsSummarized[3] = Sucesso */}
                            <Col style={{ justifyContent: "space-between", marginBottom: 10, width: "300px" }}>
                              {logsSummarized && (<>
                                <Tag color={logsSummarized[1].total === resourceInfo.quantTotal ? "orange" : logsSummarized[1].total === 0 ? "green" : "blue"}>
                                  {logsSummarized[1].total === resourceInfo.quantTotal ? "Em espera" : logsSummarized[1].total === 0 ? "Finalizado" : "Processando - " + (logsSummarized[1].total) + " Linhas"}
                                </Tag>
                                <Progress percent={((resourceInfo.quantTotal - logsSummarized[1].total) / resourceInfo.quantTotal * 100).toFixed(2)} size="default" />
                              </>)}
                            </Col>
                            <Col style={{ justifyContent: "space-between", marginBottom: 10 }}>
                              <Button style={{ marginRight: 3, borderColor: "orange", color: "orange" }} type="default" onClick={()=>setResourceControl(1)} shape="round" icon={<PauseCircleOutlined />} disabled={notHasCreated} size="middle">Pausar</Button>
                              <Button type="primary" shape="round" icon={<PlayCircleOutlined />} disabled={notHasCreated} onClick={()=>setResourceControl(2)} size="middle">Iniciar</Button>
                              <Button type="danger" shape="round" icon={<RedoOutlined />} disabled={notHasError} onClick={()=>setResourceControl(3)} size="middle">Erros</Button>
                              <Button style={{ marginLeft: 3, borderColor: "gray", color: "gray" }} disabled={!notHasCreated} onClick={()=>setResourceControl(4)} type="default" shape="round" icon={<UndoOutlined />} size="middle">Todos</Button>

                            </Col>
                          </Row>



                        </Card>
                      </Col>
                    </Row>

                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
                    <CardInfo
                      title="Status das Linhas"
                      // subTitle={}
                      lastUpdate={lastUpdate}

                    >

                      < HeliosCardChartOperation color={"green"} colors={["blue", "gray", "red", "green"]} dataChart={logsSummarized || []} />

                    </CardInfo>
                    <Button style={{ marginTop: 10, borderColor: "orange", color: "orange" }} icon={<RollbackOutlined style={{ fontSize: 18 }} />} onClick={() => {
                      history.push('/resource-activities')
                    }} type="default">
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>

          </Row>

        </div>
      ) : <SkeletonTable show={!loading} title="Operações em Massa" icon="fa fa-industry" pageSize={pageSize} />
      }

      <Drawer
        title="Filtros"
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        placement="right"
        onClose={onCloseFilter}
        visible={showFilter}
        width={500}
        style={styles.containerDrawer}
      >
        <Form layout="vertical">

          <Form.Item label="Status">
            <Select defaultValue="Todos" style={{ width: "100%" }} onChange={handleChange}>
              <Option value="Created">Em espera</Option>
              {/* <Option value="many-status-hlr">Status da Linha em Massa</Option> */}
              <Option value="Success">Sucesso</Option>
              <Option value="Error">Erro</Option>
              <Option value="Pending">Processando</Option>
              <Option value="">Todos</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Linha">
            <Input.Search
              autoComplete="off"
              name="msisdnIccid"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setLine("");
                }
                onChangeInput(event);
              }}
              value={line}
            />
          </Form.Item>
        </Form>
        <div style={styles.btnFilter}>
          <Button
            onClick={onClearFilter}
            type="danger"
            ghost
            style={styles.btnCancel}
          >
            LIMPAR
          </Button>
          <Button onClick={onCloseFilter} type="default">
            FECHAR
          </Button>
        </div>
      </Drawer>


    </div >
  );
};

const styles = {
  subTitle: {
    marginRight: 5,
    fontSize: 18,
    cursor: "pointer"
  },
  btnFilter: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },
  icon: {
    marginRight: 5,
    fontSize: 18,
  },
  container: {
    borderRadius: "10px",
    background: "#fff",
    boxShadow:
      "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  },
  containerTitle: {
    color: "gray",
    fontWeight: "bold"
  },
  titleInfo: {
    fontWeight: 900,
    // marginLeft: 20
  },

  subTitleInfo: {
    background: "linear-gradient(80deg, #43A047, #FFEB3B)",
    borderRadius: 10,
    padding: 17,
    marginRight: 10,
    fontSize: 22,
    color: "#ffffff"
    // cursor: "pointer"
  }
};

export default LineLogs;

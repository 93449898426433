import heliosApi from '../../heliosApi';

export const exportOrderServiceReport = async ({
  reportType,
  reportSubType,
  resourceId,
  rootOs,
  customerId
}) => {
  const body = {
    reportType,
    reportSubType,
    resourceId,
    rootOs,
    customerId
  };

  const response = await heliosApi.post('/resources/export/reports', body);
  return response;
};
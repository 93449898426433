import React from "react";
import PropTypes from "prop-types";
import { Table, Pagination } from "antd";

const HeliosTable = props => {
  return (
    <Table
      style={styles.table}
      title={() => (
        <div style={styles.titleContainer}>
          <h1 style={styles.title}>
            <i
              style={styles.subTitle}
              className={props.icon}
            />
            {props.title}
          </h1>
          {props.subTitle}
        </div>
      )}
      rowSelection={props.rowSelection}
      columns={props.columns}
      dataSource={props.dataSource}
      rowKey={props.rowKey}
      pagination={false}
      expandable={props.expandable}
      
      footer={() => (
        <div style={styles.paginationContainer}>
          <Pagination
            total={parseInt(props.total)}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} registros  ${"    "}`
            }
            pageSize={props.pageSize}
            defaultCurrent={props.page}
            showSizeChanger
            showQuickJumper
            onShowSizeChange={props.onShowSizeChange}
            onChange={props.onChange}
            pageSizeOptions={["5", "10", "15", "20", "25", "50", "100"]}
          />
        </div>
      )}
    />
  );
};

const styles = {
  table: {
    background: "#fff",
    borderRadius: "6px",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    padding: "10px"
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",

    // alignItems: "center"
  },
  title: {
    fontWeight: 900,
    // marginLeft: 20
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  subTitle: {
    background: "linear-gradient(80deg, #43A047, #FFEB3B)",
    borderRadius: 10,
    padding: 17,
    marginRight: 10,
    fontSize: 22,
    color: "#ffffff"
    // cursor: "pointer"
  }
};

HeliosTable.propTypes = {
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired
};

export default HeliosTable;

const isEmpty = value => {
    return value === "";
};

export const queryCustomer = (
    format,
    page,
    limit,
    customerId,
    name,
    nomeFantasiaC,
    industry,
    cpfcnpjC,
    email,
    phoneOffice,
    phoneAlternate,
    billingAddressStreet,
    billingAddressDistrict,
    billingAddressCity,
    billingAddressState,
    billingAddressPostalcode,
    billingAddressCountry,
    statusC,
    dueDay,
    activationDate,
    deactivationDate,
    totalLines
) => {
    let params = `${customerId !== "" ? "(id:" + customerId + ")" : ""}`;
    return `{
        allCustomers${
        format === "" ? "(page:" + page + ", limit:" + limit + ")" : ""
        } {
          ${
        format === ""
            ? "pagination " + params + " { page limit total }"
            : ""
        }
          customers ${params} {
            ${isEmpty(name) ? "" : name}
            ${isEmpty(nomeFantasiaC) ? "" : nomeFantasiaC}
            ${isEmpty(industry) ? "" : industry}
            ${isEmpty(cpfcnpjC) ? "" : cpfcnpjC}
            ${isEmpty(email) ? "" : email}
            ${isEmpty(phoneOffice) ? "" : phoneOffice}
            ${isEmpty(phoneAlternate) ? "" : phoneAlternate}
            ${isEmpty(billingAddressStreet) ? "" : billingAddressStreet}
            ${isEmpty(billingAddressDistrict) ? "" : billingAddressDistrict}
            ${isEmpty(billingAddressCity) ? "" : billingAddressCity}
            ${isEmpty(billingAddressState) ? "" : billingAddressState}
            ${isEmpty(billingAddressPostalcode) ? "" : billingAddressPostalcode}
            ${isEmpty(billingAddressCountry) ? "" : billingAddressCountry}
            ${isEmpty(statusC) ? "" : statusC}
            ${isEmpty(dueDay) ? "" : dueDay}
            ${isEmpty(activationDate) ? "" : activationDate}
            ${isEmpty(deactivationDate) ? "" : deactivationDate}
            ${isEmpty(totalLines) ? "" : totalLines}
          }
        }
      }`;
};
import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

// import HeliosTableAtr from "../../components/HeliosTable/HeliosTableAtr";
// import { useHistory } from "react-router-dom";

// const { info } = Modal;
const placement = "bottomRight"

export const getHardResetClient = msisdn => dispatch => {
    dispatch(fetchStart(actionType.HARD_RESET_LINE_START));
    notification.open({
        key: 'loadingHardReset',
        message: 'Efetuando operação de Reset da Linha ',
        description:
            'Em instantes você receberá a resposta do pedido da linha ' + msisdn,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/hard-reset", { "msisdn": msisdn })
        .then(res => {
            console.log(res.data)
            if (res.data) {
                notification.close('loadingHardReset')
                if (res.data.response.length === 0) {
                    notification.error({
                        message: 'Erro no pedido!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                        duration: 60,
                        placement
                    });
                }
                if (res.data.response[0].description === "OK" || res.data.response[0].description === "Ok") {
                    notification.success({
                        message: 'Operação realizada com sucesso!',
                        description:
                            'A linha ' + msisdn + ' foi resetada',
                        duration: 60,
                        placement
                    });
                }
                // if (res.data.response[0][0].description !== "OK" ||res.data.response[0][0].description !== "Ok") {
                else {
                    notification.warning({
                        message: 'A linha está com falhas na Operadora!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia',
                        duration: 60,
                        placement

                    });
                }
            }
            dispatch(fetchSuccess(actionType.HARD_RESET_LINE_SUCCESS, res.data));
        })
        .catch(err => {
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.HARD_RESET_LINE_ERROR, err));
        });
};

export const getManyHardResetClient = (customerId, customerName, file,motive,protocol,email) => dispatch => {

    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    formData.append("customerId", customerId);
    formData.append("customerName", customerName);
    formData.append("motive", motive);
    formData.append("protocol", protocol);
    formData.append("email", email);

    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.HARD_RESET_LINE_START));
    notification.open({
        key: 'loadingManyHardReset',
        message: 'Solicitando operação de Reset Massivo das Linhas',
        description:
            'Em instantes você receberá a resposta do pedido das linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });

    axios
        .post(API_ROOT + "lines/client/many-hard-reset", formData, config)
        .then(res => {
            notification.close('loadingManyHardReset')

            dispatch(fetchSuccess(actionType.HARD_RESET_LINE_SUCCESS, res.data));
            dispatch(fetchStart(actionType.HARD_RESET_LINE_CLEAR));

        })
        .catch(err => {
            notification.close('loadingManyHardReset')
            notification.error({
                message: err.response.data.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.HARD_RESET_LINE_ERROR, err));
        });
};


export const getResetandChangeApnClient = (object, apn) => dispatch => {
    dispatch(fetchStart(actionType.CHANGE_APN_LINE_START));
    notification.open({
        key: 'loadingChangeApn',
        message: 'Efetuando operação de Reset e Mudança de APN da linha ',
        description:
            'Em instantes você receberá a resposta do pedido da linha ' + object.msisdn,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });


    axios
        .post(API_ROOT + "lines/client/reset-persist", { "apn": apn,"msisdn": object.msisdn })
        .then(res => {
            console.log(res.data)
            if (res.data) {
                
                if (res.data.response.length === 0) {
                    notification.error({
                        message: 'Erro no pedido!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                        duration: 60,
                        placement
                    });
                    notification.close('loadingChangeApn')
                }
            if (res.data.response[0].description === "OK" || res.data.response[0].description === "Ok") {
                notification.close('loadingChangeApn')
                notification.success({
                    message: 'Operação realizada com sucesso!',
                    description:
                        'A linha ' + object.msisdn + ' está com a APN ' + apn,
                    duration: 30,
                    placement
                });
            }else if(res.data.response[0].description !== "OK"){
                notification.warning({
                    message: 'A linha está com falhas na operadora!',
                    description:
                        'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                    duration: 60,
                    placement
                });
                notification.close('loadingChangeApn')
            }

            dispatch(fetchSuccess(actionType.CHANGE_APN_LINE_SUCCESS, res.data));
            dispatch(fetchStart(actionType.CHANGE_APN_LINE_CLEAR));
                        
            }}).catch(err => {
                    notification.close('loadingChangeApn')
                    notification.error({
                        message: 'Erro no pedido!',
                        description:
                            'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                        duration: 60,
                        placement
                    });
                    dispatch(fetchError(actionType.CHANGE_APN_LINE_ERROR, err));
                });
                

};

export const getManyHardResetAndChangeApnClient = (customerId, customerName,apn, file,motive,protocol,email) => dispatch => {

    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    formData.append("customerId", customerId);
    formData.append("customerName", customerName);
    formData.append("apn", apn);

    formData.append("motive", motive);
    formData.append("protocol", protocol);
    formData.append("email", email);

    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.HARD_RESET_LINE_START));
    notification.open({
        key: 'loadingManyHardReset',
        message: 'Solicitando operação de Reset Massivo e Mudança de APN das Linhas',
        description:
            'Em instantes você receberá a resposta do pedido das linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });

    axios
        .post(API_ROOT + "lines/client/many-reset-persist", formData, config)
        .then(res => {
            notification.close('loadingManyHardReset')

            dispatch(fetchSuccess(actionType.HARD_RESET_LINE_SUCCESS, res.data));
            dispatch(fetchStart(actionType.HARD_RESET_LINE_CLEAR));

        })
        .catch(err => {
            notification.error({
                message: err.response.data.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.HARD_RESET_LINE_ERROR, err));
        });
};

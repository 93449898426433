import * as actionType from "../types";
import moment from "moment";

const initialState = {
    statusHlr: null,
    lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
    errors: null,
    message: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.GET_STATUS_HLR_START:
            return {
                ...state,
                loading: true
            };
        case actionType.GET_STATUS_HLR_SUCCESS:
            return {
                ...state,
                statusHlr: action.payload,
                errors: null,
                loading: false
            };
        case actionType.GET_STATUS_HLR_FAIL:
            return {
                ...state,
                errors: action.errors,
                loading: false
            };

        default:
            return state;
    }
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportCustomer, getGenerateReportCustomer } from "../../../../store/actions/reportCustomerAction";
import { getCustomerByName } from "../../../../store/actions/customerAction";
import { queryCustomer } from "./Query"

import HeliosTable from "../../../../components/HeliosTable/HeliosTable";

import {
    Tooltip,
    Drawer,
    Form,
    Input,
    Button,
    AutoComplete
} from "antd";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import { columns } from "./columnsTable";

const ReportCustomer = props => {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [optionsCustomer, setOptionsCustomer] = useState([]);
    const [optionsValue, setOptionsValue] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(
            getReportCustomer(queryCustomer(
                "",
                page,
                pageSize,
                customerId !== undefined
                    ? customerId
                    : "",
                "name",
                "nomeFantasiaC",
                "industry",
                "cpfcnpjC",
                "email",
                "phoneOffice",
                "phoneAlternate",
                "billingAddressStreet",
                "billingAddressDistrict",
                "billingAddressCity",
                "billingAddressState",
                "billingAddressPostalcode",
                "billingAddressCountry",
                "statusC",
                "dueDay",
                "activationDate",
                "deactivationDate",
                "totalLines"
            ))
        );


    }, [customerId, dispatch, page, pageSize]);

    useEffect(() => {
        if (customerName) {
            dispatch(getCustomerByName(customerName));
        }
    }, [dispatch, customerName]);

    const customers = useSelector(state => state.customers);

    useEffect(() => {
        if (customers.customers) {
            let options = [];
            customers.customers.data.map(obj => {
                return options.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsCustomer(options);
        }
    }, [customers]);

    const { allCustomers, loading } = useSelector(
        state => state.allCustomers
    );


    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(0);
    };

    const onPaginationChange = (page, pageSize) => {
        setPage(page - 1);
    };

    const onClearFilter = () => {
        setCustomerId();
        setCustomerName();
        setOptionsValue("");
    };

    const onCloseFilter = () => {
        setShowFilter(false);
    };

    const onShowFilter = () => {
        setShowFilter(true);
    };

    const generateReport = () => {
        dispatch(
            getGenerateReportCustomer(queryCustomer(
                "xlsx",
                page,
                pageSize,
                customerId !== undefined
                    ? customerId
                    : "",
                "name",
                "nomeFantasiaC",
                "industry",
                "cpfcnpjC",
                "email",
                "phoneOffice",
                "phoneAlternate",
                "billingAddressStreet",
                "billingAddressDistrict",
                "billingAddressCity",
                "billingAddressState",
                "billingAddressPostalcode",
                "billingAddressCountry",
                "statusC",
                "dueDay",
                "activationDate",
                "deactivationDate",
                "totalLines"
            ))
        );
    }

    const subTitle = (
        <div>
            <Tooltip placement="bottom" title="Download">
                <i
                    style={styles.subTitle}
                    className="fas fa-download"
                    onClick={() => {
                        generateReport()
                    }}
                />
            </Tooltip>
            <Tooltip placement="bottom" title="Filtros">
                <i
                    style={styles.subTitle}
                    className="fas fa-filter"
                    onClick={() => {
                        onShowFilter();
                    }}
                />
            </Tooltip>
        </div>
    );

    return (
        <div>
            <SkeletonTable show={loading} title="Relatório de Clientes" icon="fas fa-user-tie" pageSize={pageSize}/>

            {allCustomers && !loading?(
                <HeliosTable
                    title="Relatório de Clientes"
                    subTitle={subTitle}
                    icon="fas fa-user-tie"
                    columns={columns}
                    dataSource={allCustomers.customers || []}
                    rowKey="cpfcnpjC"
                    total={allCustomers.total}
                    pageSize={pageSize}
                    page={page}
                    onChange={onPaginationChange}
                    onShowSizeChange={onShowSizeChange}
                />
            ):""}
            <Drawer
                title="Filtros"
                headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
                placement="right"
                onClose={onCloseFilter}
                visible={showFilter}
                width={500}
                style={styles.containerDrawer}
            >
                <Form layout="vertical">
                    <Form.Item label="CLIENTE">
                        <AutoComplete
                            options={optionsCustomer}
                            value={optionsValue}
                            onChange={value => {
                                if (!value) {
                                    setCustomerId();
                                    setCustomerName();
                                }
                            }}
                            onSearch={value => {
                                setOptionsValue(value);
                                const timer = setTimeout(() => {
                                    if (value.length >= 2) {
                                        setCustomerName(value);
                                    }
                                }, 1000);
                                return () => clearTimeout(timer);
                            }}
                            onSelect={(value, option) => {
                                setOptionsValue(option.label);
                                setCustomerId(value);
                            }}
                        >
                            <Input.Search
                                size="middle"
                                placeholder="Pesquisar Cliente..."
                                allowClear
                                loading={customers.loading}
                            />
                        </AutoComplete>
                    </Form.Item>
                </Form>
                <div style={styles.btnFilter}>
                    <Button
                        onClick={onClearFilter}
                        type="danger"
                        ghost
                        style={styles.btnCancel}
                    >
                        LIMPAR
          </Button>
                    <Button onClick={onCloseFilter} type="default">
                        FECHAR
          </Button>
                </div>
            </Drawer>
        </div>
    );
};

const styles = {
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
    containerDrawer: {
        //width: 30
        //backgroundColor: "transparent"
    },
    btnFilter: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
    },
    btnCancel: {
        marginRight: 8
    },
    online: {
        color: "green"
    }
};

export default ReportCustomer;

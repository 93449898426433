import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";

export const getApn = () => dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchStart(actionsType.GET_APN_START));
    // let result = []
    axios
        .get(API_ROOT + `list_apns`)
        .then(res => {
            // res.data.map(obj => {
            //     return result.push({ label: obj.name, value: obj.name });
            // });
            dispatch(fetchSuccess(actionsType.GET_APN_SUCCESS, res.data));
        })
        .catch(error => {
            dispatch(fetchError(actionsType.GET_APN_ERROR, error));
        });
};
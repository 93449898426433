class LinesConsumptionSummarized {
  constructor(name, consumption, totalConsumption, color, prefix) {
    this.name = name;
    this.percent = consumption === 0 ? "0.0%" : ((totalConsumption * 100) / consumption).toFixed(2);
    this.total = parseFloat(totalConsumption);
    this.color = color;
    this.prefix = prefix;
  }
}

export default LinesConsumptionSummarized;
import React from "react";
import { Link } from "react-router-dom";

import { Menu } from "antd";

const MenuPermission = ({ ...props }) => {
  let permissions = [];
  if (localStorage.permissions) {
    permissions = JSON.parse(localStorage.getItem("permissions"));
  }
  return permissions.indexOf(props.permission) >= 0 ? (
    <Menu.Item key={props.key} {...props}>
      {props.icon}
      {props.title} <Link to={props.to} />
    </Menu.Item>
  ) : null;
};

export default MenuPermission;

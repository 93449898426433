import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";

export const getPhaseSimcard = (
  dateStart,
  dateNow,
  phaseId,
  customerId
) => dispatch => {
  dispatch(fetchStart(actionsType.GET_PHASE_SIMCARD_START));
  axios
    .get(
      API_ROOT +
      `simcards/phase?dateStart=${dateStart}&dateNow=${dateNow}&phaseId=${phaseId}&customerId=${customerId}`
    )
    .then(res => {
      dispatch(fetchSuccess(actionsType.GET_PHASE_SIMCARDS_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_PHASE_SIMCARD_FAIL, error));
    });
};

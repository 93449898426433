import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  createUser,
  updateUser,
  clearErrors,
  clearMessage
} from "../../../../store/actions/userAction";

import { Card, Form, Input, Radio, Row, Col, Button, notification, AutoComplete } from "antd";

import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import Backdrop from "../../../../components/Backdrop/Backdrop";
import styles from "./styles";
import { getCustomerByName, getCustomers } from "../../../../store/actions/customerAction";

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("O e-mail não é válido.")
    .required("E-mail é obrigatório."),
  name: Yup.string()
    .required("O nome do obrigatório.")
    .min(6, "O nome deve ter pelo menos 3 caracteres.")
});

const FormOperation = () => {
  const history = useHistory();
  const { state } = history.location;
  const [profile, setProfile] = useState(state ? state.profile : 2);
  const [status, setStatus] = useState(state ? (state.status ? 1 : 0) : 1);
  const [showBackdrop, setShowBackdrop] = useState(false);
  // const [clientId, setCLientId] = useState(state.clientId : 2);

  // CLIENTES
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [optionsValue, setOptionsValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
      if (customerName) {
          dispatch(getCustomerByName(customerName));
      }
  }, [dispatch, customerName]);

  const customers = useSelector(state => state.customers);
  useEffect(() => {
      if (customers.customers) {
          let options = [];
          customers.customers.data.map(obj => {
              return options.push({ value: obj.id.toString(), label: obj.name });
          });
          setOptionsCustomer(options);
      }

  }, [customers]);
  const { allCustomerList } = useSelector(state => state.allCustomerList);
//------------------------------------------------------------------------

  const user = useSelector(state => state.user);
  const { message, errors, loading } = user;
  let userUpdate = null;
  if (state) {
    userUpdate = state;
    
  }
  useEffect(() => {
    if((userUpdate && userUpdate.profile === 5) || (userUpdate && userUpdate.profile === 6)){
      dispatch(getCustomers(userUpdate.clientId,1, 0))
    }
    
    if (errors) {
      errors.map(error => {
        return notification.error({
          message: error.detail,
          placement: "topRight"
        });
      });
      dispatch(clearErrors());
    }
    if (message) {
      notification.success({
        message: message,
        placement: "topRight"
      });
      dispatch(clearMessage());
    }
    setShowBackdrop(loading);
  }, [message, errors, loading, userUpdate, dispatch]);

  return (
    <Row justify="center" type="flex">
      <Backdrop show={showBackdrop} loading={true} />
      <Col xs={24} sm={18} xl={16} xxl={10}>
        <Card
          title=
          {< h1 style={styles.title}>
            <i
              style={styles.subTitle}
              className={state ? "fas fa-user" : "fas fa-user-plus"}
            />
            {" "}
            {state ? "Atualizar Usuário" : "Adicionar Usuário"}
          </h1>}
        //   <i className={state ? "fas fa-user" : "fas fa-user-plus"}>
        //     {" "}
        //     {state ? "Atualizar Usuário" : "Adicionar Usuário"}
        //   </i>
        // }
        >
          <Form layout="vertical">
            <Formik
              initialValues={{
                name: userUpdate ? userUpdate.name : "",
                email: userUpdate ? userUpdate.email : "",
                status: userUpdate ? userUpdate.status : status,
                userType: 1, // User Operation
                profile: userUpdate ? userUpdate.profile : profile,
                clientId:userUpdate? userUpdate.clientId : customerId
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                if (state) {
                  const user = {
                    name: values.name,
                    email: values.email,
                    is_active: values.status,
                    roleId: values.profile,
                    clientId: customerId
                  };
                  dispatch(updateUser(userUpdate.id, user));
                } else {

                  dispatch(
                    createUser(
                      values.name,
                      values.email,
                      values.status,
                      values.userType,
                      values.profile,
                      values.clientId || customerId
                    )
                  );
                  if (!loading) {
                    resetForm();
                  }
                }
                // When button submits form and form is in the process of submitting, submit button is disabled
                setSubmitting(true);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid
              }) => (
                  <div>
                    <Form.Item label="Nome">
                      <Input
                        size="large"
                        type="text"
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.name && errors.name ? "error" : null}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Form.Item>
                    <Form.Item label="Email">
                      <Input
                        size="large"
                        type="email"
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.email && errors.email ? "error" : null}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Form.Item>
                    <Row>
                      <Col xs={12}>
                        <h1 style={styles.titleRadio}>Perfil</h1>
                        <Radio.Group
                          onChange={e => {
                            setProfile(e.target.value);
                            values.profile = e.target.value;
                          }}
                          value={profile}
                        >
                          <Radio style={styles.radioStyle} value={1}>
                            Administrador
                        </Radio>
                          <Radio style={styles.radioStyle} value={2}>
                            Operação
                        </Radio>
                          <Radio style={styles.radioStyle} value={3}>
                            Financeiro
                        </Radio>
                          <Radio style={styles.radioStyle} value={4}>
                            Atendimento
                        </Radio>
                        <Radio style={styles.radioStyle} value={5}>
                            Cliente
                        </Radio>
                        <Radio style={styles.radioStyle} value={6}>
                            Cliente PRO
                        </Radio>
                        </Radio.Group>
                      </Col>

                      <Col xs={12}>
                        <h1 style={styles.titleRadio}>Status</h1>
                        <Radio.Group
                          onChange={e => {
                            setStatus(e.target.value);
                            values.status = e.target.value;
                          }}
                          value={status}
                        >
                          <Radio style={styles.radioStyle} value={1}>
                            Ativo
                        </Radio>
                          <Radio style={styles.radioStyle} value={0}>
                            Inativo
                        </Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                    <Col span={24}>
                    {profile === 5 || profile === 6 ?( 
                    <Form.Item style={styles.titleRadio} label={userUpdate && userUpdate.profile === profile && allCustomerList  ?"Cliente atual: "+allCustomerList.data[0].name+"":"Escolher Cliente:"}>
                        <AutoComplete
                            style={{ width: '100%' }}
                            options={optionsCustomer}
                            value={optionsValue}
                            onChange={value => {
                                if (!value) {
                                    setCustomerId();
                                    setCustomerName();
                                }
                            }}
                            onSearch={value => {
                                setOptionsValue(value);
                                const timer = setTimeout(() => {
                                    if (value.length >= 2) {
                                        setCustomerName(value);
                                    }
                                }, 1000);
                                return () => clearTimeout(timer);
                            }}
                            onSelect={(value, option) => {
                                setOptionsValue(option.label);
                                setCustomerId(value);
                            }}
                        >
                            <Input.Search
                                size="size"
                                placeholder="Escolher ..."
                                allowClear
                                loading={customers.loading}
                            />
                        </AutoComplete>
                    </Form.Item>
                    ):""}
                    </Col>
                    </Row>
                    <Row>
                      <Col span={24} style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          ghost
                          style={{ marginLeft: 8 }}
                          onClick={() =>{
                            handleSubmit()
                            history.push("/user-operation")
                          }}
                        >
                          SALVAR
                      </Button>
                        <Button
                          type="danger"
                          ghost
                          style={{ marginLeft: 8 }}
                          onClick={() => history.push("/user-operation")}
                        >
                          CANCELAR
                      </Button>
                      </Col>
                    </Row>
                  </div>
                )}
            </Formik>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default FormOperation;

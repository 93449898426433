import { combineReducers } from "redux";

import authReducer from "./authReducer";
import userReducer from "./userReducer";
import lineStatusReducer from "./lineStatusReducer";
import lineStatusReducerNLT from "./lineStatusReducerNLT";
import lineConsumptionReducer from "./lineConsumptionReducer";
import lineConsumptionReducerNLT from "./lineConsumptionReducerNLT";

import customerReducer from "./customerReducer";

import phaseSimcardsReducer from "./phaseSimcardsReducer";
import deviceReducer from "./deviceReducer";
import deviceReducerNLT from "./deviceReducerNLT";

import deviceConsumptionReducer from "./deviceConsumptionReducer";
import deviceConsumptionReducerNLT from "./deviceConsumptionReducerNLT";
import hardResetReducer from "./hardResetReducer";
import smsMoReducer from "./smsMoReducer";
import apnReducer from "./apnReducer"
import changeApnReducer from "./changeApnReducer";
import planReducer from "./planReducer";
import reportBillingReducer from "./reportBillingReducer";
import generateReportBillingReducer from "./generateReportBillingReducer";
import generateReportSimcardReducer from "./generateReportSimcardReducer";
import reportConsumptionReducer from "./reportConsumptionReducer";
import generateReportConsumptionReducer from "./generateReportConsumptionReducer";
import reportCustomerReducer from "./reportCustomerReducer";
import generateReportCustomerReducer from "./generateReportCustomerReducer";
import customerListReducer from "./customerListReducer";
import reportSimcardReducer from './reportSimcardsReducer';
import geolocationMobileReducer from "./geolocationMobileReducer";
import statusHlrReducer from "./statusHlrReducer";
import userLogReducer from "./userLogReducer";
import lineSessionReducer from "./lineSessionReducer";
import resourceLogReducer from "./resourceLogReducer";
import suspensionReducer from "./suspensionReducer";
import reactivationReducer from "./reactivationReducer";
import transactionLogReducer from "./transactionLogReducer";
import reserveNumberReducer from "./reserveNumberReducer";
import contractReducer from "./contractReducer";
import stockReducer from "./stockReducer.js";
import linesStockStatusReducer from "./linesStockStatusReducer";


export default combineReducers({
  auth: authReducer,
  user: userReducer,
  linesStatus: lineStatusReducer,
  linesStockStatus: linesStockStatusReducer,
  linesConsumption: lineConsumptionReducer,
  customers: customerReducer,
  phaseSimcards: phaseSimcardsReducer,
  devices: deviceReducer,
  devicesNLT: deviceReducerNLT,
  devicesConsumption: deviceConsumptionReducer,
  devicesConsumptionNLT: deviceConsumptionReducerNLT,
  statusLineSummarizedNLT: lineStatusReducerNLT,
  consumptionLineSummarizedNLT: lineConsumptionReducerNLT,
  hardReset: hardResetReducer,
  smsMo: smsMoReducer,
  getApn: apnReducer,
  changeApn: changeApnReducer,
  plans: planReducer,
  reportBilling: reportBillingReducer,
  generateReportBilling: generateReportBillingReducer,
  generateReportSimcards: generateReportSimcardReducer,
  reportConsumption: reportConsumptionReducer,
  generateReportConsumption: generateReportConsumptionReducer,
  allCustomers: reportCustomerReducer,
  generateReportCustomers: generateReportCustomerReducer,
  allCustomerList: customerListReducer,
  allSimcards: reportSimcardReducer,
  allLocations: geolocationMobileReducer,
  statusHlr: statusHlrReducer,
  logs: userLogReducer,
  logsSummarized: userLogReducer,
  lineController: userLogReducer,
  lineSession: lineSessionReducer,
  resourceLogs: resourceLogReducer,
  suspension:suspensionReducer,
  reactivation:reactivationReducer,
  transactionLogs:transactionLogReducer,
  reserveNumbers:reserveNumberReducer,
  contracts:contractReducer,
  stock:stockReducer,


});

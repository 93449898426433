export const columns = [
    {
      title: "Cliente",
      dataIndex: "customer__name"
    },
    {
      title: "ICCID",
      dataIndex: "simcard__iccid"
    },
    {
      title: "MSISDN",
      dataIndex: "line__msisdn"
    },
    {
      title: "Plano",
      dataIndex: "plan__name"
    },
    {
      title: "Tipo",
      dataIndex: "plan__type"
    },
    {
      title: "Upload",
      dataIndex: "upload_f"
    },
    {
      title: "Download",
      dataIndex: "download_f"
    },
    {
      title: "Total",
      dataIndex: "total_f"
    }
  ];
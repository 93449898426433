import Axios from 'axios';

const heliosApi = Axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_HOST}/api`,
  config: {
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  },
});

// inclusão do token nas requisições
heliosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default heliosApi;

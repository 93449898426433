const isEmpty = value => {
    return value === "";
};

export const queryAllBillingDetails = (
    format,
    page,
    limit,
    dateConsumption,
    customerId,
    customerName,
    lineMsisdn,
    simcardIccid,
    planAggregate,
    planName,
    planBasePrice,
    phaseNome,
    simcardDateActivationF,
    simcardDateCancelledF,
    billingPeriod,
    billingDetailQtDays,
    itemDiscountType,
    itemDiscountValue,
    itemExemptionDays,
    billingDetailProRata,
    billingDetailServiceTiProRata,
    billingDetailConsumptionF,
    billingDetailSmsValue,
    billingDetailValue,
    contratoNumber,
    contratoServiceTi,
    serviceTelecomBase,
    totalServiceTi,
    totalServiceTelecom,
    billingDetailExcessValue,
    totalMonth
) => {
    let params = `inception: "${dateConsumption}" ${
        customerId !== "" ? ", customerId:" + customerId : ""
        }`;
    return `{
        allBillingDetails${
        format === "" ? "(page:" + page + ", limit:" + limit + ")" : ""
        } {
          ${
        format === ""
            ? "pagination (" + params + ") { page limit total }"
            : ""
        }
            billingDetails(${params}) {
              ${isEmpty(customerName) ? "" : customerName}
              ${isEmpty(lineMsisdn) ? "" : lineMsisdn}
              ${isEmpty(simcardIccid) ? "" : simcardIccid}
              ${isEmpty(planAggregate) ? "" : planAggregate}
              ${isEmpty(planName) ? "" : planName}
              ${isEmpty(planBasePrice) ? "" : planBasePrice}
              ${isEmpty(phaseNome) ? "" : phaseNome}
              ${isEmpty(simcardDateActivationF) ? "" : simcardDateActivationF}
              ${isEmpty(simcardDateCancelledF) ? "" : simcardDateCancelledF}
              ${isEmpty(billingPeriod) ? "" : billingPeriod}
              ${isEmpty(billingDetailQtDays) ? "" : billingDetailQtDays}
              ${isEmpty(itemDiscountType) ? "" : itemDiscountType}
              ${isEmpty(itemDiscountValue) ? "" : itemDiscountValue}
              ${isEmpty(itemExemptionDays) ? "" : itemExemptionDays}
              ${isEmpty(billingDetailProRata) ? "" : billingDetailProRata}
              ${isEmpty(billingDetailServiceTiProRata) ? "" : billingDetailServiceTiProRata}

              ${
        isEmpty(billingDetailConsumptionF)
            ? ""
            : billingDetailConsumptionF
        }
              ${isEmpty(billingDetailValue) ? "" : billingDetailValue}
              ${isEmpty(contratoNumber) ? "" : contratoNumber}
              ${isEmpty(contratoServiceTi) ? "" : contratoServiceTi}
              ${isEmpty(totalServiceTi) ? "" : totalServiceTi}
              ${isEmpty(serviceTelecomBase) ? "" : serviceTelecomBase}
              ${
        isEmpty(billingDetailExcessValue)
            ? ""
            : billingDetailExcessValue
        }
              ${isEmpty(billingDetailSmsValue) ? "" : billingDetailSmsValue}
              ${isEmpty(totalServiceTelecom) ? "" : totalServiceTelecom}
              ${isEmpty(totalMonth) ? "" : totalMonth}
            }
          }
    }`;
};
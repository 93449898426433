import * as actionType from "../types";

const initialState = {
    plans: null,
    errors: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.GET_PLANS_START:
            return {
                ...state,
                loading: true
            };
        case actionType.GET_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload,
                errors: null,
                loading: false
            };

        case actionType.GET_PLANS_FAIL:
            return {
                ...state,
                errors: action.errors,
                loading: false
            };

        default:
            return state;
    }
}

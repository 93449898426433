import React from "react";
import { Route } from "react-router-dom";
import Page403 from "../../views/PagesInfo/Page403";

const RoutePermission = ({ ...props }) => {
  let permissions = [];
  if (localStorage.permissions) {
    permissions = JSON.parse(localStorage.getItem("permissions"));
  }
  return permissions.indexOf(props.permission) >= 0 ? (
    <Route path={props.path} {...props}>
      {props.renderComponent}
    </Route>
  ) : (
    <Page403 />
  );
};

export default RoutePermission;

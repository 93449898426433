import * as actionType from "../types";

const initialState = {
  allCustomerList: null,
  errors: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.GET_LIST_CUSTOMERS_START:
      return {
        ...state,
        loading: true
      };
    case actionType.GET_LIST_CUSTOMERS_SUCCESS:
      return {
        ...state,
        allCustomerList: action.payload,
        errors: null,
        loading: false
      };

    case actionType.GET_LIST_CUSTOMERS_FAIL:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };

    default:
      return state;
  }
}

import moment  from "moment";
import React from "react";
import { Tag } from "antd";
// import { useHistory } from "react-router-dom";
// const history = useHistory()

export const columns = [
  {
    title: "MSISDN",
    dataIndex: "msisdn"
  },
  {
    title: "Início",
    dataIndex: "created_at",
    render: (text, record) => (
      moment(text).format("DD/MM/YYYY HH:mm:ss")
    )
  },
  {
    title: "Atualização",
    dataIndex: "updated_at",
    render: (text, record) => (
      moment(text).format("DD/MM/YYYY HH:mm:ss")
    )
  },
  {
    title: "Status",
    dataIndex: "stop_at",
    render: (text, record) => (
      <Tag color={record.stop_at !== null ? "red" : "green"}>
        {record.stop_at !== null ? moment(text).format("DD/MM/YYYY HH:mm:ss") : moment(record.updated_at).add(10,'minutes')> moment().format()?record.updated_at: <i className="fas fa-signal"> Online</i>}
      </Tag>
      
    
    
    )
  },
{
  title: "Download",
    dataIndex: "download"
},
{
  title: "Upload",
    dataIndex: "upload"
},
{
  title: "APN",
    dataIndex: "apn"
},
{
  title: "IMEI",
    dataIndex: "IMEI"
},
{
  title: "IP",
    dataIndex: "ip_linha"
},
{
  title: "Rede",
    dataIndex: "network"
},

  // {
  //   title: "Localização",
  //   key: 'action',
  //   render: (text, record) => (
  //       record.network !== "Not info" ? <Button type="primary" onClick={() => {useHistory().push("/location-mobile")}}><i style={{ fontSize: 12 }} className="fas fa-map-marker-alt" /></Button> : ""
  //   ),}

];
import React from "react";
import Avatar from "./AvatarDropdown";
import SelectLang from "../SelectLang";
import styles from "./index.less";
import VersionGlobal from "./VersionGlobal"
import Docs from "./Docs";
//import NoticeIcon from "../NoticeIcon";


const GlobalHeaderRight = props => {
  let clientId = localStorage.getItem("clientId")
  return (
    <div className={styles.right}>
      {/* <NoticeIcon title="Teste"/> */}
    {!clientId &&(
    <>
      <Docs />
      <VersionGlobal />
      </>
    )
    }
      
      <Avatar />
      <SelectLang className={styles.action} />
    </div>
  );
};

export default GlobalHeaderRight;

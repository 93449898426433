import React from 'react'
export const columns = [
  {
    title: "Razão Social",
    dataIndex: "customerName",
    // render: (text, obj) => <span style={{ min: 100 }}> {text}</span>,
    width: 200,
    ellipsis: true,
  }

  ,
  {
    title: "MSISDN",
    dataIndex: "lineMsisdn",
    width: 200,
    ellipsis: true,
  },
  {
    title: "ICCID",
    dataIndex: "simcardIccid",
    width: 200,
    ellipsis: true,
  },

  {
    title: "Compartilhado",
    dataIndex: "planAggregate",
    width: 200,
    ellipsis: true,
    render: (text, obj) => <span>{text === true ? "Sim" : "Não"}</span>
  },
  {
    title: "Plano",
    dataIndex: "planName",
    width: 150
  },
  {
    title: "Preço Base",
    dataIndex: "planBasePrice",
    width: 150

  },
  {
    title: "Fase",
    dataIndex: "phaseNome",
    width: 150

  },
  {
    title: "Data de Ativação",
    dataIndex: "simcardDateActivationF",
    width: 150
  },
  {
    title: "Data de Cancelamento",
    dataIndex: "simcardDateCancelledF",
    width: 200,
    ellipsis: true,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>
  },
  {
    title: "Período da Fatura",
    dataIndex: "billingPeriod",
    width: 150
  },
  {
    title: "Dias",
    dataIndex: "billingDetailQtDays",
    width: 150
  },
  {
    title: "Tipo do Desc.",
    dataIndex: "itemDiscountType",
    width: 150
  },
  {
    title: "Valor do Desc.",
    dataIndex: "itemDiscountValue",
    width: 150
  },
  {
    title: "Dias de Isenção",
    dataIndex: "itemExemptionDays",
    width: 150
  },
  {
    title: "Pro Rata",
    dataIndex: "billingDetailProRata",
    width: 150,
    render: (text, obj) => <span>{text === true ? "Sim" : "Não"}</span>
  },
  {
    title: "Pro Rata Serviço Ti",
    dataIndex: "billingDetailServiceTiProRata",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Consumo",
    dataIndex: "billingDetailConsumptionF",
    width: 150
  },
  {
    title: "Valor",
    dataIndex: "billingDetailValue",
    width: 150
  },
  {
    title: "Nº Contrato",
    dataIndex: "contratoNumber",
    width: 150
  },
  {
    title: "Serviço Ti Contratado",
    dataIndex: "contratoServiceTi",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Total Serviços Ti",
    dataIndex: "totalServiceTi",
    width: 150,
  },
  {
    title: "Base Serviço Telecom",
    dataIndex: "serviceTelecomBase",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Excedente",
    dataIndex: "billingDetailExcessValue",
    width: 150
  },
  {
    title: "SMS Valor",
    dataIndex: "billingDetailSmsValue",
    width: 150
  },
  {
    title: "Total Serviços Telecom",
    dataIndex: "totalServiceTelecom",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Total Mês",
    dataIndex: "totalMonth",
    width: 150
  }
];

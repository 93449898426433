import React from "react";
import { Statistic, Card, Divider, Row, Col } from "antd";

const CardInfo = props => {
  const { title, subTitle, lastUpdate, onUpdate } = props;
  return (
    <Card
      title={
        <Row justify="space-between" style={styles.containerTitle}>
          <Col lg={10} md={10} sm={10} xs={24}>
            <span>{title}</span>
          </Col>
          <Col lg={14} md={14} sm={14} xs={24} style={styles.subTitle}>
            <span>{subTitle}</span>
          </Col>
        </Row>
      }
      style={styles.container}
    >
      {props.children}
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <Statistic
        value={`Última atualização: ${lastUpdate}`}
        valueStyle={{ color: "gray", fontSize: 14 }}
        prefix={
          <i
            className="fas fa-sync-alt"
            style={{ color: "green", cursor: "pointer" }}
            onClick={onUpdate}
          ></i>
        }
      />
    </Card>
  );
};

const styles = {
  container: {
    borderRadius: "10px",
    background: "#fff",
    boxShadow:
      "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  },
  containerTitle: {
    color: "gray",
    fontWeight: "bold"
  },
  subTitle: {
    display: "flex",
    justifyContent: "flex-end",
    color: "gray",
    fontWeight: "bold"
  }
};

export default CardInfo;

import * as actionType from "../types";

const initialState = {
  listContracts: null,
  insertContract: null,
  errors: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.GET_CONTRACTS_START:
      return {
        ...state,
        loading: true
      };
    case actionType.GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        listContracts: action.payload,
        errors: null,
        loading: false
      };

    case actionType.INSERT_CONTRACT_SUCCESS:
      return {
        ...state,
        insertContract: action.payload,
        errors: null,
        loading: false
      };

    case actionType.GET_CONTRACTS_FAIL:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };

    case actionType.INSERT_CUSTOMERS_CLEAR:
      return initialState;
    default:
      return state;
  }
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLinesPaginated } from "../../../store/actions/lineStatusAction";

import { RollbackOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import HeliosTable from "../../../components/HeliosTable/HeliosTable";
import {
  Tooltip,
  Drawer,
  Form,
  Input,
  Button,
  Radio,
  Card,
  Tag,
  Col,
  Row
} from "antd";

import SkeletonTable from "../../../components/Skeleton/SkeletonTable";

// import { columns } from "./columnsTable";

const LinesClient = (props) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const history = useHistory();

  const [iccidInput, setIccidInput] = useState("");
  const [msisdnInput, setMsisdnInput] = useState("");
  const [iccid, setIccid] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [statusId, setStatusId] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLinesPaginated(props.customerId, iccid, msisdn, statusId, pageSize, page - 1));

  }, [dispatch, statusId,props.customerId, pageSize, page, iccid, msisdn]);

  // console.log(apns)



  const { linesStatus, loading } = useSelector(state => state.linesStatus);


  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize)
    console.log(page, pageSize);

  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(current);

  };

  const onClearFilter = () => {
    // setCustomerId();
    // setCustomerName();

    setIccid("");
    setIccidInput("");
    setMsisdn("");
    setMsisdnInput("");
    setStatusId("");
  };

  const onShowFilter = () => {
    setShowFilter(true);
  };

  const onCloseFilter = () => {
    setShowFilter(false);
  };

  const onChangeInput = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "iccid" && e.target.value.length <= 20) {
        setIccidInput(e.target.value);
        if (e.target.value.length === 20) {
          setIccid(e.target.value);
        }
      }
      if (e.target.name === "msisdn" && e.target.value.length <= 13) {
        setMsisdnInput(e.target.value);
        if (e.target.value.length === 13) {
          setMsisdn(e.target.value);
        }
      }
    }
  };

  const onChangeStatus = e => {
    setStatusId(e.target.value);
  };

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => {
            onShowFilter();
          }}
        />
      </Tooltip>
    </div>
  );
  const columns = [

    {
      title: "ICCID",
      dataIndex: "iccid"
    },
    {
      title: "IMSI",
      dataIndex: "imsi__number"
    },
    {
      title: "MSISDN",
      dataIndex: "msisdn"
    },
    {
      title: "Fase",
      dataIndex: "phase__name"
    },
    {
      title: "APN",
      dataIndex: "line__apn"
    },
    {
      title: "Status",
      dataIndex: "status__name",
      render: (text, record) => (
        <Tag color={record.status__name === "Offline" ? "#FF8C00" : record.status__name === "Online" ? "#87d068" : record.status__name === "Blocked" ? "#f50" : "#A0A0A0"}>
          {record.status__name}
        </Tag>
      )
    },


  ];



  return (
    <div>
      <Card >
        <SkeletonTable show={loading} title={"Linhas " + props.customerName} icon="fas fa-signal" pageSize={pageSize} />

        {linesStatus && !loading ? (
          <HeliosTable
            title={"Linhas - " + props.customerName}
            subTitle={subTitle}
            columns={columns}
            icon="fas fa-signal"
            dataSource={linesStatus.lines.data || []}
            rowKey="id"
            total={linesStatus.lines.total}
            pageSize={pageSize}
            page={page}
            onChange={handlePaginationChange}
            onShowSizeChange={onShowSizeChange}
          />
        ) : ""}
         <Row >
                    <Col span={24} style={{ textAlign: "right" }}>

                        <Button style={{ marginTop: 10, borderColor: "orange", color: "orange" }}
                            icon={<RollbackOutlined style={{ fontSize: 18 }} />}
                            type="default"
                            onClick={() => history.push("/customers")}
                        >
                            Voltar - Listar Clientes
                        </Button>
                    </Col>
                </Row>
      </Card>
      <Drawer
        title="Filtros"
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        placement="right"
        onClose={onCloseFilter}
        visible={showFilter}
        width={500}
        style={styles.containerDrawer}
      >
        <Form layout="vertical">

          <Form.Item label="ICCID">
            <Input.Search
              autoComplete="off"
              name="iccid"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setIccid("");
                }
                onChangeInput(event);
              }}
              value={iccidInput}
            />
          </Form.Item>
          <Form.Item label="MSISDN">
            <Input.Search
              autoComplete="off"
              name="msisdn"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setMsisdn("");
                }
                onChangeInput(event);
              }}
              value={msisdnInput}
            />
          </Form.Item>
          <Radio.Group name="radio2" onChange={onChangeStatus} value={statusId}>
            <div>
              <Radio value={2}>
                <strong style={{ color: "green", marginLeft: 8 }}>
                  Online
                </strong>
              </Radio>
              <Radio value={1}>
                <strong style={{ color: "orange" }}>Offline</strong>
              </Radio>
            </div>
            <div style={{ marginTop: 10 }}>
              <Radio value={3}>
                <strong style={{ color: "red" }}>Blocked</strong>
              </Radio>
              <Radio value={5}>
                <strong>Not Connect</strong>
              </Radio>
            </div>
          </Radio.Group>
        </Form>
        <div style={styles.btnFilter}>
          <Button
            onClick={onClearFilter}
            type="danger"
            ghost
            style={styles.btnCancel}
          >
            LIMPAR
          </Button>
          <Button onClick={onCloseFilter} type="default">
            FECHAR
          </Button>
        </div>
      </Drawer>

    </div>
  );
};

const styles = {
  subTitle: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },
  containerDrawer: {
    //width: 30
  },
  btnFilter: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    borderTop: "1px solid #e9e9e9",
    padding: "10px 16px",
    background: "#fff",
    textAlign: "right"
  },
  btnCancel: {
    marginRight: 8
  },
  online: {
    color: "green"
  }
};

export default LinesClient;

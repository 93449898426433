import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";
import ExportCSV from "../../components/ExportCSV/ExportCSV";

import React from "react";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const placement = "bottomRight"

export const getReportBilling = query => dispatch => {
    let response = {
        consumptions: [],
        page: 0,
        limit: 0,
        total: 0
    };
    dispatch(fetchStart(actionsType.REPORT_BILLING_START));
    axios
        .post(
            API_ROOT +
            `reports`, { query: query }
        )
        .then(res => {
            res.data.data.allBillingDetails.billingDetails.map(obj => {
                return response.consumptions.push(obj);
            });
            response.page = res.data.data.allBillingDetails.pagination.page;
            response.limit = res.data.data.allBillingDetails.pagination.limit;
            response.total = res.data.data.allBillingDetails.pagination.total;
            dispatch(fetchSuccess(actionsType.REPORT_BILLING_SUCCESS, response));
        })
        .catch(error => {
            notification.error({
                message: 'Erro na Api!',
                description:
                    'Tente novamente, se persistir Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionsType.REPORT_BILLING_FAIL, error));
        });
};
export const getGenerateReportBilling = query => dispatch => {
    console.log(query)
    let response = {
        consumptions: [],
        total: 0
    };
    notification.open({
        key: 'loadingReportBilling',
        message: 'Gerando Relatório Financeiro ',
        description:
            'Em Minutos você receberá o relatório',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    dispatch(fetchStart(actionsType.GENERATE_REPORT_BILLING_START));
    axios
        .post(
            API_ROOT +
            `reports`, { query: query }
        )
        .then(res => {
            notification.close('loadingReportBilling')
            notification.success({
                message: 'Operação realizada com sucesso!',
                description:
                    'Relatório gerado com sucesso',
                duration: 60,
                placement
            });
            ExportCSV(res.data.data.allBillingDetails.billingDetails, "Financeiro")
            dispatch(fetchSuccess(actionsType.GENERATE_REPORT_BILLING_SUCCESS, response));
        })
        .catch(error => {
            console.log(error)
            notification.close('loadingReportBilling')
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Tente novamente, se persistir Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionsType.GENERATE_REPORT_BILLING_FAIL, error));
        });
};
export const typeStock = [
    {
        id: 12,
        name: "Criar OS Estoque 2G/3G",
        type: "createStock2g3g"
        // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
    },
    {
        id: 13,
        name: "Criar OS Estoque 4G",
        type: "createStock4g"
        // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
    },

]

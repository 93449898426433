import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLinesPaginated } from "../../../../store/actions/lineStatusAction";
import { getCustomerByName } from "../../../../store/actions/customerAction";
import { getHardResetClient } from "../../../../store/actions/hardResetAction";
import {getSmsActive, getSmsInactive } from "../../../../store/actions/smsMoAction";
import { getStatusHlr } from "../../../../store/actions/statusHlrAction";
import { getApn } from "../../../../store/actions/apnAction";
import ModalAtr from "../../../../components/Modal/ModalAtr"
import moment from "moment";
import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import {
  Tooltip,
  Drawer,
  Form,
  Input,
  Button,
  Radio,
  Dropdown,
  Menu,
  Modal,
  Tag,
  AutoComplete,

} from "antd";
import {
  FileDoneOutlined,
  FileExclamationOutlined,
  FileExcelOutlined,
  FileAddOutlined,

} from "@ant-design/icons";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
const { confirm } = Modal;
// import { columns } from "./columnsTable";

const LinesStatus = () => {
  let clientId = localStorage.getItem("clientId")
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [optionsValue, setOptionsValue] = useState("");

  const [apnName,] = useState();
  const [msisdnModal, setMsisdnModal] = useState();


  const [optionsApn, setOptionsApn] = useState([]);

  const [tipoAtr, setTipoAtr] = useState("");

  const [iccidInput, setIccidInput] = useState("");
  const [msisdnInput, setMsisdnInput] = useState("");
  const [iccid, setIccid] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [statusId, setStatusId] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLinesPaginated(customerId, iccid, msisdn, statusId, pageSize, page-1));

  }, [dispatch, customerId, statusId, pageSize, page, iccid, msisdn]);

  useEffect(() => {
    if (customerName) {
      dispatch(getCustomerByName(customerName));
    }
    dispatch(getApn());

  }, [dispatch, apnName, customerName]);

  const customers = useSelector(state => state.customers);
  const apns = useSelector(state => state.getApn)
  // console.log(apns)
  useEffect(() => {
    if (customers.customers) {
      let options = [];
      customers.customers.data.map(obj => {
        return options.push({ value: obj.id.toString(), label: obj.name });
      });
      setOptionsCustomer(options);
    }
    if (apns.getApn) {
      let optionsApn = []
      apns.getApn.map(obj => {
        return optionsApn.push({ value: obj.name, label: obj.name });
      });
      setOptionsApn(optionsApn);
    }
  }, [apns, customers]);



  const { linesStatus, loading } = useSelector(state => state.linesStatus);


  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize)
    console.log(page, pageSize);
    
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(current);
 
  };

  const onClearFilter = () => {
    setCustomerId();
    setCustomerName();
    setOptionsValue("");
    setIccid("");
    setIccidInput("");
    setMsisdn("");
    setMsisdnInput("");
    setStatusId("");
  };

  const onShowFilter = () => {
    setShowFilter(true);
  };

  const onCloseFilter = () => {
    setShowFilter(false);
  };

  const onChangeInput = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "iccid" && e.target.value.length <= 20) {
        setIccidInput(e.target.value);
        if (e.target.value.length === 20) {
          setIccid(e.target.value);
        }
      }
      if (e.target.name === "msisdn" && e.target.value.length <= 13) {
        setMsisdnInput(e.target.value);
        if (e.target.value.length === 13) {
          setMsisdn(e.target.value);
        }
      }
    }
  };

  const onChangeStatus = e => {
    setStatusId(e.target.value);
  };

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => {
            onShowFilter();
          }}
        />
      </Tooltip>
    </div>
  );
  const columns = [
    {
      title: "Cliente",
      dataIndex: "customer__name"
    },
    {
      title: "ICCID",
      dataIndex: "iccid"
    },
    {
      title: "IMSI",
      dataIndex: "imsi__number"
    },
    {
      title: "MSISDN",
      dataIndex: "msisdn"
    },
    // {
    //   title: "SMS",
    //   dataIndex: "soldplan__sms",
    //   render: (text, record) => (
    //     <Tag color={record.soldplan__sms ? "green" : "red"}>
    //       {record.soldplan__sms ? "Ativo" : "Inativo"}
    //     </Tag>
    //   )
    // },
    {
      title: "Status",
      dataIndex: "status__name",
      render: (text, record) => (
        <Tag color={record.status__name === "Offline" ? "#FF8C00" : record.status__name === "Online" ? "#87d068" : record.status__name === "Blocked" ? "#f50" : "#A0A0A0"}>
          {record.status__name}
        </Tag>
      )
    },
    {
      title: "Fase",
      dataIndex: "phase__name"
    },
    {
      title: "Última conexão",
      dataIndex: "last_conn",
      render: (text, record) => (
        moment(text).format("DD/MM/YYYY HH:mm:ss")
      )

    },
    {
      title: "APN",
      dataIndex: "line__apn"
    },
    {
      title: () => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.ItemGroup title="Ações em Massa" />

            
              <Menu.Item
                key="9"
                onClick={() => {
                  showConfirm('', 'many-status-hlr');
                }}
              >
                <FileDoneOutlined style={{ color: "grey" }} />
                <span>Status das Linhas</span>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <i
            className="fas fa-cogs"
            style={{
              fontSize: 20,
              // cursor: idsSelected.length === 0 ? "not-allowed" : "default"
            }}
          />
        </Dropdown>
      ),
      key: "action",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="0"
                onClick={() => showConfirm(record, 'sms-inactive')}
              >
                <FileExcelOutlined style={{ color: "red" }} />
                Desativar SMS
              </Menu.Item>
              <Menu.Item
                key="1"
                onClick={() => showConfirm(record, 'sms-active')}
              >
                <FileAddOutlined style={{ color: "blue" }} />
                <span>Ativar SMS</span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="2"
                onClick={() => showConfirm(record, 'hard-reset')}
              >
                <FileExclamationOutlined style={{ color: "yellow" }} />
                <span>Resetar Linha</span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="3"
                onClick={() => showConfirm(record, 'change-apn')}
              >
                <FileDoneOutlined style={{ color: "green" }} />
                <span>Trocar APN</span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="4"
                onClick={() => showConfirm(record, 'hard-reset+change-apn')}
              >
                <FileDoneOutlined style={{ color: "yellow" }} />
                <span>Reset e Trocar APN</span>
              </Menu.Item>

              <Menu.Item
                key="5"
                onClick={() => showConfirm(record, 'disable-sms+change-apn')}
              >
                <FileDoneOutlined style={{ color: "red" }} />
                <span>Desabilitar SMS e Trocar APN</span>
              </Menu.Item>

              <Menu.Item
                key="6"
                onClick={() => showConfirm(record, 'enable-sms+change-apn')}
              >
                <FileDoneOutlined style={{ color: "blue" }} />
                <span>Habilitar SMS e Trocar APN</span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="8"
                onClick={() => showConfirm(record, 'status-hlr')}
              >
                <FileDoneOutlined style={{ color: "grey" }} />
                <span>Status da linha</span>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <i className="fas fa-cog" style={{ fontSize: 18 }} />
        </Dropdown>
      )
    }
  ];

  const showConfirm = (object, type) => {
    if (type === "hard-reset") {
      confirm({
        title: "Tem certeza que deseja efetuar o reset da linha?",
        content: <strong>{object.msisdn}</strong>,
        okText: "Sim",
        cancelText: "Não",
        onOk() {
          dispatch(getHardResetClient(object.msisdn));
        }
      });
    }
    if (type === "sms-active") {
      confirm({
        title: "Tem certeza que deseja habilitar SMS desta linha?",
        content: <strong>{object.msisdn}</strong>,
        okText: "Sim",
        cancelText: "Não",
        onOk() {
          dispatch(getSmsActive(object));
        }
      });
    }
    if (type === "sms-inactive") {
      confirm({
        title: "Tem certeza que deseja desabilitar SMS desta linha?",
        content: <strong>{object.msisdn}</strong>,
        okText: "Sim",
        cancelText: "Não",
        onOk() {
          dispatch(getSmsInactive(object));
        }
      });
    }
    if (type === "status-hlr") {
      confirm({
        title: "Tem certeza que deseja verificar o Status desta linha?",
        content: <strong>{object.msisdn}</strong>,
        okText: "Sim",
        cancelText: "Não",
        onOk() {
          dispatch(getStatusHlr(object));
        }
      });
    }
    if (type === "many-change-apn") {
      setVisibleModal(true)
      setTipoAtr('many-change-apn')
    }
    if (type === "many-enable-sms") {
      setVisibleModal(true)
      setTipoAtr('many-enable-sms')
    }
    if (type === "many-disable-sms") {
      setVisibleModal(true)
      setTipoAtr('many-disable-sms')
    }
    if (type === "many-hard-reset") {
      setVisibleModal(true)
      setTipoAtr('many-hard-reset')
    }
    if (type === "change-apn") {
      setMsisdnModal(object)
      setVisibleModal(true)
      setTipoAtr('change-apn')
    }
    if (type === "hard-reset+change-apn") {
      setMsisdnModal(object)
      setVisibleModal(true)
      setTipoAtr(type)
    }
    if (type === "disable-sms+change-apn") {
      setMsisdnModal(object)
      setVisibleModal(true)
      setTipoAtr(type)
    }
    if (type === "enable-sms+change-apn") {
      setMsisdnModal(object)
      setVisibleModal(true)
      setTipoAtr(type)
    }
    if (type === "many-reset+change-apn") {
      setMsisdnModal(object)
      setVisibleModal(true)
      setTipoAtr(type)
    }
    if (type === "many-enable-sms+change-apn") {
      setMsisdnModal(object)
      setVisibleModal(true)
      setTipoAtr(type)
    }
    if (type === "many-disable-sms+change-apn") {
      setMsisdnModal(object)
      setVisibleModal(true)
      setTipoAtr(type)
    }
    
    if (type === "many-status-hlr") {
      setMsisdnModal(object)
      setVisibleModal(true)
      setTipoAtr(type)
    }
  };

  return (
    <div>
      <SkeletonTable show={loading} title="Linhas X Status" icon="fas fa-signal" pageSize={pageSize}/>

      {linesStatus && !loading ?(
        <HeliosTable
          title="Linhas X Status"
          subTitle={subTitle}
          columns={columns}
          icon="fas fa-signal"
          dataSource={linesStatus.lines.data || []}
          rowKey="id"
          total={linesStatus.lines.total}
          pageSize={pageSize}
          page={page}
          onChange={handlePaginationChange}
          onShowSizeChange={onShowSizeChange}
        // rowSelection={rowSelection}
        />
      ):""}

      <Drawer
        title="Filtros"
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        placement="right"
        onClose={onCloseFilter}
        visible={showFilter}
        width={500}
        style={styles.containerDrawer}
      >
        <Form layout="vertical">
        {clientId ===null &&
          <Form.Item label="CLIENTE">
            <AutoComplete
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerId();
                  setCustomerName();
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                setOptionsValue(option.label);
                setCustomerId(value);
              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
            </AutoComplete>
          </Form.Item>
}
          <Form.Item label="ICCID">
            <Input.Search
              autoComplete="off"
              name="iccid"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setIccid("");
                }
                onChangeInput(event);
              }}
              value={iccidInput}
            />
          </Form.Item>
          <Form.Item label="MSISDN">
            <Input.Search
              autoComplete="off"
              name="msisdn"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setMsisdn("");
                }
                onChangeInput(event);
              }}
              value={msisdnInput}
            />
          </Form.Item>
          <Radio.Group name="radio2" onChange={onChangeStatus} value={statusId}>
            <div>
              <Radio value={2}>
                <strong style={{ color: "green", marginLeft: 8 }}>
                  Online
                </strong>
              </Radio>
              <Radio value={1}>
                <strong style={{ color: "orange" }}>Offline</strong>
              </Radio>
            </div>
            <div style={{ marginTop: 10 }}>
              <Radio value={3}>
                <strong style={{ color: "red" }}>Blocked</strong>
              </Radio>
              <Radio value={5}>
                <strong>Not Connect</strong>
              </Radio>
            </div>
          </Radio.Group>
        </Form>
        <div style={styles.btnFilter}>
          <Button
            onClick={onClearFilter}
            type="danger"
            ghost
            style={styles.btnCancel}
          >
            LIMPAR
          </Button>
          <Button onClick={onCloseFilter} type="default">
            FECHAR
          </Button>
        </div>
      </Drawer>
      <ModalAtr visible={visibleModal} content={msisdnModal} tipoAtr={tipoAtr} onCancel={() => {
        setVisibleModal(false);
      }} apns={optionsApn} />
    </div>
  );
};

const styles = {
  subTitle: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },
  containerDrawer: {
    //width: 30
  },
  btnFilter: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    borderTop: "1px solid #e9e9e9",
    padding: "10px 16px",
    background: "#fff",
    textAlign: "right"
  },
  btnCancel: {
    marginRight: 8
  },
  online: {
    color: "green"
  }
};

export default LinesStatus;

import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    Label,
    Tooltip
} from "recharts";

import numeral from "numeral";
import "numeral/locales/pt-br";

const COLORS = ["blue", "orange", "green"];

const OperationStatusInfo = props => {
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });


    const { data, total, columns } = props;
    return (
        <Row gutter={24}>
            <Col
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ height: height <= 650 ? "20vh" : "15vh" }}
            >
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            minAngle={0}
                            dataKey="value"
                            data={data}
                            innerRadius={height <= 650 ? 40 : 50}
                            outerRadius={height <= 650 ? 60 : 70}
                            fill="#8884d8"
                            paddingAngle={0}
                        >
                            <Label
                                value={numeral(total).format("0,0")}
                                position="centerBottom"
                                className="label-top"
                                fontSize="18px"
                            />
                            <Label
                                value="Operações"
                                position="centerBottom"
                                className="label-bottom1"
                            />
                            <Label
                                value="acionadas"
                                position="centerTop"
                                className="label-bottom2"
                            />
                            {data.map((entry, index) => (
                                <Cell key={index} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </Col>
            <Col
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ height: height <= 650 ? "20vh" : "15vh" }}
            >
                <Table
                    showHeader={false}
                    bordered={false}
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    size="small"
                    rowKey="value"
                />
            </Col>
        </Row>
    );
};
export default OperationStatusInfo;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLogs } from "../../../store/actions/userLogAction";


import HeliosTable from "../../../components/HeliosTable/HeliosTable";
import {
    Tooltip,
    Drawer,
    Form,
    Button,
    Select,
    Input

} from "antd";

import SkeletonTable from "../../../components/Skeleton/SkeletonTable";

import { columns } from "./columnsTable";
const { Option } = Select;
const UserLogs = () => {


    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    // const [showModal,setShowModal]=setState(false)
    const [typeResource, setTypeResource] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const dispatch = useDispatch();
    const [line, setLine] = useState("");

    useEffect(() => {

        dispatch(getLogs(typeResource,line, page, pageSize));

    }, [dispatch, line,pageSize, page, typeResource]);

    const { logs, loading } = useSelector(state => state.logs);
    console.log(logs)

    function handleChange(value) {
        setTypeResource(value)
    }
    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(current);
    };

    const handlePaginationChange = (current) => {
        setPage(current);
    };


    const onShowFilter = () => {
        setShowFilter(true);
    };

    const onCloseFilter = () => {
        setShowFilter(false);
    };

    const onChangeInput = e => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
          if (e.target.name === "msisdnIccid" && e.target.value.length <= 20) {
            setLine(e.target.value);
            if (e.target.value.length <= 20) {
              setLine(e.target.value);
            }
          }
        }
      };

    const subTitle = (
        <div>
            <Tooltip placement="bottom" title="Filtros">
                <i
                    style={styles.subTitle}
                    className="fas fa-filter"
                    onClick={() => {
                        onShowFilter();
                    }}
                />
            </Tooltip>
        </div>
    );

    return (
        <div>
            <SkeletonTable show={loading} title="Minhas Atividades" icon="far fa-folder" pageSize={pageSize}/>

            {logs && !loading?(
                <HeliosTable
                    title="Minhas Atividades"
                    subTitle={subTitle}
                    columns={columns}
                    icon="far fa-folder"
                    dataSource={logs.data || []}
                    rowKey="_id"
                    total={logs.total}
                    pageSize={pageSize}
                    page={page}
                    onChange={handlePaginationChange}
                    onShowSizeChange={onShowSizeChange}
                // rowSelection={rowSelection}
                />
            ):""}

            <Drawer
                title="Filtros"
                headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
                placement="right"
                onClose={onCloseFilter}
                visible={showFilter}
                width={500}
                style={styles.containerDrawer}
            >
                <Form layout="vertical">

                    <Form.Item label="Tipo do Recurso">
                        <Select defaultValue="Todos" style={{ width: "100%" }} onChange={handleChange}>
                            <Option value="many-hard-reset">Resetar Linha em Massa</Option>
                            <Option value="hard-reset">Resetar Linha</Option>
                            <Option value="status-hlr">Status da Linha</Option>
                            <Option value="many-status-hlr">Status da Linha em Massa</Option>
                            <Option value="enable-sms">Ativar SMS</Option>
                            <Option value="many-enable-sms">Ativar SMS em Massa</Option>
                            <Option value="disable-sms">Desativar SMS</Option>
                            <Option value="many-disable-sms">Desativar SMS em Massa</Option>
                            <Option value="change-apn">Trocar APN</Option>
                            <Option value="many-change-apn">Trocar APN em Massa</Option>
                            <Option value="">Todos</Option>

                        </Select>
                    </Form.Item>
                    <Form.Item label="Linha">
                        <Input.Search
                        autoComplete="off"
                        name="msisdnIccid"
                        allowClear
                        onChange={event => {
                            if (event.target.value === "") {
                            setLine("");
                            }
                            onChangeInput(event);
                        }}
                        value={line}
                        />
                    </Form.Item>
                </Form>
                <div style={styles.btnFilter}>
                    <Button onClick={onCloseFilter} type="default">
                        FECHAR
          </Button>
                </div>
            </Drawer>

        </div>
    );
};

const styles = {
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    btnFilter: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
    },
    btnCancel: {
        marginRight: 8
    },
    online: {
        color: "green"
    }
};

export default UserLogs;

let backendHost;
// Server Test
const backendHostTest = "172.16.130.26";
// const backendHostTest = "172.16.130.9"; //ligando ao HML
const frontendHostTest = "172.16.130.25";
// Server Homologation
const backendHostHml = "172.16.130.9";
const frontendHostHml = "172.16.130.27";
// Server Production
const backendHostProd = "172.16.120.9";
const frontendHostProd = "172.16.120.29";
// Server Production Redundancia
//const backendHostProdRed = "172.16.120.32";
//const frontendHostProdRed = "172.16.120.31";

const backendHostProdRed = "helios.nlt-iot.com:3003";
const frontendHostProdRed = "helios.nlt-iot.com";


const hostname = window && window.location && window.location.hostname;

if (hostname === frontendHostTest) {
  backendHost = `http://${backendHostTest}`;
} else if (hostname === frontendHostHml) {
  backendHost = `http://${backendHostHml}`;
} else if (hostname === frontendHostProd) {
  backendHost = `http://${backendHostProd}`;
} else if (hostname === frontendHostProdRed) {
  backendHost = `https://${backendHostProdRed}`;
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || "http://localhost:3333";
}
export const API_ROOT = `${backendHost}/api/`;

import React from "react";
import Spinner from "../Spinner/Spinner";

import styles from "./styles";

const Backdrop = props =>
  props.show ? (
    <div style={styles.container} onClick={props.clicked}>
      {props.loading ? (
        <div style={styles.loadingCenter}>
          <Spinner type="circle" />
        </div>
      ) : null}
    </div>
  ) : null;

export default Backdrop;

import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
const placement = "bottomRight"


export const getManyIccidFile = (file) => dispatch => {

    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
   
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.COUNT_ICCIDS_START));
    notification.open({
        key: 'loadingManyIccidFile',
        message: 'Solicitando contagem e separação das Linhas do arquivo',
        description:
            'A contagem estará na tela de resumo',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });

    axios
        .post(API_ROOT + "lines/client/query-line-phase", formData, config)
        .then(res => {
            notification.close('loadingManyIccidFile')
            notification.success({
                message: "Verifique a contagem na tela de resumo",
                description:
                    'Iccids fora da fase Criado e Conferido estarão listadas!',
                duration: 10,
                placement
            });
            dispatch(fetchSuccess(actionType.COUNT_ICCIDS_SUCCESS, res.data));
            // dispatch(fetchStart(actionType.COUNT_ICCIDS_CLEAR));

        })
        .catch(err => {
            notification.close('loadingManyIccidFile')

            notification.error({
                message: err?.response?.data?.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.COUNT_ICCIDS_ERROR, err));
        });
};


export const setManyReserveNumber = (file,motive,protocol,modelName,iccidIn) => dispatch => {


    console.log(file)

    let data={
        "customerId":1, 
        "customerName":"Engenharia NLT", 
        "nameFile":file.name,
        "motive":motive,
        "protocol":protocol,
        "modelName":modelName,
        "iccidIn":iccidIn
    }
    dispatch(fetchStart(actionType.RESERVE_NUMBER_START));
    notification.open({
        key: 'loadingManyReserveNumber',
        message: 'Solicitando operação de Reserva de Numeração dos Iccids',
        description:
            'Em instantes você receberá a resposta do pedido das operação',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });

    axios
        .post(API_ROOT + "lines/client/many-reserve-numeration", data)
        .then(res => {
            notification.close('loadingManyReserveNumber')

            dispatch(fetchSuccess(actionType.RESERVE_NUMBER_SUCCESS, res.data));
            dispatch(fetchStart(actionType.RESERVE_NUMBER_CLEAR));

        })
        .catch(err => {
            notification.close('loadingManyReserveNumber')

            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.RESERVE_NUMBER_ERROR, err));
        });
};


import React from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "antd";

const VersionGlobal = props => {
    const history = useHistory();
    return (

        <Tooltip placement="bottom" title="Versões">
            <i
                style={styles.subTitle}
                className="fas fa-info-circle"
                onClick={() => {
                    history.push("/versions");
                }}
            />
        </Tooltip>

    );
}
const styles = {
    subTitle: {
        marginRight: 10,
        marginBottom: -10,
        fontSize: 20,
        cursor: "pointer"
    },
};

export default VersionGlobal;

import React, { useState, useEffect } from "react";

import { Card, Table, Row, Col, Button, Tooltip,Tag } from 'antd';
import { RollbackOutlined, EditOutlined } from "@ant-design/icons";

import HeliosTable from "../../../components/HeliosTable/HeliosTable";
import SkeletonTable from "../../../components/Skeleton/SkeletonTable";
import { getContracts } from "../../../store/actions/contractAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


const Contracts = (props) => {

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const dispatch = useDispatch();
    const history = useHistory();
    // const [customerId, setCustomerId] = useState(0);

    const columns = [
        {/*  */
            title: "Data de Início",
            dataIndex: "inception",

        },
        {
            title: "Plano",
            dataIndex: "plan_name",

        },
        {
            title: "Ordem de serviço",
            dataIndex: "order_id",

        },
        {
            title: "Quantidade Total",
            dataIndex: "quant",

        },

        {
            title: "Início do desconto",
            dataIndex: "discount_inception",

        },

        {
            title: "Tipo do desconto",
            dataIndex: "discount_type",

        },
        
        {
            title: "Tipo de venda",
            dataIndex: "sale_type",

        },
        {
            title: "Dias de isenção",
            dataIndex: "exemption_days",

        },
        {
            title: "Ações",
            key: "action",
            render: (text, record) => (
                <>
                    <Button
                        icon={<EditOutlined style={{ fontSize: 18 }} />}
                        style={{ borderColor: "orange", color: "orange" }}
                        type="default"
                        onClick={() => {
                            history.push({
                                pathname: `/edit-contract-operation/${record.id}`,
                                state: {
                                    id: record.id,//id do contrato
                                    create: false,
                                    customer__id: props.customerId,
                                    id_item: record.id_item,
                                    order_id: record.order_id,
                                    discount_inception: record.discount_inception,
                                    discount_type: record.discount_type,
                                    sale_type: record.sale_type,
                                    discount_value: record.discount_value,
                                    exemption_days: record.exemption_days,
                                    service_ti: record.service_ti,
                                    licensed: record.licensed,
                                    plan__id: record.plan_id,
                                    plan__name: record.plan_name,
                                    requester__id: record.requester_id,
                                    requester__name: record.requester_name,
                                    associeated__lines: record.associeated_lines,
                                    remaining__lines: record.remaining_lines,
                                    quant: record.quant,
                                    crm_code: record.crm_code,
                                    cod_emp: record.cod_emp,
                                    inception: record.inception,
                                    item_inception: record.item_inception

                                }
                            });
                        }}
                        size="small"
                    >
                        Editar
                    </Button>
                </>)
        }
    ]

    // {
    useEffect(() => {
        dispatch(getContracts(props.customerId, page, pageSize));

    }, [dispatch, props.customerId, pageSize, page]);

    const { listContracts, loading } = useSelector(state => state.contracts);
    console.log(listContracts)
    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(current);
    };

    const handlePaginationChange = (current) => {
        setPage(current);
    };

    const subTitle = (
        <div>
            <Tooltip placement="bottom" title="Adicionar Contrato">
                <i
                    style={styles.subTitle}
                    className="fas fa-user-plus"
                    onClick={() => {
                        history.push(
                            {
                                pathname: `/add-contract-operation`,
                                state: {
                                    create: true,
                                    customer__id: props.customerId
                                }
                            });
                    }}
                />
            </Tooltip>
        </div>
    )

    const expandedRowRender = (record) => {

        const columns = [
            { title: 'Gerente', dataIndex: 'requester_name', key: 'requester_name' },
            { title: 'Código da empresa', dataIndex: 'cod_emp', key: 'cod_emp' },
            { title: 'Linhas Associadas', dataIndex: 'associeated_lines', key: 'associeated_lines' },
            { title: 'Linhas Remanescentes', dataIndex: 'remaining_lines', key: 'remaining_lines' },
            { title: 'Código do CRM', dataIndex: 'crm_code', key: 'crm_code' },
            { title: 'Valor do desconto', dataIndex: 'discount_value', key: 'discount_value' },
            { title: 'Licença', dataIndex: 'licensed', key: 'licensed', render: (text, record) => (
                    <>
                        <Tag color={record.licensed === 0 ? "orange" : record.licensed === 1 ? "green" : "#A0A0A0"}>
                            {record.licensed===1?"Sim":"Não"}
                        </Tag></>
                )},

        ]
        console.log(record.licensed)
        return <Table columns={columns} dataSource={[record] || []} pagination={false} />;

    }
    return (
        <>
            <Card >
                <SkeletonTable show={loading} title={"Contratos - " + props.customerName} icon="fas fa-file-signature" pageSize={pageSize} />

                {listContracts && !loading ? (
                    <HeliosTable
                        title={"Contratos - " + props.customerName}
                        subTitle={subTitle}
                        columns={columns}
                        icon="fas fa-file-signature"
                        dataSource={listContracts.data || []}
                        rowKey="id"
                        total={listContracts.total}
                        pageSize={pageSize}
                        page={page}
                        expandable={{ expandedRowRender }}
                        onChange={handlePaginationChange}
                        onShowSizeChange={onShowSizeChange}
                    />
                ) : ""}
                <Row >
                    <Col span={24} style={{ textAlign: "right" }}>

                        <Button style={{ marginTop: 10, borderColor: "orange", color: "orange" }}
                            icon={<RollbackOutlined style={{ fontSize: 18 }} />}
                            type="default"
                            onClick={() => history.push("/customers")}
                        >
                            Voltar - Listar Clientes
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
}
const styles = {
    container: {
        borderRadius: "10px",
        background: "#fff",
        boxShadow:
            "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
}

export default Contracts;
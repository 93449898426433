import React from "react";
import { Link, withRouter } from "react-router-dom";

import { Menu } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  MobileOutlined,
  AimOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  // OneToOneOutlined,
  AreaChartOutlined,
  // FolderOutlined,
  ContactsOutlined
} from "@ant-design/icons";
import MenuPermission from "../../components/Permission/MenuPermission";
import SubMenuPermission from "../../components/Permission/SubMenuPermission";

const MenuOperation = props => {
  const { location } = props;
  return (
    <div>
      <Menu
        style={{ backgroundColor: "transparent" }}
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/dashboard-mobile">
          <DashboardOutlined style={{ fontSize: 20 }} />
          <span>Dashboard Mobile</span>
          <Link to="/dashboard-mobile" />
        </Menu.Item>



        {/* <Menu.Item key="/dashboard-lora">
          <DashboardOutlined style={{ fontSize: 20 }} />
          <span>Dashboard Lora</span>
          <Link to="/dashboard-lora" />
        </Menu.Item> */}


        <SubMenuPermission
          //onClick={e => console.log(e)}
          permissions={["ROLE_VIEW_LOCATION"]}
          key="maps"
          title={
            <span>
              <AimOutlined style={{ fontSize: 20 }} />
              <span>Geolocalização</span>
            </span>
          }
        >

          <MenuPermission
            permission="ROLE_VIEW_LOCATION"
            key="/location-mobile"
            title="Linhas"
            to="/location-mobile"
          />

        </SubMenuPermission>
        <SubMenuPermission
          //onClick={e => console.log(e)}
          permissions={["ROLE_VIEW_USER", "ROLE_VIEW_USER"]}
          key="sub1"
          title={
            <span>
              <UserOutlined type="user" style={{ fontSize: 20 }} />
              <span>Usuários</span>
            </span>
          }
        >
          <MenuPermission
            permission="ROLE_VIEW_USER"
            key="/user-operation"
            title="Operação"
            to="/user-operation"
          />
          {/* <MenuPermission
            permission="ROLE_VIEW_USER"
            key="/user-customer"
            title="Cliente"
            to="/user-customer"
          /> */}
        </SubMenuPermission>


        <SubMenuPermission
          //onClick={e => console.log(e)}
          permissions={["ROLE_VIEW_USER", "ROLE_VIEW_USER"]}
          key="sub20"
          title={
            <span>
              <ContactsOutlined type="user" style={{ fontSize: 20 }} />
              <span>Clientes</span>
            </span>
          }
        >

          {/* <Menu.Item key="/customers"
              permissions={["ROLE_VIEW_USER", "ROLE_VIEW_USER"]}>

              <UsergroupAddOutlined style={{ fontSize: 20 }} />
              <span>Lista de Clientes</span>
              <Link to="/customers" />
            </Menu.Item> */}

          <MenuPermission
            permission="ROLE_VIEW_USER"
            key="/customers"
            title="Listar Clientes"
            to="/customers"
          />

          <MenuPermission
            permission="ROLE_VIEW_USER"
            key="/resource-activities-clients"
            title="Listar Operações"
            to="/resource-activities-clients"
          />

          <MenuPermission
            permission="ROLE_VIEW_LINES"
            key="/transaction-activities"
            title="Listar Reciclagens"
            to="/transaction-activities"
          />


        </SubMenuPermission>

        <SubMenuPermission
          permissions={["ROLE_VIEW_LINES", "ROLE_VIEW_LINES"]}
          key="sub2"
          title={
            <span>
              <MobileOutlined style={{ fontSize: 20 }} />
              <span>MOBILE</span>
            </span>
          }
        >
          <MenuPermission
            permission="ROLE_VIEW_LINES"
            key="/lines-status"
            title="Linhas X Status"
            to="/lines-status"
          />
          <MenuPermission
            permission="ROLE_EDIT_LINES"
            key="/lines-session"
            title="Linhas X Sessão"
            to="/lines-session"
          />
          <MenuPermission
            permission="ROLE_EDIT_LINES"
            key="/lines-consumption"
            title="Linhas X Consumo"
            to="/lines-consumption"
          />
          <MenuPermission
            permission="ROLE_EDIT_LINES"
            key="/lines-licence"
            title="Linhas X Licença"
            to="/lines-licence"
          />
        </SubMenuPermission>
        {/* LORA */}
        {/* <SubMenuPermission
          permissions={["ROLE_VIEW_USER", "ROLE_VIEW_USER"]}
          key="sub3"
          title={
            <span>
              <OneToOneOutlined style={{ fontSize: 20 }} />
              <span>LORA</span>
            </span>
          }
        >
          <MenuPermission
            permission="ROLE_VIEW_USER"
            key="/devices"
            title="Devices"
            to="/devices"
          />
          <MenuPermission
            permission="ROLE_VIEW_USER"
            key="/devices-consumption"
            title="Devices X Consumo"
            to="/devices-consumption"
          />
        </SubMenuPermission> */}
        {/* <Menu.Item key="/devices">
          <OneToOneOutlined style={{ fontSize: 20 }} />
          <span>Device</span>
          <Link to="/devices" />
        </Menu.Item> */}

        <SubMenuPermission
          permissions={["ROLE_VIEW_REPORTS", "ROLE_VIEW_REPORTS"]}
          key="sub4"
          title={
            <span>
              <AreaChartOutlined style={{ fontSize: 20 }} />
              <span>Relatórios</span>
            </span>
          }
        >
          <MenuPermission
            permission="ROLE_VIEW_REPORTS"
            key="/reports-billing-details"
            title="Faturamento"
            to="/reports-billing-details"
          />

          <MenuPermission
            permission="ROLE_VIEW_REPORTS"
            key="/reports-consumptions"
            title="Consumo"
            to="/reports-consumptions"
          />
          {/* <MenuPermission
            permission="ROLE_VIEW_REPORTS"
            key="/reports-simcards"
            title="Simcards"
            to="/reports-simcards"
          /> */}

          <MenuPermission
            permission="ROLE_VIEW_REPORTS"
            key="/reports-customers"
            title="Clientes"
            to="/reports-customers"
          />
        </SubMenuPermission>


        <SubMenuPermission
          permissions={["ROLE_VIEW_OPERATION_MASSIVE"]}
          key="sub5"
          title={
            <span>
              <i
                style={{ fontSize: 18, marginRight: 4 }}
                className="fa fa-industry"
              />
              <span>Operações</span>
            </span>
          }
        >
          <MenuPermission
            permission="ROLE_ADD_OPERATION_MASSIVE"
            key="/operations-massive"
            title="Criar Operação"
            to="/operations-massive"
          />

       
          <MenuPermission
            permission="ROLE_VIEW_OPERATION_MASSIVE"
            key="/resource-activities"
            title="Listar Operações"
            to="/resource-activities"
          />
        </SubMenuPermission>
        <SubMenuPermission
          permissions={["ROLE_EDIT_LINES", "ROLE_EDIT_LINES"]}
          key="sub10"
          title={
            <span>
              {/* <FolderOutlined style={{ fontSize: 20 }} /> */}
              <i
                style={{ fontSize: 18, marginRight: 4 }}
                className="fa fa-industry"
              />
              <span>Operações-OS</span>
            </span>
          }
        >
          <MenuPermission
              permission="ROLE_ADD_OPERATION_MASSIVE"
              key="/operations-massive-os"
              title="Criar Operação - OS"
              to="/operations-massive-os"
            />
            <MenuPermission
            permission="ROLE_VIEW_OPERATION_MASSIVE"
            key="/resource-activities-os"
            title="Listar Operações - OS"
            to="/resource-activities-os"
          />
          </SubMenuPermission>

        
        <SubMenuPermission
          permissions={["ROLE_VIEW_LINES", "ROLE_VIEW_LINES"]}
          key="sub6"
          title={
            <span>
              <FolderOutlined style={{ fontSize: 20 }} />
              <span>Histórico</span>
            </span>
          }
        >

          <MenuPermission
            icon={< FolderOpenOutlined style={{ fontSize: 20 }} />}
            permission="ROLE_VIEW_LINES"
            key="/user-activities"
            title="Atividades"
            to="/user-activities"
          />
          <MenuPermission
            icon={< FolderOpenOutlined style={{ fontSize: 20 }} />}
            permission="ROLE_VIEW_USER"
            key="/all-activities"
            title="Geral"
            to="/all-activities"
          />
        </SubMenuPermission>

      </Menu>
    </div>
  );
};

export default withRouter(MenuOperation);

import React from "react";
import { Link } from "react-router-dom";

import { Menu } from "antd";
import { DashOutlined } from '@ant-design/icons';

const MenuCustomer = () => {
  return (
    <div>
      
      <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item key="1" >
          <DashOutlined style={{ fontSize: 20 }} />
          <span>Dashboard</span>
          <Link to="/" />
        </Menu.Item>
        
      </Menu>
    </div>
  );
};

export default MenuCustomer;

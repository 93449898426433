import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLinesSession } from "../../../../store/actions/lineSessionAction";



import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import {
    Tooltip,
    Drawer,
    Form,
    Input,
    Button,
    Select,
    Tag
    } from "antd";

import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";

import { columns } from "./columnsTable";
const { Option } = Select;
const LinesSession = () => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
   
    const [typeServer, setTypeServer] = useState("mariadb-radiusspo");
    const [nameServer, setNameServer] = useState("São Paulo");
    const [msisdnInput, setMsisdnInput] = useState("");
    const [msisdn,setMsisdn] = useState("")
    const [nameTable] = useState("Linhas X Sessão - ")
    const [showFilter, setShowFilter] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLinesSession( msisdn,typeServer,page,pageSize));

    }, [dispatch, msisdn,typeServer, pageSize, page]);
  
   
    const { lineSession, loading } = useSelector(state => state.lineSession);

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(current);
    };
    function handleChange(value) {
        setTypeServer(value.value)
        console.log(value)
        setNameServer(value.label)
    }

    const handlePaginationChange = (current) => {
        setPage(current);
    };

    const onClearFilter = () => {
        setMsisdn("");
        setMsisdnInput("");
    };

    const onShowFilter = () => {
        setShowFilter(true);
    };

    const onCloseFilter = () => {
        setShowFilter(false);
    };

    const onChangeInput = e => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
          
          if (e.target.name === "msisdn" && e.target.value.length <= 13) {
            setMsisdnInput(e.target.value);
            if (e.target.value.length === 13) {
              setMsisdn(e.target.value);
            }
          }
        }
      };


    const subTitle = (

        <div>
            <Tag color={"green"}>{nameServer}</Tag>
            {msisdn && <Tag color={"gray"}>{msisdn}</Tag> }
            

            <Tooltip placement="bottom" title="Filtros">
                <i
                    style={styles.subTitle}
                    className="fas fa-filter"
                    onClick={() => {
                        onShowFilter();
                    }}
                />
            </Tooltip>
        </div>
    );

    return (
        <div>
            <SkeletonTable show={loading} title={nameTable + nameServer} icon="fas fa-satellite-dish"  pageSize={pageSize}/>
            {lineSession && !loading ?(
                <HeliosTable
                    title={nameTable + nameServer}
                    subTitle={subTitle}
                    columns={columns}
                    icon="fas fa-satellite-dish"
                    dataSource={lineSession.lines.data || []}
                    rowKey="location"
                    total={lineSession.lines.total}
                    pageSize={pageSize}
                    page={page}
                    onChange={handlePaginationChange}
                    onShowSizeChange={onShowSizeChange}
                // rowSelection={rowSelection}
                />
            ):""}

            <Drawer
                title="Filtros"
                headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
                placement="right"
                onClose={onCloseFilter}
                visible={showFilter}
                width={500}
                style={styles.containerDrawer}
            >
                <Form layout="vertical">
                    <Form.Item label="Servidor de tráfego">
                        <Select  labelInValue defaultValue={{ value: 'mariadb-radiusspo' }} style={{ width: "100%" }} onChange={handleChange}>

                            <Option value="mariadb-radiusspo">São Paulo</Option>

                            <Option value="mariadb-radiuscas">Campinas</Option>

                        </Select>
                    </Form.Item>
                    <Form.Item label="MSISDN">
                        <Input.Search
                            autoComplete="off"
                            name="msisdn"
                            allowClear
                            onChange={event => {
                                if (event.target.value === "") {
                                    setMsisdn("");
                                }
                                onChangeInput(event);
                            }}
                            value={msisdnInput}
                        />
                    </Form.Item>
                </Form>
                <div style={styles.btnFilter}>
                    <Button
                        onClick={onClearFilter}
                        type="danger"
                        ghost
                        style={styles.btnCancel}
                    >
                        LIMPAR
          </Button>
                    <Button onClick={onCloseFilter} type="default">
                        FECHAR
          </Button>
                </div>
            </Drawer>

        </div>
    );
};

const styles = {
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    btnFilter: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
    },
    btnCancel: {
        marginRight: 8
    },
    online: {
        color: "green"
    }
};

export default LinesSession;

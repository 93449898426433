import React from "react";
import { Route, Switch } from "react-router-dom";

import LayoutDefault from "../LayoutDefault";
import MenuOperation from "./MenuOperation";
import DashboardMobile from "../../views/Operation/Dashboard/DashboardMobile";
import UserOperation from "../../views/Operation/Users/Operation/UserOperation";
import UserCustomer from "../../views/Operation/Users/Customer/UserCustomer";
import FormOperation from "../../views/Operation/Users/Operation/FormOperation";
import UpdatePassword from "../../views/Operation/Users/Operation/UpdatePassword";

import Page404 from "../../views/PagesInfo/Page404";
import RoutePermission from "../../components/Permission/RoutePermission";
import LinesStatus from "../../views/Operation/Lines/LinesStatus/LinesStatus";
import DashboardLora from "../../views/Operation/Dashboard/DashboardLora";
import LinesConsumption from "../../views/Operation/Lines/LinesConsumption/LinesConsumption";
import LinesSession from "../../views/Operation/Lines/LinesSession/LinesSession";

import LinesLicence from "../../views/Operation/Lines/LinesLicence/LinesLicense";

import Devices from "../../views/Operation/Devices/Devices";
import DevicesConsumption from "../../views/Operation/Devices/DevicesConsumption/DevicesConsumption"
import ReportBillingDetail from "../../views/Operation/Reports/ReportBilling/ReportBillingDetail"
// import ReportSimcards from "../../views/Operation/Reports/ReportSimcards/ReportSimcards"
import ReportConsumptions from "../../views/Operation/Reports/ReportConsumptions/ReportConsumptions"
import ReportCustomer from "../../views/Operation/Reports/ReportCustomer/ReportCustomer"
import Customers from "../../views/Operation/Customers/Customers"
import GeolocationMobile from "../../views/Operation/Geolocation/GeolocationMobile"
import UserLogs from "../../views/Operation/Logs/UserLogs"
import AllLogs from "../../views/Operation/Logs/AllLogs"
import OperationsMassive from "../../views/Operation/OperationsMassive/OperationsMassive"




import Version from "../../views/Global/Version";
import Documentation from "../../views/Global/Documentation"
import ResourceLogs from "../../views/Operation/Logs/ResourceLogs/ResourceLogs";
import LineLogs from "../../views/Operation/Logs/ResourceLogs/LineLogs";
import LineLogsOs from "../../views/Operation/Logs/ResourceLogsOs/LineLogsOs";
import ResourceLogsOs from "../../views/Operation/Logs/ResourceLogsOs/ResourceLogsOs";

import TransactionLogs from "../../views/Operation/Logs/TransactionLogs/TransactionResourceLogs";
import TransactionLineLogs from "../../views/Operation/Logs/TransactionLogs/TransactionLineLogs";
import ResourceLogsClients from "../../views/Operation/Logs/ResourceLogsClients/ResourceLogsClients";
import LineLogsClients from "../../views/Operation/Logs/ResourceLogsClients/LineLogsClients";
import OperationsMassiveOs from "../../views/Operation/OperationsMassiveOs/OperationsMassiveOs";
import CreateCustomer from "../../views/Operation/Users/Customer/CreateCustomer";
import ViewCustomer from "../../views/Operation/Users/Customer/ViewCustomer";
import CreateContract from "../../views/Operation/Contracts/CreateContract";
import CreateStockOS from "../../views/Operation/OperationsStockOS/CreateStockOS";
import CreateStockOSActived from "../../views/Operation/OperationsStockOSActived/CreateStockOSActived";
import CreateStockOSDemand from "../../views/Operation/OperationsStockOSOnDemand/CreateStockOSDemand";


const LayoutOperation = props => {
  if (props.location.pathname === "/login") {
    props.history.push("/");
  }
  if (props.location.pathname === "/") {
    props.history.push("/dashboard-mobile");
  }

  return (
    <LayoutDefault menu={<MenuOperation />}>
      <Switch>
        <Route path="/dashboard-mobile" exact>
          <DashboardMobile />
        </Route>

        <Route path="/dashboard-lora" exact>
          <DashboardLora />
        </Route>

        <RoutePermission
          permission="ROLE_VIEW_USER"
          path="/user-operation"
          renderComponent={<UserOperation />}
        />

        <RoutePermission
          permission="ROLE_VIEW_USER"
          path="/user-customer"
          renderComponent={<UserCustomer />}
        />

        <RoutePermission
          permission="ROLE_ADD_USER"
          path="/add-user-operation"
          renderComponent={<FormOperation />}
        />

        <RoutePermission
          permission="ROLE_ADD_USER"
          path="/add-customer-operation"
          renderComponent={<CreateCustomer />}
        />


        <RoutePermission
          permission="ROLE_ADD_USER"
          path="/edit-customer-operation/:id"
          renderComponent={<CreateCustomer />}
        />
        <RoutePermission
          permission="ROLE_ADD_USER"
          path="/view-customer-operation/:id"
          renderComponent={<ViewCustomer />}
        />

        <RoutePermission
          permission="ROLE_UPDATE_USER"
          path="/edit-user-operation/:id"
          renderComponent={<FormOperation />}
        />

        <RoutePermission
          permission="ROLE_UPDATE_USER"
          path="/edit-customer-operation/:id"
          renderComponent={<FormOperation />}
        />

        <RoutePermission
          permission="ROLE_ADD_USER"
          path="/add-contract-operation"
          renderComponent={<CreateContract />}
        />
        <RoutePermission
          permission="ROLE_UPDATE_USER"
          path="/edit-contract-operation/:id"
          renderComponent={<CreateContract />}
        />

        <RoutePermission
          permission="ROLE_UPDATE_USER"
          path="/create-stock-os/:id"
          renderComponent={<CreateStockOS />}
        />

        <RoutePermission
          permission="ROLE_UPDATE_USER"
          path="/create-stock-os-demand/:id"
          renderComponent={<CreateStockOSDemand />}
        />

        <RoutePermission
          permission="ROLE_UPDATE_USER"
          path="/create-stock-os-active/:id"
          renderComponent={<CreateStockOSActived />}
        />

        <Route path="/change-password" exact>
          <UpdatePassword />
        </Route>

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/lines-status"
          renderComponent={<LinesStatus />}
        />
        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/lines-session"
          renderComponent={<LinesSession />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/lines-consumption"
          renderComponent={<LinesConsumption />}
        />
        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/lines-licence"
          renderComponent={<LinesLicence />}
        />

        <RoutePermission
          permission="ROLE_VIEW_REPORTS"
          path="/reports-billing-details"
          renderComponent={<ReportBillingDetail />}
        />
        {/* <RoutePermission
          permission="ROLE_VIEW_REPORTS"
          path="/reports-simcards"
          renderComponent={<ReportSimcards />}
        /> */}
        <RoutePermission
          permission="ROLE_VIEW_REPORTS"
          path="/reports-consumptions"
          renderComponent={<ReportConsumptions />}
        />
        <RoutePermission
          permission="ROLE_VIEW_REPORTS"
          path="/reports-customers"
          renderComponent={<ReportCustomer />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LOCATION"
          path="/location-mobile"
          renderComponent={<GeolocationMobile />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/user-activities"
          renderComponent={<UserLogs />}
        />

        <RoutePermission
          permission="ROLE_VIEW_OPERATION_MASSIVE"
          path="/resource-activities"
          renderComponent={<ResourceLogs />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/line-activities/:id"
          renderComponent={<LineLogs />}
        />

        <RoutePermission
          permission="ROLE_VIEW_OPERATION_MASSIVE_OS"
          path="/resource-activities-os"
          renderComponent={<ResourceLogsOs />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/line-activities-os/:id"
          renderComponent={<LineLogsOs />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/resource-activities-clients"
          renderComponent={<ResourceLogsClients />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/line-activities-clients/:id"
          renderComponent={<LineLogsClients />}
        />

        <RoutePermission
          permission="ROLE_ADD_OPERATION_MASSIVE"
          path="/operations-massive"
          renderComponent={<OperationsMassive />}
        />

        <RoutePermission
          permission="ROLE_ADD_OPERATION_MASSIVE_OS"
          path="/operations-massive-os"
          renderComponent={<OperationsMassiveOs />}
        />


        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/transaction-activities"
          renderComponent={<TransactionLogs />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/transaction-line-activities/:id"
          renderComponent={<TransactionLineLogs />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/all-activities"
          renderComponent={<AllLogs />}
        />

        <RoutePermission
          permission="ROLE_VIEW_LINES"
          path="/customers"
          renderComponent={<Customers />}
        />

        <Route path="/versions" exact>
          <Version />
        </Route>
        <Route path="/docs" exact>
          <Documentation />
        </Route>

        {/*Quando criar um permission substituir o componente abaixo (Route) por RoutePermission*/}
        <Route path="/devices" exact>
          <Devices />
        </Route>

        <Route path="/devices-consumption" exact>
          <DevicesConsumption />
        </Route>

        <Route>
          <Page404 />
        </Route>
      </Switch>
    </LayoutDefault>
  );
};

export default LayoutOperation;

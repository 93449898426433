import React from 'react'
export const columns = [
  {
    title: "Razão Social",
    dataIndex: "name",
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,
    width: 200,
    ellipsis: true,
  },
  {

    title: "Nome Fantasia",
    dataIndex: "nomeFantasiaC",
    width: 150,
    ellipsis: true,

  },
  {
    title: "Indústria",
    dataIndex: "industry",
    width: 220,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,
  },
  {
    title: "CNPJ",
    dataIndex: "cpfcnpjC",
    width: 140
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 250
  },
  {
    title: "Tel. Comercial",
    dataIndex: "phoneOffice",
    width: 130,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "Tel. Alternativo",
    dataIndex: "phoneAlternate",
    width: 130,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "Rua",
    dataIndex: "billingAddressStreet",
    width: 250,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "Bairro",
    dataIndex: "billingAddressDistrict",
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,
    width: 200,
  },
  {

    title: "Cidade",
    dataIndex: "billingAddressCity",
    width: 120,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "UF",
    dataIndex: "billingAddressState",
    width: 50,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "CEP",
    dataIndex: "billingAddressPostalcode",
    width: 100,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "País",
    dataIndex: "billingAddressCountry",
    width: 100,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  // {
  //      title: "Rua",
  //   dataIndex: "shippingAddressStreet",
  //   show: true
  // },
  // {
  //      title: "Bairro",
  //   dataIndex: "shippingAddressDistrict",
  //   show: true
  // },
  // {
  //      title: "Cidade",
  //   dataIndex: "shippingAddressCity",
  //   show: true
  // },
  // {
  //      title: "UF",
  //   dataIndex: "shippingAddressState",
  //   show: true
  // },
  // {
  //      title: "CEP",
  //   dataIndex: "shippingAddressPostalcode",
  //   show: true
  // },
  // {
  //      title: "País",
  //   dataIndex: "shippingAddressCountry",
  //   show: true
  // },
  {

    title: "Status",
    dataIndex: "statusC",
    width: 100,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "Vencimento",
    dataIndex: "dueDay",
    width: 100,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "Data da ativação",
    dataIndex: "activationDate",
    width: 120,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {
    title: "Data da desativação",
    dataIndex: "deactivationDate",
    width: 120,
    render: (text, obj) => <span>{text === null ? "-" : text}</span>,

  },
  {

    title: "Total de linhas",
    dataIndex: "totalLines",
    width: 120
  }
];

import * as actionType from "../types";
import moment from "moment";

const initialState = {
    logs: null,
    logsSummarized:null,
    lineController:null,
    lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
    errors: null,
    message: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.GET_LOGS_START:
            return {
                ...state,
                loading: true
            };
        case actionType.GET_LOGS_SUCCESS:
            return {
                ...state,
                logs: action.payload,
                errors: null,
                loading: false
            };
        case actionType.GET_LOGS_SUMMARIZED:
            return {
                ...state,
                logsSummarized: action.payload,
                lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
                errors: null,
                loading: false
            };
        case actionType.GET_LINE_CONTROLLER_SUCCESS:
                return {
                    ...state,
                    lineController: action.payload,
                    errors: null,
                    loading: false
                };
        case actionType.GET_LOGS_FAIL:
            return {
                ...state,
                errors: action.errors,
                loading: false
            };

        default:
            return state;
    }
}

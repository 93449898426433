
import { AutoComplete, Card, Col, Form, Input, Row, Select, } from 'antd';
import React, { useEffect, useState } from 'react'
import { getCustomerByName } from '../../../store/actions/customerAction';
import { useDispatch, useSelector } from 'react-redux';


const FormOperationOs = (props) => {
   
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [optionsValue, setOptionsValue] = useState("");
  const [protocol,setProtocol]=useState("");

  const [modelName,setModelName]=useState("");

  const [protocolInput, setProtocolInput] = useState("");

  const [motive,setMotive]=useState("");
  const [motiveInput,setMotiveInput]=useState("");

  const [email,setEmail]=useState("");
  const [emailInput,setEmailInput]=useState("");
  const [days,setDays]=useState("");
  const [daysInput,setDaysInput]=useState("");

  
  if(customerId)props.setCustomerId(customerId)
  if(customerName)props.setCustomerName(customerName)
  if(protocol)props.setProtocol(protocol)
  if(motive)props.setMotive(motive)
  if(email)props.setEmail(email)
  if(modelName!=="")props.setModelName(modelName)
 
  if(days!=="")props.setDays(days)


  const dispatch = useDispatch();
  const setIsVerified = props.setIsVerified
  const onChangeInput = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "protocol" && e.target.value.length <= 4) {
        setProtocolInput(e.target.value)
        if (e.target.value.length === 4) {
          setProtocol(e.target.value);
        }
      }
      if (e.target.name === "days" && e.target.value.length <= 3) {
        setDaysInput(e.target.value)
        if (e.target.value.length <=3) {
          setDays(e.target.value);
        }
      }
    }

    if (e.target.name === "motive") {
      setMotiveInput(e.target.value)
      if (e.target.value.length > 4) {
          setMotive(e.target.value)
      }
    };
    if (e.target.name === "email") {
      setEmailInput(e.target.value)
      if (e.target.value.length > 4) {
          setEmail(e.target.value)
      }
    };
    
  }
  

  useEffect(() => {
    if (customerName) {
      dispatch(getCustomerByName(customerName));
    }
    if ((customerId && motive!=="") || (modelName!=="" && motive!=="")){
      setIsVerified(true)
    } else {
      setIsVerified(false)
    }

  }, [dispatch, customerId, customerName,protocol,modelName,motive,setIsVerified]);

  const customers = useSelector(state => state.customers);

  useEffect(() => {
    if (customers.customers) {
      let options = [];
      customers.customers.data.map(obj => {
        return options.push({ value: obj.id.toString(), label: obj.name });
      });
      setOptionsCustomer(options);
    }
  }, [customers]);
  const selectChange=(value)=> {
    console.log(`selected ${value}`);
    setModelName(value)
  }

  const typeResource = props.typeResource
  const { Option } = Select;
  return (
    <>
    {/* <Animate transitionName="fade" transitionAppear> */}
      <Card style={styles.container}>
      {typeResource.includes("Reserva")?(
        <Form.Item label="Modelo do SimCard:" layout="vertical">
         <Select style={{ width: 120 }} onChange={selectChange}>
         <Option value="4FF">4FF</Option>
         <Option value="3FF">3FF</Option>
         <Option value="2FF">2FF</Option>
         </Select>
         </Form.Item>
      ):""}
      {!typeResource.includes("Reserva")?(
        <Form style={{ marginTop: 3 }} layout="vertical">
          <Form.Item label="Cliente:">
            <AutoComplete
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerId();
                  setCustomerName();
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                console.log("Onselect:" + value)
                console.log("Onselect:" + option)

                setOptionsValue(option.label);
                setCustomerId(value);
                setCustomerName(option.label);

              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
        ):""}
        
        <Form style={{ marginTop: 3 }} layout="vertical">
          <Form.Item label="Motivo da Operação:">
            <Input.TextArea
             name="motive"
             allowClear
            onChange={event => {
                    if (event.target.value === "") {
                      setMotive("");
                    }
                    onChangeInput(event);
                  }}
                  value={motiveInput}/>
          </Form.Item>
        </Form>
        <Row gutter={24}>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={24}>
            <Form style={{ marginTop: 3 }} layout="vertical">
              <Form.Item label="Protocolo:">
                <Input
                  name="protocol"
                  allowClear
                  onChange={event => {
                    if (event.target.value === "") {
                      setProtocol("");
                    }
                    onChangeInput(event);
                  }}
                  value={protocolInput}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={24}>
            {!typeResource.includes("Reserva")?(
            <Form style={{ marginTop: 3 }} layout="vertical" rules={[{ type: 'email' }]}>
              <Form.Item label="Email/Nome do Requerente:">
                <Input 
                name="email"
               onChange={event => {
                       if (event.target.value === "") {
                         setEmail("");
                       }
                       onChangeInput(event);
                     }}
                     value={emailInput}
                     />
              </Form.Item>
            </Form>
            ):""}
          </Col>
        </Row>
        {typeResource.includes("Temporária") ? (
       
          <Form style={{ marginTop: 3 }} layout="vertical">
              <Form.Item label="Quantidade de dias:">
                <Input
                  name="days"
                  allowClear
                  onChange={event => {
                    if (event.target.value === "") {
                      setDays("");
                    }
                    onChangeInput(event);
                  }}
                  value={daysInput}
                />
              </Form.Item>
            </Form>
        ):""}
      </Card>
      {/* </Animate> */}
    </>
  )

}

const styles = {
  container: {
    borderRadius: "10px",
    background: "#fff",
    boxShadow:
      "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  },
  containerTitle: {
    color: "gray",
    fontWeight: "bold"
  },
  subTitle: {
    display: "flex",
    justifyContent: "flex-end",
    color: "gray",
    fontWeight: "bold"
  }
};

export default FormOperationOs;
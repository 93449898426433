import axios from "axios";
import * as actionType from "../types";

import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";


const deleteUserSuccess = (message, userId) => {
  return {
    type: actionType.DELETE_USERS_SUCCESS,
    message: message,
    userId: userId
  };
};


export const getUsers = (userType, page, perPage) => dispatch => {
  dispatch(fetchStart(actionType.GET_USERS_START));
  axios
    .get(
      API_ROOT + `users?userType=${userType}&page=${page}&perPage=${perPage}`
    )
    .then(res => {
      dispatch(fetchSuccess(actionType.GET_USER_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionType.GET_USERS_FAIL, error.response.status));
    });
};
export const AllUsers = (email, perPage) => dispatch => {
  dispatch(fetchStart(actionType.GET_USERS_START));
  axios
    .get(
      API_ROOT + `usersByEmail?userType=1&email=${email}&perPage=${perPage}`
    )
    .then(res => {
      console.log(res.data)
      dispatch(fetchSuccess(actionType.GET_USER_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionType.GET_USERS_FAIL, error));
    });
};

export const createUser = (
  name,
  email,
  is_active,
  user_type,
  roleId,
  clientId
) => dispatch => {
  dispatch(fetchStart(actionType.GET_USERS_START));
  const data = {
    name: name,
    email: email,
    is_active: is_active,
    user_type: user_type,
    roleId: roleId,
    clientId : clientId 
  };
  axios
    .post(API_ROOT + "users", data)
    .then(res => {
      dispatch(fetchSuccess(actionType.CREATE_USERS_START, res.data.message));
    })
    .catch(error => {
      const resError = error.response.data;
      dispatch(fetchError(actionType.GET_USERS_FAIL, resError.error.message.errors));
    });
};

export const deleteUser = userId => dispatch => {
  dispatch(fetchStart(actionType.GET_USERS_START));
  axios
    .delete(API_ROOT + `users/${userId}`)
    .then(res => {
      dispatch(deleteUserSuccess(res.data.message, userId));
    })
    .catch(error => {
      dispatch(fetchError(actionType.GET_USERS_FAIL, error));
    });
};

export const updateUser = (userId, user) => dispatch => {
  dispatch(fetchStart(actionType.GET_USERS_START));
  axios
    .put(API_ROOT + `users/${userId}`, user)
    .then(res => {
      console.log("RES UPDATE:  ", res.data.message);
      dispatch(fetchSuccess(actionType.UPDATE_USERS_SUCCESS, res.data.message))
    })
    .catch(error => {
      console.log("ERROR:", error);
      dispatch(fetchError(actionType.GET_USERS_FAIL, error));
    });
};

export const enableOrDisableUser = (userIds, isActive) => dispatch => {
  dispatch(fetchStart(actionType.GET_USERS_START));
  axios
    .put(API_ROOT + "users", { ids: userIds, is_active: isActive })
    .then(res => {
      console.log("RES Enable or Disable:  ", res.data.message);
      dispatch(fetchSuccess(actionType.ENABLE_DISABLE_USERS_SUCCESS, res.data.message))
    })
    .catch(error => {
      console.log("ERROR:", error.response);
      dispatch(fetchError(actionType.GET_USERS_FAIL, error));
    });
};
export const changePasswordUser = (userId, user) => dispatch => {
  dispatch(fetchStart(actionType.GET_USERS_START));
  axios
    .put(API_ROOT + `users/change-password/${userId}`, user)
    .then(res => {
      console.log("RES UPDATE:  ", res.data.message);
      dispatch(fetchSuccess(actionType.UPDATE_USERS_SUCCESS, res.data.message))
    })
    .catch(error => {
      console.log("ERROR:", error);
      dispatch(fetchError(actionType.GET_USERS_FAIL, error));
    });
};

export const clearErrors = () => dispatch => {
  dispatch({ type: actionType.CLEAR_ERRORS_USER });
};

export const clearMessage = () => dispatch => {
  dispatch({ type: actionType.CLEAR_MESSAGE_USER });
};

import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Button, Steps, Card } from "antd";
import "numeral/locales/pt-br";
import { SolutionOutlined, RightOutlined, LeftOutlined, StopOutlined } from '@ant-design/icons';

import CardInfo from "../container/Dashborad/CardInfo";
const { Step } = Steps;

const CustomUnorderList = ({ items = [] }) => {
  return (
    <>
      {items.map((value) => (
        <ul>
          {value}
        </ul>
      ))}
    </>
  );
};

CustomUnorderList.prototype = {
  items: PropTypes.array
};

const styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 900,
  },
  subTitle: {
    background: "linear-gradient(80deg, #43A047, #FFEB3B)",
    borderRadius: 10,
    padding: 17,
    marginRight: 10,
    fontSize: 22,
    color: "#ffffff"
  }
};

const Version = () => {
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: 'Versão 2.0',
      content: (
        <>
          <ul>
            <li>
              <strong>Usuários clientes da NLT</strong>
              <ul>
                <li>
                  <u>Funcionalidades:</u>

                  <ul>
                    <li>
                      Módulo logística NLT:
                      <CustomUnorderList items={[
                        'Criação massivo de pedido Sob Demanda',
                        'Criação massivo de pedido Estoque',
                        'Criação massivo de pedido Estoque Ativado'
                      ]} />
                    </li>

                    <li>
                      Acompanhamento das operações de logística:
                      <CustomUnorderList items={[
                        'Visualização do processo',
                        'Export de romaneio do pedido'
                      ]} />
                    </li>
                  </ul>

                </li>
              </ul>
            </li>
          </ul>
        </>
      ),
      date: "10 de Outubro de 2023"
    },
    {
      title: 'Versão 1.10',
      content: <ul>
        <li>
          <strong>Usuários clientes da NLT</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li> Criar Operação Massiva como cliente:
                  <ul>Habilitar SMS com APN</ul>
                  <ul>Desabilitar SMS com APN</ul>
                  <ul>Reset das linhas com APN</ul>
                  <li> Acompanhamento das operações como cliente através dos recursos:
                    <ul>Habilitar/Desabilitar SMS</ul>
                    <ul>Atribuir APN</ul>
                    <ul>Reset das linhas</ul>
                  </li>
                  <li> Listagem das operações com informações por:
                    <ul>Lote e nome do arquivo</ul>
                    <ul>Total das linhas</ul>
                    <ul>Status do processo como em espera, processando e finalizado, além de uma barra visual e a porcentagem do processo</ul>
                  </li>
                  <li>Filtros:
                    <ul>Seleção do tipo de recurso</ul>
                  </li>
                </li>

              </ul>
            </li>
          </ul>
        </li >
      </ul >,
      date: "Agosto de 2021"
    }
    ,
    {
      title: 'Versão 1.9',
      content: <ul>
        <li>
          <strong>Tela de Criar Operações Massivas Com OS</strong>
          <ul>
            <li>
              <u>Funcionalidades com OS:</u>
              <ul>
                <li> Criar Operação Massiva:
                  <ul>Reserva de numeração</ul>
                  <ul>Suspensão temporária</ul>
                  <ul>Suspensão a pedido do cliente</ul>
                  <ul>Suspensão perda/roubo</ul>
                  <ul>Suspensão quarentena</ul>
                </li>

              </ul>
            </li>
          </ul>
        </li >

        <li>
          <strong>Tela de Listagem de Operações Massivas com OS</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Lista de operações com tipo de recurso com OS. </li>
                <li>Quantidade de linhas e número da OS/Request</li>
                <li>Gráfico com processo linha a linha com status de: Processando, Em espera, Erro e Sucesso</li>
              </ul>
            </li>
          </ul>
        </li >


      </ul >,
      date: "Agosto de 2021"
    }
    ,
    {
      title: 'Versão 1.8',
      content: <ul>
        <li>
          <strong>Tela de Criar Operações Massivas com quantidade por mês</strong>
          <ul>
            <li>
              <u>Funcionalidades com Steps:</u>
              <ul>
                <li> Criar Operação Massiva:
                  <ul>Step de Operação</ul>
                  <ul>Step de Upload</ul>
                  <ul>Step de Ocorrência</ul>
                  <ul>Step de Resumo</ul>
                  <ul>Finalização pelo botão de Processar</ul>
                </li>

              </ul>
            </li>
          </ul>
        </li >

        <li>
          <strong>Tela de Listagem de Operações Massivas e de detalhes</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Lista de operações com atribuição de APN. </li>
                <li>Card de Informação na lista de detalhes da operação</li>
                <li>Gráfico com processo linha a linha com status de: Processando, Em espera, Erro e Sucesso</li>
              </ul>
            </li>
          </ul>
        </li >


      </ul >,
      date: "Junho de 2021"
    }
    ,
    {
      title: 'Versão 1.7',
      content: <ul>
        <li>
          <strong>Tela de Operações Massivas com acréscimo de APN</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li> Acompanhamento das operações através dos recursos:
                  <ul>Habilitar SMS com APN</ul>
                  <ul>Desabilitar SMS com APN</ul>
                  <ul>Reset das linhas com APN</ul>
                </li>

              </ul>
            </li>
          </ul>
        </li >

        <li>
          <strong>Tela de Linhas x Status com APN</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Lista de operações com atribuição de APN. </li>
                <li>Botões e tela com cadastro da Operação Massiva incluindo APN e Cliente. </li>
                <li>Recurso massivo obrigatório a inserção do arquivo</li>
              </ul>
            </li>
          </ul>
        </li >
        <li>
          <strong>Tela de Linhas x Status com APN</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Lista de operações com atribuição de APN. </li>
                <li>Botões e tela com cadastro da Operação Massiva incluindo APN e Cliente. </li>
                <li>Recurso massivo obrigatório a inserção do arquivo</li>
              </ul>
            </li>
          </ul>
        </li >
        <li>
          <strong>Correção do carregamento das telas e Skelleton</strong>
          <ul>
            <li>
              <u>Alteração:</u>
              <ul>
                <li>Telas com carregamento(loading) do tipo Skelleton com paginação </li>

              </ul>
            </li>
          </ul>
        </li >


      </ul >,
      date: "Janeiro de 2021"
    },
    {
      title: 'Versão 1.7',
      content: <ul>
        <li>
          <strong>Tela de Operações Massivas</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li> Acompanhamento das operações através dos recursos:
                  <ul>Habilitar/Desabilitar SMS</ul>
                  <ul>Atribuir APN</ul>
                  <ul>Reset das linhas</ul>
                </li>
                <li> Listagem das operações com informações por:
                  <ul>Lote e nome do arquivo</ul>
                  <ul>Total das linhas e Clientes</ul>
                  <ul>Status do processo como em espera, processando e finalizado, além de uma barra visual e a porcentagem do processo</ul>
                </li>
                <li>Filtros:
                  <ul>Busca por Cliente</ul>
                  <ul>Seleção do tipo de recurso</ul>
                  <ul>Busca por operador responsável pelos lotes</ul>
                </li>
              </ul>
            </li>
          </ul>
        </li >
        <li></li>
        <li>
          <strong>Tela de Operações Detalhadas</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Lista das linhas repassadas pelo recurso massivo. </li>
                <li>Informação detalhada por gráfico para visualizar o status, além do operador responsável, lote, arquivo e recurso chamado. </li>
                <li>Informações das linhas por status:
                  <ul>Em espera</ul>
                  <ul>Erro</ul>
                  <ul>Sucesso</ul>
                  <ul>Processando</ul>
                </li>
                <li>Filtragem por MSISDN e Status das linhas </li>
              </ul>
            </li>
          </ul>
        </li >


      </ul >,
      date: "Outubro de 2020"
    },
    {
      title: 'Versão 1.6',
      content: <ul>
        <li>
          <strong>Listagem de Linhas X Sessão</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li> Status de conexão das Linhas. </li>
                <li> Informação de Rede e Apn de tráfego. </li>
                <li>Filtros:
                  <ul>Filtragem por MSISDN</ul>
                  <ul>Seleção do servidor entre Campinas-RJ e São Paulo-SP</ul>
                </li>
              </ul>
            </li>
          </ul>
        </li >
        <li></li>
        <li>
          <strong>Geolocalização das Linhas</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Geolocalização em tempo real ou por última conexão da linha. </li>
                <li>Busca da localização por Msisdn. </li>
                <li>Busca da localização de linhas massivamente, enviando arquivo XLSX. </li>
                <li>Informação da localização das linhas por triangulação das Antenas.</li>
                <li>Dados de localização da linha por bairro, cidade, estado e país.</li>
                <li>Opção FullScreen e seleção Dia e Noite no mapa.</li>

              </ul>
            </li>
          </ul>
        </li >


      </ul >,
      date: "Setembro de 2020"
    },
    {
      title: 'Versão 1.5',
      content: <ul>
        <li>
          <strong>Status da Linha no HLR</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Status da Linha na tela Linhas x Status. </li>
                <li>Recurso: Opção de consulta massiva e de única linha do cliente. </li>
                <li>Informações recebidas:
                  <ul>Conexão GSM</ul>
                  <ul>Status do SMS</ul>
                  <ul>APN's atribuídas na linha</ul>
                  <ul>Status do Resultado HLR</ul>
                </li>
              </ul>
            </li>
          </ul>
        </li >
        <li></li>
        <li>
          <strong>histórico</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Listagem das atividades realizadas. </li>
                <li>Recurso: Filtragem por tipo de recurso solicitado. </li>
                <li>Recurso: Visualização do resultado da atividade. </li>

              </ul>
            </li>
          </ul>
        </li >
        <li>
          <strong>Alterações em Linhas X Status</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Adição do campo Fase do Simcard</li>
                <li>Remoção do campo Status do SMS</li>
                <li>Pesquisa do status SMS pelo Status da linha no HLR</li>


              </ul>
            </li>
          </ul>
        </li>

      </ul >,
      date: "Julho de 2020"
    },
    {
      title: 'Versão 1.4',
      content: <ul>
        <li>
          <strong>Verticais IOT</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Login com o tema das verticais IOT. </li>
                <li>Recurso: Tipo das verticais
                  <ul>SmartCities</ul>
                  <ul>Health Care</ul>
                  <ul>Agro Business</ul>
                  <ul>Industry 4.0</ul>
                </li>
              </ul>
            </li>
          </ul>
        </li >
        <li></li>
        <li>
          <strong>Migração do Sistema</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Mudança do Design do sistema. </li>
                <li>Recurso: Mudança da Arquitetura do sistema. </li>
                <li>Recurso: Adaptada a adição de funcionalidades Rede LORA</li>
              </ul>
            </li>
          </ul>
        </li >
        <li>
          <strong>Linhas por Licença</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recurso: Linhas X Licença .
                  <ul>
                    <li>Listagem de Linhas por licença</li>
                    <li>Filtro por tipo de Plano de Licença;</li>
                    <li>Filtro por Clientes;</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Relatório Financeiro</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Adição do Campo Dias de Isenção(ItemExceptionDays)</li>
                <li>Adição do Campo Serviços Telecom Base;</li>

              </ul>
            </li>
          </ul>
        </li>

      </ul >,
      date: "Maio de 2020"
    },
    {
      title: 'Versão 1.3',
      content: <ul>
        < li >
          <strong>Histórico</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>
                  Recursos: Filtrar por histórico.
                  <ul>
                    <li>Reset Registro HLR;</li>
                    <li>Ativação de SMS MO;</li>
                    <li>Desativação de SMS MO;</li>
                    <li>Troca de APN.</li>
                  </ul>
                </li>
                <li>Recurso: Filtrar por Cliente;</li>
                <li>Recurso: Filtrar por mês.</li>
              </ul>
            </li>
          </ul>
        </li >
        <li>
          <strong>Adicionado perfis para usuário</strong>
          <ul>
            <li>Perfil para o Financeiro;</li>
            <li>Perfil para Atendimento ao Cliente.</li>
          </ul>
        </li>
        <li>
          <strong>
            Adicionado regras de controle para acesso aos Recursos.
          </strong>
        </li>
      </ul >,
      date: "Novembro de 2019"
    },
    {
      title: 'Versão 1.2',
      content: <ul>
        <li>
          <strong>Reset Registro HLR</strong>
          <ul>
            <li>
              <u>Funcionalidade:</u>
              <ul>
                <li>Recursos: Restauração da condição inicial da Linha.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Ativar SMS MO</strong>
          <ul>
            <li>
              <u>Funcionalidade:</u>
              <ul>
                <li>Recursos: Ativar Mensagem de Origem.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Desativar SMS MO</strong>
          <ul>
            <li>
              <u>Funcionalidade:</u>
              <ul>
                <li>Recursos: Desativar Mensagem de Origem.</li>
              </ul>
            </li>
          </ul>
        </li>
        {/* <li>
            <strong>Trocar APN</strong>
            <ul>
              <li>
                <u>Funcionalidade:</u>
                <ul>
                  <li>Recursos: Trocar a APN da Linha.</li>
                </ul>
              </li>
            </ul>
          </li> */}
        <li>
          <strong>Linhas X Status</strong>
          <ul>
            <li>Recursos: Visualização da APN existente da Linha.</li>
          </ul>
        </li>
      </ul>,
      date: "Novembro de 2019"

    },
    {
      title: 'Versão 1.1',
      content: <ul>
        <li>
          <strong>Relatório de Faturamento</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recursos: Filtros por Cliente e Data;</li>
                <li>Recursos: Ocultar ou Exibir colunas;</li>
                <li>Recursos: Download do relatório no formato XLSX.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Relatório de Simcards</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>
                  Recursos: Filtros por Tipo de Relatório, Cliente, Status e
                  Fases das Linhas;
                </li>
                <li>Recursos: Ocultar ou Exibir colunas;</li>
                <li>Recursos: Download do relatório no formato XLSX.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Relatório de Consumo</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recursos: Filtros por Cliente e Data;</li>
                <li>Recursos: Ocultar ou Exibir colunas;</li>
                <li>Recursos: Download do relatório no formato XLSX.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Relatório de Clientes</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recursos: Filtros por Cliente;</li>
                <li>Recursos: Ocultar ou Exibir colunas;</li>
                <li>Recursos: Download do relatório no formato XLSX.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>,
      date: "Outubro de 2019"

    },
    {
      title: 'Versão 1.0',
      content: <ul>
        <li>
          <strong>Autenticação</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Login;</li>
                <li>Esqueci minha senha;</li>
                <li>Editar Perfil;</li>
                <li>Alterar Senha.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Dashboard</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Pesquisar por cliente.</li>
                <li>
                  Sumarizado total de linhas:
                  <ul>
                    <li>Recurso: Status das linhas.</li>
                  </ul>
                </li>
                <li>
                  Sumarizado de Consumo:
                  <ul>
                    <li>Recursos: Download, Upload e Total.</li>
                  </ul>
                </li>
                <li>
                  Gráficos de fases:
                  <ul>
                    <li>Ativadas;</li>
                    <li>Suspensas;</li>
                    <li>Canceladas;</li>
                    <li>Bloqueadas.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <strong>Usuários</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Adicionar usuário;</li>
                <li>Ativar/Desativar usuário;</li>
                <li>Editar usuário;</li>
                <li>Deletar usuário.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Status das linhas</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Listagem geral do status das linhas:</li>
                <ul>
                  <li>Recursos: Filtros por Cliente, ICCID e MSISDN;</li>
                  <li>Recursos: Reset Conexão de Dados.</li>
                </ul>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <strong>Consumo das linhas</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Listagem geral do consumo das linhas:</li>
                <ul>
                  <li>Recursos: Filtros por Cliente, ICCID e MSISDN.</li>
                </ul>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <strong>Segunda via da Nota Fiscal e Boleto</strong>
          <ul>
            <li>
              <u>Funcionalidades:</u>
              <ul>
                <li>Recursos: Filtros por cliente.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>,
      date: "Agosto de 2019"
    },
  ];

  // Funções
  const next = () => {
    setCurrent(current + 1);
  }

  const prev = () => {
    setCurrent(current - 1);
  }

  return (
    <div>
      <Card
        title={(
          <h1 style={styles.title}>
            <i style={styles.subTitle} className="fas fa-code-branch" />
            Controle de Versões
          </h1>
        )}
      >
        <div>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} icon={<SolutionOutlined />} title={item.title} />
            ))}
          </Steps>

          <div className="steps-action" style={{ marginBottom: 10, marginTop: 10 }}>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Próximo <RightOutlined style={{ fontSize: '20px' }} />
              </Button>
            )}

            {current === steps.length - 1 && (
              <Button type="primary" onClick={() => console.log('Processing complete!')}>
                Último <StopOutlined style={{ fontSize: '20px' }} />
              </Button>
            )}

            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Anterior <LeftOutlined style={{ fontSize: '20px' }} />
              </Button>
            )}
          </div>

          <CardInfo title={steps[current].title} style={{ marginTop: 10 }}
            lastUpdate={steps[current].date}>
            <div className="steps-content">{steps[current].content}</div>
          </CardInfo>

        </div>
      </Card>
    </div>
  );
};



export default Version;

import { AutoComplete, Card, Col, Form, Input, Row, } from 'antd';
import React, { useEffect, useState } from 'react'
import { getCustomerByName, getCustomers } from '../../../store/actions/customerAction';
import { useDispatch, useSelector } from 'react-redux';
import { getApn } from "../../../store/actions/apnAction";

const FormOperation = (props) => {
   
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [optionsValue, setOptionsValue] = useState("");
  const [protocol,setProtocol]=useState("");
  const [protocolInput, setProtocolInput] = useState("");
  const [apnName, setApnName] = useState();



  const [motive,setMotive]=useState("");
  const [motiveInput,setMotiveInput]=useState("");

  const [email,setEmail]=useState("");
  const [emailInput,setEmailInput]=useState("");
  const [days,setDays]=useState("");
  const [daysInput,setDaysInput]=useState("");

  
  if(customerId)props.setCustomerId(customerId)
  if(customerName)props.setCustomerName(customerName)
  if(protocol)props.setProtocol(protocol)
  if(motive)props.setMotive(motive)
  if(email)props.setEmail(email)
  if(apnName)props.setApnName(apnName)
  if(days!=="")props.setDays(days)
  

  // props.setApnName

  const [optionsApnValue, setOptionsApnValue] = useState([]);
  const dispatch = useDispatch();
  const setIsVerified = props.setIsVerified
  const onChangeInput = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "protocol" && e.target.value.length <= 4) {
        setProtocolInput(e.target.value)
        if (e.target.value.length === 4) {
          setProtocol(e.target.value);
        }
      }
      if (e.target.name === "days" && e.target.value.length <= 3) {
        setDaysInput(e.target.value)
        if (e.target.value.length <=3) {
          setDays(e.target.value);
        }
      }
    }

    if (e.target.name === "motive") {
      setMotiveInput(e.target.value)
      if (e.target.value.length > 4) {
          setMotive(e.target.value)
      }
    };
    if (e.target.name === "email") {
      setEmailInput(e.target.value)
      if (e.target.value.length > 4) {
          setEmail(e.target.value)
      }
    };
    
  }
  

  useEffect(() => {
    if (customerName && !clientId) {
      dispatch(getCustomerByName(customerName));
    }
    if(clientId){
      dispatch(getCustomers(clientId, 1, 0))
    }
    if ((customerId || clientId) && protocol!=="") {
      setIsVerified(true)
    } else {
      setIsVerified(false)
    }
    dispatch(getApn());

  }, [dispatch, customerId, customerName,protocol,setIsVerified]);

  const customers = useSelector(state => state.customers);
  //get customers by clientId
  const { allCustomerList } = useSelector(state => state.allCustomerList);
  const apns = useSelector(state => state.getApn)
  console.log(allCustomerList)

  useEffect(() => {
    if (customers.customers) {
      let options = [];
      customers.customers.data.map(obj => {
        return options.push({ value: obj.id.toString(), label: obj.name });
      });
      setOptionsCustomer(options);
    }
  }, [customers]);

  let clientId = localStorage.getItem("clientId")
  useEffect(()=>{
    if (apns.getApn) {
      let optionsApn = []
      apns.getApn.map(obj => {
        return optionsApn.push({ value: obj.name, label: obj.name });
      });
      setOptionsApnValue(optionsApn);
    }
    if(clientId && allCustomerList){
      setCustomerId(clientId)
      console.log(allCustomerList.data[0].name)
      setCustomerName(allCustomerList.data[0].name)
    }
  
  },[apns,allCustomerList,clientId]);

  const typeResource = props.typeResource

console.log(customers)
  return (
    <>
    {/* <Animate transitionName="fade" transitionAppear> */}
      <Card style={styles.container}>
    {clientId === null &&
        <Form style={{ marginTop: 3 }} layout="vertical">
          <Form.Item label="Cliente:">
            <AutoComplete
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerId();
                  setCustomerName();
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                console.log("Onselect:" + value)
                console.log("Onselect:" + option)

                setOptionsValue(option.label);
                setCustomerId(value);
                setCustomerName(option.label);

              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
            </AutoComplete>
          </Form.Item>
        </Form>
      }
        {typeResource.includes("APN") ? (
          <Form style={{ marginTop: 5 }} layout="vertical">
            <Form.Item label="Access Point Name(APN):">
              <AutoComplete
                options={optionsApnValue}
                // value={optionsApnValue}
                onChange={valueApn => {
                  if (!valueApn) {
                    setApnName();
                  }
                }}
                onSearch={valueApn => {
                  // setOptionsApnValue(valueApn);
                  setApnName(valueApn)
                }}
                onSelect={(valueApn, optionApn) => {
                  // setOptionsApnValue(optionApn.label);
                  setApnName(valueApn);
                }}
              >
                <Input.Search
                  size="middle"
                  placeholder="Pesquisar Apn..."
                  allowClear
                />
              </AutoComplete>
            </Form.Item>
          </Form>
        ) : ""}

        <Form style={{ marginTop: 3 }} layout="vertical">
          <Form.Item label="Motivo da Operação:">
            <Input.TextArea
             name="motive"
             allowClear
            onChange={event => {
                    if (event.target.value === "") {
                      setMotive("");
                    }
                    onChangeInput(event);
                  }}
                  value={motiveInput}/>
          </Form.Item>
        </Form>
        <Row gutter={24}>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={24}>
            <Form style={{ marginTop: 3 }} layout="vertical">
              <Form.Item label="Protocolo:">
                <Input
                  name="protocol"
                  allowClear
                  onChange={event => {
                    if (event.target.value === "") {
                      setProtocol("");
                    }
                    onChangeInput(event);
                  }}
                  value={protocolInput}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={24}>
            <Form style={{ marginTop: 3 }} layout="vertical" rules={[{ type: 'email' }]}>
              <Form.Item label="Email/Nome do Requerente:">
                <Input 
                name="email"
               onChange={event => {
                       if (event.target.value === "") {
                         setEmail("");
                       }
                       onChangeInput(event);
                     }}
                     value={emailInput}
                     />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {typeResource.includes("Temporária") ? (
       
          <Form style={{ marginTop: 3 }} layout="vertical">
              <Form.Item label="Quantidade de dias:">
                <Input
                  name="days"
                  allowClear
                  onChange={event => {
                    if (event.target.value === "") {
                      setDays("");
                    }
                    onChangeInput(event);
                  }}
                  value={daysInput}
                />
              </Form.Item>
            </Form>
        ):""}
      </Card>
      {/* </Animate> */}
    </>
  )

}
const styles = {
  container: {
    borderRadius: "10px",
    background: "#fff",
    boxShadow:
      "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  },
  containerTitle: {
    color: "gray",
    fontWeight: "bold"
  },
  subTitle: {
    display: "flex",
    justifyContent: "flex-end",
    color: "gray",
    fontWeight: "bold"
  }
};

export default FormOperation;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image from "../assets/img/smart_cities.jpg"
import { Layout, Row, Col, Divider, Breadcrumb } from "antd";
import {
  DashOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from "@ant-design/icons";
import logo from "../assets/img/logo_green.png";

import RightContent from "../components/GlobalHeader/RightContent";

const { Header, Content, Sider } = Layout;

const routes = [
  {
    path: "/",
    breadcrumbName: "Operação"
  },

  // {
  //   path: "dashboard-mobile",
  //   breadcrumbName: "Dashboard-Mobile"
  // },
  // {
  //   path: "lines-status",
  //   breadcrumbName: "LinesxStatus"
  // }
];

const LayoutDefault = props => {
  const [collapsed, setCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const itemRender = (route, params, routes, paths) => {
    return (
      <Link to={paths.join("/")}>
        {route.path === "/" && <DashOutlined />} {route.breadcrumbName}
      </Link>
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
          backgroundSize: "500px 1100px",
          backgroundImage: `url(${image})`,
        }}
        // theme="light"
        trigger={width <= 768 ? null : ""}
        collapsible
        collapsed={collapsed}
        collapsedWidth={width <= 768 ? "0" : "80"}
        breakpoint={"lg"}
        onBreakpoint={broken => {
          setCollapsed(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
          toggle();
        }}
      >
        <Divider style={{ margin: "2px 0px 0px 0px" }} />

        <div className="logo">
          <a href="/">
            <img src={logo} alt="Logo" />
            <h1 style={{fontFamily:"MS PMincho",fontSize:30,fontWeight:"900"}}>Helios</h1>
          </a>
        </div>
        {/* <Divider style={{ margin: "0px 0px 10px" }} /> */}
        {props.menu}
      </Sider>

      <Layout>
        <Header
          style={{
            position: "fixed",
            width: "100%",
            background: "#fff",
            padding: 0,
            zIndex: 1
          }}
        >
          <Row>
            <Col xl={8} lg={9} md={8} sm={8} xs={8}>
              {/* <Breadcrumb className="breadcrumb" style={{ margin: 20 }}>
                <Breadcrumb.Item href="">
                  <DashOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                  <UserOutlined />
                  <span>Application List</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Application</Breadcrumb.Item>
              </Breadcrumb> */}
              <Breadcrumb
                className="breadcrumb"
                style={{ margin: 20 }}
                itemRender={itemRender}
                routes={routes}
              />
              <div className="trigger">
                {collapsed ? (
                  <MenuUnfoldOutlined onClick={toggle} />
                ) : (
                    <MenuFoldOutlined onClick={toggle} />
                  )}
              </div>
            </Col>
            <Col xl={12} lg={10} md={13} sm={16} xs={16}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <RightContent />
              </div>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            //margin: "24px 16px",
            padding: 10,
            marginTop: 60
            //background: "#fff",
            //minHeight: 280
            // margin: "24px 16px",
            // padding: 24,
            // background: "#fff",
            // minHeight: 280
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDefault;

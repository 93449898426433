import { useState } from 'react';

const useAsyncFunc = (asyncFunction) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const execute = async (...args) => {
    setLoading(true);

    try {
      const { data } = await asyncFunction(...args);
      setData(data);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }

  return [
    {
      data,
      loading,
      error
    },
    (...args) => execute(...args)
  ];
};

export {useAsyncFunc};
export default useAsyncFunc;

const styles = {
  titleRadio: {
    color: "grey",
    fontWeight: "bold"
  },
  radioStyle: {
    display: "block",
    height: "30px",
    lineHeight: "30px"
  },

  titleContainer: {
    display: "flex",
    justifyContent: "space-between",

    // alignItems: "center"
  },
  title: {
    fontWeight: 900,
    // marginLeft: 20
  },

  subTitle: {
    background: "linear-gradient(80deg, #43A047, #FFEB3B)",
    borderRadius: 10,
    padding: 17,
    marginRight: 10,
    fontSize: 22,
    color: "#ffffff"
    // cursor: "pointer"
  }

};

export default styles;

import React from "react";
import { Menu } from "antd";
const { SubMenu } = Menu;

const SubMenuPermission = ({ ...props }) => {
  let permissions = [];
  const hasPermissions = props.permissions || [];
  if (localStorage.permissions) {
    permissions = JSON.parse(localStorage.getItem("permissions"));
  }
  return permissions.some(per => hasPermissions.includes(per)) ? (
    <SubMenu key={props.key} {...props}>
      {props.children}
    </SubMenu>
  ) : null;
};

export default SubMenuPermission;

import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";

const HeliosTableAtr = props => {
    return (
        <Table
            style={styles.table}
            scroll={{ y: 350 }}
            width="100%"
            title={() => (
                <div style={styles.titleContainer}>
                    <h1 style={styles.title}>{props.title}</h1>
                    {props.subTitle}
                </div>
            )}
            rowSelection={props.rowSelection}
            columns={props.columns}
            dataSource={props.dataSource}
            rowKey={props.rowKey}
            pagination={false}
        />
    );
};

const styles = {
    table: {
        background: "#fff",
        borderRadius: "6px",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
        padding: "10px"
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        fontWeight: 500,
        marginTop: -60
    },
    paginationContainer: {
        display: "flex",
        justifyContent: "flex-end"
    }
};

HeliosTableAtr.propTypes = {
    columns: PropTypes.array.isRequired,
    dataSource: PropTypes.array.isRequired
};

export default HeliosTableAtr;

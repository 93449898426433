import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Upload, Form, Input, AutoComplete, Button, Modal, Row,notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getResetandChangeApnClient,getManyHardResetAndChangeApnClient, getManyHardResetClient } from "../../store/actions/hardResetAction";
import { getManyChangeApnClient,  getChangeApnClient } from "../../store/actions/changeApnAction";
import {  getManySmsAWS,getSMSAndChangeAPN,getManySmsAndChangeAPNClient } from "../../store/actions/smsMoAction";
import { getManyStatusHlr } from "../../store/actions/statusHlrAction";
import { getCustomerByName } from "../../store/actions/customerAction"
import { useHistory } from "react-router-dom";


const ModalAtr = props => {
    let clientId = localStorage.getItem("clientId")
    const [fileInput, setFileInput] = useState();
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const history = useHistory();
    const [apnName, setApnName] = useState();
    const [isVerified, setIsVerified] = useState(false);

    const [optionsCustomer, setOptionsCustomer] = useState([]);

    const [optionsValue, setOptionsValue] = useState("");
    const [,setOptionsApnValue] = useState("");

    const dispatch = useDispatch();
    const placement = "bottomRight"
    const { visible, onCancel, content, tipoAtr, apns } = props;

    
    
    useEffect(() => {
        if (customerName) {
          dispatch(getCustomerByName(customerName));
        }
        if(customerId && fileInput){
            setIsVerified(true)
        }else{
            setIsVerified(false)
        }

        if(tipoAtr==="many-status-hlr" && fileInput){
            setIsVerified(true)
        }
        if(tipoAtr.includes("change-apn") && !tipoAtr.includes("many") && apnName){
            setIsVerified(true)
        }

        if(tipoAtr.includes("+") && apnName && customerId && fileInput){
            setIsVerified(true)
        }
       
      }, [dispatch,apnName,tipoAtr,fileInput ,customerId,customerName]);
    
      const customers = useSelector(state => state.customers);
      const hardReset = useSelector(state => state.hardReset);
      const smsMo = useSelector(state => state.smsMo);
      const changeApn = useSelector(state => state.changeApn);


      useEffect(() => {
        if (customers.customers) {
          let options = [];
          customers.customers.data.map(obj => {
            return options.push({ value: obj.id.toString(), label: obj.name });
          });
          setOptionsCustomer(options);
        }
        
        
        
      }, [customers]);
      const btn = (
        <Button type="primary" size="small" onClick={() => history.push("/resource-activities")}>
          Visualizar Operações
        </Button>
      );
    


    let title = ""
    if (tipoAtr === "many-hard-reset") {
        title = "Reset de linhas em Massa"
        if(hardReset.hardReset){
            notification.success({
                message: hardReset.hardReset.message,
                description:
                    'Linhas para Reset estão na fila de operação massiva',
                    btn,
                duration: 60,
                placement
            });
        hardReset.hardReset = null
    }
    }
    if (tipoAtr === "many-change-apn") {
        title = "Mudança de APN"
        if(changeApn.changeApn){
            notification.success({
                message: changeApn.changeApn.message,
                description:
                    'Linhas para mudança de APN estão na fila de operação massiva',
                    btn,
                duration: 5,
                placement
            });
        }
    }
    if (tipoAtr === "change-apn") {
        title = "Mudança de APN da linha "+content.msisdn
    }
    if (tipoAtr === "hard-reset+change-apn") {
        title = "Reset e Adição da APN da linha "+content.msisdn

    }
    if (tipoAtr === "enable-sms+change-apn") {
        title = "Habilitar SMS e Adição da APN da linha "+content.msisdn

    }
    if (tipoAtr === "disable-sms+change-apn") {
        title = "Desabilitar SMS e Adição da APN da linha "+content.msisdn

    }
    if (tipoAtr === "many-enable-sms") {
        title = "Ativar SMS em Massa"
        if(smsMo.smsMo){
            notification.success({
                message: smsMo.smsMo.message,
                description:
                    'Linhas para mudança do status de sms estão na fila de operação massiva',
                    btn,
                duration: 5,
                placement
            });

        }
    }
    if (tipoAtr === "many-disable-sms") {
        title = "Desativar SMS em Massa"
        if(smsMo.smsMo){
            notification.success({
                message: smsMo.smsMo.message,
                description:
                    'Linhas para mudança do status de sms estão na fila de operação massiva',
                    btn,
                duration: 5,
                placement
            });

        }
    }
    if (tipoAtr === "many-reset+change-apn") {
        title = "Reset e Alterar APN em Massa"
        if(hardReset.hardReset){
            notification.success({
                message: hardReset.hardReset.message,
                description:
                    'Linhas para resetar e alterar APN estão na fila de operação massiva',
                    btn,
                duration: 5,
                placement
            });

        }
    }

    if (tipoAtr === "many-enable-sms+change-apn") {
        title = "Ativar SMS e Alterar APN em Massa"
        if(smsMo.smsMo){
            notification.success({
                message: smsMo.smsMo.message,
                description:
                    'Linhas para ativar SMS e alterar APN estão na fila de operação massiva',
                    btn,
                duration: 5,
                placement
            });

        }
    }


    if (tipoAtr === "many-disable-sms+change-apn") {
        title = "Desativar SMS e Alterar APN em Massa"
        if(smsMo.smsMo){
            notification.success({
                message: smsMo.smsMo.message,
                description:
                    'Linhas para desativar SMS e alterar APN estão na fila de operação massiva',
                    btn,
                duration: 5,
                placement
            });

        }
    }
    if (tipoAtr === "many-status-hlr") {
        title = "Consulta de Status das linhas"
    }
    
    
    return (

        <Modal
            visible={visible}
            title={title}
            // style={styles.container}
            okText="Sim"
            cancelText="Não"
            onCancel={onCancel}
            okButtonProps={{ disabled: !isVerified  }}
            onOk={() => {
                if (tipoAtr === "many-hard-reset") {

                    // dispatch(getManyHardReset(fileInput))
                    dispatch(getManyHardResetClient(customerId, customerName, fileInput))
                    
                }
                if (tipoAtr === "many-change-apn") {
                    // dispatch(getManyChangeApn(fileInput, apnName))
                    dispatch(getManyChangeApnClient(customerId, customerName,fileInput, apnName))

                }
                if (tipoAtr === "change-apn") {
                    dispatch(getChangeApnClient(content, apnName))
                }

                if (tipoAtr === "hard-reset+change-apn") {                    
                    dispatch(getResetandChangeApnClient(content, apnName))
                }

                if (tipoAtr === "enable-sms+change-apn") {
                    dispatch(getSMSAndChangeAPN(content, apnName,"enable"))
                }

                if (tipoAtr === "disable-sms+change-apn") {
                    dispatch(getSMSAndChangeAPN(content, apnName,"disable"))
                }

                if (tipoAtr === "many-enable-sms") {
                    dispatch(getManySmsAWS(customerId, customerName,"enable",fileInput))
                }
                if (tipoAtr === "many-enable-sms+change-apn") {
                    dispatch(getManySmsAndChangeAPNClient(customerId, customerName,"enable",apnName,fileInput))
                }
                if (tipoAtr === "many-disable-sms+change-apn") {
                    dispatch(getManySmsAndChangeAPNClient(customerId, customerName,"disable",apnName,fileInput))
                }
                if (tipoAtr === "many-reset+change-apn") {
                    dispatch(getManyHardResetAndChangeApnClient(customerId, customerName,apnName,fileInput))
                }
                if (tipoAtr === "many-disable-sms") {
                    dispatch(getManySmsAWS(customerId, customerName,"disable",fileInput))
                    // dispatch(getManySmsInactive(fileInput))
                }
                if (tipoAtr === "many-status-hlr") {
                    dispatch(getManyStatusHlr(fileInput))
                }
                onCancel()
            }}
        >
            <div>
                {tipoAtr.includes("many") ? <Row><p>Faça o Upload do arquivo xlsx com uma coluna contendo MSISDN's</p></Row> : ""}
                {tipoAtr.includes("many")
                    ? (<Upload
                        listType='picture'
                        className='upload-list-inline'
                        multiple={false}
                        accept=".xlsx"
                        onChange={(info)=>{
                            info.fileList.length===0 && setFileInput()
                        }}
                        // showUploadList={true}
                        beforeUpload={file => {
                            setFileInput(file)
                            return false;
                        }}
                        >
                        <Button>
                            <UploadOutlined /> Clique aqui para Upload do arquivo XLSX
                        </Button>
                        </Upload>) : ""}
                {tipoAtr.includes("apn") ? (
                    <Row>
                        <Form style={{marginTop:5}} layout="horizontal">
                            <Form.Item label="ACCESS POINT NAME">
                                <AutoComplete
                                    options={apns}
                                    // value={optionsApnValue}
                                    onChange={valueApn => {
                                        if (!valueApn) {
                                            setApnName();
                                        }
                                    }}
                                    onSearch={valueApn => {
                                        setOptionsApnValue(valueApn);
                                        setApnName(valueApn);


                                    }}
                                    onSelect={(valueApn, optionApn) => {
                                        setOptionsApnValue(optionApn.label);
                                        setApnName(valueApn);
                                    }}
                                >
                                    <Input.Search
                                        size="middle"
                                        placeholder="Pesquisar Apn..."
                                        allowClear
                                    // loading={apns.loading}
                                    />
                                </AutoComplete>
                            </Form.Item>
                        </Form>
                    </Row>) : ""}
                
                    {/* <strong>MSISDN:{content.msisdn}</strong> */}
                {!tipoAtr.includes("status") && tipoAtr.includes("many") && clientId ?(
                    <Form style={{marginTop:3}} layout="horizontal">
                        <Form.Item label="CLIENTE">
                            <AutoComplete
                                options={optionsCustomer}
                                value={optionsValue}
                                onChange={value => {
                                    if (!value) {
                                        setCustomerId();
                                        setCustomerName();
                                    }
                                }}
                                onSearch={value => {
                                    setOptionsValue(value);
                                    const timer = setTimeout(() => {
                                        if (value.length >= 2) {
                                            setCustomerName(value);
                                        }
                                    }, 1000);
                                    return () => clearTimeout(timer);
                                }}
                                onSelect={(value, option) => {
                                    console.log("Onselect:"+value)
                                    console.log("Onselect:"+option)

                                    setOptionsValue(option.label);
                                    setCustomerId(value);
                                    setCustomerName(option.label);
                                    
                                }}
                            >
                                <Input.Search
                                    size="middle"
                                    placeholder="Pesquisar Cliente..."
                                    allowClear
                                    loading={customers.loading}
                                />
                            </AutoComplete>
                        </Form.Item>
                    </Form>
                )
                    : ""}
            </div>
        </Modal >
    );
};

export default ModalAtr;

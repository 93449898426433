export const columns = [
    {
        title: "Dev EUI",
        dataIndex: "dev_eui"
    },
    {
        title: "App EUI",
        dataIndex: "app_eui"
    },
    {
        title: "Cliente",
        dataIndex: "tags",
        render: (text, record) => (record.tags[0])
    },
    {
        title: "CNPJ",
        dataIndex: "tags",
        render: (text, record) => (record.tags[1])
    },
    {
        title: "Uplink",
        dataIndex: "counter_up"
    },
    {
        title: "Downlink",
        dataIndex: "counter_down"
    }
];

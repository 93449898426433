import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AllLogs } from "../../../store/actions/userLogAction";
import { AllUsers } from "../../../store/actions/userAction";



import HeliosTable from "../../../components/HeliosTable/HeliosTable";
import {
    Tooltip,
    Drawer,
    Form,
    Button,
    Select,
    AutoComplete,
    Input
} from "antd";

import SkeletonTable from "../../../components/Skeleton/SkeletonTable";

import { columns } from "./columnsTable";
const { Option } = Select;
const UserLogs = () => {


    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    // const [showModal,setShowModal]=setState(false)
    const [typeResource, setTypeResource] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [email, setEmail] = useState();
    const [userId, setUserId] = useState();

    const [optionsValue, setOptionsValue] = useState()
    const [optionsUsers, setOptionsUsers] = useState([]);
    const perPage = 100
    const dispatch = useDispatch();
    const [line, setLine] = useState("");


    useEffect(() => {

        dispatch(AllLogs(userId,line, typeResource, page, pageSize));
        if (email) {
            dispatch(AllUsers(email, perPage))
        }

    }, [dispatch, userId,line, email, pageSize, page, typeResource]);
    const users = useSelector(state => state.user);
    console.log(users)
    useEffect(() => {
        if (users.users) {
            let options = [];
            users.users.data.map(obj => {
                return options.push({ value: obj.id.toString(), label: obj.email });
            })
            setOptionsUsers(options);
        }

    }, [users]);

    const { logs, loading } = useSelector(state => state.logs)

    function handleChange(value) {
        setTypeResource(value)
    }
    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(current);
    };

    const handlePaginationChange = (current) => {
        setPage(current);
    };


    const onShowFilter = () => {
        setShowFilter(true);
    };

    const onCloseFilter = () => {
        setShowFilter(false);
    };

    const onChangeInput = e => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
          if (e.target.name === "msisdnIccid" && e.target.value.length <= 20) {
            setLine(e.target.value);
            if (e.target.value.length <= 20) {
              setLine(e.target.value);
            }
          }
        }
      };

    const subTitle = (
        <div>
            <Tooltip placement="bottom" title="Filtros">
                <i
                    style={styles.subTitle}
                    className="fas fa-filter"
                    onClick={() => {
                        onShowFilter();
                    }}
                />
            </Tooltip>
        </div>
    );

    return (
        <div>
            <SkeletonTable show={loading} title="Atividades no Sistema" icon="far fa-folder" pageSize={pageSize}/>

            {logs && !loading?(
                <HeliosTable
                    title="Atividades no Sistema"
                    subTitle={subTitle}
                    columns={columns}
                    icon="far fa-folder"
                    dataSource={logs.data || []}
                    rowKey="_id"
                    total={logs.total}
                    pageSize={pageSize}
                    page={page}
                    onChange={handlePaginationChange}
                    onShowSizeChange={onShowSizeChange}
                // rowSelection={rowSelection}
                />
            ):""}

            <Drawer
                title="Filtros"
                headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
                placement="right"
                onClose={onCloseFilter}
                visible={showFilter}
                width={500}
                style={styles.containerDrawer}
            >
                <Form layout="vertical">

                    <Form.Item label="Tipo do Recurso">
                        <Select defaultValue="Todos" style={{ width: "100%" }} onChange={handleChange}>
                            <Option value="many-hard-reset">Resetar Linha em Massa</Option>
                            <Option value="hard-reset">Resetar Linha</Option>
                            <Option value="status-hlr">Status da Linha</Option>
                            <Option value="many-status-hlr">Status da Linha em Massa</Option>
                            <Option value="enable-sms">Ativar SMS</Option>
                            <Option value="many-enable-sms">Ativar SMS em Massa</Option>
                            <Option value="disable-sms">Desativar SMS</Option>
                            <Option value="many-disable-sms">Desativar SMS em Massa</Option>
                            <Option value="change-apn">Trocar APN</Option>
                            <Option value="many-change-apn">Trocar APN em Massa</Option>
                            <Option value="many-reset+change-apn">Reset e Troca de APN em Massa</Option>
                            <Option value="many-enable-sms+change-apn">Ativar SMS e Troca de APN em Massa</Option>
                            <Option value="many-disable-sms+change-apn">Desativar SMS e Troca de APN em Massa</Option>
                            <Option value="login">Login</Option>
                            <Option value="">Todos</Option>


                        </Select>
                    </Form.Item>
                    <Form.Item label="Linha">
                        <Input.Search
                        autoComplete="off"
                        name="msisdnIccid"
                        allowClear
                        onChange={event => {
                            if (event.target.value === "") {
                            setLine("");
                            }
                            onChangeInput(event);
                        }}
                        value={line}
                        />
                    </Form.Item>
                    <Form.Item label="Email do Usuário">
                        <AutoComplete
                            options={optionsUsers}
                            value={optionsValue}
                            onChange={value => {
                                if (!value) {
                                    setUserId()
                                    setEmail();
                                }
                            }}
                            onSearch={value => {
                                setOptionsValue(value);
                                const timer = setTimeout(() => {
                                    if (value.length >= 2) {
                                        setEmail(value);
                                    }
                                }, 1000);
                                return () => clearTimeout(timer);
                            }}
                            onSelect={(value, option) => {
                                console.log(value)
                                setOptionsValue(option.label);
                                setUserId(value)
                            }}
                        >
                            <Input.Search
                                size="middle"
                                placeholder="Pesquisar email..."
                                allowClear
                                loading={users.loading}
                            />
                        </AutoComplete>
                    </Form.Item>
                </Form>
                <div style={styles.btnFilter}>
                    <Button onClick={onCloseFilter} type="default">
                        FECHAR
          </Button>
                </div>
            </Drawer>

        </div>
    );
};

const styles = {
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    btnFilter: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
    },
    btnCancel: {
        marginRight: 8
    },
    online: {
        color: "green"
    }
};

export default UserLogs;


import { Card, Col, Row, Divider, Button, Descriptions, Tabs } from 'antd';
import React from 'react'
import { useHistory } from "react-router-dom";
import { BankOutlined, ContactsOutlined, DollarCircleOutlined, PercentageOutlined, RollbackOutlined } from "@ant-design/icons";
import Contracts from '../../Contracts/Contracts';
import LinesClient from '../../Contracts/LinesClient';
import ResourceClient from '../../Contracts/ResourceClient';
import StockOS from '../../Contracts/StockOS';
import OrderServiceProcessing from '../../Contracts/OrderServiceProcessing';

const { TabPane } = Tabs;
const ViewCustomer = () => {

    const history = useHistory();
    const { state } = history.location;

    let customerView = null;
    if (state) {
        customerView = state;

    }

    return <>
            <Tabs type="card">
                <TabPane tab={<span>
                            <i style={styles.iconTab} className="fas fa-user" />
                            Cliente
                        </span>} key="1">
                    <Card title={<h1 style={styles.title}>
                                <i style={styles.icon} className="fas fa-user" />
                                {"Visualizar Cliente - " + customerView.name}
                            </h1>} extra={<div />}>
                        <Card style={styles.container}>
                            <>
                                <Divider>
                                    <BankOutlined style={styles.iconDivider} /> Dados Empresariais
                                </Divider>

                                <Descriptions style={{ marginTop: 3 }} bordered column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical">
                                    <Descriptions.Item label="Status: ">
                                        {/* {"ATIVO" ? customerView.status_c === active : "INATIVO"} */}
                                        {customerView.status_c}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Nome da Empresa: *">
                                        {customerView.name}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Tipo de Pessoa: ">
                                        {customerView.tipopessoa_c}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="CNPJ: *">
                                        {customerView.cpfcnpj_c}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Tipo de Indústria: ">
                                        {customerView.industry}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Nome Fantasia: *">
                                        {customerView.nome_fantasia_c}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Tipo de Conta: ">
                                        {customerView.account_type}
                                    </Descriptions.Item>
                                </Descriptions>

                                <Divider>
                                    <ContactsOutlined style={styles.iconDivider} /> Contato
                                </Divider>
                                <Descriptions style={{ marginTop: 3 }} bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical">
                                    <Descriptions.Item label="Email: *">
                                        {customerView.email}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Email de Suporte: ">
                                        {customerView.email_support}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Telefone: *">
                                        {customerView.phone_office}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Telefone Alternativo: ">
                                        {customerView.phone_alternate}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider>
                                    <DollarCircleOutlined style={styles.iconDivider} /> Endereço de Cobrança
                                </Divider>
                                <Descriptions style={{ marginTop: 3 }} bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical">
                                    <Descriptions.Item label="CEP: *">
                                        {
                                            customerView.billing_address_postalcode
                                        }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="País: *">
                                        {
                                            customerView.billing_address_country
                                        }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Estado: *">
                                        {customerView.billing_address_state}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Endereço: *">
                                        {
                                            customerView.billing_address_street
                                        }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Número: ">
                                        {""}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Bairro: *">
                                        {
                                            customerView.billing_address_district
                                        }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Cidade: *">
                                        {customerView.billing_address_city}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider>
                                    <PercentageOutlined style={styles.iconDivider} /> Dados de Venda
                                </Divider>
                                <Descriptions style={{ marginTop: 3 }} bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical">
                                    <Descriptions.Item label="Filial de Venda: ">
                                        {customerView.branch__name}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Gerente Comercial: ">
                                        {customerView.seller__name}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Tipo de nome: ">
                                        {customerView.type__name}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Data de Vencimento: ">
                                        {customerView.due_day}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Descrição: ">
                                        {customerView.notes}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Row>
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Button style={{ marginTop: 10, borderColor: "orange", color: "orange" }} icon={<RollbackOutlined style={{ fontSize: 18 }} />} type="default" onClick={() => history.push("/customers")}>
                                            Voltar
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        </Card>
                    </Card>
                </TabPane>
                <TabPane tab={<span>
                            <i style={styles.iconTab} className="fas fa-file-signature" />
                            Contratos
                        </span>} key="2">
                    <Contracts customerName={customerView.name} customerId={customerView.id} />
                </TabPane>

                <TabPane tab={<span>
                            <i style={styles.iconTab} className="fas fa-signal" />
                            Linhas
                        </span>} key="5">
                    <LinesClient customerName={customerView.name} customerId={customerView.id} />
                </TabPane>

                <TabPane tab={<span>
                            <i style={styles.iconTab} className="fa fa-industry" />
                            Operações em lote
                        </span>} key="6">
                    <ResourceClient customerName={customerView.name} customerId={customerView.id} />
                </TabPane>

                <TabPane tab={
                    <span>
                        <i style={styles.iconTab} className="fa fa-industry" />
                        OS de Pedido
                    </span>} key="7">
                    <StockOS  customerName={customerView.name} customerId={customerView.id} />
                </TabPane>
                
                <TabPane tab={
                    <span>
                        <i style={styles.iconTab} className="fa fa-industry" />
                        Processamento de pedido
                    </span>
                }
                key="processamento-de-pedido">
                    <OrderServiceProcessing customerName={customerView.name} customerId={customerView.id} />
                </TabPane>
            </Tabs>
        </>;
}

const styles = {
    container: {
        borderRadius: "10px",
        background: "#fff",
        boxShadow:
            "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },

    icon: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 17,
        marginRight: 10,
        fontSize: 22,
        color: "#ffffff"
    },
    iconTab: {
        
        borderRadius: 10,
        padding: 8,
        fontSize: 18,
        // color: "#ffffff"
    },

    iconDivider: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 8,
        fontSize: 18,
        color: "#ffffff"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    title: {
        fontWeight: 900,
        // marginLeft: 20
    },
};

export default ViewCustomer;

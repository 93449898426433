import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";

import { notification  } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

let placement = "bottomRight"

export const getTransactionResource = (page, limit) => dispatch => {
    dispatch(fetchStart(actionType.GET_TRANSACTION_LOGS_START));
    // userId = userId===undefined?"":userId
    axios
        .get(API_ROOT + `lines/transaction?page=${page}&size=${limit}`)

        .then(res => {
            dispatch(fetchSuccess(actionType.GET_TRANSACTION_LOGS_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_TRANSACTION_LOGS_FAIL, err));

        });

};

export const getTransactionLines = (transaction_id,page, limit) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));
    
    axios
        .get(API_ROOT + `lines/transaction?transactionId=${transaction_id}&page=${page}&size=${limit}`)

        .then(res => {
            console.log(res.data)
            dispatch(fetchSuccess(actionType.GET_LOGS_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};
export const getTransactionLogsSummarized = (transaction_id) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));
    
    
    axios
        .get(API_ROOT + `lines/transaction/summarized?transactionId=${transaction_id}`)

        .then(res => {
            console.log(res.data)
            dispatch(fetchSuccess(actionType.GET_LOGS_SUMMARIZED, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};

export const setRetryTransaction = (transaction_id,iccid_in,iccid_out) => dispatch => {
    dispatch(fetchStart(actionType.GET_RETRY_START));
    notification.open({
        key: 'loadingRetry',
        message: 'Efetuando operação para inicio do fluxo',
        description:
            'Em instantes as linhas estarão na fila da transação' + transaction_id,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    
    let data = {"iccid_out":iccid_out,"iccid_in":iccid_in}
    axios
        .post(API_ROOT + `lines/transaction/retry?transaction_id=${transaction_id}`,data)

        .then(res => {
            console.log(res.data)
            notification.close('loadingRetry')
            notification.success({
                message: 'Linhas na fila com sucesso!',
                description:
                    'O processo está em execução',
                duration: 30,
                placement
            });
            dispatch(fetchSuccess(actionType.GET_RETRY_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            notification.close('loadingRetry')
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.GET_RETRY_FAIL, err));

        });

};

export const setRetryTransactionErrors = (transaction_id) => dispatch => {
    dispatch(fetchStart(actionType.GET_RETRY_START));
    notification.open({
        key: 'loadingRetry',
        message: 'Efetuando operação para inicio do fluxo',
        description:
            'Em instantes as linhas com erros estarão na fila da transação' + transaction_id,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    
    let data = {"transaction_id":transaction_id}
    axios
        .post(API_ROOT + `lines/transaction/retry-errors`,data)

        .then(res => {
            console.log(res.data)
            notification.close('loadingRetry')
            notification.success({
                message: 'Linhas na fila com sucesso!',
                description:
                    'O processo está em execução',
                duration: 30,
                placement
            });
            dispatch(fetchSuccess(actionType.GET_RETRY_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            notification.close('loadingRetry')
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.GET_RETRY_FAIL, err));

        });

};


import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Dropdown, Tag, Tooltip, Modal, notification } from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  StopOutlined,
  EditOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Page403 from "../../../PagesInfo/Page403";
import {
  getUsers,
  deleteUser,
  enableOrDisableUser,
  clearMessage
} from "../../../../store/actions/userAction";
import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import Backdrop from "../../../../components/Backdrop/Backdrop";

const { confirm } = Modal;

const UserOperation = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [idsSelected, setIdsSelected] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { users, loading, message, errors } = useSelector(state => state.user);

  const loadUsers = useCallback(() => {
    dispatch(getUsers(1, page, pageSize));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (message) {
      notification.success({
        message: message,
        placement: "topRight"
      });
      dispatch(clearMessage());
    }
    loadUsers();
  }, [dispatch, page, pageSize, loadUsers, message]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setIdsSelected(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name
    })
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
  };

  const showDeleteConfirm = (id, nameUser) => {
    confirm({
      title: "Tem certeza de que deletar este usuário?",
      content: <strong>{nameUser}</strong>,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        dispatch(deleteUser(id));
        setPage(page === 1 ? page : page - 1);
      }
    });
  };

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Adicionar usuário">
        <i
          style={styles.subTitle}
          className="fas fa-user-plus"
          onClick={() => {
            history.push("/add-user-operation");
          }}
        />
      </Tooltip>
      {/* <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => {
            alert("Filtros");
          }}
        />
      </Tooltip> */}
    </div>
  );

  const columns = [
    {
      title: "Nome",
      dataIndex: "name"
    },
    {
      title: "Email",
      dataIndex: "email"
    },
    {
      title: "Perfil",
      dataIndex: "roles",
      render: (text, record) =>
        text.map((role, index) => {
          return (
            <span style={styles.profile} key={role.id}>
              {role.description}
              {text.length !== index + 1 ? "," : ""}
            </span>
          );
        })
    },
    {
      title: "Ativo",
      dataIndex: "is_active",
      render: (text, record) => (
        <Tag color={record.is_active ? "#87d068" : "#f50"}>
          {record.is_active ? "Ativo" : "Inativo"}
        </Tag>
      )
    },
    {
      title: () => (
        <Dropdown
          disabled={idsSelected.length === 0}
          overlay={
            <Menu>
              <Menu.Item
                key="2"
                onClick={() => {
                  dispatch(enableOrDisableUser(idsSelected, 1));
                }}
              >
                <CheckCircleOutlined style={{ color: "green" }} />
                <span>Ativar</span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="3"
                onClick={() => {
                  dispatch(enableOrDisableUser(idsSelected, 0));
                }}
              >
                <StopOutlined style={{ color: "red" }} />
                <span>Destativar</span>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <i
            className="fas fa-cogs"
            style={{
              fontSize: 20,
              cursor: idsSelected.length === 0 ? "not-allowed" : "default"
            }}
          />
        </Dropdown>
      ),
      key: "action",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="0"
                onClick={() => showDeleteConfirm(record.id, record.name)}
              >
                <DeleteOutlined style={{ color: "red" }} />
                Deletar
              </Menu.Item>
              <Menu.Item
                key="1"
                onClick={() => {
                  history.push({
                    pathname: `/edit-user-operation/${record.id}`,
                    state: {
                      id: record.id,
                      name: record.name,
                      email: record.email,
                      status: record.is_active,
                      profile: record.roles[0].id,
                      clientId: record.client_id
                    }
                  });
                }}
              >
                <EditOutlined style={{ color: "gray" }} />
                <span>Editar</span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="2"
                onClick={() => {
                  dispatch(enableOrDisableUser([record.id], 1));
                }}
              >
                <CheckCircleOutlined style={{ color: "green" }} />
                <span>Ativar</span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="3"
                onClick={() => {
                  dispatch(enableOrDisableUser([record.id], 0));
                }}
              >
                <StopOutlined style={{ color: "red" }} />
                <span>Desativar</span>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <i className="fas fa-cog" style={{ fontSize: 18 }} />
        </Dropdown>
      )
    }
  ];

  return (
    <div>
      <Backdrop show={loading} loading={true} />
      {errors ===403 && (<Page403/>)}
      {users && (
        <HeliosTable
          title="Usuário Operação"
          subTitle={subTitle}
          columns={columns}
          icon="fas fa-users"
          dataSource={users.data}
          rowKey="id"
          total={users.total}
          pageSize={pageSize}
          defaultCurrent={page}
          onChange={handlePaginationChange}
          onShowSizeChange={onShowSizeChange}
          rowSelection={rowSelection}
        />
      )}
    </div>
  );
};

const styles = {
  subTitle: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },
  profile: {
    marginRight: 10,
    //fontWeight: 600,
    fontStyle: "italic"
  }
};

export default UserOperation;

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer
} from "recharts";

const HeliosCardChart = props => {
  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          barGap={10}
          barCategoryGap={window.innerWidth <= 1500 ? 25 : 35}
          data={props.dataChart}
          margin={{
            top: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="monthYear" tick={{ fontSize: 12 }} />
          <YAxis />
          <Tooltip />
          <Legend wrapperStyle={{ textTransform: "capitalize" }} />
          <Bar
            dataKey="total"
            fill={props.color}
            minPointSize={2}
            label={{ position: "top" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HeliosCardChart;

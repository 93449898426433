import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLinesConsumption } from "../../../../store/actions/lineConsumptionAction";
import { getCustomerByName } from "../../../../store/actions/customerAction";

import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import {
  Tooltip,
  Drawer,
  Form,
  Input,
  Button,
  AutoComplete,
  DatePicker
} from "antd";
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";
import { columns } from "./columnsTable";
import moment from "moment";

const { Search } = Input;

const LinesConsumption = props => {
  let clientId = localStorage.getItem("clientId")
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [optionsValue, setOptionsValue] = useState("");
  const [iccidInput, setIccidInput] = useState("");
  const [msisdnInput, setMsisdnInput] = useState("");
  const [iccid, setIccid] = useState("");
  const [msisdn, setMsisdn] = useState("");
  const [date, setDate] = useState(moment());
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getLinesConsumption(customerId, iccid, msisdn, date, page-1, pageSize)
    );
  }, [customerId, date, dispatch, iccid, msisdn, page, pageSize]);

  useEffect(() => {
    if (customerName) {
      dispatch(getCustomerByName(customerName));
    }
  }, [dispatch, customerName]);

  const customers = useSelector(state => state.customers);

  useEffect(() => {
    if (customers.customers) {
      let options = [];
      customers.customers.data.map(obj => {
        return options.push({ value: obj.id.toString(), label: obj.name });
      });
      setOptionsCustomer(options);
    }
  }, [customers]);

  const { linesConsumptions, loading } = useSelector(
    state => state.linesConsumption
  );

  const onChangeInput = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "iccid" && e.target.value.length <= 20) {
        setIccidInput(e.target.value);
        if (e.target.value.length === 20) {
          setIccid(e.target.value);
        }
      }
      if (e.target.name === "msisdn" && e.target.value.length <= 13) {
        setMsisdnInput(e.target.value);
        if (e.target.value.length === 13) {
          setMsisdn(e.target.value);
        }
      }
    }
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(current);
  };

  const onPaginationChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);

  };

  const onClearFilter = () => {
    setCustomerId();
    setCustomerName();
    setOptionsValue("");
    setIccid("");
    setIccidInput("");
    setMsisdn("");
    setMsisdnInput("");
  };

  const onCloseFilter = () => {
    setShowFilter(false);
  };

  const onShowFilter = () => {
    setShowFilter(true);
  };

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => {
            onShowFilter();
          }}
        />
      </Tooltip>
    </div>
  );

  return (
    <div>

      <SkeletonTable show={loading} title="Linhas X Consumo" icon="fas fa-chart-line"  pageSize={pageSize}/>

      {linesConsumptions && !loading? (
        <HeliosTable
          title="Linhas X Consumo"
          subTitle={subTitle}
          icon="fas fa-chart-line"
          columns={columns}
          dataSource={linesConsumptions.lines.data || []}
          rowKey="id"
          total={linesConsumptions.lines.total}
          pageSize={pageSize}
          page={page}
          onChange={onPaginationChange}
          onShowSizeChange={onShowSizeChange}
        />
      ):""}

      <Drawer
        title="Filtros"
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        placement="right"
        onClose={onCloseFilter}
        visible={showFilter}
        width={500}
        style={styles.containerDrawer}
      >
        <Form layout="vertical">
        {clientId ===null &&
          <Form.Item label="CLIENTE">
            <AutoComplete
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerId();
                  setCustomerName();
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                setOptionsValue(option.label);
                setCustomerId(value);
              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
            </AutoComplete>
          </Form.Item>
          }
          <Form.Item label="ICCID">
            <Search
              autoComplete="off"
              name="iccid"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setIccid("");
                }
                onChangeInput(event);
              }}
              value={iccidInput}
            />
          </Form.Item>
          <Form.Item label="MSISDN">
            <Search
              autoComplete="off"
              name="msisdn"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setMsisdn("");
                }
                onChangeInput(event);
              }}
              value={msisdnInput}
            />
          </Form.Item>
          <Form.Item label="DATA">
            <DatePicker
              style={{ width: "100%" }}
              name="date"
              value={date}
              onChange={(date, dateString) => {
                setDate(date);
                console.log(date, dateString);
              }}
              disabledDate={current => {
                return current && current > moment();
              }}
              picker="month"
              format={"MM/YYYY"}
            />
          </Form.Item>
        </Form>
        <div style={styles.btnFilter}>
          <Button
            onClick={onClearFilter}
            type="danger"
            ghost
            style={styles.btnCancel}
          >
            LIMPAR
          </Button>
          <Button onClick={onCloseFilter} type="default">
            FECHAR
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

const styles = {
  subTitle: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },
  containerDrawer: {
    //width: 30
    //backgroundColor: "transparent"
  },
  btnFilter: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    borderTop: "1px solid #e9e9e9",
    padding: "10px 16px",
    background: "#fff",
    textAlign: "right"
  },
  btnCancel: {
    marginRight: 8
  },
  online: {
    color: "green"
  }
};

export default LinesConsumption;

import React, { useEffect } from 'react'
import { typeOperations } from './constantsOperationsOs'
import {
    Card,
    List,
    Tooltip,
    Modal,
    Row
} from "antd";
import Texty from 'rc-texty';
import Animate from 'rc-animate';
import OperationStatusInfo from '../../container/Dashborad/OperationsStatusInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getOperationsLinesSummarized } from '../../../store/actions/lineStatusAction';


const columnsStatus = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
    {
      title: 'value',
      dataIndex: 'value',
      key: 'value',
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
  ];


const CardsOperationOs = (props) => {
    // const { onChange, onType } = props
    const data = typeOperations;
    const { Item } = List
    const { Meta } = Card;

    const { statusOperationSummarized } = useSelector(state => state.linesStatus);
    
    const dispatch = useDispatch();

    const statusQuant=(typeResource)=> statusOperationSummarized && ([
        { name: 'Processando', value: statusOperationSummarized.processing[typeResource], color: "blue" },
        { name: 'Em espera', value: statusOperationSummarized.waiting[typeResource], color: "orange" },
        { name: 'Finalizados', value: statusOperationSummarized.finalized[typeResource], color: "green" }
    
      ]);

    useEffect(() => {
      const intervalSummarized = setInterval(() => {
        dispatch(getOperationsLinesSummarized());
      }, 5000);
      return () => {
        clearInterval(intervalSummarized)
      };
    },[dispatch]);

    return (
        <>

        <Animate transitionAppear transitionName="fade">
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 4
            }}
            dataSource={data}
            renderItem={item => (
                <Item >
                    <Card  hoverable extra={<><Tooltip placement="bottom" title={"Ajuda com "+item.name}><i onClick={()=>Modal.info({
    title: <center>{item.name}</center>,
    content: (
      <div><Card>
                    {item.name ===("Suspensão Temporária") ? 
                    <><Row><p>Recurso que suspende a(s) linha(s) temporariamente do cliente, impedindo o tráfego de dados e SMS. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação tem um tempo de processamento maior</li>
                    <li>Necessário informar a quantidade de dias que a linha ficará suspensa, geralmente suspendendo em 30/90/120 dias</li>
                    
                    </ul></Row>
                    </>: ""}
                    {item.name ===("Suspensão a Pedido") ? 
                    <><Row><p>Recurso que suspende a(s) linha(s) quando o cliente faz o requerimento, impedindo o tráfego de dados e SMS. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação tem um tempo de processamento maior</li>
                    
                    </ul></Row>
                    </>: ""}
                    {item.name ===("Suspensão Perda/Roubo") ? 
                    <><Row><p>Recurso que suspende a(s) linha(s) quando os simcards são perdidos ou roubados, impedindo o tráfego de dados e SMS. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação tem um tempo de processamento maior</li>
                    
                    </ul></Row>
                    </>: ""}
                    {item.name ===("Suspensão Quarentena") ? 
                    <><Row><p>Recurso que suspende a(s) linha(s) da NLT, impedindo o tráfego de dados e SMS. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação tem um tempo de processamento maior</li>
                    
                    </ul></Row>
                    </>: ""}

                    {item.name ===("Reserva de Numeração") ? 
                    <><Row><p>Recurso que adiciona msisdn's ao's ICCID's da NLT, transformando o iccid virgem em uma linha com casamento ICCID+IMSI+MSISDN. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação tem um tempo de processamento maior</li>
                    <li>Essa operação é necessário enviar apenas arquivo com ICCID's</li>
                    <li>Não é permitido enviar msisdn's no arquivo</li>
                    
                    </ul></Row>
                    </>: ""}

                    {item.name ===("Reativação") ? 
                    <><Row><p>Recurso que reativa o ICCID da NLT, transformando o iccid desativado em uma linha com casamento ICCID+MSISDN. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação tem um tempo de processamento maior</li>
                    <li>Essa operação é necessário enviar apenas arquivo com ICCID's</li>
                    <li>Não é permitido enviar msisdn's no arquivo</li>
                    
                    </ul></Row>
                    </>: ""}
        </Card></div>
    ),
    icon:(<><i style={styles.icon} className="far fa-question-circle" /></>),
    width:500,
    onOk() {},
  })
} style={styles.icon} className="far fa-question-circle"></i></Tooltip></>} title={<Texty
                    type={"left"}
                     mode={"smooth"}>{item.name}</Texty>
                    } 
                    >
                      {statusOperationSummarized &&(
                        <Meta
                        description={ <OperationStatusInfo
                            data={statusQuant(item.type)}
                            total={statusOperationSummarized.totalized[item.type]}
                            columns={columnsStatus}
                          />}
                        
                        onClick={()=>{
                          props.onType(item.name)
                          props.onChange()
                      }
                        }
                        />)}
                    </Card>
                </Item>
            )}
        />
        </Animate>
        </>
    //  icon question
    )

}
const styles = {
  icon:{
      fontSize: 18,
      cursor: "pointer"    
  }
}

export default CardsOperationOs;
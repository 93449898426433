import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
const placement = "bottomRight"


export const getManySuspension = (customerId, customerName, file,motive,protocol,email,typeResource,days) => dispatch => {

    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    formData.append("customerId", customerId);
    formData.append("customerName", customerName);
    formData.append("motive", motive);
    formData.append("protocol", protocol);
    formData.append("email", email);
    formData.append("typeSubset", "susp");

    if(typeResource==="Suspensão a Pedido"){
        formData.append("typeOs", 23);

    }
    else if(typeResource==="Suspensão Perda/Roubo"){
        formData.append("typeOs", 19);


    }else if(typeResource==="Suspensão Temporária"){
        formData.append("typeOs", 20);
        formData.append("days",days)

    }else if(typeResource==="Suspensão Quarentena"){
        formData.append("typeOs", 8);

    }
    else if(typeResource==="Reativação"){
    formData.append("typeOs", 24);

    }

    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.SUSPENSION_START));
    notification.open({
        key: 'loadingManySuspension',
        message: 'Solicitando operação de ' + typeResource + " massiva das Linhas",
        description:
            'Em instantes você receberá a resposta do pedido das linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });

    axios
        .post(API_ROOT + "lines/client/many-suspension", formData, config)
        .then(res => {
            notification.close('loadingManySuspension')

            dispatch(fetchSuccess(actionType.SUSPENSION_SUCCESS, res.data));
            dispatch(fetchStart(actionType.SUSPENSION_CLEAR));

        })
        .catch(err => {
            notification.close('loadingManySuspension')

            notification.error({
                message: err.response.data.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.SUSPENSION_ERROR, err));
        });
};


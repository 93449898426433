import * as actionType from "../types";
import moment from "moment";

const initialState = {
    countIccids: null,
    reserve: null,
    lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
    errors: null,
    message: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.COUNT_ICCIDS_START:
            return {
                ...state,
                loading: true
            };
        case actionType.COUNT_ICCIDS_SUCCESS:
            return {
                ...state,
                countIccids: action.payload,
                errors: null,
                loading: false
            };
        case actionType.COUNT_ICCIDS_ERROR:
            return {
                ...state,
                errors: action.errors,
                loading: false
            };
        case actionType.RESERVE_NUMBER_START:
            return {
                ...state,
                loading: true
            };
        case actionType.RESERVE_NUMBER_SUCCESS:
            return {
                ...state,
                reserve: action.payload,
                errors: null,
                loading: false
            };
        case actionType.RESERVE_NUMBER_ERROR:
            return {
                ...state,
                errors: action.errors,
                loading: false
            };
        case actionType.RESERVE_NUMBER_CLEAR:
                return initialState

        default:
            return state;
    }
}

import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
// import { notification, Button, Modal, Tag } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
// import React from "react";
// import HeliosTableAtr from "../../components/HeliosTable/HeliosTableAtr";

// const { info } = Modal;
// const placement = "bottomRight"

export const getLogs = (typeResource,line, page, limit) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));
    const user_id = localStorage.getItem("history");
    console.log(user_id)
    axios
        // .get(API_ROOT + `user_logs?userId=${userId}&typeResource=${typeResource}&email=${email}&page=${page}&limit=${limit}`)
        .get(API_ROOT + `user_logs?line=${line}&userId=${user_id}&page=${page}&typeResource=${typeResource}&limit=${limit}`)

        .then(res => {
            dispatch(fetchSuccess(actionType.GET_LOGS_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};

export const AllLogs = (userId,line, typeResource, page, limit) => dispatch => {
    dispatch(fetchStart(actionType.GET_LOGS_START));
    const user_id = localStorage.getItem("history");
    userId = userId===undefined?user_id:userId
    
    axios
        // .get(API_ROOT + `user_logs?userId=${userId}&typeResource=${typeResource}&email=${email}&page=${page}&limit=${limit}`)
        .get(API_ROOT + `user_logs?userId=${userId}&line=${line}&page=${page}&typeResource=${typeResource}&limit=${limit}`)

        .then(res => {
            console.log(res.data)

            dispatch(fetchSuccess(actionType.GET_LOGS_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            dispatch(fetchError(actionType.GET_LOGS_FAIL, err));

        });

};


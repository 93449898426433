import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatusLinesSummarized, getStatusLinesSummarizedNLT } from "../../../store/actions/lineStatusAction";
import { getConsumptionLinesSummarized, getConsumptionLinesSummarizedNLT } from "../../../store/actions/lineConsumptionAction";
import { getPhaseSimcard } from "../../../store/actions/phaseSimcardsAction";
import { getCustomerByName, getCustomers } from "../../../store/actions/customerAction";

import { Row, Col, Card, Input, AutoComplete } from "antd";
import LinesStatusInfo from "../../container/Dashborad/LinesStatusInfo";
// import ConsumptionInfo from "../../container/Dashborad/ConsumptionInfo";

import numeral from "numeral";
import "numeral/locales/pt-br";

import HeliosCardChart from "../../../components/HeliosCardChart/HeliosCardChart";
import Loading from "../../../components/Loading/Loading";
import CardInfo from "../../container/Dashborad/CardInfo";
import LinesSummarized from "../../../model/LinesSummarized";
import LinesConsumptionSummarized from "../../../model/LinesConsumptionSummarized";
import moment from "moment";

const dateStart = moment()
  .subtract(12, "months")
  .format("YYYY-MM-DD");

const dateNow = moment().format("YYYY-MM-DD");


const columnsStatus = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
  },
  {
    title: "%",
    dataIndex: "percent",
    key: "percent"
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: text => numeral(text).format("0,0")
  }
];

let localDate = moment(new Date()).format("YYYY-MM-DD");

const Dashboard = () => {
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [customerSelected, setCustomerSelected] = useState("Nenhum cliente selecionado");
  const [customerSelectedPhase, setCustomerSelectedPhase] = useState("Operação NLT");


  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [optionsValue, setOptionsValue] = useState("");
  const [height, setHeight] = useState(window.innerHeight);
  const dispatch = useDispatch();
  let clientId = localStorage.getItem("clientId")
  numeral.locale("pt-br");

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    dispatch(getStatusLinesSummarized(customerId));
    dispatch(getStatusLinesSummarizedNLT());
    dispatch(getConsumptionLinesSummarized(customerId, localDate, localDate));
    dispatch(getConsumptionLinesSummarizedNLT());
    dispatch(getPhaseSimcard(dateStart, dateNow, 1, customerId));
    dispatch(getCustomers(null,1, 0))
    
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customerName && !clientId) {
      dispatch(getCustomerByName(customerName));
    }
   
  }, [dispatch, customerName,clientId]);

  const status = useSelector(state => state.linesStatus);
  const statusNLT = useSelector(state => state.statusLineSummarizedNLT);
  const consumptions = useSelector(state => state.linesConsumption);
  const consumptionsNLT = useSelector(state => state.consumptionLineSummarizedNLT);
  
  const phaseSimcards = useSelector(state => state.phaseSimcards);
  const customers = useSelector(state => state.customers);

  const { allCustomerList, loading } = useSelector(state => state.allCustomerList);

  useEffect(() => {
    if (customers.customers) {
      let options = [];
      customers.customers.data.map(obj => {
        return options.push({ value: obj.id.toString(), label: obj.name });
      });
      setOptionsCustomer(options);

      
    }

  }, [customers]);
  let dataStatus = [];

  if (status.statusLineSummarized) {
    const {
      total_lines,
      online,
      offline,
      blocked,
      not_connected
    } = status.statusLineSummarized;
    dataStatus.push(
      new LinesSummarized("Online", total_lines, online, "green")
    );
    dataStatus.push(
      new LinesSummarized("Offline", total_lines, offline, "orange")
    );
    dataStatus.push(
      new LinesSummarized("Bloqueado", total_lines, blocked, "red")
    );
    dataStatus.push(
      new LinesSummarized("Nunca usado", total_lines, not_connected, "gray")
    );
  }

  let dataStatusNLT = [];
  if (statusNLT.statusLineSummarizedNLT) {
    const {
      total_lines,
      online,
      offline,
      blocked,
      not_connected
    } = statusNLT.statusLineSummarizedNLT;
    dataStatusNLT.push(
      new LinesSummarized("Online", total_lines, online, "green")
    );
    dataStatusNLT.push(
      new LinesSummarized("Offline", total_lines, offline, "orange")
    );
    dataStatusNLT.push(
      new LinesSummarized("Bloqueado", total_lines, blocked, "red")
    );
    dataStatusNLT.push(
      new LinesSummarized("Nunca usado", total_lines, not_connected, "gray")
    );
  }

  let dataConsumption = [];

  if (consumptions.consumptionLineSummarized) {
    const {
      total,
      dowload,
      upload,
      prefixDW,
      prefixUP
    } = consumptions.consumptionLineSummarized;
    dataConsumption.push(
      new LinesConsumptionSummarized(        "Download",
        total,
        dowload,
        "green",
        prefixDW
      )
    );
    dataConsumption.push(
      new LinesConsumptionSummarized(
        "Upload",
        total,
        upload,
        "orange",
        prefixUP
      )
    );
  }
  let dataConsumptionNLT = [];

  if (consumptionsNLT.consumptionLineSummarizedNLT) {
    const {
      total,
      dowload,
      upload,
      prefixDW,
      prefixUP
    } = consumptionsNLT.consumptionLineSummarizedNLT;
    dataConsumptionNLT.push(
      new LinesConsumptionSummarized(
        "Download",
        total,
        dowload,
        "green",
        prefixDW
      )
    );
    dataConsumptionNLT.push(
      new LinesConsumptionSummarized(
        "Upload",
        total,
        upload,
        "orange",
        prefixUP
      )
    );
  }

  return (

    <div>

      <Card
        title={<h1 style={styles.title}>
          <i
            style={styles.subTitle}
            className="fas fa fa-tachometer-alt"
          />
              Dashboard Mobile
          </h1>}
        extra={
          
          <div wrappercol={{ sm: 24 }} style={{ width: 500 }}>
            {!clientId && 

            <AutoComplete style={{ width: "100%" }}
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerId();
                  setCustomerName();
                  setCustomerSelected("Nenhum cliente selecionado");
                  setCustomerSelectedPhase("Operação NLT");
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                setOptionsValue(option.label);
                setCustomerId(value);
                setCustomerSelected(option.label);
                setCustomerSelectedPhase(option.label);
              }}
            >
              
              <Input.Search
                size="large"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
              
            </AutoComplete>
             }
          </div>
          
        }
      >
        <Row gutter={16}>
          {!clientId &&
          <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
            <CardInfo
              title="Base total de linhas"
              subTitle="Operação NLT"
              lastUpdate={statusNLT.lastUpdate}
              onUpdate={() => dispatch(getStatusLinesSummarizedNLT())}
            >
              {statusNLT.loading ? (
                <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
              ) : (
                  <LinesStatusInfo
                    data={dataStatusNLT}
                    total={
                      statusNLT.statusLineSummarizedNLT
                        ? statusNLT.statusLineSummarizedNLT.total_lines
                        : 0
                    }
                    columns={columnsStatus}
                  />
                )}
            </CardInfo>
          </Col>
          }
          <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
          {loading ? (
                <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
              ) : (
            <CardInfo
              title={clientId && allCustomerList ? allCustomerList.data[0].name : customerSelected}
              subTitle=""
              lastUpdate={status.lastUpdate}
              onUpdate={() => dispatch(getStatusLinesSummarized(customerId))}
            >
              {status.loading ? (
                <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
              ) : (
                  <LinesStatusInfo
                    data={dataStatus}
                    total={
                      status.statusLineSummarized
                        ? status.statusLineSummarized.total_lines
                        : 0
                    }
                    columns={columnsStatus}
                  />
                )}
            </CardInfo>
              )}
            
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
            <CardInfo
              title="Base total de consumo"
              subTitle="Operação NLT"
              lastUpdate={consumptionsNLT.lastUpdate}
              onUpdate={() =>
                dispatch(
                  getConsumptionLinesSummarizedNLT()
                )
              }
            >
              {consumptionsNLT.loading ? (
                <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
              ) : (
                  <ConsumptionInfo
                    columns={columnsConsumption}
                    data={dataConsumptionNLT}
                    total={parseFloat(
                      consumptionsNLT.consumptionLineSummarizedNLT
                        ? consumptionsNLT.consumptionLineSummarizedNLT.total
                        : 0
                    )}
                    prefixTotal={
                      consumptionsNLT.consumptionLineSummarizedNLT
                        ? consumptionsNLT.consumptionLineSummarizedNLT.prefixTotal
                        : "B"
                    }
                  />
                )}
            </CardInfo>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
            <CardInfo
              title={customerSelected}
              subTitle=""
              lastUpdate={consumptions.lastUpdate}
              onUpdate={() =>
                dispatch(
                  getConsumptionLinesSummarized(
                    customerId,
                    localDate,
                    localDate
                  )
                )
              }
            >
              {consumptions.loading ? (
                <Loading heightContainer={height <= 650 ? "30vh" : "25vh"} />
              ) : (
                  <ConsumptionInfo
                    columns={columnsConsumption}
                    data={dataConsumption}
                    total={parseFloat(
                      consumptions.consumptionLineSummarized
                        ? consumptions.consumptionLineSummarized.total
                        : 0
                    )}
                    prefixTotal={
                      consumptions.consumptionLineSummarized
                        ? consumptions.consumptionLineSummarized.prefixTotal
                        : "B"
                    }
                  />
                )}
            </CardInfo>
          </Col>
        </Row> */}
       {!clientId &&
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
            <CardInfo
              title="Total de linhas ativadas"
              subTitle={customerSelectedPhase}
              lastUpdate={phaseSimcards.lastUpdate}
              onUpdate={() =>
                dispatch(getPhaseSimcard(dateStart, dateNow, 1, customerId))
              }
            >
              {phaseSimcards.loading ? (
                <Loading heightContainer={height <= 650 ? "47vh" : "30vh"} />
              ) : (
                  < HeliosCardChart color="green" dataChart={phaseSimcards.simcardActivated} />
                )}
            </CardInfo>
          </Col>
        </Row>
        }
      </Card>
    </div>
  );
};
const styles = {

  titleContainer: {
    display: "flex",
    justifyContent: "space-between",

    // alignItems: "center"
  },
  title: {
    fontWeight: 900,
    // marginLeft: 20
  },

  subTitle: {
    background: "linear-gradient(80deg, #43A047, #FFEB3B)",
    borderRadius: 10,
    padding: 17,
    marginRight: 10,
    fontSize: 22,
    color: "#ffffff"
    // cursor: "pointer"
  }
};
export default Dashboard;

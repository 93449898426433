import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";

//http://localhost:3333/api/customers?name=Li
export const getPlanByName = name => dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchStart(actionsType.GET_PLANS_START));

    axios
        .get(API_ROOT + `plans?name=${name}`)
        .then(res => {
            // dispatch(getCustomerSuccess(res.data));
            dispatch(fetchSuccess(actionsType.GET_PLANS_SUCCESS, res.data));
        })
        .catch(error => {
            dispatch(fetchError(actionsType.GET_PLANS_FAIL, error));
        });
};

import * as actionType from "../types";
import moment from "moment";

const initialState = {
    statusLineSummarizedNLT: null,
    lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
    errors: null,
    message: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.GET_LINES_START_NLT:
            return {
                ...state,
                loading: true
            };
        case actionType.GET_STATUS_LINES_SUMMARIZED_SUCCESS_NLT:
            return {
                ...state,
                statusLineSummarizedNLT: action.payload,
                lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
                errors: null,
                loading: false
            };
        case actionType.GET_LINES_FAIL_NLT:
            return {
                ...state,
                errors: action.errors,
                loading: false
            };

        default:
            return state;
    }
}
// import React from 'react'
// import {
//     Button
// } from "antd";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';



const ExportCSV = (csvData, fileName) => {
    console.log("aqui")
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // const exportToCSVBilling = ( fileName) => {


    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'Dados': ws }, SheetNames: ['Dados'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);


    // return (
    //     <Button type="warning" onClick={(e) => exportToCSVBilling(csvData, fileName)}>Baixar Relatório</Button>
    // )
}
export default ExportCSV;
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../store/actions/authAction";

import { Avatar, Menu, Spin } from "antd";
import { SettingOutlined, UserOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons';

import { FormattedMessage } from "umi-plugin-react/locale";
import HeaderDropdown from "../HeaderDropdown";

const AvatarDropdown = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id, name } = useSelector(state => state.auth.user.data);

  const logout = () => {
    dispatch(logoutUser(id));
    history.push("/login");
  };

  const {
    currentUser = {
      avatar: "",
      name: name
    },
    menu
  } = props;

  const menuHeaderDropdown = (
    <Menu className="menuAvatar" selectedKeys={[]} onClick={() => { }}>
      {menu && (
        <Menu.Item key="center">
          <UserOutlined />

          <FormattedMessage
            id="menu.account.center"
            defaultMessage="account center"
          />
        </Menu.Item>
      )}
      {menu && (
        <Menu.Item key="settings">
          <SettingOutlined />
          <FormattedMessage
            id="menu.account.settings"
            defaultMessage="account settings"
          />
        </Menu.Item>
      )}
      {menu && <Menu.Divider />}

      <Menu.Item key="change-password" onClick={() => {
        history.push({
          pathname: `/change-password/`,
          state: {
            id: id,
            name: name,
          }
        });
      }}>
        <EditOutlined style={{ fontSize: "16px" }} />
        Alterar senha
      </Menu.Item>
      <Menu.Item key="logout" onClick={() => logout()}>
        <LogoutOutlined />
        Sair
      </Menu.Item>
    </Menu>
  );
  return currentUser && currentUser.name ? (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span style={{ padding: 5 }}>
        <Avatar
          size="small"
          icon={<UserOutlined />}
          className="avatar"
          src={currentUser.avatar}
          alt="avatar"
        />
        <span className="name" >{currentUser.name}</span>
      </span>
    </HeaderDropdown>
  ) : (
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8
        }}
      />
    );
};

export default AvatarDropdown;

import * as type from "../types";
import moment from "moment";
const initialState = {
  lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
  devices: null,
  errors: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.GET_DEVICES_START:
      return {
        ...state,
        loading: true
      };
    case type.GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
        errors: null,
        loading: false
      };

    case type.GET_DEVICES_FAIL:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };

    default:
      return state;
  }
}

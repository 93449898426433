export const getOrderServicesReportName = (resourceType) => {
  switch(resourceType) {
    case 'on_demand_os': {
      return 'os_sob_demanda'
    }
    case 'stock_os_with_booking_numbering': {
      return 'os_estoque_avancado_com_reserva_de_numeracao'
    }
    case 'stock_os_with_msisdn': {
      return 'os_estoque_avancado'
    }
    case 'active_stock_os_with_booking_numbering': {
      return 'os_estoque_ativado'
    }
    default: {
      return '';
    }
  }
};

export const getOrderServiceReportHeaders = (typeResource) => {
  switch (typeResource) {
    case 'stock_os_with_booking_numbering': 
    case 'active_stock_os_with_booking_numbering':
    case 'on_demand_os':
    case 'stock_os_with_msisdn': {
      return [
        {label: 'ICCID', key: 'iccid'},
        {label: 'MSISDN', key: 'msisdn'},
        {label: 'Fase', key: 'phase'},
        {label: 'Plano', key: 'planType'},
        {label: 'Nome do Cliente', key: 'customerName'},
        {label: 'Data de Ativação', key: 'activationDate'}
      ];
    }
    default: {
      return [];
    }
  };
  
};

export const getOrderServiceType = (resourceType) => {
  switch(resourceType) {
    case 'stock_os_with_msisdn':
    case 'active_stock_os_with_booking_numbering':
    case 'stock_os_with_booking_numbering': {
      return 'stock';
    }
    case 'on_demand_os': {
      return 'on-demand'
    }
    default: {
      return ''
    }
  }
};

export const massiveOrdersTypes = (typeResource) => {
  switch(typeResource) {
    case 'on_demand_os':
      return 'OS com Estoque Sob Demanda';
    case 'stock_os_with_msisdn':
    case 'stock_os_with_booking_numbering':
      return 'OS com Estoque Avançado';
    case 'active_stock_os_with_booking_numbering':
      return 'OS com Estoque Ativado';
    default:
      return '';
  };
};

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Dropdown, Tag } from "antd";

import { getUsers } from "../../../../store/actions/userAction";
import HeliosTable from "../../../../components/HeliosTable/HeliosTable";

const menu = (
  <Menu>
    <Menu.Item key="0">
      <span>1st menu item</span>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="http://www.taobao.com/">2nd menu item</a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3">3rd menu item</Menu.Item>
  </Menu>
);

const columns = [
  {
    title: "Name",
    dataIndex: "name"
  },
  {
    title: "Email",
    dataIndex: "email"
  },
  {
    title: "Ativo",
    dataIndex: "is_active",
    render: (text, record) => (
      <Tag color={record.is_active ? "#87d068" : "#f50"}>
        {record.is_active ? "Ativo" : "Inativo"}
      </Tag>
    )
  },
  {
    title: () => (
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <i className="fas fa-cogs" style={{ fontSize: 20 }} />
      </Dropdown>
    ),
    key: "action",
    render: (text, record) => (
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <i
          className="fas fa-cog"
          style={{ fontSize: 18 }}
          onClick={() => console.log("text: ", record.is_active)}
        />
      </Dropdown>
    )
  }
];

const UserCustomer = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();

  const users = useSelector(state => state.user.users);

  const loadUsers = useCallback(() => {
    dispatch(getUsers(2, page, pageSize));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setPageSize(pageSize);
  };

  const handlePaginationChange = page => {
    setPage(page);
  };
  return (
    <div>
      {users && (
        <HeliosTable
          title="Usuário Clientes"
          columns={columns}
          dataSource={users.data}
          rowKey="email"
          total={users.total}
          pageSize={pageSize}
          defaultCurrent={page}
          onChange={handlePaginationChange}
          onShowSizeChange={onShowSizeChange}
        />
      )}
    </div>
  );
};

export default UserCustomer;

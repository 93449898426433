import React, { useState } from 'react';
import { Card, Col, Form, Input, Row } from 'antd';

const MAX_PROTOCOL_LENGTH = 10;
const styles = {
  container: {
    borderRadius: "10px",
    background: "#fff",
    boxShadow:
      "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  },
  containerTitle: {
    color: "gray",
    fontWeight: "bold"
  },
  subTitle: {
    display: "flex",
    justifyContent: "flex-end",
    color: "gray",
    fontWeight: "bold"
  }
};

const FormStockOsDemand = (props) => {
  const [protocol, setProtocol] = useState("");
  const [protocolInput, setProtocolInput] = useState("");

  const [motive, setMotive] = useState("");
  const [motiveInput, setMotiveInput] = useState("");

  const [email, setEmail] = useState("");
  const [emailInput, setEmailInput] = useState("");

  if (protocol) {
    props.setProtocol(protocol);
  }

  if (motive) {
    props.setMotive(motive);
  }

  if (email) {
    props.setEmail(email);
  }

  // Funções
  const onChangeInput = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "protocol" && e.target.value.length <= MAX_PROTOCOL_LENGTH) {
        setProtocolInput(e.target.value);

        if (e.target.value.length === MAX_PROTOCOL_LENGTH) {
          setProtocol(e.target.value);
        }
      }
    }

    if (e.target.name === "motive") {
      setMotiveInput(e.target.value);

      if (e.target.value.length > 4) {
        setMotive(e.target.value);
      }
    }

    if (e.target.name === "email") {
      setEmailInput(e.target.value);

      if (e.target.value.length > 4) {
        setEmail(e.target.value);
      }
    }
  }

  return (
    <>
      <Card style={styles.container}>
        <Form style={{ marginTop: 3 }} layout="vertical">
          <Form.Item label="Motivo da Operação:">
            <Input.TextArea
              name="motive"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setMotive("");
                }
                onChangeInput(event);
              }}
              value={motiveInput} />
          </Form.Item>
        </Form>

        <Row gutter={24}>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form style={{ marginTop: 3 }} layout="vertical">
              <Form.Item label="Protocolo:">
                <Input
                  name="protocol"
                  allowClear
                  onChange={event => {
                    if (event.target.value === "") {
                      setProtocol("");
                    }
                    onChangeInput(event);
                  }}
                  value={protocolInput}
                />
              </Form.Item>
            </Form>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form style={{ marginTop: 3 }} layout="vertical" rules={[{ type: 'email' }]}>
              <Form.Item label="Email do Requerente:">
                <Input
                  name="email"
                  onChange={event => {
                    if (event.target.value === "") {
                      setEmail("");
                    }
                    onChangeInput(event);
                  }}
                  value={emailInput}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default FormStockOsDemand;

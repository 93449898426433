import * as type from "../types";
import moment from "moment";
const initialState = {
    lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
    devicesConsumptionNLT: null,
    errors: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case type.COUNT_DEVICES_START_NLT:
            return {
                ...state,
                loading: true
            }
        case type.COUNT_DEVICES_SUCCESS_NLT:
            return {
                ...state,
                devicesConsumptionNLT: action.payload,
                lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
                errors: null,
                loading: false
            };
        case type.COUNT_DEVICES_FAIL_NLT:
            return {
                ...state,
                errors: action.errors,
                loading: false
            };

        default:
            return state;
    }
}

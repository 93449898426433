import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
import { notification } from "antd";

const placement = "bottomRight"

export const getLocationMsisdn = msisdn => dispatch => {
    dispatch(fetchStart(actionType.GET_GEOLOCATION_START));

    axios
        .post(API_ROOT + "location-mobile", { "msisdn": msisdn })
        .then(res => {
            if(res.data[0]){
                if (res.data[0].status === "Not found") {
                    notification.error(res.data[0].status);
                }
            }
         
            dispatch(fetchSuccess(actionType.GET_GEOLOCATION_SUCCESS, res.data));
        })
        .catch(err => {
            console.log(err)
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.GET_GEOLOCATION_FAIL, err));
        });
};
export const getManyLocationMsisdn = file => dispatch => {

    const formData = new FormData();
    console.log(file)
    formData.append("file", file);
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.GET_GEOLOCATION_START));

    axios
        .post(API_ROOT + "many-location-mobile", formData, config)
        .then(res => {

            dispatch(fetchSuccess(actionType.GET_GEOLOCATION_SUCCESS, res.data));
        })
        .catch(err => {
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.GET_GEOLOCATION_FAIL, err));
        });
};

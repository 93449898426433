import * as actionType from "../types";
import moment from "moment";

const initialState = {
  simcardActivated: null,
  lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
  errors: null,
  message: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.GET_PHASE_SIMCARD_START:
      return {
        ...state,
        loading: true
      };
    case actionType.GET_PHASE_SIMCARDS_SUCCESS:
      return {
        ...state,
        simcardActivated: action.payload,
        lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
        errors: null,
        loading: false
      };
    case actionType.GET_PHASE_SIMCARD_FAIL:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };

    default:
      return state;
  }
}

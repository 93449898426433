
import { AutoComplete, Card, Col, Form, Input, Row, Divider, Button, Radio, Select, notification, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import {FileOutlined } from "@ant-design/icons";
import { CodEmpresa, DiscountType, SaleType } from './constantsSelect';
import { getPlanByName } from '../../../store/actions/planAction';
import { getEmployeesByName } from '../../../store/actions/customerAction';
import { editContract, insertContracts } from '../../../store/actions/contractAction';
import moment from 'moment';
// import 'moment/locale/en'  // without this line it didn't work
// moment.locale('en')
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

const { Option } = Select;
// Schema for yup
const validationSchema = Yup.object().shape({
    //DADOS EMPRESARIAIS
    crm_code: Yup.string()
        .required("Número do CRM é obrigatório."),
    discount_value: Yup.string()
        .required("Tipo de desconto é obrigatório."),
    service_ti: Yup.string()
        .required("O Serviço de TI é obrigatório."),
    cod_emp: Yup.string()
        .required("O Código da Empresa é obrigatório."),
    plan__name: Yup.string()
        .required("O Plano é obrigatório."),
    requester__name: Yup.string()
        .required("O gerente é obrigatório."),
    // //Contato
    // phone: Yup.string()
    //     .required("O telefone é obrigatório.")
    //     .min(10, "O telefone deve ter pelo menos 10 caracteres."),
    // email: Yup.string()
    //     .email("O email não é válido.").required("E-mail é obrigatório."),


});


const CreateContract = (props) => {

    const history = useHistory();
    const { state } = history.location;

    const [planName, setPlanName] = useState("")
    const [planId, setPlanId] = useState()
    const [optionsPlan, setOptionsPlan] = useState([]);
    const [optionsValue, setOptionsValue] = useState("")

    const [discountType, setDiscountType] = useState(DiscountType[0])
    const [saleType, setSaleType] = useState(SaleType[0])

    const [codEmp, setCodEmp] = useState(CodEmpresa[0]);

    const [dateDiscount, setDateDiscount] = useState(moment());
    const [license, setLicensed] = useState(0);

    const [sellerName, setSellerName] = useState("")
    const [sellerId, setSellerId] = useState()
    const [optionsSeller, setOptionsSeller] = useState([]);
    const [optionsSellerValue, setOptionsSellerValue] = useState("")

    let contractUpdate = null;
    if (state && state.create ===false) {
        contractUpdate = state;

    }
    let contractCreate = null;

    if(state && state.create ===true){
        contractCreate = state
    }

    // {
    //     "customer__id": 1,
    //     "number": "1",
    //     "crm_code": "1",
    //     "inception": "2021-09-03T00:00:00",
    //     "cancelled": null,
    //     "cod_emp": "D38",
    //     "service_ti": "1.50",
    //     "plan__id": 1,
    //     "exemption_days": 1,
    //     "discount_type": "Percentual",
    //     "discount_value": 100,
    //     "discount_inception": "2021-09-03",
    //     "quant": 1,
    //     "coment":"Venda",
    //     "licensed":0
    // }

    const dispatch = useDispatch();

    useEffect(() => {
        if (planName) {
            dispatch(getPlanByName(planName));
        }
        if (sellerName) {
            dispatch(getEmployeesByName(sellerName));
        }

    }, [dispatch, planName, sellerName]);



    const placement = "bottomRight"
    const customers = useSelector(state => state.customers);
    const plans = useSelector(state => state.plans);
    const contract = useSelector(state => state.contracts);
    console.log(contract)

    useEffect(() => {
        if (plans.plans) {
            let optionsPlano = [];
            plans.plans.data.map(obj => {
                return optionsPlano.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsPlan(optionsPlano);
        }
    }, [plans]);

    useEffect(() => {
        if (customers.customers && customers.customers.data) {
            let options = [];
            customers.customers.data.map(obj => {
                return options.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsSeller(options);
        }

        if (contract.insertContract && contract.insertContract.contract) {
            notification.success({
                message: contractUpdate ? "Contrato atualizado com sucesso!" : "Contrato criado com sucesso!",
                description:
                    '',
                duration: 5,
                placement
            });
            contract.insertContract.contract = null
            history.goBack();
        }
        if (contract.insertContract && contract.insertContract.message) {
            notification.error({
                message: contract.insertcontract.message,
                description: "Contrato não cadastrado!",
                duration: 5,
                placement
            });
            // history.push('/customers')
        }
    }, [customers,contract, history, contractUpdate]);

    return (
        <>
            <Card
                title={<h1 style={styles.title}>
                    <i
                        style={styles.icon}
                        className="fas fa-file-signature"
                    />
                    {state.create===false ? "Atualizar Contrato" : "Inserir Contrato"}
                </h1>}
                extra={<div>

                </div>}
            >
                <Card style={styles.container}>

                    <Formik
                        initialValues={{
                            customer__id: contractUpdate ? contractUpdate.customer__id : contractCreate.customer__id,
                            cod_emp: contractUpdate ? contractUpdate.cod_emp : codEmp,
                            service_ti: contractUpdate ? contractUpdate.service_ti : "",
                            crm_code: contractUpdate ? contractUpdate.crm_code : "",
                            plan__id: contractUpdate ? contractUpdate.plan__id : planId,
                            plan__name: contractUpdate ? contractUpdate.plan__name : planName,
                            exemption_days: contractUpdate ? contractUpdate.exemption_days : 0,
                            discount_type: contractUpdate ? contractUpdate.discount_type : discountType,
                            sale_type: contractUpdate ? contractUpdate.sale_type : saleType,
                            discount_value: contractUpdate ? contractUpdate.discount_value : 0,
                            discount_inception: contractUpdate ? contractUpdate.discount_inception : dateDiscount,
                            quant: contractUpdate ? contractUpdate.quant : 0,
                            requester__id: contractUpdate ? contractUpdate.requester__id : sellerId,
                            requester__name: contractUpdate ? contractUpdate.requester__name : sellerName,
                            licensed: contractUpdate ? contractUpdate.licensed : license,
                            inception: contractUpdate ? contractUpdate.inception : "",
                            item_inception: contractUpdate ? contractUpdate.item_inception : ""

                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            if (state && state.create ===false) {
                                values.order_id = contractUpdate.order_id
                                values.id_item = contractUpdate.id_item
                                console.log("paramentros ", contractUpdate.id, values)

                                dispatch(editContract(contractUpdate.id,values))

                            } else {
                                console.log(values)
                                dispatch(insertContracts(values))
                                //   if (!loading) {
                                //     resetForm();
                                //   }
                            }
                            // When button submits form and form is in the process of submitting, submit button is disabled
                            // setSubmitting(true);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid
                        }) => (
                            <>
                                <Divider><FileOutlined style={styles.iconDivider} /> Contrato</Divider>

                                <Form style={{ marginTop: 3 }} layout="vertical">
                                    <Row gutter={16}>
                                        <Col xs={8}>
                                            <Form.Item label="Código da Empresa: ">
                                                <Select value={values.cod_emp} onChange={e => {
                                                    setCodEmp(e)
                                                    values.cod_emp = e
                                                }}>
                                                    {CodEmpresa.map(cod => (
                                                        <Option key={cod}>{cod}</Option>
                                                    ))}
                                                </Select>

                                            </Form.Item>
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Item label="Número do CRM: *">
                                                <Input
                                                    type="text"
                                                    value={values.crm_code}
                                                    name="crm_code"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.name && errors.name ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="crm_code"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Item label="Serviço de TI: *">
                                                <Input
                                                    type="number"
                                                    value={values.service_ti}
                                                    name="service_ti"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.service_ti && errors.service_ti ? "error" : null}
                                                    maxLength={4}
                                                />
                                                <ErrorMessage
                                                    name="service_ti"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Divider><FileOutlined style={styles.iconDivider} /> Item do Contrato</Divider>
                                    <Row gutter={16}>
                                        <Col xs={12}>
                                            <Form.Item label="Plano: *">
                                                <AutoComplete
                                                    options={optionsPlan}
                                                    value={optionsValue || values.plan__name}
                                                    onChange={value => {
                                                        if (!value) {
                                                            setPlanId();
                                                            setPlanName();
                                                        }
                                                    }}
                                                    onSearch={value => {
                                                        setOptionsValue(value);
                                                        const timer = setTimeout(() => {
                                                            if (value.length >= 2) {
                                                                setPlanName(value);
                                                            }
                                                        }, 1000);
                                                        return () => clearTimeout(timer);
                                                    }}
                                                    onSelect={(value, option) => {
                                                        console.log(value)
                                                        values.plan__id = value
                                                        values.plan__name = option.label
                                                        setOptionsValue(option.label);
                                                        setPlanId(value);
                                                    }}
                                                    
                                                >
                                                    <Input.Search
                                                        size="middle"
                                                        placeholder="Pesquisar Planos..."
                                                        allowClear
                                                        loading={plans.loading}
                                                    />
                                                </AutoComplete>
                                                <ErrorMessage
                                                    name="plan__name"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Item label="Dia de Isenção: ">
                                                <Input
                                                    type="number"
                                                    value={values.exemption_days}
                                                    name="exemption_days"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.exemption_days && errors.exemption_days ? "error" : null}
                                                    maxLength={3}
                                                />
                                                <ErrorMessage
                                                    name="exemption_days"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item label="Tipo de Venda: ">
                                                <Select value={values.sale_type} onChange={e => {
                                                    setSaleType(e)
                                                    values.sale_type = e
                                                }}>
                                                    {SaleType.map(sale => (
                                                        <Option key={sale}>{sale}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col xs={8}>
                                            <Form.Item label="Tipo do Desconto: ">
                                                <Radio.Group onChange={e => {
                                                    console.log(e.target.value)
                                                    setDiscountType(e.target.value)
                                                    values.discount_type = e.target.value
                                                }} defaultValue={values.discount_type}>
                                                    {DiscountType.map(desc => (
                                                        <Radio.Button key={desc} value={desc} >{desc}</Radio.Button>
                                                    ))}
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Item label="Valor do Desconto: ">
                                                <Input
                                                    type="number"
                                                    value={values.discount_value}
                                                    name="discount_value"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.discount_value && errors.discount_value ? "error" : null}
                                                    maxLength={4}
                                                />
                                                <ErrorMessage
                                                    name="discount_value"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Item label="Data do Desconto: ">
                                                <DatePicker
                                                    locale={locale}
                                                    defaultValue={moment(values.discount_inception)}
                                                    value={moment(values.discount_inception)}
                                                    name="discount_inception"
                                                    style={{ width: '100%' }}
                                                    onChange={(date,dateString) => {
                                                        setDateDiscount(moment(date))
                                                        values.discount_inception = moment(dateString)
                                                    }}
                                                    
                                                />
                                                {/* <ErrorMessage
                                                    name="discount_inception"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                /> */}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider><FileOutlined style={styles.iconDivider} /> Ordem de Serviço</Divider>
                                    <Row gutter={16}>
                                        <Col xs={12}>
                                            <Form.Item label="Gerente Comercial: ">
                                                <AutoComplete
                                                    options={optionsSeller}
                                                    value={optionsSellerValue || values.requester__name}
                                                    onChange={value => {
                                                        if (!value) {
                                                            setSellerId();
                                                            setSellerName();
                                                        }
                                                    }}
                                                    onSearch={value => {
                                                        setOptionsSellerValue(value);
                                                        const timer = setTimeout(() => {
                                                            if (value.length >= 2) {
                                                                setSellerName(value);
                                                            }
                                                        }, 1000);
                                                        return () => clearTimeout(timer);
                                                    }}
                                                    onSelect={(value, option) => {
                                                        console.log(value)
                                                        values.requester__id = value
                                                        values.requester__name = option.label
                                                        setOptionsSellerValue(option.label);
                                                        // setSellerId(value);
                                                    }}
                                                    
                                                >
                                                    <Input.Search
                                                        
                                                        size="middle"
                                                        placeholder="Pesquisar Gerente..."
                                                        allowClear
                                                        loading={customers.loading}
                                                    />
                                                </AutoComplete>
                                                <ErrorMessage
                                                    name="requester__name"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Item label="Quantidade de linhas: ">
                                                <Input
                                                    type="number"
                                                    value={values.quant}
                                                    name="quant"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.quant && errors.quant ? "error" : null}
                                                    maxLength={4}
                                                />
                                                <ErrorMessage
                                                    name="quant"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Item label="Licença: ">
                                                <Radio.Group onChange={e => {
                                                    console.log(e.target.value)
                                                    setLicensed(e.target.value)
                                                    values.licensed = e.target.value
                                                }} defaultValue={values.licensed}>
                                                    <Radio.Button value={0}>Não</Radio.Button>
                                                    <Radio.Button value={1}>Sim</Radio.Button>
                                                </Radio.Group>

                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                                <Row >
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Button
                                            type="primary"
                                            ghost
                                            style={{ marginLeft: 8 }}
                                            onClick={() => handleSubmit()}
                                        >
                                            SALVAR
                                        </Button>
                                        <Button
                                            type="danger"
                                            ghost
                                            style={{ marginLeft: 8 }}
                                            // onClick={() => history.push(`view-customer-operation/${values.customer__id}`)}
                                            onClick={() => history.goBack()}

                                        >
                                            CANCELAR
                                        </Button>
                                    </Col>
                                </Row>
                            </>)}

                    </Formik>

                </Card>
            </Card>
            {/* </Animate> */}
        </>
    )

}

const styles = {
    container: {
        borderRadius: "10px",
        background: "#fff",
        boxShadow:
            "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },

    icon: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 17,
        marginRight: 10,
        fontSize: 22,
        color: "#ffffff"
    },
    iconDivider: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 8,
        fontSize: 18,
        color: "#ffffff"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    title: {
        fontWeight: 900,
        // marginLeft: 20
    },
};

export default CreateContract;


import { AutoComplete, Card, Col, Form, Input, Row, Divider, Button, Radio, Select, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import cep from 'cep-promise'
import { Segment, AccountType, Days, typeNames, Branches } from './constantsSelect';
import { BankOutlined, ContactsOutlined, DollarCircleOutlined, PercentageOutlined } from "@ant-design/icons";
import { editCustomer, getEmployeesByName, insertCustomer } from '../../../../store/actions/customerAction';
import MaskedInput from 'antd-mask-input'

const { Option } = Select;
// Schema for yup
const validationSchema = Yup.object().shape({
    //DADOS EMPRESARIAIS
    name: Yup.string()
        .required("Nome é obrigatório."),
    cnpj: Yup.string()
        .required("O CNPJ é obrigatório.")
        .min(14, "O CNPJ deve ter pelo menos 18 caracteres."),
    nome_fantasia: Yup.string()
        .required("O nome fantasia é obrigatório.")
        .min(10, "O nome fantasia deve ter pelo menos 10 caracteres."),
    //Contato
    phone: Yup.string()
        .required("O telefone é obrigatório.")
        .min(10, "O telefone deve ter pelo menos 10 caracteres."),
    email: Yup.string()
        .email("O email não é válido.").required("E-mail é obrigatório."),
    //Endereço Cobrança
    postalcode: Yup.string()
        .required("O CEP é obrigatório.").min(8, "Cep deve ter 8 números."),
    street: Yup.string()
        .required("O endereço é obrigatório.").min(5, "endereço deve ter no mínimo 5 caracteres."),
    country: Yup.string()
        .required("O País é obrigatório.").min(3, "País deve ter 3 no mínimo 3 caracteres."),
    district: Yup.string()
        .required("O Bairro é obrigatório.").min(3, "Bairro deve ter 3 no mínimo 3 caracteres."),
    state: Yup.string()
        .required("O Estado é obrigatório.").min(2, "Estado deve ter no mínimo 2 caracteres."),
    seller__id: Yup.string()
        .required("O gerente é obrigatório."),


});


const CreateCustomer = () => {


    const history = useHistory();
    const { state } = history.location;
    const [postalcode, setPostalcode] = useState("");
    const [tipPessoa, setTipPessoa] = useState("Jurídica")
    const [status, setStatus] = useState("Ativo")
    //Gerente comercial/employees no WS
    const [sellerName, setSellerName] = useState("")
    const [sellerId, setSellerId] = useState()
    const [optionsSeller, setOptionsSeller] = useState([]);
    const [optionsValue, setOptionsValue] = useState("")

    const [branch, setBranch] = useState(Branches[0].name)
    const [branchId,] = useState(Branches[0].key)


    const [industry, setIndustry] = useState(Segment[0]);
    const [dueDay, setDueDay] = useState(Days[3]);
    const [typeAccount, setTypeAccount] = useState(AccountType[0]);

    const [typeName, setTypeName] = useState(typeNames[0].name);
    const [typeNameId,] = useState(typeNames[0].key);
    
    let listStreetNumber = null
    let streetUpdate = null
    let numberUpdate = null
    let customerUpdate = null;
    if (state) {
        customerUpdate = state;
        listStreetNumber = customerUpdate.billing_address_street.split(', ')
        streetUpdate = listStreetNumber[0] //get street
        numberUpdate = listStreetNumber[1] //get number
    }

    const dispatch = useDispatch();


    useEffect(() => {
        
        if (sellerName) {
            dispatch(getEmployeesByName(sellerName));
        }
    }, [dispatch, sellerName]);
    const placement = "bottomRight"
    const customers = useSelector(state => state.customers);



    useEffect(() => {
        if (customers.customers && customers.customers.data) {
            let options = [];
            customers.customers.data.map(obj => {
                return options.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsSeller(options);
        }
        if (customers.insertCustomers && customers.insertCustomers.data) {
            notification.success({
                message: customerUpdate?"Cliente atualizado com sucesso!":"Cliente criado com sucesso!",
                description:
                    '',
                duration: 5,
                placement
            });
            history.push('/customers')
        }
        if (customers.insertCustomers && customers.insertCustomers.message) {
            notification.error({
                message: customers.insertCustomers.message,
                description: "Cliente não cadastrado!",
                duration: 5,
                placement
            });
            // history.push('/customers')
        }
    }, [customers, history,customerUpdate]);

    return (
        <>
            <Card
                title={<h1 style={styles.title}>
                    <i
                        style={styles.icon}
                        className="fas fa-user-plus"
                    />
                    {state? "Atualizar Cliente":"Inserir Cliente"}
                </h1>}
                extra={<div>

                </div>}
            >
                <Card style={styles.container}>

                    <Formik
                        initialValues={{
                            name:customerUpdate ? customerUpdate.name : "",
                            cnpj:customerUpdate ? customerUpdate.cpfcnpj_c : "",
                            nome_fantasia:customerUpdate ? customerUpdate.nome_fantasia_c : "",
                            tipo_pessoa:customerUpdate ? customerUpdate.tipopessoa_c : tipPessoa,
                            industry:customerUpdate ? customerUpdate.industry : industry,
                            account_type:customerUpdate ? customerUpdate.account_type : typeAccount,
                            status:customerUpdate ? customerUpdate.status_c : status,
                            //Contatos
                            email:customerUpdate ? customerUpdate.email : "",
                            email_support:customerUpdate ? customerUpdate.email_support : "",
                            phone:customerUpdate ? customerUpdate.phone_office : "",
                            phone_alternate:customerUpdate ? customerUpdate.phone_alternate : "",
                            //Endereço Cobrança
                            postalcode:customerUpdate ? customerUpdate.billing_address_postalcode : postalcode,
                            street:customerUpdate ? streetUpdate: "",
                            district:customerUpdate ? customerUpdate.billing_address_district : "",
                            state:customerUpdate ? customerUpdate.billing_address_state : "",
                            country:customerUpdate ? customerUpdate.billing_address_country : "Brasil",
                            number:customerUpdate ? numberUpdate: "",
                            city:customerUpdate ? customerUpdate.billing_address_city : "",
                            //dados Telecom
                            due_day:customerUpdate ? customerUpdate.due_day : dueDay,
                            seller__id:customerUpdate ? customerUpdate.seller__id : sellerId,
                            branch__id:customerUpdate ? customerUpdate.branch__id : branchId,
                            type__id:customerUpdate ? customerUpdate.type__id : typeNameId,
                            seller__name:customerUpdate ? customerUpdate.seller__name : sellerName,
                            branch__name:customerUpdate ? customerUpdate.branch__name : branch,
                            type__name:customerUpdate ? customerUpdate.type__name : typeName,
                            notes:customerUpdate ? customerUpdate.notes : "helios"

                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            console.log("values: ", values);
                            if (state) {
                                console.log("paramentros ",customerUpdate.id,values)
                                dispatch(editCustomer(customerUpdate.id,values))
                            } else {
                                console.log(values)
                                dispatch(insertCustomer(values))
                                //   if (!loading) {
                                //     resetForm();
                                //   }
                            }
                            // When button submits form and form is in the process of submitting, submit button is disabled
                            // setSubmitting(true);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid
                        }) => (
                            <>
                                <Divider><BankOutlined style={styles.iconDivider} /> Dados Empresariais</Divider>

                                <Form style={{ marginTop: 3 }} layout="vertical">
                                    <Row gutter={16}>
                                        <Col xs={4}>
                                            <Form.Item label="Status: ">
                                                <Radio.Group onChange={e => {
                                                    console.log(e.target.value)
                                                    setStatus(e.target.value)
                                                    values.status = e.target.value
                                                }} defaultValue={values.status}>
                                                    <Radio.Button value="active">Ativo</Radio.Button>
                                                    <Radio.Button value="deactive">Inativo</Radio.Button>
                                                </Radio.Group>

                                            </Form.Item>
                                        </Col>
                                        <Col xs={20}>
                                            <Form.Item label="Nome da Empresa: *">
                                                <Input
                                                    type="text"
                                                    value={values.name}
                                                    name="name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.name && errors.name ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="name"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={4}>
                                            <Form.Item label="Tipo de Pessoa: ">
                                                <Radio.Group onChange={e => {
                                                    console.log(e.target.value)
                                                    setTipPessoa(e.target.value)
                                                    values.tipo_pessoa = e.target.value
                                                }} defaultValue={values.tipo_pessoa}>
                                                    <Radio.Button value="JURIDICA">Jurídica</Radio.Button>
                                                    <Radio.Button value="FISICA">Física</Radio.Button>
                                                </Radio.Group>

                                            </Form.Item>
                                        </Col>
                                        <Col xs={14}>
                                            <Form.Item label="CNPJ: *">
                                                <MaskedInput
                                                    mask="11.111.111/1111-11"
                                                    type="text"
                                                    placeholder="11.111.111/1111-11"
                                                    value={values.cnpj}
                                                    name="cnpj"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.cnpj && errors.cnpj ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="cnpj"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Item label="Tipo de Indústria: ">
                                                <Select value={values.industry} onChange={e => {
                                                    setIndustry(e)
                                                    values.industry = e
                                                }}>
                                                    {Segment.map(segment => (
                                                        <Option key={segment}>{segment}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={18}>
                                            <Form.Item label="Nome Fantasia: *">
                                                <Input
                                                    type="text"
                                                    value={values.nome_fantasia}
                                                    name="nome_fantasia"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.nome_fantasia && errors.nome_fantasia ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="nome_fantasia"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Item label="Tipo de Conta: ">
                                                <Select value={values.account_type} onChange={e => {
                                                    setTypeAccount(e)
                                                    values.account_type = e
                                                }}>
                                                    {AccountType.map(typeAccount => (
                                                        <Option key={typeAccount}>{typeAccount}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Divider><ContactsOutlined style={styles.iconDivider} /> Contato</Divider>
                                    <Row gutter={16}>
                                        <Col xs={12}>
                                            <Form.Item label="Email: *">
                                                <Input
                                                    type="email"
                                                    value={values.email}
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.email && errors.email ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Item label="Email de Suporte: ">
                                                <Input
                                                    type="email_support"
                                                    value={values.email_support}
                                                    name="email_support"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.email_support && errors.email_support ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="email_support"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={12}>
                                            <Form.Item label="Telefone: *">
                                                <MaskedInput
                                                    mask="(11)1111-1111"
                                                    placeholder="(83)9999-9999"
                                                    type="text"
                                                    value={values.phone}
                                                    name="phone"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.phone && errors.phone ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="phone"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Item label="Telefone Alternativo: ">
                                                <MaskedInput
                                                    mask="(11)1111-1111"
                                                    placeholder="(83)9999-9999"
                                                    type="text"
                                                    value={values.phone_alternate}
                                                    name="phone_alternate"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.phone_alternate && errors.phone_alternate ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="phone_alternate"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider><DollarCircleOutlined style={styles.iconDivider} /> Endereço de Cobrança</Divider>
                                    <Row gutter={16}>
                                        <Col xs={10}>
                                            <Form.Item label="CEP: *">
                                                <MaskedInput
                                                    mask="11111-111"
                                                    placeholder="01234-123"
                                                    type="postalcode"
                                                    name="postalcode"
                                                    allowClear
                                                    onChange={e => {
                                                        setPostalcode(e.target.value);
                                                        if (e.target.value.length === 8 || e.target.value.length === 9) {
                                                            cep(e.target.value, { timeout: 5000 }).then(result => {
                                                                values.state = result.state
                                                                values.street = result.street
                                                                values.district = result.neighborhood
                                                                values.city = result.city
                                                            }
                                                            )
                                                        } else if (e.target.value.length === 0) {
                                                            values.state = ""
                                                            values.street = ""
                                                            values.district = ""
                                                            values.city = ""
                                                        }

                                                        values.postalcode = e.target.value;
                                                    }}
                                                    value={values.postalcode}

                                                    onBlur={handleBlur}
                                                    className={touched.postalcode && errors.postalcode ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="postalcode"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={10}>
                                            <Form.Item label="País: *">
                                                <Input
                                                    type="text"
                                                    value={values.country}
                                                    name="country"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.country && errors.country ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="country"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Item label="Estado: *">
                                                <Input
                                                    type="text"
                                                    value={values.state}
                                                    name="state"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.state && errors.state ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="state"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={20}>
                                            <Form.Item label="Endereço: *">
                                                <Input
                                                    type="text"
                                                    value={values.street}
                                                    name="street"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.street && errors.street ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="street"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Item label="Número: ">
                                                <Input
                                                    type="text"
                                                    value={values.number}
                                                    name="number"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.number && errors.number ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="number"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={12}>
                                            <Form.Item label="Bairro: *">
                                                <Input
                                                    type="text"
                                                    value={values.district}
                                                    name="district"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.district && errors.district ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="district"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Item label="Cidade: *">
                                                <Input
                                                    type="text"
                                                    value={values.city}
                                                    name="city"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={touched.city && errors.city ? "error" : null}
                                                />
                                                <ErrorMessage
                                                    name="city"
                                                    component="div"
                                                    style={{ color: "red" }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Divider><PercentageOutlined style={styles.iconDivider} /> Dados de Venda</Divider>
                                    <Row gutter={16}>
                                        <Col xs={12}>
                                            <Form.Item label="Filial de Venda: ">
                                                <Select value={values.branch__name} onChange={(key,value) => {
                                                    console.log(value)
                                                    setBranch(value.children)
                                                    values.branch__id = value.key
                                                    values.branch__name = value.children

                                                }}>
                                                    {Branches.map(branch => (
                                                        <Option key={branch.key}>{branch.name}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Item label="Gerente Comercial: ">
                                                <AutoComplete
                                                    options={optionsSeller}
                                                    value={optionsValue || values.seller__name}
                                                    onChange={value => {
                                                        if (!value) {
                                                            setSellerId();
                                                            setSellerName();
                                                        }
                                                    }}
                                                    onSearch={value => {
                                                        setOptionsValue(value);
                                                        const timer = setTimeout(() => {
                                                            if (value.length >= 2) {
                                                                setSellerName(value);
                                                            }
                                                        }, 1000);
                                                        return () => clearTimeout(timer);
                                                    }}
                                                    onSelect={(value, option) => {
                                                        console.log(value)
                                                        values.seller__id = value
                                                        values.seller__name = option.label
                                                        setOptionsValue(option.label);
                                                        // setSellerId(value);
                                                    }}
                                                >
                                                    <Input.Search
                                                        size="middle"
                                                        placeholder="Pesquisar Gerente..."
                                                        allowClear
                                                        loading={customers.loading}
                                                    />
                                                </AutoComplete>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>

                                        <Col xs={12}>
                                            <Form.Item label="Tipo de nome: ">
                                                <Select value={values.type__name}  
                                                 onChange={(key, value) => {
                                                    console.log(value)
                                                    setTypeName(value.children)
                                                    values.type__name = value.children
                                                    values.type__id = value.key
                                                }}>
                                                    {typeNames.map(type => (
                                                        <Option key={type.key}>{type.name}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Item label="Data de Vencimento: ">
                                                <Select value={values.due_day} onChange={e => {
                                                    setDueDay(e)
                                                    values.due_day = e
                                                }}>
                                                    {Days.map(day => (
                                                        <Option key={day}>{day}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item label="Descrição: ">
                                        <Input.TextArea
                                            type="text"
                                            value={values.notes}
                                            name="notes"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={touched.notes && errors.notes ? "error" : null}
                                        />
                                    </Form.Item>
                                </Form>
                                <Row >
                                    <Col span={24} style={{ textAlign: "right" }}>
                                        <Button
                                            type="primary"
                                            ghost
                                            style={{ marginLeft: 8 }}
                                            onClick={() => handleSubmit()}
                                        >
                                            SALVAR
                                        </Button>
                                        <Button
                                            type="danger"
                                            ghost
                                            style={{ marginLeft: 8 }}
                                            onClick={() => history.push("/customers")}
                                        >
                                            CANCELAR
                                        </Button>
                                    </Col>
                                </Row>
                            </>)}

                    </Formik>

                </Card>
            </Card>
            {/* </Animate> */}
        </>
    )

}

const styles = {
    container: {
        borderRadius: "10px",
        background: "#fff",
        boxShadow:
            "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },

    icon: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 17,
        marginRight: 10,
        fontSize: 22,
        color: "#ffffff"
    },
    iconDivider: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 8,
        fontSize: 18,
        color: "#ffffff"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    title: {
        fontWeight: 900,
        // marginLeft: 20
    },
};

export default CreateCustomer;

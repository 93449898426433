import React from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "antd";

const Docs = props => {
    const history = useHistory();
    return (

        <Tooltip placement="bottom" title="Documentação">
            <i
                style={styles.subTitle}
                className="fas fa-file-code"
                onClick={() => {
                    history.push("/docs");
                }}
            />
        </Tooltip>

    );
}
const styles = {
    subTitle: {
        marginRight: 10,
        marginBottom: -10,
        fontSize: 20,
        cursor: "pointer"
    },
};

export default Docs;

import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";


export const getLinesConsumption = (
  customerId,
  iccid,
  msisdn,
  date,
  page,
  size
) => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_CONSUMPTION_START));
  axios
    .get(
      API_ROOT +
      `lines/consumption?customerId=${customerId}&iccid=${iccid}&msisdn=${msisdn}&date=${date}&page=${page}&size=${size}`
    )
    .then(res => {
      dispatch(fetchSuccess(actionsType.GET_LINES_CONSUMPTION_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_CONSUMPTION_FAIL, error));
    });
};

export const getConsumptionLinesSummarized = (
  customerId,
  dateCustomer,
  dateTotal
) => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_CONSUMPTION_START));
  axios
    .get(
      API_ROOT +
      `lines/consumption/summarized?customerId=${customerId}&dateCustomer=${dateCustomer}&dateTotal=${dateTotal}`
    )
    .then(res => {
      let customer = "OPERAÇÃO";
      let upload = 0;
      let dowload = 0;
      let total = 0;
      let prefixUP = "";
      let prefixDW = "";
      let prefixTotal = "";

      if (res.data.length === 0) {
        upload = 0;
        dowload = 0;
        total = 0;
        prefixUP = "B";
        prefixDW = "B";
        prefixTotal = "B";

      }

      if (res.data.length > 0) {
        customer = res.data[0].customer__name;
        upload = res.data[0].total_upload_f.replace(/(KB|MB|GB)/gi, "");
        dowload = res.data[0].total_download_f.replace(/(KB|MB|GB)/gi, "");
        total = res.data[0].total_month_f.replace(/(KB|MB|GB)/gi, "");
        prefixUP = res.data[0].total_upload_f.match(/(KB|MB|GB)/gi)[0];
        prefixDW = res.data[0].total_download_f.match(/(KB|MB|GB)/gi)[0];
        prefixTotal = res.data[0].total_month_f.match(/(KB|MB|GB)/gi)[0];
      } else if (res.data.length !== 0) {
        upload = res.data.total_upload_f.replace(/(KB|MB|GB)/gi, "");
        dowload = res.data.total_download_f.replace(/(KB|MB|GB)/gi, "");
        total = res.data.total_month_f.replace(/(KB|MB|GB)/gi, "");
        prefixUP = res.data.total_upload_f.match(/(KB|MB|GB)/gi)[0];
        prefixDW = res.data.total_download_f.match(/(KB|MB|GB)/gi)[0];
        prefixTotal = res.data.total_month_f.match(/(KB|MB|GB)/gi)[0];
      }

      let consumption = {
        customer: customer,
        upload: upload,
        dowload: dowload,
        total: total,
        prefixUP: prefixUP,
        prefixDW: prefixDW,
        prefixTotal: prefixTotal
      };
      dispatch(fetchSuccess(actionsType.GET_CONSUMPTION_LINES_SUMMARIZED_SUCCESS, consumption));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_CONSUMPTION_FAIL, error));
    });
};
export const getConsumptionLinesSummarizedNLT = () => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_CONSUMPTION_START_NLT));
  axios
    .get(
      API_ROOT +
      `lines/consumption/summarized/nlt`)
    .then(res => {
      let customer = "OPERAÇÃO";
      let upload = 0;
      let dowload = 0;
      let total = 0;
      let prefixUP = "";
      let prefixDW = "";
      let prefixTotal = "";

      if (res.data.length === 0) {
        upload = 0;
        dowload = 0;
        total = 0;
        prefixUP = "B";
        prefixDW = "B";
        prefixTotal = "B";

      }

      if (res.data.length > 0) {
        customer = res.data[0].customer__name;
        upload = res.data[0].total_upload_f.replace(/(KB|MB|GB)/gi, "");
        dowload = res.data[0].total_download_f.replace(/(KB|MB|GB)/gi, "");
        total = res.data[0].total_month_f.replace(/(KB|MB|GB)/gi, "");
        prefixUP = res.data[0].total_upload_f.match(/(KB|MB|GB)/gi)[0];
        prefixDW = res.data[0].total_download_f.match(/(KB|MB|GB)/gi)[0];
        prefixTotal = res.data[0].total_month_f.match(/(KB|MB|GB)/gi)[0];
      } else if (res.data.length !== 0) {
        upload = res.data.total_upload_f.replace(/(KB|MB|GB)/gi, "");
        dowload = res.data.total_download_f.replace(/(KB|MB|GB)/gi, "");
        total = res.data.total_month_f.replace(/(KB|MB|GB)/gi, "");
        prefixUP = res.data.total_upload_f.match(/(KB|MB|GB)/gi)[0];
        prefixDW = res.data.total_download_f.match(/(KB|MB|GB)/gi)[0];
        prefixTotal = res.data.total_month_f.match(/(KB|MB|GB)/gi)[0];
      }

      let consumption = {
        customer: customer,
        upload: upload,
        dowload: dowload,
        total: total,
        prefixUP: prefixUP,
        prefixDW: prefixDW,
        prefixTotal: prefixTotal
      };
      dispatch(fetchSuccess(actionsType.GET_CONSUMPTION_LINES_SUMMARIZED_SUCCESS_NLT, consumption));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_CONSUMPTION_FAIL_NLT, error));
    });
};
import { Menu } from "antd";
import { getLocale, setLocale } from "umi-plugin-react/locale";
import React from "react";
import HeaderDropdown from "../HeaderDropdown";

const SelectLang = props => {
  const selectedLang = getLocale();
  const changeLang = ({ key }) => setLocale(key);

  let lang = "pt-BR"
  if (localStorage.umi_locale) {
    lang = localStorage.getItem("umi_locale")
  }
  const locales = ["pt-BR", "en-US"];
  const languageLabels = {
    "pt-BR": "Português",
    //"en-US": "English"
  };
  const languageIcons = {
    "pt-BR": "🇧🇷",
    //"en-US": "🇺🇸"
  };
  const langMenu = (
    <Menu
      className="menuLang"
      selectedKeys={[selectedLang]}
      onClick={changeLang}
    >
      {locales.map(locale => (
        <Menu.Item key={locale}>
          <span role="img" aria-label={languageLabels[locale]}>
            {languageIcons[locale]}
          </span>{" "}
          {languageLabels[locale]}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <HeaderDropdown overlay={langMenu} placement="bottomRight">
      <span className="dropDown"
        style={{ marginRight: 5, marginLeft: 5, padding: "0 23px 0 16px", cursor: "pointer", fontSize: 18 }}
      >
        <span role="img" aria-label={languageLabels[lang]}>
          {languageIcons[lang]}
        </span>{" "}
        {/* <Icon 
          type="global"
          title={formatMessage({
            id: "navBar.lang"
          })}
        /> */}
      </span>
    </HeaderDropdown>
  );
};

export default SelectLang;

import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
import { notification, Modal, Tag } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import HeliosTableAtr from "../../components/HeliosTable/HeliosTableAtr";

const { info } = Modal;
const placement = "bottomRight"

const columns = [{
    title: "MSISDN",
    dataIndex: "msisdn",
    width: 140,
    ellipsis: true,
},
{
    title: "Resultado",
    dataIndex: "description",
    width: 120,
    ellipsis: true,
},
{
    title: "Conexão",
    dataIndex: "status-line",
    width: 200,
    ellipsis: true,
    render: (text, record) => (
        <Tag color={record.status_line === "WITH CONNECTION GSM" ? "green" : record.status_line === "HAD CONNECTION GSM" ? "yellow" : "red"}>
            {record.status_line === "WITH CONNECTION GSM" ? "Com conexão GSM" : record.status_line === "HAD CONNECTION GSM" ? "Teve conexão GSM" : "Sem registro de conexão GSM"}
        </Tag>
    )
},
{
    title: "SMS",
    dataIndex: "sms",
    width: 100,
    ellipsis: true,
    render: (text, record) => (
        <Tag color={record.sms === true ? "green" : "red"}>
            {record.sms === true ? "Ativo" : "Inativo"}
        </Tag>
    )
},
{
    title: "APN's",
    dataIndex: "apns",
    render: (text) =>
        text?(
        text.map((apn, i) => {
            return (<Tag key={i} style={{ marginBottom: 5 }} color={"gray"}>
                {apn}
            </Tag>)
        })):null
},


]

export const getStatusHlr = object => dispatch => {
    dispatch(fetchStart(actionType.GET_STATUS_HLR_START));
    notification.open({
        key: 'loadingStatusHlr',
        message: 'Efetuando operação de consulta HLR da linha',
        description:
            'Em instantes você receberá a resposta do pedido ' + object.msisdn,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/query", { "msisdn": object.msisdn })
        .then(res => {
            console.log(res.data)
            notification.close('loadingStatusHlr')
            if (res.data) {
                var response = res.data.response[0];
                response = JSON.parse(JSON.stringify(response).split('"status-line":').join('"status_line":'));
                response = [response]
                info({
                    width: 800,
                    title: "",
                    content: <HeliosTableAtr
                        title="Resultado da linha"
                        columns={columns}
                        dataSource={response || []}
                        rowKey="msisdn"
                    />,
                    okText: "Fechar",
                    onOk() {
                    }
                })
            } else {
                notification.error({
                    message: 'Erro no pedido!',
                    description:
                        'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                    duration: 60,
                    placement
                });
            }
            dispatch(fetchSuccess(actionType.GET_STATUS_HLR_SUCCESS, res.data));
        })
        .catch(err => {
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.GET_STATUS_HLR_FAIL, err));
        });
};
export const getManyStatusHlr = file => dispatch => {
    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.GET_STATUS_HLR_START));
    notification.open({
        key: 'loadingManyStatusHlr',
        message: 'Efetuando operação de consulta HLR das linhas',
        description:
            'Em instantes você receberá a resposta do pedido das linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/many-query", formData, config)
        .then(res => {
            console.log(res.data)
            var response = res.data.response[0];
            response = JSON.parse(JSON.stringify(response).split('"status-line":').join('"status_line":'));
            response = [response]
            notification.close('loadingManyStatusHlr')
            console.log(response)
            if (res.data.length !== 0) {
                info({
                    width: 800,
                    title: "",
                    content: <HeliosTableAtr
                        title="Resultado das linhas"
                        columns={columns}
                        dataSource={response[0] || []}
                        rowKey="msisdn"
                    />,
                    okText: "Fechar",
                    onOk() {
                    }
                })
            }
            dispatch(fetchSuccess(actionType.GET_STATUS_HLR_SUCCESS, res.data));
        })
        .catch(err => {
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.GET_STATUS_HLR_FAIL, err));
        });
};


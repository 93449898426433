export const Segment = ["M-Payments",
    "POS",
    "Seguradora",
    "Automação Residencial",
    "Instalador/Integrador de Soluções",
    "Tecnologia da Informação",
    "Segurança Patrimonial",
    "Comércio Atacadista",
    "Comércio Varejo",
    "M-Health",
    "Telecomunicações",
    "Serviços Especializados",
    "Outras atividades de telecomunicações não específicas anteriormente",
    "Serviços de instalação, manutenção e reparação de acessórios para veículos automotores",
    "Atividades de monitoramento de sistemas de segurança eletrônica",
    "Outras atividades de serviços de segurança"
]

export const AccountType = ["PME", "LA", "Canal indireto",]

export const Days = [1, 5, 8, 10, 15]

export const typeNames = [
    { key: 1, name: "Default" },
    { key: 2, name: "Excedente gratuito" },
    { key: 3, name: "Sem bloqueio por consumo" },
    { key: 4, name: "Irrestrito" }
]

export const Branches = [
    { key: 1, name: "Filial João Pessoa" },
    { key: 2, name: "Filial Barueri" }
]
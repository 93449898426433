import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportConsumption, getGenerateReportConsumption } from "../../../../store/actions/reportConsumptionAction";
import { getCustomerByName } from "../../../../store/actions/customerAction";
import { queryReportConsumptions } from "./Query"
import SkeletonTable from "../../../../components/Skeleton/SkeletonTable"
import HeliosTable from "../../../../components/HeliosTable/HeliosTable";

import {
    Tooltip,
    Drawer,
    Form,
    Input,
    Button,
    AutoComplete,
    DatePicker,
} from "antd";
import { columns } from "./columnsTable";
import moment from "moment";
let data = moment().subtract(1, 'M').format("MM/YYYY");


const ReportConsumptions = props => {


    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [customerId, setCustomerId] = useState();
    const [isShared,] = useState(null);
    const [dateConsumption, setDateConsumption] = useState(data);

    const [customerName, setCustomerName] = useState();
    const [optionsCustomer, setOptionsCustomer] = useState([]);
    const [optionsValue, setOptionsValue] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const dispatch = useDispatch();

    // const value = useState((state) => {
    //     if (this.state[state] !== undefined) {
    //         if (this.state[state] !== "") {
    //             return this.state[state];
    //         }
    //     }
    //     return "";
    // });


    useEffect(() => {

        dispatch(
            getReportConsumption(queryReportConsumptions(
                "",
                page,
                pageSize,
                customerId !== undefined
                    ? customerId
                    : "",
                dateConsumption,
                isShared
            ))
        );

    }, [customerId, dispatch, page, pageSize, dateConsumption, isShared]);

    useEffect(() => {
        if (customerName) {
            dispatch(getCustomerByName(customerName));
        }
    }, [dispatch, customerName]);

    const customers = useSelector(state => state.customers);

    useEffect(() => {
        if (customers.customers) {
            let options = [];
            customers.customers.data.map(obj => {
                return options.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsCustomer(options);
        }
    }, [customers]);

    const { reportConsumption, loading } = useSelector(
        state => state.reportConsumption
    );


    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(0);
    };

    const onPaginationChange = (page, pageSize) => {
        setPage(page - 1);
    };

    const onClearFilter = () => {
        setCustomerId();
        setCustomerName();
        setOptionsValue("");
        setDateConsumption(data);

    };

    const onCloseFilter = () => {
        setShowFilter(false);
    };

    const onShowFilter = () => {
        setShowFilter(true);
    };

    const generateReport = () => {
        dispatch(
            getGenerateReportConsumption(queryReportConsumptions(
                "xlsx",
                page,
                pageSize,
                customerId !== undefined
                    ? customerId
                    : "",
                dateConsumption,
                isShared
            ))
        );
    }

    const subTitle = (
        <div>
            <Tooltip placement="bottom" title="Download">
                <i
                    style={styles.subTitle}
                    className="fas fa-download"
                    onClick={() => {
                        generateReport()
                    }}
                />
            </Tooltip>
            <Tooltip placement="bottom" title="Filtros">
                <i
                    style={styles.subTitle}
                    className="fas fa-filter"
                    onClick={() => {
                        onShowFilter();
                    }}
                />
            </Tooltip>
        </div>
    );


    return (
        <div>
            <SkeletonTable show={loading} title="Relatório de Consumo Mensal" icon="fas fa-chart-line" pageSize={pageSize}/>

            {reportConsumption && !loading?(
                <HeliosTable
                    title="Relatório de Consumo Mensal"
                    subTitle={subTitle}
                    icon="fas fa-chart-line"
                    columns={columns}
                    dataSource={reportConsumption.consumptions || []}
                    rowKey="lineMsisdn"
                    total={reportConsumption.total}
                    pageSize={pageSize}
                    page={page}
                    onChange={onPaginationChange}
                    onShowSizeChange={onShowSizeChange}
                />
            ):""}
            <Drawer
                title="Filtros"
                headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
                placement="right"
                onClose={onCloseFilter}
                visible={showFilter}
                width={500}
                style={styles.containerDrawer}
            >
                <Form layout="vertical">
                    <Form.Item label="CLIENTE">
                        <AutoComplete
                            options={optionsCustomer}
                            value={optionsValue}
                            onChange={value => {
                                if (!value) {
                                    setCustomerId();
                                    setCustomerName();
                                }
                            }}
                            onSearch={value => {
                                setOptionsValue(value);
                                const timer = setTimeout(() => {
                                    if (value.length >= 2) {
                                        setCustomerName(value);
                                    }
                                }, 1000);
                                return () => clearTimeout(timer);
                            }}
                            onSelect={(value, option) => {
                                setOptionsValue(option.label);
                                setCustomerId(value);
                            }}
                        >
                            <Input.Search
                                size="middle"
                                placeholder="Pesquisar Cliente..."
                                allowClear
                                loading={customers.loading}
                            />
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item label="MÊS DO CONSUMO">
                        <DatePicker
                            style={{ width: "100%" }}
                            name="date"
                            selected={dateConsumption}
                            onChange={(date) => {
                                setDateConsumption(date.format("MM/YYYY"));
                            }}
                            disabledDate={current => {
                                return current && current > moment();
                            }}
                            picker="month"
                            format={"MM/YYYY"}
                        />
                    </Form.Item>
                </Form>
                <div style={styles.btnFilter}>
                    <Button
                        onClick={onClearFilter}
                        type="danger"
                        ghost
                        style={styles.btnCancel}
                    >
                        LIMPAR
          </Button>
                    <Button onClick={onCloseFilter} type="default">
                        FECHAR
          </Button>
                </div>
            </Drawer>
        </div>
    );
};

const styles = {
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
    containerDrawer: {
        //width: 30
        //backgroundColor: "transparent"
    },
    btnFilter: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
    },
    btnCancel: {
        marginRight: 8
    },
    online: {
        color: "green"
    }
};

export default ReportConsumptions;

import React from "react";

import { LoadingOutlined } from "@ant-design/icons";

const Loading = props => {
  const { heightContainer, sizeSpinner } = props;
  return (
    <div
      style={{
        height: heightContainer,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <LoadingOutlined style={{ fontSize: sizeSpinner }} />
    </div>
  );
};

Loading.defaultProps = {
  heightContainer: "23vh",
  sizeSpinner: 100
};

export default Loading;

import * as actionType from "../types";

const initialState = {
  customers: null,
  insertCustomers: null,
  errors: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.GET_CUSTOMERS_START:
      return {
        ...state,
        loading: true
      };
    case actionType.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        errors: null,
        loading: false
      };

    case actionType.INSERT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        insertCustomers: action.payload,
        errors: null,
        loading: false
      };

    case actionType.GET_CUSTOMERS_FAIL:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };

    case actionType.INSERT_CUSTOMERS_CLEAR:
      return initialState

    default:
      return state;
  }
}

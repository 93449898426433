import React from "react";
import { Collapse, Card,Carousel } from "antd";
import "numeral/locales/pt-br";
import { } from '@ant-design/icons';
import loginImage from "../../assets/img/login.png";
import updateProfile from "../../assets/img/updateProfile.png";
import changePassword from "../../assets/img/changePassword.png";
import linhasxlicença from "../../assets/img/linhasxlicença.png";

import dashboard from "../../assets/img/dashboard.png";
import linhasxstatus from "../../assets/img/linhasxstatus.png";
import linhasxsessao from "../../assets/img/linhasxsessao.png";
import geolocalizacao from "../../assets/img/geolocalizacao.png";
import reciclagem from "../../assets/img/reciclagem.png";
import operacaomassiva from "../../assets/img/operacaomassiva.png";
import operacaomassivacliente from "../../assets/img/operacaomassivacliente.png";

import criaroperacaomassiva from "../../assets/img/criaroperacaomassiva.png";
import operacaodetalhada from "../../assets/img/operacaodetalhada.png";

import linhasxconsumo from "../../assets/img/linhasxconsumo.png";
// import segundavia from "../../assets/img/login.png";
import reportBilling from "../../assets/img/reportBilling.png";
import reportConsumption from "../../assets/img/reportConsumption.png";
import reportCustomer from "../../assets/img/reportCustomer.png";
import reportSimcards from "../../assets/img/reportSimcards.png";
import inventory from "../../assets/img/inventario.png";
const { Panel } = Collapse;

const settings = {
    dots: true,
    effect: "fade",
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1
  };

const styles = {

    titleContainer: {
        display: "flex",
        justifyContent: "space-between",

        // alignItems: "center"
    },
    title: {
        fontWeight: 900,
        // marginLeft: 20
    },

    subTitle: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 17,
        marginRight: 10,
        fontSize: 22,
        color: "#ffffff"
        // cursor: "pointer"
    },
    content: {
        textAlign: "justify"
    },
    image: {
        display: "block",
        margin: "0 auto",
        maxWidth: "90%",
        height: "auto",
        boxShadow: "10px 10px 10px grey",
        marginBottom: "10px"
    },
    contentStyle : {
        backgroundColor: "#000",
      }
};



// import CardInfo from "../container/Dashborad/CardInfo";

const apresen = <p>
    O Helios User System (Helios US) é o sistema de
    atendimento à operação e aos clientes finais da NLT. O
    seu intuito é fornecer as principais funcionalidades
    de gestão de linhas para que os clientes possam ter
    autonomia e informação pertinente às suas aquisições,
    assim como a operação ter acesso e informação
    relevante ao atendimento e resolução de problemas
    relativos às linhas dos clientes da NLT. Abaixo temos algumas telas do Helios:
                        </p>
                        
const login = <div><p style={styles.content}>
    A tela de login corresponde à interface inicial de
    acesso ao Helios. Ela é a principal barreira de
    segurança a acesso de terceiros não autorizados.
                          </p>
    <p style={styles.content}>
        Outra camada de segurança adicional é a verificação
        de ‘recaptcha’, onde os servidores da Google
        verificam se o usuário que está tentando acessar o
        sistema é um ser humano ou um robô malicioso que
        está fazendo tentativa de acesso por força bruta.
                          </p>
    <img
        alt="Login"
        src={loginImage}
        style={styles.image}
    />
</div>;
const alterarSenha = <div>
    <p style={styles.content}>
        Nessa tela o usuário poderá alterar a senha.
        Deve informar a nova senha para que, na próxima autenticação, ser usada para o acesso ao sistema.
                          </p>

    <img
        alt="Alterar Senha"
        src={changePassword}
        style={styles.image}
    />
</div>

const atualizarUsuario = <div>
    <p style={styles.content}>
        Na tela Editar Perfil é possível alterar o nome,
        sobrenome e o email.
                          </p>

    <img
        alt="Login"
        src={updateProfile}
        styles={styles.image}
    />
</div>
const dashboarde = <div>
    <p style={styles.content}>
        A tela de Dashboard é a área principal de informação
        para o usuário do Helios US. O objetivo da mesma é
        fornecer informação estratégica sobre as linhas para
        quem a acessa. As visualizações disponíveis dependem
        do tipo de usuário e de seu nível de acesso.
                          </p>
    <p style={styles.content}>
        No caso do cliente final, ele terá acesso às
        informações principais sobre a quantidade e o status
        de suas linhas, sobre seu consumo e sobre a fase dos
        seus simcards. No caso do usuário operação, ele terá
        acesso a toda base de linhas de clientes, assim como
        seus respectivos status, ao consumo geral e aos
        gráficos de mudanças de fases. A operação terá
        acesso tanto às informações gerais da base como a
        informação de clientes estratégicos.
                          </p>
    <img
        alt="Dashboard"
        src={dashboard}
        style={styles.image}
    />
</div>;
const linhasStatus = <div> <p style={styles.content}>
    A seção de ‘Linhas X Status’ tem como objetivo
    trazer as informações da base total de linhas e suas
    respectivas informações de status. A seção traz uma
    tabela que dispõe de todos os registros de linhas da
    base e a informação específica se aquela linha está
    nos status: ‘Online’, ‘Offline’ e ‘Out Connection’.
    Cada registro exibido na tabela também tem opções de
    ações a serem executadas nas linhas, elas são: o {" "}
    <strong>Reset Registro HLR</strong>,{" "}
    <strong>Ativar SMS MO</strong>,{" "}
    <strong>Desativar SMS MO</strong> e{" "}
    <strong>Reset Registro HLR com APN</strong>,{" "}
    <strong>Ativar SMS MO com APN</strong>,{" "}
    <strong>Desativar SMS MO com APN</strong>,{" "}
    <strong>Reset Conexão de dados</strong> e{" "}
    <strong>Consulta Status HLR</strong>.

                          </p>
    <p style={styles.content}>
        No caso do usuário ‘Cliente’, esta seção apresenta
        todos os registros de linha da base daquele cliente
        específico. No caso do usuário ‘Operação’, esta
        seção apresenta todos os registros da base total num
        primeiro momento, entretanto, também é possível
        realizar filtragens por ‘Cliente’, por ‘MSISDN’ e
        por ‘ICCID’.
                          </p>
    <img
        alt="Linhas Status"
        src={linhasxstatus}
        style={styles.image}
    />
</div>

const linhasSessão = <div> <p style={styles.content}>
    A seção de ‘Linhas X Sessão tem como objetivo
    trazer as informações do status de linhas advindas da comunicação das linhas com o Radius da NLT.
    A seção traz uma
    tabela que dispõe de todos os registros de linhas da
    base e a informação específica se aquela linha está
    nos status de: ‘Online’ e ‘Data de Offline’. A seção tem o filtro por servidor que a linha se conecta que podem
    ser por São Paulo ou Campinas-RJ e também a busca por MSISDN.
    Cada registro exibido na tabela tem o tipo de Rede que a linha
    está trafegando como por exemplo <strong>Rede 4G e 3G</strong> e
    a APN que a linha está trafegando.

                          </p>
    
    <img
        alt="Linhas Sessão"
        src={linhasxsessao}
        style={styles.image}
    />
</div>

const linhasConsumo = <div>
    <p style={styles.content}>
        A seção de ‘Linhas X Consumo’ tem como objetivo
        trazer as informações da base total de linhas e suas
        respectivas informações de consumo. A seção traz uma
        tabela que dispõe de todos os registros de linhas da
        base e a informação específica do consumo que uma
        linha utilizou desde o início do mês. Ao final de
        cada mês esse consumo é zerado e reiniciado do zero.
        Cada registro exibido na tabela também tem opções de
        ações a serem executadas nas linhas, como por
        exemplo o upgrade de um determinado plano de dados,
        assim como outras funcionalidades.
                          </p>
    <p style={styles.content}>
        No caso do usuário ‘Cliente’, esta seção apresenta
        todos os registros de linha da base daquele cliente
        específico. No caso do usuário ‘Operação’, esta
        seção apresenta todos os registros da base total num
        primeiro momento, entretanto, também é possível
        realizar filtragens por ‘Cliente’, por ‘MSISDN’ e
        por ‘ICCID’.
                          </p>
    <img
        alt="Linhas Consumo"
        src={linhasxconsumo}
        style={styles.image}
    />
</div>

const linhasLicença =
    <div>
        <p style={styles.content}>
            A seção de ‘Linhas X Licença tem como objetivo
            trazer as informações da base total de linhas de planos por licença e suas
            respectivas informações. A seção traz uma
            tabela que dispõe de todos os registros de linhas da
        base e a informação específica da linha e seu plano.</p>
        <p style={styles.content}>
            No caso do filtro, o usuário pode filtrar pelo tipo plano que as linhas estão, assim como filtrar o cliente
                          </p>
        <img
            alt="Linhas Licença"
            src={linhasxlicença}
            style={styles.image}
        />
    </div>


// const notaFiscal = <div>
//     <p style={styles.content}>
//         A seção de ‘Segunda via de Nota Fiscal’ tem como
//         objetivo trazer as notas fiscais de ‘Serviços’ e de
//         ‘Telecomunicações’ dos últimos 3 meses de um
//         determinado cliente. A seção traz uma tabela que
//         dispõe dos registros de notas fiscais de um
//         determinado cliente nos últimos 3 meses. Cada
//         registro exibido na tabela também tem duas opções de
//         ações a serem executadas, no caso o download da
//         segunda via da nota fiscal e o download do boleto de
//         pagamento.
//                           </p>
//     <img
//         alt="Segunda Via"
//         src={segundavia}
//         style={styles.image}
//     />
// </div>
const relatorioFaturamento = <div>
    <p style={styles.content}>
        Nessa página, é possível realizar o download do
        Faturamento de todos os Clientes ou, apenas, de um
        determinado Cliente, por meio do filtro. Também é
        possível utilizar o filtro por mês (atual ou meses
        anteriores).
                          </p>
    <img
        alt="Relatório de Faturamanento"
        src={reportBilling}
        style={styles.image}
    />
</div>

const relatorioSimcards = <div>
    <p style={styles.content}>
        Essa página traz todos os Simcards da base de dados
        do WS. Entretanto, é possível realizar alguns
                            filtros como,{" "}
        <strong>Selecionar o tipo de Relatório</strong>,{" "}
        <strong>Pesquisar por Cliente</strong>,{" "}
        <strong>Status da Linhas</strong> (Online, Offline
                            ou Out of Connection) e por{" "}
        <strong>Fases da Linha</strong> (Ativadas,
                            Bloqueadas, Canceladas e Suspensas).
                          </p>
    <p>
        Na opção de Selecionar o tipo de Relatório, existem
        três opções:
                          </p>
    <ul>
        <li>
            <strong>Linha X Contrato</strong>
            <ul>
                <li>
                    Contempla a geração de relatorios da base,
                    baseado no contrato especifico ou cliente.
                                </li>
            </ul>
        </li>

        <li>
            <strong>Linha X Sem Contrato</strong>
            <ul>
                <li>
                    Tem como funcionalidade a geração de relatório
                    das linhas que não ainda não estão vinculadas
                    a nenhum cliente ou que foram descartadas.
                                </li>
            </ul>
        </li>

        <li>
            <strong>Simcard em Branco</strong>
            <ul>
                <li>
                    Simcards que estão disponíveis em nossa base.
                                </li>
            </ul>
        </li>
    </ul>
    <img
        alt="Relatório de Faturamento"
        src={reportSimcards}
        style={styles.image}
    />
</div>


const relatorioConsumo = <div>
    <p style={styles.content}>
        Nessa página contém as informações sumarizada do
        consumo de dados das linhas por Cliente.
                          </p>
    <img
        alt="Relatório Consumo Mensal de Dados"
        src={reportConsumption}
        style={styles.image}
    />
</div>

const relatorioCliente = <div>
    <p style={styles.content}>
        Nessa página, possui a relação de todos os Clientes
        da NLT.
                          </p>
    <img
        alt="Relatório de Clientes"
        src={reportCustomer}
        style={styles.image}
    />
</div>

const Histórico = <div>
    <p style={styles.content}>
        A tela de Histórico tem como principal função,
        mostrar uma lista dos principais recursos utilizados no sistema.
        Seguindo o princípio de um Histórico, registramos
        infomações como: As ações dos usuários incluíndo
        data, hora, endereço de acesso, referências do
        usuário (Cadastradas no Helios) e tipo dos recursos
        utilizados. Sendo eles:
                          </p>
    <ul>
        <li>Reset Registro HLR</li>
        <li>Ativação SMS MO</li>
        <li>Desativação SMS MO</li>
        <li>Troca de APN</li>
        <li>Status da linha no HLR</li>

    </ul>
    <img
        alt="Inventory"
        src={inventory}
        style={styles.image}
    />
</div>

const Geolocalização = <div>
    <p style={styles.content}>
        A tela de Geolocalização tem como principal função,
        informar a localização da linha por triangulação das antenas. 
        Seguindo o princípio da triangulação, os dados informados da linha tem o raio de 2 quilômetros
        informados no mapa.
        Também pode obter o tipo rede acessado pela linha, como informar várias linhas fazendo Upload de um arquivo,
        ao qual o mapa mostrará todas as linhas informadas e que teve conexão com o Radius da NLT.
        O mapa no Helios traz algumas funções como:
    </p>
    <ul>
        <li>Informação de Bairro, Cidade. Estado e País</li>
        <li>Modo Full Screen no mapa</li>
        <li>Modo Dia e Noite</li>
        <li>Lista das linhas informadas pelo usuário</li>
        <li>Botões de Zoom In e Zoom Out</li>
    </ul>
    <img
        alt="GeoLocalização"
        src={geolocalizacao}
        style={styles.image}
    />
</div>

const ListarReciclagem = <div>
    <p style={styles.content}>
        A tela de Reciclagem dos clientes tem como principal função,
        informar a troca de Simcards efetuadas pelos clientes junto ao WS. 
        A tela apenas exibe as reciclagens detalhando:
    </p>
    <ul>
        <li>Transação</li>
        <li>Tipo de recurso</li>
        <li>Cliente</li>
        <li>Hora Iniciada da transação</li>
    </ul>
    <img
        alt="Reciclagem"
        src={reciclagem}
        style={styles.image}
    />
</div>

const OperaçãoMassiva = <div>
    <p style={styles.content}>
        A tela de Operações Massivas traz informações sobre os recursos chamados na tela Linhas x Status. Assim como
        informações por Lote, Número total de linhas, nome do arquivo e Status do processo.
        Exclusivo para processos massivos, ela traz a opção do usuário acompanhar os processos chamados a seguir:
    </p>
    <ul>
        <li>Reset de Linhas</li>
        <li>Atribuição de Apn</li>
        <li>Habilitar SMS</li>
        <li>Desabilitar SMS</li>
    </ul>
    <p style={styles.content}>
        A tela ainda conta com opção de filtragem por Tipo do Recurso chamado, Clientes e Email do Responsável que solicitou o processo.
    </p>
    <img
        alt="Operações Massivas"
        src={operacaomassiva}
        style={styles.image}
    />
</div>

const OperaçãoMassivaCliente = <div>
    <p style={styles.content}>
        A tela de Operações Massivas dos clientes traz informações sobre os recursos chamados pela api de clientes. Assim vem
        informações da api como: Transação, quantidade de linhas, o cliente, tipo de recurso e data. Na tela de detalhamento lista as linhas
        operadas pelo cliente.
        Acessado pela aba CLIENTES, as operações massivas serão listadas com os recursos abaixo:
    </p>
    <ul>
        <li>Reset de Linhas</li>
        <li>Atribuição de Apn</li>
        <li>Habilitar SMS</li>
        <li>Desabilitar SMS</li>
    </ul>
    <p style={styles.content}>
        A tela ainda conta com opção de filtragem por Tipo do Recurso chamado, Clientes e Email do Responsável que solicitou o processo.
    </p>
    <img
        alt="Lista de Operações Massivas pelo Cliente"
        src={operacaomassivacliente}
        style={styles.image}
    />
</div>

const CriarOperaçãoMassiva = <div>
    <p style={styles.content}>
        A tela de Criação das Operações Massivas é o meio de usar os recursos. A tela vem no formato de passo a passo
        para auxiliar a criação do fluxo de cada recurso de gerenciamento das linhas.
        Exclusivo para processos massivos, ela traz a opção do usuário acompanhar os chamados após a criação, a seguir temos os recursos:
    </p>
    <ul>
        <li>Reset de Linhas</li>
        <li>Reset de Linhas com APN</li>
        <li>Atribuição de Apn</li>
        <li>Habilitar SMS</li>
        <li>Habilitar SMS com APN</li>
        <li>Desabilitar SMS</li>
        <li>Desabilitar SMS com APN</li>
        

    </ul>
    <p>O passo a passo para criar operação vem como:</p>
    <ul>
        <li>Operação</li>
        <li>Upload</li>
        <li>Ocorrência</li>
        <li>Resumo</li>
    </ul>
    <p>Informação importante é que na tela tem a quantidade mensal das operações efetuadas de cada recurso.</p>
    <img
        alt="Operações Massivas"
        src={criaroperacaomassiva}
        style={styles.image}
    />
</div>

const Operação = <div>
    <p style={styles.content}>
        A tela de Operação Detalhada traz informações sobre as linhas que passam pela operação de algum recurso. Ele traz uma tabela com informações 
        sobre a linha, data e hora e Status de cada linha.
        Ela é acessada pela tela de OPERAÇÕES MASSIVAS, ela também traz a opção do usuário acompanhar o processo por um gráfico em barras e as informações da operação massiva.
    </p>
            
    <img
        alt="Operações Detalhadas"
        src={operacaodetalhada}
        style={styles.image}
    />
</div>

const Documentation = () => {



    return (

        <div>

            <Card
                title={<h1 style={styles.title}>
                    <i
                        style={styles.subTitle}
                        className="fas fa-file-code"
                    />
              Documentação
          </h1>}
            >
                <div>
                    <Collapse accordion>
                        <Panel header="Apresentação" key="1">
                            <p>{apresen}</p>
                            <br/>
                            <Carousel dotPosition={"left"} autoplay {...settings}>
                                <div>
                                <h3 style={styles.contentStyle}>
                                    <img
                                    alt="Login"
                                    src={loginImage}
                                    style={styles.image}
                                /></h3>
                                </div>
                                <div>
                                <h3 style={styles.contentStyle}>
                                    <img
                                    alt="Dashboard"
                                    src={dashboard}
                                    style={styles.image}
                                /></h3>
                                </div>
                                <div>
                                <h3 style={styles.contentStyle}>
                                    <img
                                    alt="LinhasXStatus"
                                    src={linhasxstatus}
                                    style={styles.image}
                                /></h3>
                                </div>
                                <div>
                                <h3 style={styles.contentStyle}>
                                    <img
                                    alt="LinhasXSessão"
                                    src={linhasxsessao}
                                    style={styles.image}
                                /></h3>
                                </div>
                                <div>
                                <h3 style={styles.contentStyle}>
                                    <img
                                    alt="Operações Detelhadas"
                                    src={operacaodetalhada}
                                    style={styles.image}
                                /></h3>
                                </div>
                            </Carousel>
                        </Panel>
                        <Panel header="Login" key="2">
                            <p>{login}</p>
                        </Panel>
                        <Panel header="Alterar Senha" key="3">
                            <p>{alterarSenha}</p>
                        </Panel>
                        <Panel header="Atualizar usuário" key="4">
                            <p>{atualizarUsuario}</p>
                        </Panel>
                        <Panel header="Dashboard" key="14">
                            <p>{dashboarde}</p>
                        </Panel>
                        <Panel header="Linhas x Status" key="5">
                            <p>{linhasStatus}</p>
                        </Panel>
                        <Panel header="Linhas x Consumo" key="6">
                            <p>{linhasConsumo}</p>
                        </Panel>
                        <Panel header="Linhas x Licença" key="7">
                            <p>{linhasLicença}</p>
                        </Panel>
                        <Panel header="Relatório de Faturamento" key="8">
                            <p>{relatorioFaturamento}</p>
                        </Panel>
                        <Panel header="Relatório de Simcards" key="9">
                            <p>{relatorioSimcards}</p>
                        </Panel>
                        <Panel header="Relatório de Consumo" key="10">
                            <p>{relatorioConsumo}</p>
                        </Panel>
                        <Panel header="Relatório de Clientes" key="11">
                            <p>{relatorioCliente}</p>
                        </Panel>
                        {/* <Panel header="Nota Fiscal" key="12">
                            <p>{notaFiscal}</p>
                        </Panel> */}
                        <Panel header="Histórico" key="13">
                            <p>{Histórico}</p>
                        </Panel>
                        <Panel header="Linhas X Sessão" key="15">
                            <p>{linhasSessão}</p>
                        </Panel>
                        <Panel header="Geolocalização" key="16">
                            <p>{Geolocalização}</p>
                        </Panel>
                        <Panel header="Lista de Operações Massivas" key="17">
                            <p>{OperaçãoMassiva}</p>
                        </Panel>
                        <Panel header="Operação Detalhada" key="18">
                            <p>{Operação}</p>
                        </Panel>
                        <Panel header="Criar Operações Massivas" key="19">
                            <p>{CriarOperaçãoMassiva}</p>
                        </Panel>

                        <Panel header="Listar Operações / Clientes" key="20">
                            <p>{OperaçãoMassivaCliente}</p>
                        </Panel>
                        <Panel header="Listar Reciclagens / Clientes" key="21">
                            <p>{ListarReciclagem}</p>
                        </Panel>
                    </Collapse>

                </div>
            </Card>
        </div>
    );
};


export default Documentation;
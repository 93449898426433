import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getDevices } from "../../../store/actions/deviceAction";
import { getCustomerByName } from "../../../store/actions/customerAction";

import HeliosTable from "../../../components/HeliosTable/HeliosTable";
import { Tooltip, Drawer, Form, Input, Button, AutoComplete } from "antd";
import SkeletonTable from "../../../components/Skeleton/SkeletonTable";
import { columns } from "./columnsTable";

const Devices = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState();
  const [devEui, setDevEui] = useState();
  const [appEui, setAppEui] = useState();
  const [customerName, setCustomerName] = useState();
  const [optionsCustomer, setOptionsCustomer] = useState([]);
  const [optionsValue, setOptionsValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDevices(query, offset, limit));
  }, [dispatch, query, offset, limit]);


  useEffect(() => {
    if (customerName) {
      dispatch(getCustomerByName(customerName));
    }
  }, [dispatch, customerName]);

  const customers = useSelector(state => state.customers);

  useEffect(() => {
    if (customers.customers) {
      let options = [];
      customers.customers.data.map(obj => {
        return options.push({
          value: obj.cpfcnpj_c.toString(),
          label: obj.name
        });
      });
      setOptionsCustomer(options);
    }
  }, [customers]);

  const { devices, loading } = useSelector(state => state.devices);

  const onPaginationChange = valeu => {
    if (devices) {
      setOffset(limit * (valeu - 1));
    }
  };

  const onShowSizeChange = (current, limit) => {
    setOffset(0);
    setLimit(limit);
  };

  const onCloseFilter = () => {
    setShowFilter(false);
  };

  const onShowFilter = () => {
    setShowFilter(true);
  };

  const onClearFilter = () => {
    setQuery();
    setDevEui();
    setAppEui();
  };

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => {
            onShowFilter();
          }}
        />
      </Tooltip>
    </div>
  );

  const onChangeInput = e => {
    if (e.target.name === "dev_eui") {
      setDevEui(e.target.value);
    }
    if (e.target.name === "app_eui") {
      setAppEui(e.target.value);
    }
  };

  return (
    <div>
      <SkeletonTable show={loading} title="DEVICES" icon="fas fa-chart-line" />
      {devices && (
        <HeliosTable
          title="Devices"
          subTitle={subTitle}
          columns={columns}
          dataSource={devices.devices || []}
          rowKey="app_eui"
          total={devices.total}
          pageSize={limit}
          defaultCurrent={offset}
          onChange={onPaginationChange}
          onShowSizeChange={onShowSizeChange}
        />
      )}

      <Drawer
        title="Filtros"
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        placement="right"
        onClose={onCloseFilter}
        visible={showFilter}
        width={500}
        style={styles.containerDrawer}
      >
        <Form layout="vertical">
          <Form.Item label="CLIENTE">
            <AutoComplete
              options={optionsCustomer}
              value={optionsValue}
              onChange={value => {
                if (!value) {
                  setCustomerName();
                }
              }}
              onSearch={value => {
                setOptionsValue(value);
                const timer = setTimeout(() => {
                  if (value.length >= 2) {
                    setCustomerName(value);
                  }
                }, 1000);
                return () => clearTimeout(timer);
              }}
              onSelect={(value, option) => {
                setOptionsValue(option.label);
                setQuery(`tags:${value}`);
              }}
            >
              <Input.Search
                size="middle"
                placeholder="Pesquisar Cliente..."
                allowClear
                loading={customers.loading}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item label="Dev EUI">
            <Input.Search
              autoComplete="off"
              name="dev_eui"
              allowClear
              onSearch={valeu => {
                setQuery(`dev_eui:${valeu}`);
              }}
              onChange={event => {
                if (event.target.value === "") {
                  setDevEui();
                  setQuery();
                }
                onChangeInput(event);
              }}
              value={devEui}
            />
          </Form.Item>
          <Form.Item label="App EUI">
            <Input.Search
              autoComplete="off"
              name="app_eui"
              allowClear
              onSearch={valeu => {
                setQuery(`app_eui:${valeu}`);
              }}
              onChange={event => {
                if (event.target.value === "") {
                  setAppEui();
                  setQuery();
                }
                onChangeInput(event);
              }}
              value={appEui}
            />
          </Form.Item>
        </Form>
        <div style={styles.btnFilter}>
          <Button
            onClick={onClearFilter}
            type="danger"
            ghost
            style={styles.btnCancel}
          >
            LIMPAR
          </Button>
          <Button onClick={onCloseFilter} type="default">
            FECHAR
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

const styles = {
  subTitle: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  }
};

export default Devices;

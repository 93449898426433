
import { Upload, message, Card } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Animate from 'rc-animate';

import React from 'react'
const { Dragger } = Upload;

const UploadStockOs = (props) => {


  function beforeUpload(file) {
    console.log(file.type)

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Apenas arquivo menos que 2MB!');
    }
    const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isXLSX) {
      message.error('Apenas tipo de arquivo XLSX!');
    } else {
      props.setFileInput(file)
    }

    return false;
  }
  return (
    <>
      <Animate transitionAppear transitionName="fade">
        <Card style={styles.container}>

          <Dragger beforeUpload={beforeUpload}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click ou arraste o arquivo para esta área de upload</p>
            <p className="ant-upload-hint">
              Suporte para upload único e tipo de arquivo XLSX. Arquivo deve conter obrigatoriamente lista de ICCID's ou MSISDN's
                </p>
          </Dragger>
        </Card>
      </Animate>

    </>
  )

}
const styles = {
  container: {
    borderRadius: "10px",
    background: "#fff",
    boxShadow:
      "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  }
};
export default UploadStockOs;
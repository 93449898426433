import React, { useEffect } from 'react'
import { typeOperations } from './constantsOperations'
import {
    Card,
    List,
    Tooltip,
    Modal,
    Row
} from "antd";
import Texty from 'rc-texty';
import Animate from 'rc-animate';
import OperationStatusInfo from '../../container/Dashborad/OperationsStatusInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getOperationsLinesSummarized } from '../../../store/actions/lineStatusAction';


const columnsStatus = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
    {
      title: 'value',
      dataIndex: 'value',
      key: 'value',
      render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
    },
  ];


const CardsOperation = (props) => {
    // const { onChange, onType } = props
    const data = typeOperations;
    const { Item } = List
    const { Meta } = Card;

    const { statusOperationSummarized } = useSelector(state => state.linesStatus);
    
    const dispatch = useDispatch();

    const statusQuant=(typeResource)=> statusOperationSummarized && ([
        { name: 'Processando', value: statusOperationSummarized.processing[typeResource], color: "blue" },
        { name: 'Em espera', value: statusOperationSummarized.waiting[typeResource], color: "orange" },
        { name: 'Finalizados', value: statusOperationSummarized.finalized[typeResource], color: "green" }
    
      ]);

    useEffect(() => {
      const intervalSummarized = setInterval(() => {
        dispatch(getOperationsLinesSummarized());
      }, 5000);
      return () => {
        clearInterval(intervalSummarized)
      };
    },[dispatch]);

    return (
        <>

        <Animate transitionAppear transitionName="fade">
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 4
            }}
            dataSource={data}
            renderItem={item => (
                <Item >
                    <Card  hoverable extra={<><Tooltip placement="bottom" title={"Ajuda com "+item.name}><i onClick={()=>Modal.info({
    title: <center>{item.name}</center>,
    content: (
      <div><Card>
                    {item.name ===("Hard Reset") ? 
                    <><Row><p>Recurso que limpa o registro da(s) linha(s), retornando às configurações iniciais. Salientando que ela:</p></Row>
                    <Row><ul>
                    <li>Remove a(s) APN(s) (Access Point Name)</li>
                    <li>Desativa o SMS</li>
                    </ul></Row>
                    </>: ""}
                    {item.name ===("Hard Reset com APN") ? 
                    <><Row><p>Recurso que limpa o registro da(s) linha(s), porém persiste em:</p></Row>
                    <Row><ul>
                    <li>Adiciona a APN removida no Reset</li>
                    <li>O SMS continua na configuração existente</li>
                    </ul></Row>
                    </>:""}
                     {item.name ===("Alterar APN") ? 
                    <><Row><p>Recurso que adiciona APN na linha. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>A linha pode agrupar APN's</li>
                    <li>Alguns clientes usam linhas com APN padrão da NLT, não sendo necessário atribuir APN a eles</li>
                    </ul></Row>
                    </>: ""}
                    {item.name ===("Ativar SMS") ? 
                    <><Row><p>Recurso que ativa o envio SMS da(s) linha(s). Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação remove a APN da linha</li>
                    
                    </ul></Row>
                    </>: ""}
                    {item.name ===("Desativar SMS") ? 
                    <><Row><p>Recurso que desativa o envio SMS da(s) linha(s). Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação remove a APN da linha</li>
                    
                    </ul></Row>
                    </>: ""}
                    {item.name ===("Ativar SMS com APN") ? 
                    <><Row><p>Recurso que ativa o envio de SMS da(s) linha(s) e adiciona APN. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação remove as APN's antiga e adiciona uma nova da linha</li>
                    
                    </ul></Row>
                    </>: ""}
                    {item.name ===("Desativar SMS com APN") ? 
                    <><Row><p>Recurso que desativa o envio de SMS da(s) linha(s) e adiciona APN. Informações complementares:</p></Row>
                    <Row><ul>
                    <li>Essa operação remove as APN's antiga e adiciona uma nova da linha</li>
                    
                    </ul></Row>
                    </>: ""}
                   
        </Card></div>
    ),
    icon:(<><i style={styles.icon} className="far fa-question-circle" /></>),
    width:500,
    onOk() {},
  })
} style={styles.icon} className="far fa-question-circle"></i></Tooltip></>} title={<Texty
                    type={"left"}
                     mode={"smooth"}>{item.name}</Texty>
                    } 
                    >
                      {statusOperationSummarized &&(
                        <Meta
                        description={ <OperationStatusInfo
                            data={statusQuant(item.type)}
                            total={statusOperationSummarized.totalized[item.type]}
                            columns={columnsStatus}
                          />}
                        
                        onClick={()=>{
                          props.onType(item.name)
                          props.onChange()
                      }
                        }
                        />)}
                    </Card>
                </Item>
            )}
        />
        </Animate>
        </>
    //  icon question
    )

}
const styles = {
  icon:{
      fontSize: 18,
      cursor: "pointer"    
  }
}

export default CardsOperation;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLinesLicense } from "../../../../store/actions/lineStatusAction";
import { getCustomerByName } from "../../../../store/actions/customerAction";
import { getPlanByName } from "../../../../store/actions/planAction";


import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import {
    Tooltip,
    Drawer,
    Form,
    Input,
    Button,

    AutoComplete,

} from "antd";

import SkeletonTable from "../../../../components/Skeleton/SkeletonTable";

// import { columns } from "./columnsTable";

const LinesLicense = () => {
    let clientId = localStorage.getItem("clientId")
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [optionsCustomer, setOptionsCustomer] = useState([]);
    const [optionsValue, setOptionsValue] = useState("");

    const [planId, setPlanId] = useState();
    const [planName, setPlanName] = useState();
    const [optionsPlan, setOptionsPlan] = useState([]);
    const [optionsValuePlan, setOptionsValuePlan] = useState("");


    const [showFilter, setShowFilter] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLinesLicense(customerId, planId, pageSize, page-1));

    }, [dispatch, customerId, pageSize, page, planId]);

    useEffect(() => {
        if (customerName) {
            dispatch(getCustomerByName(customerName));
        }
        dispatch(getPlanByName(planName));

    }, [dispatch, planName, customerName]);

    const customers = useSelector(state => state.customers);
    const plans = useSelector(state => state.plans);
    useEffect(() => {
        if (customers.customers) {
            let options = [];
            customers.customers.data.map(obj => {
                return options.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsCustomer(options);
        }
        if (plans.plans) {
            let optionsPlano = [];
            plans.plans.data.map(obj => {
                return optionsPlano.push({ value: obj.id.toString(), label: obj.name });
            });
            setOptionsPlan(optionsPlano);
        }

    }, [customers, plans]);



    const { linesStatus, loading } = useSelector(state => state.linesStatus);

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(current);
    };

    const handlePaginationChange = (current) => {
        setPage(current);
    };

    const onClearFilter = () => {
        setCustomerId();
        setCustomerName();
        setOptionsValue("");
    };

    const onShowFilter = () => {
        setShowFilter(true);
    };

    const onCloseFilter = () => {
        setShowFilter(false);
    };


    const subTitle = (
        <div>
            <Tooltip placement="bottom" title="Filtros">
                <i
                    style={styles.subTitle}
                    className="fas fa-filter"
                    onClick={() => {
                        onShowFilter();
                    }}
                />
            </Tooltip>
        </div>
    );
    const columns = [
        {
            title: "Cliente",
            dataIndex: "customer__name"
        },
        {
            title: "ICCID",
            dataIndex: "iccid"
        },
        {
            title: "IMSI",
            dataIndex: "imsi__number"
        },
        {
            title: "MSISDN",
            dataIndex: "msisdn"
        },
        {
            title: "Plano",
            dataIndex: "soldplan__name"
        },
        {
            title: "Tipo de Plano",
            dataIndex: "soldplan__type"
        },

    ]
    return (
        <div>
            <SkeletonTable show={loading} title="Linhas X Licença" icon="fas fa-medal" pageSize={pageSize}/>

            {linesStatus && !loading ?(
                <HeliosTable
                    title="Linhas X Licença"
                    subTitle={subTitle}
                    columns={columns}
                    icon="fas fa-medal"
                    dataSource={linesStatus.lines.data || []}
                    rowKey="id"
                    total={linesStatus.lines.total}
                    pageSize={pageSize}
                    page={page}
                    onChange={handlePaginationChange}
                    onShowSizeChange={onShowSizeChange}
                // rowSelection={rowSelection}
                />
            ):""}

            <Drawer
                title="Filtros"
                headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
                placement="right"
                onClose={onCloseFilter}
                visible={showFilter}
                width={500}
                style={styles.containerDrawer}
            >
                <Form layout="vertical">
                {clientId ===null &&
                    <Form.Item label="CLIENTE">
                        <AutoComplete
                            options={optionsCustomer}
                            value={optionsValue}
                            onChange={value => {
                                if (!value) {
                                    setCustomerId();
                                    setCustomerName();
                                }
                            }}
                            onSearch={value => {
                                setOptionsValue(value);
                                const timer = setTimeout(() => {
                                    if (value.length >= 2) {
                                        setCustomerName(value);
                                    }
                                }, 1000);
                                return () => clearTimeout(timer);
                            }}
                            onSelect={(value, option) => {
                                setOptionsValue(option.label);
                                setCustomerId(value);
                            }}
                        >
                            <Input.Search
                                size="middle"
                                placeholder="Pesquisar Cliente..."
                                allowClear
                                loading={customers.loading}
                            />
                        </AutoComplete>
                    </Form.Item>
                }
                    <Form.Item label="Tipo de PLANO por Licença">
                        <AutoComplete
                            options={optionsPlan}
                            value={optionsValuePlan}
                            onChange={value => {
                                if (!value) {
                                    setPlanId();
                                    setPlanName();
                                }
                            }}
                            onSearch={value => {
                                setOptionsValuePlan(value);
                                const timer = setTimeout(() => {
                                    if (value.length >= 1) {
                                        setPlanName(value);
                                    }
                                }, 1000);
                                return () => clearTimeout(timer);
                            }}
                            onSelect={(value, option) => {
                                setOptionsValuePlan(option.label);
                                setPlanId(value);
                            }}
                        >
                            <Input.Search
                                size="middle"
                                placeholder="Pesquisar Plano..."
                                allowClear
                                loading={plans.loading}
                            />
                        </AutoComplete>
                    </Form.Item>
                </Form>
                <div style={styles.btnFilter}>
                    <Button
                        onClick={onClearFilter}
                        type="danger"
                        ghost
                        style={styles.btnCancel}
                    >
                        LIMPAR
          </Button>
                    <Button onClick={onCloseFilter} type="default">
                        FECHAR
          </Button>
                </div>
            </Drawer>

        </div>
    );
};

const styles = {
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    btnFilter: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
    },
    btnCancel: {
        marginRight: 8
    },
    online: {
        color: "green"
    }
};

export default LinesLicense;

import React, { useState } from "react";

import {
    Card,
    Steps,
    Button,
    Descriptions,
    notification,
    Collapse,
    Table
} from "antd";
import { RollbackOutlined, RightOutlined } from "@ant-design/icons";
import CardsOperation from "./CardsOperationOs";
import UploadOperation from "./UploadOperationOs";
import FormOperation from "./FormOperationOs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getManySuspension } from "../../../store/actions/suspensionAction";
import { getManyIccidFile, setManyReserveNumber } from "../../../store/actions/reserveNumberAction";

const { Step } = Steps;
const { Panel } = Collapse;
const OperationsMassive = () => {
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [protocol, setProtocol] = useState("");
    const [motive, setMotive] = useState("")
    const [days, setDays] = useState("")

    const [current, setCurrent] = useState(0);
    const [typeResource, setTypeResource] = useState("")
    const [fileInput, setFileInput] = useState()
    const [isVerified, setIsVerified] = useState(false)
    const [email, setEmail] = useState("");
    const [modelName, setModelName] = useState("");

    const dispatch = useDispatch();
    const suspension = useSelector(state => state.suspension);
    const reactivation = useSelector(state => state.reactivation);

    const history = useHistory();
    const placement = "bottomRight"

    const proccessOperation = () => {
        if (typeResource === "Suspensão a Pedido" || typeResource === "Suspensão Perda/Roubo" || typeResource === "Suspensão Temporária" || typeResource === "Suspensão Quarentena" || typeResource === "Reativação") {
            dispatch(getManySuspension(customerId, customerName, fileInput, motive, protocol, email, typeResource, days));
        }

        if (typeResource === "Reserva de Numeração") {
            if (reserveNumbers.countIccids && reserveNumbers.countIccids.iccidsIn.length > 0) {
                dispatch(setManyReserveNumber(fileInput, motive, protocol, modelName, reserveNumbers.countIccids.iccidsIn))
            } else {
                notification.error({
                    message: 'Arquivo sem linhas na Fase Conferido!',
                    description:
                        'Tente novamente com arquivo válido. Se persistir,Caso o problema persista, entre em contato com o departamento de Engenharia!',
                    duration: 10,
                    placement
                });
            }
        }
    }

    const verifyFile = (fileInput) => {
        console.log("teste", fileInput)
        dispatch(getManyIccidFile(fileInput))
    };

    const reserveNumbers = useSelector(state => state.reserveNumbers);
    console.log(reserveNumbers);

    if (typeResource === "Suspensão a Pedido" || typeResource === "Suspensão Perda/Roubo" || typeResource === "Suspensão Quarentena" || typeResource === "Suspensão Temporária") {
        if (suspension.suspension) {
            notification.success({
                message: suspension.suspension.message,
                description:
                    'Linhas para Suspensão estão na fila de operação massiva',
                duration: 60,
                placement
            });
            suspension.suspension = null
            history.push("/resource-activities-os")

        }
    }

    if (typeResource === "Reserva de Numeração") {
        if (reserveNumbers.reserve) {
            notification.success({
                message: reserveNumbers.reserve.message,
                description:
                    'Linhas para Reserva de Numeração estão na fila de operação massiva',
                duration: 60,
                placement
            });
            reserveNumbers.reserve = null
            history.push("/resource-activities-os")

        }
    }

    if (typeResource === "Reativação") {
        if (reactivation.reactivation) {
            notification.success({
                message: reactivation.reactivation.message,
                description:
                    'Linhas para Reativação estão na fila de operação massiva',
                duration: 60,
                placement
            });

            reactivation.reactivation = null;
            history.push("/resource-activities-os");
        }
    }

    const columns = [
        {
            title: 'ICCID',
            dataIndex: 'iccid',
            key: 'iccid',
            render: text => <b>{text}</b>,
        },
        {
            title: 'FASE',
            dataIndex: 'nome',
            key: 'nome',
            render: text => <b>{text}</b>,
        },

    ]
    const next = () => {
        setCurrent(current + 1);
    };


    const prev = () => {
        setCurrent(current - 1);
        setFileInput()
    };
    const steps = [
        {
            title: 'Operação',
            content: <CardsOperation onChange={next} onType={setTypeResource} />,
        },
        {
            title: 'Upload',
            content: <UploadOperation setFileInput={setFileInput} />,
        },
        {
            title: 'Ocorrência',
            content: <FormOperation
                setIsVerified={setIsVerified}
                typeResource={typeResource}
                setCustomerId={setCustomerId}
                setCustomerName={setCustomerName}
                setProtocol={setProtocol}
                setMotive={setMotive}
                setEmail={setEmail}
                setModelName={setModelName}
                setDays={setDays}
            />,
        }, {
            title: 'Resumo',
            content:
                <>
                    {/* <Animate transitionName="fade" transitionAppear> */}
                    <Card styles={styles.container}>
                        <Descriptions title="Resumo da Operação" bordered layout="vertical">
                            <Descriptions.Item label="Recurso solicitado:" span={3}>{typeResource}</Descriptions.Item>
                            {customerName && <Descriptions.Item label="Cliente:">{customerName}</Descriptions.Item>}
                            {protocol && <Descriptions.Item label="Número do protocolo:">{protocol}</Descriptions.Item>}
                            {email && <Descriptions.Item label="Email do requerente:">{email}</Descriptions.Item>}
                            {motive && <Descriptions.Item label="Motivo da operação:" span={3}>
                                {motive}
                            </Descriptions.Item>}
                            {reserveNumbers.countIccids ? (
                                <>
                                    {modelName && <Descriptions.Item label="Modelo do Simcard:">
                                        {modelName}
                                    </Descriptions.Item>}
                                    <Descriptions.Item label="Iccids totais no arquivo:">
                                        {reserveNumbers.countIccids.total}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Iccids totais aprovados para o fluxo:">
                                        {reserveNumbers?.countIccids?.iccidsIn?.length}
                                    </Descriptions.Item>

                                </>
                            ) : ""}
                        </Descriptions>
                        {reserveNumbers.countIccids ? (
                            <>
                                <Collapse >
                                    <Panel header="Iccids inválidos" key="1">
                                        {/* <List
                            size="large"
                            // footer={<div>Footer</div>}
                            bordered
                            dataSource={countIccids.countIccids.iccidsOut}
                            renderItem={item => <List.Item style={{textAlign:"center"}}>{item.iccid}</List.Item>}/>
                            */}
                                        <Table dataSource={reserveNumbers.countIccids.iccidsOut} columns={columns} />;
                                    </Panel>
                                </Collapse>
                            </>

                        ) : ""}

                    </Card>
                    {/* </Animate> */}
                </>,
        },
    ];
    console.log(typeResource)

    return (
        <Card
            title={<h1 style={styles.title}>
                <i
                    style={styles.icon}
                    className="fa fa-industry"
                />
                Criar Operação em Massa com OS
            </h1>}
            extra={<div>

            </div>}
        >
            <div>
                <Card>
                    <Steps current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div style={styles.steps_content}>{steps[current].content}</div>
                    <div style={styles.steps_action}>

                        {current > 0 && (
                            <Button style={{ marginTop: 10, borderColor: "orange", color: "orange" }} icon={<RollbackOutlined style={{ fontSize: 18 }} />} onClick={() => prev()}>
                                Voltar
                            </Button>
                        )}
                        {current === 1 && fileInput && (
                            <Button style={{ marginTop: 10, marginLeft: 3 }} type="primary" onClick={() => {
                                next()
                                if (typeResource === "Reserva de Numeração") {
                                    verifyFile(fileInput)
                                }
                            }} icon={<RightOutlined style={{ fontSize: 18 }} />}>
                                Ocorrência
                            </Button>
                        )}
                        {current === 2 && isVerified && fileInput && (
                            <Button style={{ marginTop: 10, marginLeft: 3 }} type="primary" onClick={() => next()} icon={<RightOutlined style={{ fontSize: 18 }} />}>
                                Resumo
                            </Button>
                        )}
                        {current === 3 && isVerified && fileInput && (
                            <Button style={{ marginTop: 10, marginLeft: 3 }} type="primary" onClick={() => proccessOperation()} icon={<RightOutlined style={{ fontSize: 18 }} />}>
                                Processar
                            </Button>
                        )}

                    </div>
                </Card>
            </div>
        </Card>

    )
}
const styles = {
    steps_content: {
        minHeight: 200,
        marginTop: 20,
        padding: 10,
        textAlign: "center",
        backgroundColor: "#fafafa",
        border: "1px dashed #e9e9e9",
        borderRadius: 2,
    },
    steps_action: {
        marginTop: 24,
        textAlign: "right"

    },
    container: {
        borderRadius: "10px",
        background: "#fff",
        boxShadow:
            "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },

    icon: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 17,
        marginRight: 10,
        fontSize: 22,
        color: "#ffffff"
    },
    containerDrawer: {
        // background: "linear-gradient(80deg, #43A047, #FFEB3B)",

        //width: 30
    },
    title: {
        fontWeight: 900,
        // marginLeft: 20
    },

    btnCancel: {
        marginRight: 8
    },

};

export default OperationsMassive;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Tooltip, Select, Button, Row, Card, Col, Descriptions, Drawer, Input, Form,Switch } from "antd";


import { RollbackOutlined, RedoOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

import HeliosTable from "../../../../components/HeliosTable/HeliosTable";
import CardInfo from "../../../container/Dashborad/CardInfo";
import HeliosCardChartOperation from "../../../../components/HeliosCardChart/HeliosCardChartOperation";
import { getTransactionLines, getTransactionLogsSummarized, setRetryTransaction } from "../../../../store/actions/transactionLogsAction";


const { Option } = Select;
const TransactionLineLogs = () => {
  const history = useHistory();
  const { state } = history.location;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [linesSelectIn, setLinesSelectIn] = useState([]);
  const [linesSelectOut, setLinesSelectOut] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);


  const [listSelect, setListSelect] = useState([]);

  const dispatch = useDispatch();

  const [, setTypeStatus] = useState("");
  const [line, setLine] = useState("");

  const [showFilter, setShowFilter] = useState(false);
  const [resource, setResource] = useState("")
  const { logs, } = useSelector(state => state.logs);
  const { logsSummarized, lastUpdate } = useSelector(state => state.logsSummarized);

  let resourceInfo = null;
  if (state) {
    resourceInfo = state;
    console.log(resourceInfo)

  }

  useEffect(() => {
    dispatch(getTransactionLines(resourceInfo.transaction_id, page-1, pageSize));
    dispatch(getTransactionLogsSummarized(resourceInfo.transaction_id));
    let typeResource = resourceInfo.queue_type
    setResource(typeResource === "recicle" ? "Troca de simcard" : "")


    const interval = setInterval(() => {
      dispatch(getTransactionLines(resourceInfo.transaction_id, page-1, pageSize));
    }, 20000);
    const intervalSummarized = setInterval(() => {
      dispatch(getTransactionLogsSummarized(resourceInfo.transaction_id));
    }, 20000);
    return () => {
      clearInterval(interval)
      clearInterval(intervalSummarized)
    };
  }, [dispatch, resourceInfo, page, pageSize]);

  // console.log(logs)
  // console.log(logsSummarized)

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };
  function handleChange(value) {
    setTypeStatus(value)
  }
  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
  };

  const onShowFilter = () => {
    setShowFilter(true);
  };
  const onCloseFilter = () => {
    setShowFilter(false);
  };
  const onClearFilter = () => {
    setTypeStatus("");
    setLine("")
  };
  function onChangeSwitch(checked) {
    console.log(`switch to ${checked}`);
    setHasSelected(checked)

  }
  const onChangeInput = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (e.target.name === "msisdnIccid" && e.target.value.length <= 20) {
        setLine(e.target.value);
        if (e.target.value.length <= 20) {
          setLine(e.target.value);
        }
      }
    }
  };

  const subTitle = (
    <div>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => {
            onShowFilter();
          }}
        />
      </Tooltip>

    </div>
  );

  const columns = [

    {
      title: "Iccid",
      dataIndex: "iccid",
      render: (text, record) => (
        <>
          {record.iccid}
        </>
      )
    },
    {
      title: "Msisdn",
      dataIndex: "msisdn",
      render: (text, record) => (
        <>
          {record.msisdn}
        </>
      )
    },
    {
      title: "Status do Processo",
      dataIndex: "status",
      render: (text, record) => (
        <>
          <Tag color={record.status === 1 ? "blue" : record.status === 2 ? "red" : "green"}>
            {record.status === 1 ? "Em andamento" : record.status === 2 ? "Finalizado com erro" : "Finalizado com sucesso"}
          </Tag>
          {/* <Progress percent={((record.quantTotal-record.quantLines)/record.quantTotal*100).toFixed(2)} size="small" /> */}
        </>
      )

    },
    {
      title: "Início",
      dataIndex: "initial_date",
      render: (text, record) => (
        moment(text).format("DD/MM/YYYY HH:mm:ss")
      )
    },
    {
      title: "Concluído",
      dataIndex: "end_date",
      render: (text, record) => (
        moment(text).format("DD/MM/YYYY HH:mm:ss") ? text : "-"
      )
    },
    {
      title: "Subset",
      dataIndex: "subset_type",
      render: (text, record) => (
        <>
          <Tag color={record.subset_type === "in" ? "green" : "black"}>
            {record.subset_type === "in" ? "Entra" : "Sai"}
          </Tag>

        </>
      )
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, 'selectedRows: ', selectedRows);
      // setHasSelected(selectedRows.length > 1 && selectedRows.length % 2 == 0)
       setHasSelected(selectedRows.length > 1 && selectedRows.length % 2 === 0)
      setListSelect(selectedRows)
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.status === 3, // Column configuration not to be checked
    //   status: record.status,
    // }),
  };



  return (
    <div>

      {logs ? (
        <div>

          <Row gutter={16}>
            <Col lg={14} md={24} sm={24} xs={24}>
              <HeliosTable
                title="Detalhes da Transação"
                rowSelection={rowSelection}
                subTitle={subTitle}
                columns={columns}
                icon="fab fa-phabricator"
                dataSource={logs.data || []}
                rowKey="iccid"
                total={logs.total}
                pageSize={pageSize}
                page={page}
                onChange={handlePaginationChange}
                onShowSizeChange={onShowSizeChange}
              />
            </Col>
            <Col lg={10} md={24} sm={24} xs={24}>
              <Card
                title={<h1 style={styles.titleInfo}>
                  <i
                    style={styles.subTitleInfo}
                    className="fas fa-info-circle"
                  />
                  Informações
                </h1>}
                extra={<div></div>}
              >
                <Row gutter={16}>
                  <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10, width: "300px" }}>
                    <Card

                      title={
                        <Row justify="space-between" style={styles.containerTitle}>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <span>{resourceInfo.client}</span>
                          </Col>
                        </Row>
                      }
                      style={styles.container}
                    >
                      <Descriptions
                        bordered
                        size="small"
                        column={{ xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                      >
                        <Descriptions.Item label="Transação:">{resourceInfo.transaction_id}</Descriptions.Item>
                        <Descriptions.Item label="Recurso:">{resource}</Descriptions.Item>

                      </Descriptions>


                    </Card>


                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
                    <CardInfo
                      title="Status das Linhas"

                      lastUpdate={lastUpdate}

                    >

                      < HeliosCardChartOperation color={"green"} colors={["blue", "red", "green"]} dataChart={logsSummarized || []} />

                    </CardInfo>
                    <Row style={{ marginTop: 10}}>
                    <Tag color="gold">Selecionados </Tag><Switch  onChange={onChangeSwitch} /><Tag color="red">Com erro </Tag>

                    </Row>
                    <Button style={{ marginTop: 10, borderColor: "orange", color: "orange" }} icon={<RollbackOutlined style={{ fontSize: 18 }} />} onClick={() => {
                      history.push('/transaction-activities')
                    }} type="default">
                      Voltar
                    </Button>

                    <Button style={{ marginTop: 10, marginLeft: 5, borderColor: "blue"}} type="primary" ghost disabled={!hasSelected} icon={<RedoOutlined style={{ fontSize: 18 }} />} onClick={() => {
                      if(listSelect.length>0){
                        listSelect.forEach(element=>{
                          console.log("ListaCompleta: "+listSelect)
                          if(element.subset_type==='in'){
                            linesSelectIn.push(element.iccid)
                          }else{
                            linesSelectOut.push(element.iccid)
                          }
                        })
                        dispatch(setRetryTransaction(resourceInfo.transaction_id, linesSelectIn,linesSelectOut))
                      }else{
                        // dispatch(setRetryTransactionErrors(resourceInfo.transaction_id))
                        console.log("Reprocessando com erro")
                      }
                     
                      setLinesSelectIn([])
                      setLinesSelectOut([])
                      setHasSelected(false)
                    }} >
                      Reprocessar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>

          </Row>

        </div>) : ""}
      {/* ) : <SkeletonTable show={!loading} title="Transações das linhas" icon="fab fa-phabricator" pageSize={pageSize}/> */}

      <Drawer
        title="Filtros"
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        placement="right"
        onClose={onCloseFilter}
        visible={showFilter}
        width={500}
        style={styles.containerDrawer}
      >
        <Form layout="vertical">

          <Form.Item label="Status">
            <Select defaultValue="Todos" style={{ width: "100%" }} onChange={handleChange}>
              <Option value="Created">Em espera</Option>
              <Option value="Success">Sucesso</Option>
              <Option value="Error">Erro</Option>
              <Option value="Pending">Processando</Option>
              <Option value="">Todos</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Linha">
            <Input.Search
              autoComplete="off"
              name="msisdnIccid"
              allowClear
              onChange={event => {
                if (event.target.value === "") {
                  setLine("");
                }
                onChangeInput(event);
              }}
              value={line}
            />
          </Form.Item>
        </Form>
        <div style={styles.btnFilter}>
          <Button
            onClick={onClearFilter}
            type="danger"
            ghost
            style={styles.btnCancel}
          >
            LIMPAR
          </Button>
          <Button onClick={onCloseFilter} type="default">
            FECHAR
          </Button>
        </div>
      </Drawer>


    </div>
  );
};

const styles = {
  subTitle: {
    marginRight: 5,
    fontSize: 18,
    cursor: "pointer"
  },
  btnFilter: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },
  icon: {
    marginRight: 5,
    fontSize: 18,
  },
  container: {
    borderRadius: "10px",
    background: "#fff",
    boxShadow:
      "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  },
  containerTitle: {
    color: "gray",
    fontWeight: "bold"
  },
  titleInfo: {
    fontWeight: 900,
    // marginLeft: 20
  },

  subTitleInfo: {
    background: "linear-gradient(80deg, #43A047, #FFEB3B)",
    borderRadius: 10,
    padding: 17,
    marginRight: 10,
    fontSize: 22,
    color: "#ffffff"
    // cursor: "pointer"
  }
};

export default TransactionLineLogs;

import React, { useEffect, useCallback } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "./views/Login/Login";
import ForgotPassword from "./views/Login/ForgotPassword";

import LayoutOperation from "./layouts/Operation/LayoutOperation";
import LayoutCustomer from "./layouts/Customer/LayoutCustomer";

import jwtDecode from "jwt-decode";
import setAuthToken from "./setAuthToken";
import { logoutUser, refreshToken } from "./store/actions/authAction";

const RouteRedirect = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(state => state.auth.user.data);

  const getUserType = useCallback(() => {
    if (localStorage.jwtToken) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwtDecode(localStorage.jwtToken);
      // Check for expired token
      const currentTime = Date.now() / 1000;

      if (currentTime < decoded.exp) {
        // Refresh Token
        dispatch(refreshToken());
      }
       else {
        dispatch(logoutUser(decoded));

      }
    }

  }, [dispatch]);

  useEffect(() => {
    getUserType();
    let jwtToken = localStorage.getItem("jwtToken")
    if (!jwtToken) {
      history.push("/login");
    }
  }, [getUserType, history]);

  let LayoutRedirect = () => {
    if (user) {
      if (user.user_type === 1) {
        return (
          <Switch>
            <Route path="/" component={LayoutOperation} />
          </Switch>
        );
      }
      if (user.user_type === 2) {
        return (
          <Switch>
            <Route path="/" component={LayoutCustomer} />
          </Switch>
        );
      }
    }
    return (
      <div>

        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path={"/login"} component={Login} />

        {/* <Switch>
          <Route path="/forgot-password" component={ForgotPassword} />
        </Switch> */}
      </div>
    );
  };

  return <LayoutRedirect />;
};

export default RouteRedirect;

import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";

export const getLinesPaginated = (
  customerId,
  iccid,
  msisdn,
  statusId,
  size,
  page
) => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_START));
  axios
    .get(
      API_ROOT +
      `lines/status?customerId=${customerId}&iccid=${iccid}&msisdn=${msisdn}&statusId=${statusId}&size=${size}&page=${page}`
    )
    .then(res => {
      dispatch(fetchSuccess(actionsType.GET_LINES_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_FAIL, error));
    });
};
export const getLinesLicense = (
  customerId,
  plan_id,
  size,
  page
) => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_START));
  axios
    .get(
      API_ROOT +
      `lines/status/license?customerId=${customerId}&plan_id=${plan_id}&size=${size}&page=${page}`
    )
    .then(res => {
      dispatch(fetchSuccess(actionsType.GET_LINES_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_FAIL, error));
    });
};

export const getStatusLinesSummarized = (customerId) => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_START));
  axios
    .get(API_ROOT + `lines/status/summarized?customerId=${customerId}`)
    .then(res => {
      let lines;
      if (res.data === "") {
        lines = {
          "blocked": 0,
          "not_connected": 0,
          "offline": 0,
          "online": 0,
          "suspended": 0,
          "total_lines": 0
        }
      } else {
        lines = res.data
      }

      dispatch(fetchSuccess(actionsType.GET_STATUS_LINES_SUMMARIZED_SUCCESS, lines));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_FAIL, error));
    });
};

export const getStatusLinesSummarizedNLT = () => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_START_NLT));
  axios
    .get(API_ROOT + `lines/status/summarized/nlt`)
    .then(res => {
      dispatch(fetchSuccess(actionsType.GET_STATUS_LINES_SUMMARIZED_SUCCESS_NLT, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_FAIL_NLT, error));
    });
};


export const getOperationsLinesSummarized = () => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_START));
  axios
    .get(API_ROOT + `lines/operations/quantity`)
    .then(res => {
      dispatch(fetchSuccess(actionsType.GET_STATUS_OPERATION_SUMMARIZED_SUCCESS, res.data));

    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_FAIL, error));
    });
};

export const getOperationsStockLinesSummarized = () => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_STOCK_START));
  axios
    // .get( `https://93d81f47-5fea-45a7-b384-5dd3e7f3734e.mock.pstmn.io/api/lines/operations/quantity`)
    .get(API_ROOT + `lines/operations/quantity`)
    .then(res => {
      dispatch(fetchSuccess(actionsType.GET_STATUS_OPERATION_STOCK_SUMMARIZED_SUCCESS, res.data));

    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_STOCK_FAIL, error));
    });
};
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Cell
} from "recharts";

const HeliosCardChartOperation = props => {
  // let colors = ["blue","gray","red","green"]
  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          barGap={10}
          barCategoryGap={window.innerWidth <= 1500 ? 25 : 35}
          data={props.dataChart}
          margin={{
            top: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="status" tick={{ fontSize: 12 }} />
          <YAxis />
          <Tooltip />
          <Legend payload={props.dataChart.map(
            (item,index) => ({
              value: `${item.status}`,
              color: `${props.colors[index]}`
            })
          )
          } wrapperStyle={{ textTransform: "capitalize" }} />
          <Bar
            dataKey="total"
            // fill={props.color}
            minPointSize={2}
            label={{ position: "top" }}
          >
             {
            props.dataChart.map((entry, index) => (
            <Cell key={index} fill={props.colors[index]}  />
            ))
    }
          </Bar>
            

        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HeliosCardChartOperation;

export const fetchStart = type => {
    return {
        type: type
    };
};

export const fetchError = (type, errors) => {
    return {
        type: type,
        errors: errors
    };
};

export const fetchSuccess = (type, payload) => {
    return {
        type: type,
        payload: payload
    };
};

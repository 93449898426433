const isEmpty = value => {
    return value === "";
};


export const queryReportConsumptions = (
    format,
    page,
    limit,
    customerId,
    period,
    isShared
) => {
    let customer = `${customerId !== "" ? "customerId:" + customerId : ""}`;
    let params = "";
    if (!isEmpty(customer.trim()) && isShared !== null) {
        params = `,${customer}, shared:${isShared}`;
    } else if (isShared !== null) {
        params = `, shared:${isShared}`;
    }

    return `{
    allConsumptions${
        format === "" ? "(page:" + page + ", limit:" + limit + ")" : ""
        } {
      pagination(period:${'"' + period + '"' + params}) {
        page
        limit
        total
      }
      consumptions(period:${'"' + period + '"' + params}){
        customerName
        lineMsisdn
        simIccid
        planName
        planAggregate
        lineDownloadF
        lineUploadF
        lineTotalF
        itemDownloadF
        itemUploadF
        itemTotalF
      }
    }
  }`;
};
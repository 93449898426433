import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import setAuthToken from "./setAuthToken";
//import { setCurrentUser, refreshToken } from "./store/actions/authAction";
import axios from "axios";

//import store from "./store";
import { API_ROOT } from "./config/api-config";

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const {
      config,
      response: { status }
    } = error;
    const originalRequest = config;

    if (status === 401) {
      let history = localStorage.history
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        let refresh = { refresh_token: localStorage.refreshToken };
        axios
          .post(API_ROOT + "auth/refresh_token", refresh)
          .then(res => {
            isAlreadyFetchingAccessToken = false;
            const token = res.data.token;
            // const history = res.data.user_id
            const refreshToken = res.data.refreshToken;
            localStorage.setItem("jwtToken", token);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("history", history);
            setAuthToken(token);
            onAccessTokenFetched(token);
          })
          .catch(err => {
            console.log("ERROR refreshToken: ", err);
            // localStorage.removeItem("jwtToken");
            // localStorage.removeItem("refreshToken");
            // localStorage.removeItem("userType");
            // localStorage.removeItem("roles");
            // localStorage.removeItem("permissions");
            // // Remove auth header for future requests
            // setAuthToken(false);
            // window.location = "/login";
          });
      }

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(access_token => {
          originalRequest.headers.Authorization = "Bearer " + access_token;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    // if (status === 403) {
    //   localStorage.removeItem("jwtToken");
    //   localStorage.removeItem("refreshToken");
    //   localStorage.removeItem("userType");
    //   localStorage.removeItem("roles");
    //   localStorage.removeItem("permissions");
    //   // Remove auth header for future requests
    //   setAuthToken(false);
    // }
    return Promise.reject(error);
  }
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import { CSVLink } from "react-csv";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Form,
  InputNumber,
  Progress,
  Select,
  Tag,
  Tooltip,
  notification
} from 'antd';

// Componentes internos
import HeliosTable from "../../../../components/HeliosTable/HeliosTable";

// Utils & Services & Helpers
import { useAsyncFunc } from '../../../../hooks';
import { getOrderServiceProcessing } from '../../../../services/resourceLogs';
import { exportOrderServiceReport } from '../../../../services/helios/orderServices';
import {
  getOrderServiceReportHeaders,
  getOrderServiceType,
  getOrderServicesReportName,
  massiveOrdersTypes
} from '../../../../helpers/get-reports-config';

// #startregion [Styles]
const styles = {
  subTitle: {
    marginRight: 10,
    fontSize: 18,
    cursor: "pointer"
  },
  btnFilter: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    borderTop: "1px solid #e9e9e9",
    padding: "10px 16px",
    background: "#fff",
    textAlign: "right"
  },
  icon: {
    marginRight: 5,
    fontSize: 18,
  },
  profile: {
    marginRight: 10,
    fontStyle: "italic"
  }
};

const orderServicesOptions = [
  {
    id: 'on_demand_os',
    label: 'Estoque sob demanda',
  },
  {
    id: 'advanced_stock',
    label: 'Estoque avançado'
  },
  {
    id: 'active_stock',
    label: 'Estoque ativado',
  }
];

const orderOptions = {
  on_demand_os: ['on_demand_os'],
  advanced_stock: ['stock_os_with_msisdn', 'stock_os_with_booking_numbering'],
  active_stock: ['active_stock_os_with_booking_numbering']
}
// #endregion [Styles]

// #startregion [PRIVATE COMPONENTS]
const DownloadReportButton = ({ rowData }) => {
  // Hooks
  const [exportDataState, exportDataRequest] = useAsyncFunc(exportOrderServiceReport);
  const [data, setData] = useState([]);

  // Variáveis auxiliares
  const {
    _id: resourceId,
    typeResource = '',
    operationCategory,
    rootOs,
    customerId
  } = rowData;

  const fileHeaders = getOrderServiceReportHeaders(typeResource);
  const reportSubType = getOrderServiceType(typeResource);
  const reportName = getOrderServicesReportName(typeResource);

  // Funções
  const downloadOperationReport = async () => {
    await exportDataRequest({
      rootOs,
      reportType: operationCategory,
      reportSubType,
      customerId
    });
  };

  // Efeitos
  useEffect(() => {
    setData(exportDataState.data?.data);

    if (exportDataState.error) {
      notification.error({
        key: 'erroOnDownload',
        message: 'Erro no download',
        duration: 20,
        placement: 'bottomRight'
      });
    }
  }, [exportDataState]);

  return (
    <>
      {data?.length > 0 ?
        (
          <CSVLink
            key={resourceId}
            headers={fileHeaders}
            data={data}
            filename={`relatorio_${reportName}_${Date.now()}.csv`}
            separator=";"
          >
            Baixar relatório completo
          </CSVLink>
        ) :
        (
          <Tooltip placement="top" title="Baixar relatório completo">
            <Button
              key={resourceId}
              icon={<DownloadOutlined style={{ fontSize: 18 }} />}
              style={{ borderColor: "orange", color: "orange", marginLeft: 3, marginBottom: 5 }}
              type="default"
              size="small"
              loading={exportDataState.loading}
              onClick={downloadOperationReport}
            />
          </Tooltip>
        )}
    </>
  );
};

DownloadReportButton.prototype = {
  rowData: PropTypes.object
};

const Filters = ({
  onClose,
  showFilter,
  requestData,
  queryParams,
  setLimit,
  setPage,
  setQueryParams,
  ...otherProps
}) => {
  const [filters] = Form.useForm();
  const { Option } = Select;
  const [resourceType, setRourceType] = useState([]);

  // Handlers
  const handleApplyFilters = ({ rootOs, osId }) => {
    const params = {
      ...queryParams,
      ...(rootOs && { rootOs: [rootOs] }),
      ...(resourceType.length && { resourceType: [...resourceType] }),
      ...(osId && { osId: [osId] }),
      offset: 0
    };

    requestData(params);
    setQueryParams(params);
  };

  const onClearFilters = () => {
    filters.resetFields();
    setRourceType([
      'on_demand_os',
      'stock_os_with_booking_numbering',
      'active_stock_os_with_booking_numbering',
      'stock_os_with_msisdn',
    ]);

    const resetedParams = {
      ...queryParams,
      resourceType: [
        'on_demand_os',
        'stock_os_with_booking_numbering',
        'active_stock_os_with_booking_numbering',
        'stock_os_with_msisdn',
      ],
      rootOs: [],
      osId: [],
      limit: 5,
      offset: 0
    };

    setQueryParams(resetedParams);
    requestData(resetedParams);

    setLimit(5);
    setPage(1);
  };

  return (
    <>
      <Drawer
        title="Filtros"
        onClose={onClose}
        visible={showFilter}
        width={500}
        headerStyle={{ background: "linear-gradient(80deg, #B3FB9B, #43A047)", height: 65 }}
        style={styles.containerDrawer}
        {...otherProps}
      >
        <Form
          form={filters}
          onFinish={handleApplyFilters}
          layout='vertical'
        >
          {/* Seleção do tipo da OS */}
          <Form.Item label="Tipo da OS" name="resourceType">
            <Select style={{ width: '100%' }} onChange={(value) => setRourceType(orderOptions[value])}>
              {orderServicesOptions.map(({ id, label }, index) => (
                <Option key={index} value={id}>{label}</Option>
              ))}
            </Select>
          </Form.Item>

          {/* Seleção da OS Pai do processo */}
          <Form.Item label="Os Pai" name="rootOs">
            <InputNumber style={{ width: '100%' }} min={1} />
          </Form.Item>

          {/* Seleção de OS gerada pelo processo */}
          <Form.Item label="Os gerada" name="osId">
            <InputNumber style={{ width: '100%' }} min={1} />
          </Form.Item>

          <Form.Item style={styles.btnFilter}>
            {/* Botão para limpar os filtros */}
            <Button onClick={onClearFilters} type="default">
              LIMPAR
            </Button>

            {/* Botão para filtrar */}
            <Button type="primary" htmlType="submit" ghost style={styles.btnCancel}>
              FILTRAR
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

Filters.prototype = {
  onClose: PropTypes.func,
  showFilter: PropTypes.bool,
  requestData: PropTypes.func,
  queryParams: PropTypes.object,
  setLimit: PropTypes.func,
  setPage: PropTypes.func,
  setQueryParams: PropTypes.func
};

// #endregion [PRIVATE COMPONENTS]

const OrderServiceProcessing = ({ customerName, customerId }) => {
  // Hooks
  const [orderServiceState, orderServiceRequest] = useAsyncFunc(getOrderServiceProcessing);

  // Variaveis de estado
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [queryParams, setQueryParams] = useState({
    customerId, limit: 5,
    resourceType:
      [
        'on_demand_os',
        'stock_os_with_booking_numbering',
        'active_stock_os_with_booking_numbering',
        'stock_os_with_msisdn'
      ]
  });

  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  // Constantes
  const columns = [
    {
      title: "Lote",
      dataIndex: "lote"
    },
    {
      title: "OS gerada",
      dataIndex: "osId"
    },
    {
      title: "OS Pai",
      dataIndex: "rootOs"
    },
    {
      title: "Total de Simcards",
      dataIndex: "quantTotal"
    },
    {
      title: "Status do Processo",
      render: (rowData) => {
        // fixme: Adicionar a lógica da cor de acordo com progresso
        const colorPresets = {
          Concluded: 'green',
          Pending: 'blue',
          Failure: 'orange'
        };

        const statusTypes = {
          Failure: 'Erro',
          Concluded: 'Concluído',
          Pending: 'Em processamento'
        };

        const progress = ((rowData?.quantTotal - rowData?.quantLines) / rowData?.quantTotal * 100).toFixed(2);
        return (
          <>
            {/* Fixme */}
            <Tag color={colorPresets[rowData?.statusProgress]}>
              {statusTypes[rowData?.statusProgress]}
            </Tag>
            <Progress percent={progress} size="small" />
          </>
        )
      }

    },
    {
      title: "Recurso",
      dataIndex: "typeResource",
      render: (text) => (<>{massiveOrdersTypes(text)}</>)
    },
    {
      title: "Data e Hora",
      dataIndex: "created_at",
      render: (text) => moment(text).format("DD/MM/YYYY HH:mm:ss")
    },
    {
      // fixme: ajustar tamanho dessa coluna
      title: "Arquivo",
      dataIndex: "nameFile"
    },
    {
      title: "Ações",
      render: (rowData) => {

        return (
          <DownloadReportButton rowData={rowData} key={rowData?._id} />
        );
      }
    }
  ];

  // Handlers
  const handleRefreshData = () => {
    orderServiceRequest(queryParams);
  };

  const renderDataTableSubTitle = (
    <>
      <Tooltip placement="bottom" title="Filtros">
        <i
          style={styles.subTitle}
          className="fas fa-filter"
          onClick={() => setShowFilter(true)}
        />
      </Tooltip>

      {/* Refresh Button */}
      <Tooltip placement="bottom" title="Atualizar">
        <ReloadOutlined style={{ fontSize: 20 }} onClick={handleRefreshData} />
      </Tooltip>
    </>
  );

  // Efeitos
  useEffect(() => {
    const offset = (page - 1) * limit;
    orderServiceRequest({ customerId, offset, limit, ...queryParams });
  }, [customerId, limit, page]);

  // Atualiza dados da tabela
  useEffect(() => {
    setData(orderServiceState.data?.data);
    setTotal(orderServiceState.data?.total);
  }, [orderServiceState]);

  console.log({ queryParams });

  return (
    <>
      <Card>
        <HeliosTable
          title={`Processamento de pedido - ${customerName}`}
          subTitle={renderDataTableSubTitle}
          columns={columns}
          icon="fa fa-industry"
          dataSource={data}
          rowKey="_id"
          total={total}
          pageSize={limit}
          page={page}
          // loading={orderServiceState.loading}
          onChange={(value) => {
            setPage(value);
            const offset = (value - 1) * limit;
            setQueryParams({ ...queryParams, offset });
          }}
          onShowSizeChange={(value) => {
            setLimit(value);
            setQueryParams({ ...queryParams, limit: value });
          }}
        />
      </Card>

      <Filters
        showFilter={showFilter}
        dataTable={data}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setLimit={setLimit}
        setPage={setPage}
        requestData={orderServiceRequest}
        onClose={() => setShowFilter(false)} />
    </>
  );
};

OrderServiceProcessing.propTypes = {
  customerId: PropTypes.number,
  customerName: PropTypes.string
};

export default OrderServiceProcessing;

import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";


export const getLinesSession = (
  msisdn,
  server,
  page,
  size
) => dispatch => {
  dispatch(fetchStart(actionsType.GET_LINES_SESSION_START));
  axios
    .get(
      API_ROOT +
      `lines/sessions?servidor=${server}&msisdn=${msisdn}&size=${size}&page=${page}`
    )
    .then(res => {
      console.log(res.data.lines)
      dispatch(fetchSuccess(actionsType.GET_LINES_SESSION_SUCCESS, res.data));
    })
    .catch(error => {
      dispatch(fetchError(actionsType.GET_LINES_SESSION_FAIL, error));
    });
};


import heliosApi from '../heliosApi';

export const getOrderServiceProcessing = async ({
    customerId, 
    resourceType = [], 
    rootOs = [],
    osId = [],
    limit, 
    offset
  }) => {
  const body = {
    customerId,
    resourceType,
    rootOs,
    osId,
    limit,
    offset
  };

  const response = await heliosApi.post('/resource-logs/order-services', body);
  return response;
};
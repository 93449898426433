
import React from "react";
import {
  Button, Modal
} from "antd";
import moment from "moment";

import HeliosTableAtr from "../../../components/HeliosTable/HeliosTableAtr";

import { EyeOutlined } from "@ant-design/icons";
const { info } = Modal;
export const columns = [
  {
    title: "Tipo do Recurso",
    dataIndex: "typeResource"
  },
  {
    title: "Data e hora",
    dataIndex: "created_at",
    render: (text, record) => (
      moment(text).format("DD/MM/YYYY HH:mm:ss")
    )
  },
  // {
  //   title: "Response",
  //   dataIndex: "response"
  // },
  // {
  //   title: "Request",
  //   dataIndex: "request"
  // },
  {
    title: "Email do Responsável",
    dataIndex: "email"
  },
  {
    title: "Resultado",
    key: "action",
    render: (text, record) => (
      <Button type="primary" onClick={() => {

        let columnsResource = []

        let title = Object.keys(record.response[0])
        for (let i = 0; i < title.length; i++) {
          columnsResource.push({ title: title[i], dataIndex: title[i], width: 200 })
        }

        info({
          width: 800,
          title: "Recurso " + record.typeResource,
          content: <HeliosTableAtr
            width="100%"
            title=""
            columns={columnsResource}
            dataSource={record.response || []}
            rowKey={title[0]}
          />,
          okText: "Fechar",
          onOk() {
          }
        })
      }}><EyeOutlined style={{ fontSize: 15 }} /></Button>
    )
  }
];
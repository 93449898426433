
import * as actionType from "../types";
import moment from "moment";

const initialState = {
    stock: null,
    lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
    errors: null,
    message: null,
    loading: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case actionType.STOCK_NUMBER_START:
            return { ...state, loading: true };
        case actionType.STOCK_NUMBER_SUCCESS:
            return { ...state, stock: action.payload, errors: null, loading: false };
        case actionType.STOCK_NUMBER_ERROR:
            return { ...state, errors: action.errors, loading: false };
        case actionType.STOCK_NUMBER_CLEAR:
            return initialState;

        default:
            return state;
    }
}

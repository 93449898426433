import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RollbackOutlined, RightOutlined, LoadingOutlined } from "@ant-design/icons";
import {
    Card,
    Steps,
    Button,
    Descriptions,
    Collapse,
    Table,
    notification
} from "antd";
import axios from 'axios';

// Componentes
import CardsStockOS from "./CardsStockOS";
import UploadOperationStockOs from "./UploadStockOs";
import FormOperationStockOs from "./FormStockOs";

// Helps | Utils | Services
import { getManyIccidFile } from "../../../store/actions/reserveNumberDemandAction";
import { API_ROOT } from '../../../config/api-config';

const styles = {
    steps_content: {
        minHeight: 200,
        marginTop: 20,
        padding: 10,
        textAlign: "center",
        backgroundColor: "#fafafa",
        border: "1px dashed #e9e9e9",
        borderRadius: 2
    },
    steps_action: {
        marginTop: 24,
        textAlign: "right"
    },
    container: {
        borderRadius: "10px",
        background: "#fff",
        boxShadow:
            "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },
    icon: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 17,
        marginRight: 10,
        fontSize: 22,
        color: "#ffffff"
    },
    title: { fontWeight: 900 },
    btnCancel: { marginRight: 8 }
};

const CreateStockOS = (props) => {
    const { Step } = Steps;
    const { Panel } = Collapse;

    // Hooks
    const dispatch = useDispatch();
    const history = useHistory();

    // Redux
    const reserveNumbers = useSelector(state => state.reserveNumbers);
    const auth = useSelector(state => state.auth);

    // Variáveis locais de estado
    const [protocol, setProtocol] = useState("");
    const [motive, setMotive] = useState("");
    const [fileName, setFileName] = useState("");
    const [current, setCurrent] = useState(0);
    const [typeResource, setTypeResource] = useState("");
    const [fileInput, setFileInput] = useState();
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [customerName, setCustomerName] = useState();
    const [associatedLines,setAssociatedLines] = useState("")
    const [planName,setPlanName] = useState("")
    const [sellingOrderId,setSellingOrderId] = useState("")   
    const [requesterId, setRequesterId] = useState("");

    const iccidList = reserveNumbers?.countIccids ? 
        reserveNumbers?.countIccids?.accepted_iccids?.map(({iccid}) => iccid) : reserveNumbers;

    const verifyFile = (fileInput) => {
        setFileName(fileInput.name)
        dispatch(getManyIccidFile({ file: fileInput, mode: 'stock'}));
    };

    const proccessOperation = async () => {
        const generation = typeResource === "Criar OS Estoque 2G/3G" ? "3G" : "4G";
        
        notification.open({
            key: 'requestStockOS',
            message: 'Solicitando criação de Ordem de Estoque',
            icon: <LoadingOutlined />,
            placement: 'bottomRight'
        });

        const body = {
            stockType: 'advanced',
            protocol,
            motivation: motive,
            customerName,
            fileName,
            sellingOrderId,
            requesterId,
            clientEmail: email,
            generation,
            iccidList
        };

        await axios.post(`${API_ROOT}orders/stock`, body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}` 
            }
          })
        .then(() => { 
            notification.close('requestStockOS');
            notification.success({
                message: "Ordem de estoque criada!",
                description: 'Ordem de estoque passada para a próxima fase!',
                duration: 10,
                placement: 'bottomRight'
            });
        })
        .catch((error) => {
            const errorDescription = (
                error?.response?.data?.error?.message || 
                'Caso o problema persista, entre em contato com o departamento de Engenharia!'
            ) ;

            notification.close('requestStockOS');
            notification.error({
                message: "Erro no pedido!",
                description: errorDescription,
                duration: 30,
                placement: 'bottomRight',
            });
        });

        // Redireciona para página anterior
        history.goBack();
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
        setFileInput();
    };

    const columns = [
        {
            title: "ICCID",
            dataIndex: "iccid",
            key: "iccid",
            render: text => <b>{text}</b>
        },
        {
            title: "FASE",
            dataIndex: "nome",
            key: "nome",
            render: text => <b>{text}</b>
        }
    ];

    const steps = [
        {
            title: "Operação",
            content: <CardsStockOS onChange={next} onType={setTypeResource} />
        },
        {
            title: "Upload",
            content: <UploadOperationStockOs setFileInput={setFileInput} />
        },
        {
            title: "Ocorrência",
            content: (
                <FormOperationStockOs
                    setProtocol={setProtocol}
                    setMotive={setMotive}
                    setEmail={setEmail}
                    setPlanName={setPlanName}
                    setAssociatedLines={setAssociatedLines}
                    setDate={setDate}
                    setCustomerName={setCustomerName}
                />
            )
        },
        {
            title: "Resumo",
            content: (
                <>
                    <Card styles={styles.container}>
                        <Descriptions
                            title="Resumo da Operação"
                            bordered
                            layout="vertical"
                        >
                            <Descriptions.Item label="Nome do Cliente:">
                                {customerName}
                            </Descriptions.Item>

                            <Descriptions.Item label="Recurso solicitado:" span={3}>
                                {typeResource}
                            </Descriptions.Item>

                            {protocol && (
                                <Descriptions.Item label="Número do protocolo:">
                                    {protocol}
                                </Descriptions.Item>
                            )}

                            {email && (
                                <Descriptions.Item label="Email do requerente:">
                                    {email}
                                </Descriptions.Item>
                            )}

                            {sellingOrderId && (
                                <Descriptions.Item label="Ordem de serviço:">
                                    {sellingOrderId}
                                </Descriptions.Item>
                            )}

                            {planName && (
                                <Descriptions.Item label="Plano:">
                                    {planName}
                                </Descriptions.Item>
                            )}

                            {associatedLines && (
                                <Descriptions.Item label="Linhas associadas:">
                                    {associatedLines}
                                </Descriptions.Item>
                            )}
                            
                            {motive && (
                                <Descriptions.Item label="Motivo da operação:" span={3}>
                                    {motive}
                                </Descriptions.Item>
                            )}
                            
                            {date && (
                                <Descriptions.Item label="Data de Início:">
                                    {date}
                                </Descriptions.Item>
                            )}

                            {reserveNumbers?.countIccids && (
                                <>
                                    <Descriptions.Item label="Iccids totais aprovados para o fluxo:">
                                        {reserveNumbers?.countIccids?.total_accepted}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Iccids totais reprovados para o fluxo:">
                                        {reserveNumbers?.countIccids?.total_rejected}
                                    </Descriptions.Item>
                                </>
                            )}
                        </Descriptions>
                        
                        {reserveNumbers?.countIccids && (
                            <>
                                <Collapse >
                                    <Panel header="Iccids inválidos" key="1">
                                        <Table dataSource={reserveNumbers?.countIccids?.rejected_iccids} columns={columns} />;
                                    </Panel>
                                </Collapse>
                            </>
                        )}
                    </Card>
                </>
            )
        }
    ];

    // Efeitos
    useEffect(() => {
        setPlanName(history.location.state.plan__name)
        setAssociatedLines(history.location.state.associeated_lines)
        setDate(history.location.state.inception)
        setCustomerName(history.location.state.customer__name)
        setSellingOrderId(history.location.state.order_id);
    }, [
        history.location.state.order_id,
        history.location.state.plan__name,
        history.location.state.associeated_lines,
        history.location.state.inception,
        history.location.state.customer__name
    ]);

    // Setando o usuário logado que está fazendo a requisição (Requester)
    useEffect(() => {
        setRequesterId(auth.user.data.id);
    }, [auth.user.data.id]);

    return (
        <Card
            title={
                <h1 style={styles.title}>
                    <i style={styles.icon} className="fa fa-industry" />
                    Criar Ordem de Serviço de Estoque Avançado a partir de OS de venda
                </h1>
            }
            extra={<div />}
        >
            <div>
                <Card>
                    <Steps current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div style={styles.steps_content}>
                        {steps[current].content}
                    </div>
                    <div style={styles.steps_action}>
                        {current > 0 && (
                            <Button
                                style={{
                                    marginTop: 10,
                                    borderColor: "orange",
                                    color: "orange"
                                }}
                                icon={<RollbackOutlined style={{ fontSize: 18 }} />}
                                onClick={() => prev()}
                            >
                                Voltar
                            </Button>
                        )}

                        {current === 1 && fileInput && (
                            <Button
                                type="primary"
                                style={{ marginTop: 10, marginLeft: 3 }}
                                icon={<RightOutlined style={{ fontSize: 18 }} />}
                                onClick={() => {
                                    next();
                                    verifyFile(fileInput);
                                }}
                            >
                                Ocorrência
                            </Button>
                        )}

                        {current === 2 && fileInput && (
                            <Button
                                style={{ marginTop: 10, marginLeft: 3 }}
                                type="primary"
                                onClick={() => next()}
                                icon={<RightOutlined style={{ fontSize: 18 }} />}
                            >
                                Resumo
                            </Button>
                        )}

                        {current === 3 && fileInput && (
                            <Button
                                style={{ marginTop: 10, marginLeft: 3 }}
                                type="primary"
                                onClick={proccessOperation}
                                icon={<RightOutlined style={{ fontSize: 18 }} />}
                            >
                                Processar
                            </Button>
                        )}
                    </div>
                </Card>
            </div>
        </Card>
    );
};

export default CreateStockOS;

import * as actionType from "../types";
import moment from "moment";

const initialState = {
linesStockStatus: null,
  statusLineStockSummarized: null,
  statusOperationStockSummarized:null,
  lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
  errors: null,
  message: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.GET_LINES_STOCK_START:
      return {
        ...state,
        loading: true
      };
    case actionType.GET_LINES_STOCK_SUCCESS:
      return {
        ...state,
        linesStockStatus: action.payload,
        errors: null,
        loading: false
      };
    case actionType.GET_STATUS_LINES_STOCK_SUMMARIZED_SUCCESS:
      return {
        ...state,
        statusLineStockSummarized: action.payload,
        lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
        errors: null,
        loading: false
      };
    
    case actionType.GET_STATUS_OPERATION_STOCK_SUMMARIZED_SUCCESS:
      return {
        ...state,
        statusOperationStockSummarized: action.payload,
        lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
        errors: null,
        loading: false
      };
    case actionType.GET_LINES_STOCK_FAIL:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };
    
    default:
      return state;
  }
}

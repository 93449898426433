import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getCustomerByName } from "../../../store/actions/customerAction";
import { Map, TileLayer, Marker, Circle, ZoomControl,Popup } from 'react-leaflet'
import { divIcon } from 'leaflet';
import Backdrop from "../../../components/Backdrop/Backdrop";

import { renderToStaticMarkup } from 'react-dom/server';
import Control from "react-leaflet-control";
import FullscreenControl from 'react-leaflet-fullscreen';
import { BulbFilled, SearchOutlined, MobileOutlined, UploadOutlined } from '@ant-design/icons';
import { getLocationMsisdn, getManyLocationMsisdn } from "../../../store/actions/geolocationMobileAction"
import { getAddress } from "../../../services/Maps/AdressStreetMap"
import Loading from "../../../components/Loading/Loading";

import 'react-leaflet-fullscreen/dist/styles.css'
import {
    Input,
    Upload,
    Card,
    Switch,
    Button,
    Table,
    Col,
    Row,
    message

} from "antd";


const GeolocationMobile = props => {

    const [locationLine, setLocationLine] = useState();
    const [lat, setLat] = useState(-15.83);
    const [long, setLong] = useState(-47.86);
    const [url, setUrl] = useState("https://api.mapbox.com/styles/v1/nicknyr/cje7mtk2y6gf92snsydobiahf/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoibmlja255ciIsImEiOiJjajduNGptZWQxZml2MndvNjk4eGtwbDRkIn0.L0aWwfHlFJVGa-WOj7EHaA")
    const [zoom, setZoom] = useState(4);


    const [fileInput, setFileInput] = useState()

    const iconMarkup = renderToStaticMarkup(<i style={{ fontSize: 18, color: "green" }} className=" fas fa-sim-card fa-border" />);
    const customMarkerIcon = divIcon({
        html: iconMarkup,
    });
    // const [optionsValue, setOptionsValue] = useState("");
    const dispatch = useDispatch();

    const columns = [
        {
            title: "MSISDN's",
            dataIndex: "msisdn",
            width: 140,

        },
        {
            title: "Rede",
            dataIndex: "network",
            width: 70,

        },
        {
            title: "Status",
            dataIndex: "status",
            width: 90,

        },
        {
            title: <Button onClick={() => setZoom(4)}><i style={{ fontSize: 12 }} className="fas fa-map-marker-alt" /> Geral</Button>,
            key: 'action',
            render: (text, record) => (
                record.status === "Ok" ? <Button type="primary" onClick={() => getLocation(record)}><i style={{ fontSize: 12 }} className="fas fa-map-marker-alt" /></Button> : ""
            ),
        },
    ]
    const getLocation = (record) => {
        console.log(record)
        setLocationLine()
        setLat(parseFloat(record.LATITUDE))
        setLong(parseFloat(record.LONGITUDE))
        setZoom(13)
        setTimeout(() => {
            getAddress(parseFloat(record.LATITUDE), parseFloat(record.LONGITUDE)).then(response => {
                console.log(response.data)
                return setLocationLine(response.data.address)
            }).catch(error => {
                console.log(error)
                message.error("Não foi possível obter informações da Localização")
            })
        }, 2000);

    }

    // if ('geolocation' in navigator) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         setLat(position.coords.latitude)
    //         setLong(position.coords.longitude)
    //         if (!locationBool) {
    //             getAddress(parseFloat(lat), parseFloat(long)).then(response => {
    //                 setLocationBool(true)

    //                 return setLocationUser(response.data.address)
    //             }).catch(error => {
    //                 console.log(error)
    //                 message.error("Não foi possível obter informações da Localização")
    //                 setLocationBool(true)
    //             })
    //         }

    //     }, error => {
    //         setLocationBool(false)

    //         console.log(error)
    //     })
    // } else {
    //     alert('Navegador não possui navegação')
    // }

    const toogleMap = (change) => {

        if (change === true) {
            setUrl('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoibmlja255ciIsImEiOiJjajduNGptZWQxZml2MndvNjk4eGtwbDRkIn0.L0aWwfHlFJVGa-WOj7EHaA')
        } else {
            setUrl("https://api.mapbox.com/styles/v1/nicknyr/cje7mtk2y6gf92snsydobiahf/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoibmlja255ciIsImEiOiJjajduNGptZWQxZml2MndvNjk4eGtwbDRkIn0.L0aWwfHlFJVGa-WOj7EHaA")
        }
    }


    const allLocations = useSelector(state => state.allLocations);
    const getAddressMsisdn = (value) => {
        dispatch(getLocationMsisdn(value))
    }
    const getAddressManyMsisdns = () => {
        dispatch(getManyLocationMsisdn(fileInput))
    }

    console.log(allLocations)

    const createMarkers = (locationsLine) => {
        console.log(locationsLine)
        let markers = [];
        for (let index = 0; index < locationsLine.length; index++) {
            if (locationsLine[index].LATITUDE) {

                markers.push(
                    <Marker
                        key={index}
                        icon={customMarkerIcon}
                        position={[parseFloat(locationsLine[index].LATITUDE), parseFloat(locationsLine[index].LONGITUDE)]}
                        
                    >
                        <Circle
                            center={[parseFloat(locationsLine[index].LATITUDE), parseFloat(locationsLine[index].LONGITUDE)]}
                            fillColor="blue"
                            radius={1000}
                        />
                        <Popup>
                        Msisdn: {locationsLine[index].msisdn}
                        Rede: {locationsLine[index].network}

                             </Popup>
                    </Marker>
                );
            }
        }
        return markers;
    }




    return (
        <div>

            <Card
                title={<h1 style={styles.title}>
                    <i
                        style={styles.subTitle}
                        className="fas fa-map-marker-alt"
                    />
              Geolocalização MOBILE
          </h1>}
                extra={
                    <div>

                    </div>
                }
            >

                <Map
                    style={{ width: "100%", height: "75vh", marginTop: "-3em" }}
                    className="maps"
                    center={[lat, long]}
                    zoomControl={false}
                    zoom={zoom}
                >
                    <TileLayer
                        url={url}
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />

                    {allLocations.allLocations ? createMarkers(allLocations.allLocations) : ""}

                    <FullscreenControl position="topright" />
                    <Control position="topright">
                        <ZoomControl
                            position='topright'
                        ></ZoomControl>
                    </Control>

                    <Control position="topright">
                        <Switch
                            checkedChildren={<BulbFilled />}
                            icon={<SearchOutlined />}
                            onChange={toogleMap}
                        ></Switch>
                    </Control>

                    <Control position="topleft">
                        <Card
                            size="small"
                            bordered={false}
                            style={{
                                borderRadius: 5,
                                width: 460,
                                boxShadow:"10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
                                // height: "24vh",
                                // backgroundImage: `url(${image})`,
                            }}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Input.Search
                                        autoComplete="off"
                                        allowClear style={{ marginBottom: 5 }}
                                        placeholder="MSISDN"
                                        prefix={<MobileOutlined />}
                                        onSearch={value => {
                                            getAddressMsisdn(value);
                                        }}
                                        onChange={event => {
                                            if (event.target.value === "") {
                                              setLocationLine()
                                            }}}

                                    />
                                </Col>
                                <Col span={10}>
                                    <Upload
                                        // listType='picture'
                                        // className='upload-list-inline'
                                        multiple={false}
                                        accept=".xlsx"
                                        // showUploadList={true}
                                        beforeUpload={file => {
                                            setFileInput(file)
                                            return false;
                                        }}
                                    >
                                        <Button>
                                            <UploadOutlined /> Upload de MSISDNs.xlsx
                                </Button>
                                    </Upload>
                                </Col>
                                <Col span={4} >
                                    <Button
                                        type="primary"
                                        onClick={getAddressManyMsisdns}
                                        disabled={fileInput === undefined || null}
                                    >
                                        Enviar
                                    </Button>
                                </Col>
                            </Row>
                            {/* {this.props.upload} */}


                        </Card>
                        <Backdrop show={allLocations.loading} loading={true} />
                        {allLocations && (
                            <Table
                                style={{
                                    marginTop: 10,
                                    borderRadius: 10,
                                    width: 460,
                                    boxShadow:
                                        "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
                                }}
                                columns={columns}
                                dataSource={allLocations.allLocations || []}
                                rowKey="msisdn"
                                pagination={false}
                                scroll={{ y: 170 }}
                                footer={() => (  
                                <Row gutter={16}>
                                    <Col span={14}>{ allLocations.allLocations != null?allLocations.allLocations.length : 0} linhas encontradas</Col>
                                   
                                </Row>)}

                            />
                        )}
                    </Control>

                    <Control position="bottomleft">
                        {locationLine ?
                            <Card
                                size="small"

                                title={<h4><i className="fas fa-sim-card" style={{ fontSize: 18, color: "green" }} /> MSISDN</h4>}
                                style={{
                                    marginTop: 10,
                                    borderRadius: 5,
                                    width: 310,
                                    boxShadow:
                                        "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
                                    // height: "20vh",
                                    // backgroundImage: `url(${image})`,
                                }}>
                                {locationLine.length === 0 ? <Loading heightContainer={"25vh"} /> :
                                    <>
                                        <h5><i className="fas fa-map-marker-alt" style={{ fontSize: 12, color: "green" }} /> {locationLine.suburb || locationLine.road}, {locationLine.city || locationLine.town}</h5>
                                        <h4><i className="fas fa-globe" style={{ fontSize: 12, color: "green" }} /> {locationLine.state} - {locationLine.country_code}</h4>
                                    </>
                                }

                            </Card> : <Card
                                size="small"

                                title={<h4><i className="fas fa-sim-card" style={{ fontSize: 18, color: "green" }} /> MSISDN</h4>}
                                style={{
                                    marginTop: 10,
                                    borderRadius: 5,
                                    width: 310,
                                    boxShadow:
                                        "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
                                    // height: "20vh",
                                    // backgroundImage: `url(${image})`,
                                }}>
                                <>
                                    <h5><i className="fas fa-map-marker-alt" style={{ fontSize: 12, color: "green" }} /> Bairro / Cidade</h5>
                                    <h4><i className="fas fa-globe" style={{ fontSize: 12, color: "green" }} /> Estado - País</h4>
                                </>


                            </Card>}


                        {/* </Card> */}
                    </Control> : ""



                    {/* {locationBool ? //Localização do Usuário
                        <Control position="bottomright">
                            <Card
                                size="small"
                                title={<h4><i className="fas fa-map-marker" style={{ fontSize: 15, color: "blue" }} /> Minha Localização</h4>}
                                style={{
                                    marginTop: 10,
                                    opacity: 0.9,
                                    borderRadius: 5,
                                    width: 220,
                                    // height: "20vh",
                                    // backgroundImage: `url(${image})`,
                                }}>

                                <h5><i className="fas fa-map-marker-alt" style={{ fontSize: 12, color: "blue" }} /> {locationUser ? locationUser.suburb : locationUser.road}, {locationUser ? locationUser.city : locationUser.county}</h5>
                                <h4><i className="fas fa-globe" style={{ fontSize: 12, color: "blue" }} /> {locationUser.state} - {locationUser.country_code}</h4>


                            </Card>
                        </Control> : ""

                    } */}
                </Map>
            </Card>
        </div>
    );
};

const styles = {
    subTitle: {
        background: "linear-gradient(80deg, #43A047, #FFEB3B)",
        borderRadius: 10,
        padding: 17,
        marginRight: 10,
        fontSize: 22,
        color: "#ffffff"
    },
    containerDrawer: {
        //width: 30
        //backgroundColor: "transparent"
    },
    btnFilter: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
    },
    btnCancel: {
        marginRight: 8
    },
    online: {
        color: "green"
    }
};

export default GeolocationMobile;

import React, { useState, useEffect } from "react";
import { RollbackOutlined, FileSyncOutlined, BookOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Card, 
    Table, 
    Row, 
    Col, 
    Button, 
    Tooltip, 
    Tag, 
    Modal
} from "antd";

import HeliosTable from "../../../components/HeliosTable/HeliosTable";
import SkeletonTable from "../../../components/Skeleton/SkeletonTable";
import { getContractV2 } from "../../../store/actions/contractAction";
import "./StockOS.css";

const styles = {
    container: {
        borderRadius: "10px",
        background: "#fff",
        boxShadow: "10px 10px 10px -10px rgba(0, 0, 0, 0.7), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    },
    subTitle: {
        marginRight: 10,
        fontSize: 18,
        cursor: "pointer"
    },
    icon: {
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
    },
};

const StockOs = props => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { listContracts, loading } = useSelector(state => state.contracts);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    // Funções
    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(current);
    };

    function switchSaleType(saleType, record) {
        const { remainingMsisdns, remainingSimcards } = record;

        const remaningOfMsisdnOrSimcards = saleType === 'Venda Sob Demanda' ? remainingSimcards : remainingMsisdns;
        const disableOsButton = remaningOfMsisdnOrSimcards <= 0;

        if (saleType === "Venda Estoque") {
            return (
                <>
                    <Button
                        type="default"
                        size="middle"
                        disabled={disableOsButton}
                        icon={<FileSyncOutlined style={{ fontSize: 20 }} />}
                        style={{
                            borderColor: "orange",
                            color: "orange", 
                            width: "200px",
                            marginRight: "5px"
                        }}
                        onClick={() => {
                            history.push({pathname: `/create-stock-os/${record.id}`,
                                state: {
                                    id: record.id, //id do contrato
                                    create: false,
                                    customer__id: props.customerId,
                                    id_item: record.id_item,
                                    order_id: record.order_id,
                                    customer__name: props.customerName,
                                    associeated_lines: record.associeated_lines,
                                    inception: record.inception,
                                    discount_inception: record.discount_inception,
                                    discount_type: record.discount_type,
                                    discount_value: record.discount_value,
                                    exemption_days: record.exemption_days,
                                    service_ti: record.service_ti,
                                    licensed: record.licensed,
                                    plan__id: record.plan_id,
                                    plan__name: record.plan_name,
                                    requester__id: record.requester_id,
                                    requester__name: record.requester_name,
                                    quant: record.quant,
                                    crm_code: record.crm_code,
                                    cod_emp: record.cod_emp
                                }
                            });
                        }}
                    >
                        Estoque
                    </Button>
                    <Tooltip placement="bottom">
                        <i onClick={() => Modal.info({
                            title: <center>Estoque</center>,
                            content: (
                                <div><Card>
                                    <>
                                        <Row>
                                            <p>O processo de estoque pode consistir em um das duas opções:</p>
                                            <ul>
                                                <li>O vínculo de linhas ao cliente, caso os simcards já possuam msisdn;</li>
                                                <li>A reserva de numeração de linhas e posteriormente o vínculo entre cliente e linhas reservadas</li>
                                            </ul>
                                            <p>As linhas podem ser ativadas posteriormente a pedido do cliente.</p>
                                        </Row>
                                    </>
                                </Card></div>
                            ),
                            icon: (<><i style={styles.icon} /></>),
                            width: 500,
                            onOk() { },
                        })
                        } style={styles.icon} className="far fa-question-circle"></i>
                    </Tooltip>
                </>

            )
        } else if (saleType === "Venda Sob Demanda") {
            return (
                <>
                    <Button
                        icon={<BookOutlined style={{ fontSize: 20 }} />}
                        style={{ borderColor: "teal", color: "teal", width: "200px", marginRight: "5px" }}
                        type="default"
                        disabled={disableOsButton}
                        onClick={() => {
                            history.push({
                                pathname: `/create-stock-os-demand/${record.id}`,
                                state: {
                                    id: record.id, //id do contrato
                                    create: false,
                                    customer__id: props.customerId,
                                    id_item: record.id_item,
                                    order_id: record.order_id,
                                    customer__name: props.customerName,
                                    associeated_lines: record.associeated_lines,
                                    inception: record.inception,
                                    discount_inception: record.discount_inception,
                                    discount_type: record.discount_type,
                                    discount_value: record.discount_value,
                                    exemption_days: record.exemption_days,
                                    service_ti: record.service_ti,
                                    licensed: record.licensed,
                                    plan__id: record.plan_id,
                                    plan__name: record.plan_name,
                                    requester__id: record.requester_id,
                                    requester__name: record.requester_name,
                                    quant: record.quant,
                                    remaining_lines: record.remaining_lines,
                                    crm_code: record.crm_code,
                                    cod_emp: record.cod_emp
                                }
                            });
                        }}
                        size="middle"
                    >
                        Sob Demanda
                    </Button>
                    <Tooltip placement="bottom">
                        <i onClick={() => Modal.info({
                            title: <center>Sob Demanda</center>,
                            content: (
                                <div><Card>
                                    <>
                                        <Row>
                                            <p>O processo de sob demanda consiste em realizar o vínculo entre cliente e simcard. Os pré-requisitos são:</p>
                                            <ul>
                                                <li>Os simcards devem estar na fase conferido ou criado;</li>
                                                <li>Os simcards não devem possuir linhas vinculadas</li>
                                            </ul>
                                            <p>Neste processo não é realizado o casamento entre linhas e iccids. O processo de reserva de numeração e ativação ficam a pedido do cliente.</p>
                                        </Row>
                                    </>
                                </Card></div>
                            ),
                            icon: (<><i style={styles.icon} /></>),
                            width: 500,
                            onOk() { },
                        })
                        } style={styles.icon} className="far fa-question-circle"></i>
                    </Tooltip>
                </>
            )
        } else if (saleType === "Venda Estoque Ativado") {
            return (
                <>
                    <Button
                        icon={<BookOutlined style={{ fontSize: 20 }} />}
                        style={{ borderColor: "blue", color: "blue", width: "200px" }}
                        type="default"
                        disabled={disableOsButton}
                        onClick={() => {
                            history.push({
                                pathname: `/create-stock-os-active/${record.id}`,
                                state: {
                                    id: record.id, //id do contrato
                                    create: false,
                                    customer__id: props.customerId,
                                    id_item: record.id_item,
                                    order_id: record.order_id,
                                    customer__name: props.customerName,
                                    associeated_lines: record.associeated_lines,
                                    inception: record.inception,
                                    discount_inception: record.discount_inception,
                                    discount_type: record.discount_type,
                                    discount_value: record.discount_value,
                                    exemption_days: record.exemption_days,
                                    service_ti: record.service_ti,
                                    licensed: record.licensed,
                                    plan__id: record.plan_id,
                                    plan__name: record.plan_name,
                                    requester__id: record.requester_id,
                                    requester__name: record.requester_name,
                                    quant: record.quant,
                                    remaining_lines: record.remaining_lines,
                                    crm_code: record.crm_code,
                                    cod_emp: record.cod_emp
                                }
                            });
                        }}
                        size="middle"

                    >
                        Estoque Ativado
                    </Button>
                    <Tooltip placement="bottom">
                        <i onClick={() => Modal.info({
                            title: <center>Estoque Ativado</center>,
                            content: (
                                <div>
                                    <Card>
                                        <>
                                            <Row>
                                                <p>O processo de estoque ativado consiste em:</p>
                                                <ul>
                                                    <li>Realizar reserva de numeração, caso os simcards processados não possuam linhas;</li>
                                                    <li>Vincular as linhas ao cliente;</li>
                                                    <li>Ativar as linhas vinculadas</li>
                                                </ul>
                                            </Row>
                                        </>
                                    </Card>
                                </div>
                            ),
                            icon: (<><i style={styles.icon} /></>),
                            width: 500,
                            onOk() { },
                        })
                        } style={styles.icon} className="far fa-question-circle"></i>
                    </Tooltip>
                </>)
        }
    }

    const handlePaginationChange = current => {
        setPage(current);
    };

    const subTitle = (
        <div>
            <Tooltip placement="bottom" title="Adicionar Contrato">
                <i
                    style={styles.subTitle}
                    className="fas fa-user-plus"
                    onClick={() => {
                        history.push({
                            pathname: `/add-contract-operation`,
                            state: {
                                create: true,
                                customer__id: props.customerId,
                                customer__name: props.customerName
                            }
                        });
                    }}
                />
            </Tooltip>
        </div>
    );

    const expandedRowRender = record => {
        const columns = [
            {
                title: "Gerente",
                dataIndex: "requester_name",
                key: "requester_name"
            },
            {
                title: "Código da empresa",
                dataIndex: "cod_emp",
                key: "cod_emp"
            },
            { title: "Código do CRM", dataIndex: "crm_code", key: "crm_code" },
            {
                title: "Valor do desconto",
                dataIndex: "discount_value",
                key: "discount_value"
            },
            {
                title: "Licença",
                dataIndex: "licensed",
                key: "licensed",
                render: (text, record) => (
                    <>
                        <Tag
                            color={record.licensed === 0 ? "orange" : record.licensed === 1 ? "green" : "#A0A0A0"}
                        >
                            {record.licensed === 1 ? "Sim" : "Não"}
                        </Tag>
                    </>
                )
            }
        ];

        return (
            <Table
                columns={columns}
                dataSource={[record] || []}
                pagination={false}
            />
        );
    };

    const columns = [
        {
            title: "Data de Início",
            dataIndex: "inception"
        },
        {
            title: "Plano",
            dataIndex: "plan_name"
        },
        {
            title: "Ordem de serviço",
            dataIndex: "order_id"
        },
        {
            title: "Quantidade Total",
            dataIndex: "quant"
        },
        {
            title: "Simcards Associados",
            render: (rowData) => {
                const saleType = rowData?.sale_type;
                const associatedMsisdn = rowData?.associatedMsisdn;
                const associatedSimcards = rowData?.associatedSimcards;

                const amountOfMsisdnOrSimcards = saleType === 'Venda Sob Demanda' ? associatedSimcards : associatedMsisdn;
                return (
                    <>
                        {amountOfMsisdnOrSimcards}
                    </>
                );
            }
        },
        {
            title: "Simcards Remanescentes",
            render: (rowData) => {
                const saleType = rowData?.sale_type;
                const remainingMsisdns = rowData?.remainingMsisdns;
                const remainingSimcards = rowData?.remainingSimcards;

                const remaningItems = saleType === 'Venda Sob Demanda' ? remainingSimcards : remainingMsisdns;
                return (
                    <>
                        {remaningItems <= 0 ? 0 : remaningItems}
                    </>
                );
            }
        },

        {
            title: "Ações",
            key: "action",
            render: (rowData) => (
                <div className="contractActions">
                    {
                        (listContracts ? switchSaleType(rowData.sale_type, rowData) : <Button />)
                    }
                </div>
            )
        }
    ];

    // Efeitos
    useEffect(() => {
        dispatch(getContractV2({customerId: props.customerId, page, limit: pageSize}));
    }, [dispatch, props.customerId, pageSize, page]);

    return (
        <>
            <Card>
                <SkeletonTable
                    show={loading}
                    title={"OS de Pedido - " + props.customerName}
                    icon="fas fa-file-signature"
                    pageSize={pageSize}
                />

                {listContracts && !loading ? (
                    <HeliosTable
                        title={"OS de Pedido - " + props.customerName}
                        subTitle={subTitle}
                        columns={columns}
                        icon="fas fa-file-signature"
                        dataSource={listContracts.data || []}
                        rowKey="id"
                        total={listContracts.total}
                        pageSize={pageSize}
                        page={page}
                        expandable={{ expandedRowRender }}
                        onChange={handlePaginationChange}
                        onShowSizeChange={onShowSizeChange}
                    />
                ) : (
                    ""
                )}
                <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button
                            style={{
                                marginTop: 10,
                                borderColor: "orange",
                                color: "orange"
                            }}
                            icon={<RollbackOutlined style={{ fontSize: 18 }} />}
                            type="default"
                            onClick={() => history.push("/customers")}
                        >
                            Voltar - Listar Clientes
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default StockOs;

/* TYPES LOGIN */
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCSESS = "LOGIN_USER_SUCCSESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_USER = "LOGOUT_USER";

/* TYPES USER*/
export const GET_USERS_START = "GET_USERS_START";
export const CREATE_USERS_START = "CREATE_USERS_START";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const ENABLE_DISABLE_USERS_SUCCESS = "ENABLE_DISABLE_USERS_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const CLEAR_ERRORS_USER = "CLEAR_ERRORS_USER";
export const CLEAR_MESSAGE_USER = "CLEAR_MESSAGE_USER";

/* TYPES LINE*/
export const GET_LINES_START = "GET_LINES_START";
export const GET_LINES_SUCCESS = "GET_LINES_SUCCESS";
export const GET_STATUS_LINES_SUMMARIZED_SUCCESS = "GET_STATUS_LINES_SUMMARIZED_SUCCESS";
export const GET_STATUS_OPERATION_SUMMARIZED_SUCCESS = "GET_STATUS_OPERATION_SUMMARIZED_SUCCESS";
export const GET_LINES_FAIL = "GET_LINES_ERROR";


// TYPES LINE STOCK OS
export const GET_LINES_STOCK_START = "GET_LINES_STOCK_START";
export const GET_LINES_STOCK_SUCCESS = "GET_LINES_STOCK_SUCCESS";
export const GET_STATUS_LINES_STOCK_SUMMARIZED_SUCCESS = "GET_STATUS_LINES_STOCK_SUMMARIZED_SUCCESS";
export const GET_STATUS_OPERATION_STOCK_SUMMARIZED_SUCCESS = "GET_STATUS_OPERATION_STOCK_SUMMARIZED_SUCCESS";
export const GET_LINES_STOCK_FAIL = "GET_LINES_STOCK_FAIL";

/* TYPES LINE SESSION*/
export const GET_LINES_SESSION_START = "GET_LINES_SESSION_START";
export const GET_LINES_SESSION_SUCCESS = "GET_LINES_SESSION_SUCCESS";
export const GET_LINES_SESSION_FAIL = "GET_LINES_SESSION_NLT";

/* TYPES LINE STATUS NLT*/
export const GET_LINES_START_NLT = "GET_LINES_START_NLT";
export const GET_STATUS_LINES_SUMMARIZED_SUCCESS_NLT = "GET_STATUS_LINES_SUMMARIZED_SUCCESS_NLT";
export const GET_LINES_FAIL_NLT = "GET_LINES_ERROR_NLT";

/* TYPES LINE CONSUMPTIONS*/
export const GET_LINES_CONSUMPTION_START = "GET_LINES_CONSUMPTION_START";
export const GET_LINES_CONSUMPTION_SUCCESS = "GET_LINES_CONSUMPTION_SUCCESS";
export const GET_CONSUMPTION_LINES_SUMMARIZED_SUCCESS = "GET_CONSUMPTION_LINES_SUMMARIZED_SUCCESS";
export const GET_LINES_CONSUMPTION_FAIL = "GET_LINES_CONSUMPTION_FAIL";

/* TYPES LINE CONSUMPTIONS NLT*/
export const GET_LINES_CONSUMPTION_START_NLT = "GET_LINES_CONSUMPTION_START_NLT";
export const GET_CONSUMPTION_LINES_SUMMARIZED_SUCCESS_NLT = "GET_CONSUMPTION_LINES_SUMMARIZED_SUCCESS_NLT";
export const GET_LINES_CONSUMPTION_FAIL_NLT = "GET_LINES_CONSUMPTION_FAIL_NLT";

/* TYPES LINE PHASE */
export const GET_PHASE_SIMCARD_START = "GET_PHASE_SIMCARD_START";
export const GET_PHASE_SIMCARDS_SUCCESS = "GET_PHASE_SIMCARDS_SUCCESS";
export const GET_PHASE_SIMCARD_FAIL = "GET_PHASE_SIMCARD_FAIL";

/* TYPES CUSTOMERS*/
export const GET_CUSTOMERS_START = "GET_CUSTOMERS_START";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const INSERT_CUSTOMERS_SUCCESS = "INSERT_CUSTOMERS_SUCCESS";
export const INSERT_CUSTOMERS_CLEAR = "INSERT_CUSTOMERS_CLEAR"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

/* TYPES DEVICES*/
export const GET_DEVICES_START = "GET_DEVICES_START";
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
export const GET_DEVICES_FAIL = "GET_DEVICES_FAIL";

/* TYPES DEVICES NLT*/
export const GET_DEVICES_START_NLT = "GET_DEVICES_START_NLT";
export const GET_DEVICES_SUCCESS_NLT = "GET_DEVICES_SUCCESS_NLT";
export const GET_DEVICES_FAIL_NLT = "GET_DEVICES_FAIL_NLT";

/* TYPES COUNT DEVICES*/
export const COUNT_DEVICES_START = "COUNT_DEVICES_START";
export const COUNT_DEVICES_SUCCESS = "COUNT_DEVICES_SUCCESS";
export const COUNT_DEVICES_FAIL = "COUNT_DEVICES_FAIL";

/* TYPES COUNT DEVICES NLT*/
export const COUNT_DEVICES_START_NLT = "COUNT_DEVICES_START_NLT";
export const COUNT_DEVICES_SUCCESS_NLT = "COUNT_DEVICES_SUCCESS_NLT";
export const COUNT_DEVICES_FAIL_NLT = "COUNT_DEVICES_FAIL_NLT";

/* TYPES ATR HARD RESET*/
export const HARD_RESET_LINE_START = "HARD_RESET_LINE_START";
export const HARD_RESET_LINE_SUCCESS = "HARD_RESET_LINE_SUCCESS";
export const HARD_RESET_LINE_ERROR = "HARD_RESET_LINE_ERROR";
export const HARD_RESET_LINE_CLEAR = "HARD_RESET_LINE_CLEAR";

/* TYPES SUSPENSION*/
export const SUSPENSION_START = "SUSPENSION_START";
export const SUSPENSION_SUCCESS = "SUSPENSION_SUCCESS";
export const SUSPENSION_ERROR = "SUSPENSION_ERROR";
export const SUSPENSION_CLEAR = "SUSPENSION_CLEAR";

/* TYPES RESERVE_NUMBER*/
export const RESERVE_NUMBER_START = "RESERVE_NUMBER_START";
export const RESERVE_NUMBER_SUCCESS = "RESERVE_NUMBER_SUCCESS";
export const RESERVE_NUMBER_ERROR = "RESERVE_NUMBER_ERROR";
export const RESERVE_NUMBER_CLEAR = "RESERVE_NUMBER_CLEAR";

/* TYPES STOCK_NUMBER */
export const STOCK_NUMBER_START = "STOCK_NUMBER_START";
export const STOCK_NUMBER_SUCCESS = "STOCK_NUMBER_SUCCESS";
export const STOCK_NUMBER_ERROR = "STOCK_NUMBER_ERROR";
export const STOCK_NUMBER_CLEAR = "STOCK_NUMBER_CLEAR";

/* TYPES ATR CHANGE APN*/
export const CHANGE_APN_LINE_START = "CHANGE_APN_LINE_START";
export const CHANGE_APN_LINE_SUCCESS = "CHANGE_APN_LINE_SUCCESS";
export const CHANGE_APN_LINE_ERROR = "CHANGE_APN_LINE_ERROR";
export const CHANGE_APN_LINE_CLEAR = "CHANGE_APN_LINE_CLEAR";


/* TYPES ATR SMS ENABLE*/
export const SMS_LINE_START = "SMS_LINE_START";
export const SMS_LINE_SUCCESS = "SMS_LINE_SUCCESS";
export const SMS_LINE_ERROR = "SMS_LINE_ERROR";
export const SMS_LINE_CLEAR = "SMS_LINE_CLEAR";


/* TYPES ATR get apn*/
export const GET_APN_START = "GET_APN_START";
export const GET_APN_SUCCESS = "GET_APN_SUCCESS";
export const GET_APN_ERROR = "GET_APN_ERROR";

/* TYPES PLANS*/
export const GET_PLANS_START = "GET_PLANS_START";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAIL = "GET_PLANS_FAIL";

/* TYPES REPORT BILLING*/
export const REPORT_BILLING_START = "REPORT_BILLING_START";
export const REPORT_BILLING_SUCCESS = "REPORT_BILLING_SUCCESS";
export const REPORT_BILLING_FAIL = "REPORT_BILLING_FAIL";

/* TYPES Generate REPORT BILLING*/
export const GENERATE_REPORT_BILLING_START = "GENERATE_REPORT_BILLING_START";
export const GENERATE_REPORT_BILLING_SUCCESS = "GENERATE_REPORT_BILLING_SUCCESS";
export const GENERATE_REPORT_BILLING_FAIL = "GENERATE_REPORT_BILLING_FAIL";

/* TYPES REPORT SIMCARDS*/
export const REPORT_SIMCARDS_START = "REPORT_SIMCARDS_START";
export const REPORT_SIMCARDS_SUCCESS = "REPORT_SIMCARDS_SUCCESS";
export const REPORT_SIMCARDS_FAIL = "REPORT_SIMCARDS_FAIL";

/* TYPES Generate REPORT SIMCARDS*/
export const GENERATE_REPORT_SIMCARDS_START = "GENERATE_REPORT_SIMCARDS_START";
export const GENERATE_REPORT_SIMCARDS_SUCCESS = "GENERATE_REPORT_SIMCARDS_SUCCESS";
export const GENERATE_REPORT_SIMCARDS_FAIL = "GENERATE_REPORT_SIMCARDS_FAIL";

/* TYPES REPORT CONSUMPTIONS*/
export const REPORT_CONSUMPTION_START = "REPORT_CONSUMPTION_START";
export const REPORT_CONSUMPTION_SUCCESS = "REPORT_CONSUMPTION_SUCCESS";
export const REPORT_CONSUMPTION_FAIL = "REPORT_CONSUMPTION_FAIL";

/* TYPES Generate REPORT CONSUMPTIONS*/
export const GENERATE_REPORT_CONSUMPTION_START = "GENERATE_REPORT_CONSUMPTION_START";
export const GENERATE_REPORT_CONSUMPTION_SUCCESS = "GENERATE_REPORT_CONSUMPTION_SUCCESS";
export const GENERATE_REPORT_CONSUMPTION_FAIL = "GENERATE_REPORT_CONSUMPTION_FAIL";


/* TYPES REPORT CUSTOMERS*/
export const REPORT_CUSTOMERS_START = "REPORT_CUSTOMERS_START";
export const REPORT_CUSTOMERS_SUCCESS = "REPORT_CUSTOMERS_SUCCESS";
export const REPORT_CUSTOMERS_FAIL = "REPORT_CUSTOMERS_FAIL";

/* TYPES Generate REPORT CUSTOMERS*/
export const GENERATE_REPORT_CUSTOMERS_START = "GENERATE_REPORT_CUSTOMERS_START";
export const GENERATE_REPORT_CUSTOMERS_SUCCESS = "GENERATE_REPORT_CUSTOMERS_SUCCESS";
export const GENERATE_REPORT_CUSTOMERS_FAIL = "GENERATE_REPORT_CUSTOMERS_FAIL";

/* TYPES CUSTOMERS*/
export const GET_LIST_CUSTOMERS_START = "GET_LIST_CUSTOMERS_START";
export const GET_LIST_CUSTOMERS_SUCCESS = "GET_LIST_CUSTOMERS_SUCCESS";
export const GET_LIST_CUSTOMERS_FAIL = "GET_LIST_CUSTOMERS_FAIL";

/* TYPES CONTRACTS*/
export const GET_CONTRACTS_START = "GET_CONTRACTS_START";
export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_SUCCESS";
export const INSERT_CONTRACT_SUCCESS = "INSERT_CONTRACT_SUCCESS";
export const INSERT_CONTRACT_CLEAR = "INSERT_CONTRACT_CLEAR";
export const GET_CONTRACTS_FAIL = "GET_CONTRACTS_FAIL";

/* TYPES GEOLOCATION*/
export const GET_GEOLOCATION_START = "GET_GEOLOCATION_START";
export const GET_GEOLOCATION_SUCCESS = "GET_GEOLOCATION_SUCCESS";
export const GET_GEOLOCATION_FAIL = "GET_GEOLOCATION_FAIL";

/* TYPES STATUS HLR*/
export const GET_STATUS_HLR_START = "GET_STATUS_HLR_START";
export const GET_STATUS_HLR_SUCCESS = "GET_STATUS_HLR_SUCCESS";
export const GET_STATUS_HLR_FAIL = "GET_STATUS_HLR_FAIL";

/* TYPES LOGS*/
export const GET_LOGS_START = "GET_LOGS_START";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";
export const GET_LOGS_SUMMARIZED = "GET_LOGS_SUMMARIZED";
export const GET_LINE_CONTROLLER_SUCCESS="GET_LINE_CONTROLLER_SUCCESS"
export const GET_LOGS_FAIL = "GET_LOGS_FAIL";


/* TYPES RETRY*/
export const GET_RETRY_START = "GET_RETRY_START";
export const GET_RETRY_SUCCESS = "GET_RETRY_SUCCESS";
export const GET_RETRY_FAIL = "GET_RETRY_FAIL";


/* TYPES RESOURCE LOGS*/
export const GET_RESOURCE_LOGS_START = "GET_RESOURCE_LOGS_START";
export const GET_RESOURCE_LOGS_SUCCESS = "GET_RESOURCE_LOGS_SUCCESS";
export const GET_RESOURCE_LOGS_FAIL = "GET_RESOURCE_LOGS_FAIL";

/* TYPES TRNSACTION LOGS*/
export const GET_TRANSACTION_LOGS_START = "GET_TRANSACTION_LOGS_START";
export const GET_TRANSACTION_LOGS_SUCCESS = "GET_TRANSACTION_LOGS_SUCCESS";
export const GET_TRANSACTION_LOGS_FAIL = "GET_TRANSACTION_LOGS_FAIL";

/* TYPES COUNT ICCIDS*/
export const COUNT_ICCIDS_START = "COUNT_ICCIDS_START";
export const COUNT_ICCIDS_SUCCESS = "COUNT_ICCIDS_SUCCESS";
export const COUNT_ICCIDS_ERROR = "COUNT_ICCIDS_ERROR";
export const COUNT_ICCIDS_CLEAR = "SUSPENSION_CLEAR";

/* TYPES REACTIVATION*/
export const REACTIVATION_START = "REACTIVATION_START";
export const REACTIVATION_SUCCESS = "REACTIVATION_SUCCESS";
export const REACTIVATION_ERROR = "REACTIVATION_ERROR";
export const REACTIVATION_CLEAR = "REACTIVATION_CLEAR";
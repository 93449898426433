import React from "react";
import PropTypes from "prop-types";

import "./Spinner.css";

const Spinner = props => {
  return <div className={props.type}>Loading...</div>;
};

Spinner.propTypes = {
  type: PropTypes.string.isRequired
};

export default Spinner;

import React, { useEffect } from 'react'
import { typeStock } from './constantsStockOsDemand'
import {
  Card,
  List,
  // Tooltip,
  // Modal,
  // Row
} from "antd";
import Texty from 'rc-texty';
import Animate from 'rc-animate';
import OperationStatusInfo from '../../container/Dashborad/OperationsStatusInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getOperationsStockLinesSummarized } from '../../../store/actions/lineStatusAction';

const columnsStatus = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
  },
  {
    title: 'value',
    dataIndex: 'value',
    key: 'value',
    render: (text, obj) => <span style={{ color: obj.color }}> {text}</span>
  },
];

const CardsStockOSDemand = (props) => {
  // const { onChange, onType } = props
  const data = typeStock;
  const { Item } = List
  const { Meta } = Card;

  const { statusOperationStockSummarized } = useSelector(state => state.linesStockStatus);
  console.log(statusOperationStockSummarized)

  const dispatch = useDispatch();

  const statusQuant = (typeResource) => statusOperationStockSummarized && ([
    { name: 'Processando', value: statusOperationStockSummarized.processing[typeResource], color: "blue" },
    { name: 'Em espera', value: statusOperationStockSummarized.waiting[typeResource], color: "orange" },
    { name: 'Finalizados', value: statusOperationStockSummarized.finalized[typeResource], color: "green" }

  ]);

  useEffect(() => {
    const intervalSummarized = setInterval(() => {
      dispatch(getOperationsStockLinesSummarized());
    }, 5000);
    return () => {
      clearInterval(intervalSummarized)
    };
  }, [dispatch]);


  useEffect(() => {
    const intervalSummarized = setInterval(() => {
      dispatch(getOperationsStockLinesSummarized());
    }, 5000);
    return () => {
      clearInterval(intervalSummarized)
    };
  }, [dispatch]);

  return (
    <>

      <Animate transitionAppear transitionName="fade">
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4
          }}
          dataSource={data}
          renderItem={item => (
            <Item >
              <Card hoverable title={<Texty
                type={"left"}
                mode={"smooth"}>{item.name}</Texty>
              }
              >
                {statusOperationStockSummarized && (
                  <Meta
                    description={<OperationStatusInfo
                      data={statusQuant(item.type)}
                      total={statusOperationStockSummarized.totalized[item.type]}
                      columns={columnsStatus}
                    />}

                    onClick={() => {
                      props.onType(item.name)
                      props.onChange()
                    }
                    }
                  />)}
              </Card>
            </Item>
          )}
        />
      </Animate>
    </>
    //  icon question
  )

}
// const styles = {
//   icon: {
//     fontSize: 18,
//     cursor: "pointer"
//   }
// }

export default CardsStockOSDemand;
import React from "react"
import axios from "axios";

import * as actionType from "../types";
import setAuthToken from "../../setAuthToken";
import jwtDecode from "jwt-decode";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const placement = "bottom"

export const loginUser = user => dispatch => {
  dispatch(fetchStart(actionType.LOGIN_USER_START));
  notification.open({
    key: 'loadingAuth',
    message: 'Autenticando...',
    description:
      '',
    icon: <LoadingOutlined />,
    duration: 0,
    placement

  });
  axios
    .post(API_ROOT + "auth/signin", user)
    .then(res => {
      notification.close('loadingAuth')
      const token = res.data.token;
      const refreshToken = res.data.refreshToken;
      const history = res.data.user_id;
      const clientId = res.data.client_id 

      const userType = res.data.user_type;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("history", history);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("userType", userType);
      localStorage.setItem("roles", [res.data.roles]);
      localStorage.setItem("permissions", JSON.stringify(res.data.permissions));
      if(clientId !== null) localStorage.setItem("clientId", clientId);

      
      setAuthToken(token);
      notification.success({
        message: 'Bem Vindo ao sistema HELIOS!',
        description:
          '',
        duration: 5,
        placement
      });
      const decoded = jwtDecode(token);
      dispatch(fetchSuccess(actionType.SET_CURRENT_USER, decoded));
    })
    .catch(err => {
      notification.close('loadingAuth')
      notification.error({
        message: 'Email ou senha incorreta!',
        description:
          'Tente novamente com novas credenciais. Se persistir,Caso o problema persista, entre em contato com o departamento de Engenharia!',
        duration: 5,
        placement
      });
      dispatch(fetchError(actionType.LOGIN_USER_FAIL, err));
    });
};


// Log user out
export const logoutUser = id => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userType");
  localStorage.removeItem("roles");
  localStorage.removeItem("permissions");
  localStorage.removeItem("history");
  localStorage.removeItem("clientId");

  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(fetchSuccess(actionType.SET_CURRENT_USER, {}));

  axios
    .post(API_ROOT + "auth/logout", { id: id })
    .then(() => {
      dispatch({ type: actionType.LOGOUT_USER });
    })
    .catch(err => {
      dispatch(fetchError(actionType.LOGIN_USER_FAIL, err));
    });
};

export const forgotPassword = values => dispatch => {
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(fetchSuccess(actionType.SET_CURRENT_USER, {}));
  notification.open({
    key: 'loadingForgotPassword',
    message: 'Enviando email para ' + values.email,
    description:
      'Em instantes você receberá email com senha',
    icon: <LoadingOutlined />,
    duration: 0,
    placement

  });
  axios
    .post(API_ROOT + "users/forgot-password", values)
    .then((res) => {
      notification.close('loadingForgotPassword')
      if (res.data.message === 'Email incorreto!') {
        notification.error({
          message: 'Email incorreto!',
          description:
            'Caso o problema persista, entre em contato com o departamento de Engenharia para criar sua credencial ou digite corretamente',
          duration: 60,
          placement
        });

      } else {
        notification.success({
          message: res.data.message,
          description:
            'Verifique sua caixa de entrada e sua senha foi alterada por segurança!',
          duration: 60,
          placement
        });
      }

      dispatch({ type: actionType.LOGOUT_USER });
    })
    .catch(err => {
      notification.close('loadingForgotPassword')
      notification.error({
        message: 'Erro no envio do email!',
        description:
          'Caso o problema persista, entre em contato com o departamento de Engenharia.',
        duration: 60,
        placement
      });
      dispatch(fetchError(actionType.LOGIN_USER_FAIL, err));
    });
};

export const refreshToken = () => dispatch => {
  
  if (!localStorage.refreshToken) {
    dispatch(logoutUser());
  }
  console.log(parseInt(localStorage.getItem("history")))
  // console.log(historyUser)
  let refresh = { refresh_token: localStorage.refreshToken };
  axios
    .post(API_ROOT + "auth/refresh_token", refresh)
    .then(res => {
      const token = res.data.token;
      const refreshToken = res.data.refreshToken;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("refreshToken", refreshToken);
      // localStorage.setItem("history",historyUser)
      setAuthToken(token);
      const decoded = jwtDecode(token);
      dispatch(fetchSuccess(actionType.SET_CURRENT_USER, decoded));
    })
    .catch(err => {
      dispatch(fetchError(actionType.LOGIN_USER_FAIL, err));
    });
    
};



export const typeOperations = [{
    id:1,
    name: "Hard Reset",
    type:"reset"
    // img: <img src={reset_alterado} style={{height:250}} alt="HardReset"/>,
}, 
{
    id:2,
    name: "Hard Reset com APN",
    type:"resetApn"
    // img: <img src={reset} style={{height:250}} alt="HardReset"/>
},
{
    id:12,
    name: "Alterar APN",
    type:"changeApn"
    // img: <img src={reset} style={{height:250}} alt="HardReset"/>
},  
{
    id:3,
    name: "Ativar SMS",
    type:"smsEnable"
    // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
},
{
    id:4,
    name:"Desativar SMS",
    type:"smsDisable"
    // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
},
{
    id:5,
    name:"Ativar SMS com APN",
    type:"smsApnEnable"
    // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
},
{
    id:6,
    name:"Desativar SMS com APN",
    type:"smsApnDisable"
    // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
},
// {
//     id:8,
//     name:"Suspensão Temporária",
//     type:"suspTemporary"
//     // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
// },
// {
//     id:9,
//     name:"Suspensão a Pedido",
//     type:"suspClient"
//     // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
// },
// {
//     id:10,
//     name:"Suspensão Perda/Roubo",
//     type:"suspRob"
//     // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
// },
// {
//     id:11,
//     name:"Suspensão Quarentena",
//     type:"suspQuarentine"
//     // img: <img src={agrobusiness} style={{height:250}} alt="HardReset"/>
// }
]

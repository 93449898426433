import * as actionType from "../types";
import moment from "moment";

const initialState = {
    changeApn: null,
    lastUpdate: moment().format("DD/MM/YYYY HH:mm:ss"),
    errors: null,
    message: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.CHANGE_APN_LINE_START:
            return {
                ...state,
                loading: true
            };
        case actionType.CHANGE_APN_LINE_SUCCESS:
            return {
                ...state,
                changeApn: action.payload,
                errors: null,
                loading: false
            };
        case actionType.CHANGE_APN_LINE_ERROR:
            return {
                ...state,
                errors: action.errors,
                loading: false
            };
            case actionType.CHANGE_APN_LINE_CLEAR:
                return initialState

        default:
            return state;
    }
}
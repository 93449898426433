import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip
} from "recharts";

import numeral from "numeral";
import "numeral/locales/pt-br";

const COLORS = ["#008000", "#ffa500", "#ff0000", "#808080"];

const LinesStatusInfo = props => {
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const { data, total, columns } = props;
  return (
    <Row style={{ marginTop: "-15px" }}>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={24}
        style={{ height: height <= 650 ? "30vh" : "25vh" }}
      >
        <ResponsiveContainer>
          <PieChart>
            <Pie
              minAngle={20}
              dataKey="total"
              data={data}
              innerRadius={height <= 650 ? 50 : 60}
              outerRadius={height <= 650 ? 70 : 80}
              fill="#8884d8"

              paddingAngle={2}
            >
              <Label
                value={numeral(total).format("0,0")}
                position="centerBottom"
                className="label-top"
                fontSize="25px"
              />
              <Label
                value="Linhas"
                position="centerBottom"
                className="label-bottom1"
              />
              <Label
                value="atuais"
                position="centerTop"
                className="label-bottom2"
              />
              {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />

          </PieChart>
        </ResponsiveContainer>
      </Col>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={24}
        style={{ height: height <= 650 ? "30vh" : "25vh" }}
      >
        <Table
          showHeader={false}
          bordered={false}
          pagination={false}
          columns={columns}
          dataSource={data}
          size="small"
          rowKey="name"
        />
      </Col>
    </Row>
  );
};

export default LinesStatusInfo;

import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    Label,
    Tooltip
} from "recharts";

const COLORS = ["#008000", "#ffa500"];

const DeviceConsumptionInfo = props => {
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    const { data, columns, total } = props;
    let dataTable = [];
    data.map((entry, index) => {
        return dataTable.push({
            name: entry.name,
            total: entry.total,
            color: entry.color,
        });
    });
    return (
        <Row style={{ marginTop: "-15px" }}>
            <Col
                lg={12}
                md={12}
                sm={12}
                xs={24}
                style={{ height: height <= 650 ? "30vh" : "25vh" }}
            >
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            minAngle={20}
                            dataKey="total"
                            data={data}
                            //cy={height <= 650 ? 70 : 100}
                            innerRadius={height <= 650 ? 50 : 60}
                            outerRadius={height <= 650 ? 70 : 80}
                            fill="#8884d8"
                            paddingAngle={2}
                        >
                            <Label
                                value={total}
                                position="centerBottom"
                                className="label-top"
                                fontSize="25px"
                            />
                            <Label
                                value="Consumo"
                                position="centerBottom"
                                className="label-bottom1"
                            />
                            <Label
                                value="total"
                                position="centerTop"
                                className="label-bottom2"
                            />

                            {data.map((entry, index) => (
                                <Cell key={index} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </Col>
            <Col
                lg={12}
                md={12}
                sm={12}
                xs={24}
                style={{ height: height <= 650 ? "30vh" : "25vh" }}
            >
                <Table
                    showHeader={false}
                    bordered={false}
                    pagination={false}
                    columns={columns}
                    dataSource={dataTable}
                    size="small"
                    rowKey="name"
                />
            </Col>
        </Row>
    );
};

export default DeviceConsumptionInfo;
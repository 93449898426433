import axios from "axios";
import * as actionType from "../types";
import { fetchStart, fetchError, fetchSuccess } from "./utils/defaultMethods";
import { API_ROOT } from "../../config/api-config";
import { notification, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import HeliosTableAtr from "../../components/HeliosTable/HeliosTableAtr";

const { info } = Modal;
const placement = "bottomRight"

const columns = [
    {
        title: "MSISDN",
        dataIndex: "msisdn"
    },
    {
        title: "Resultado",
        dataIndex: "description"
    }]

export const getChangeApnClient = (object, apn) => dispatch => {
    dispatch(fetchStart(actionType.CHANGE_APN_LINE_START));
    notification.open({
        key: 'loadingChangeApn',
        message: 'Efetuando operação de Mudança de APN da linha ',
        description:
            'Em instantes você receberá a resposta do pedido da linha ' + object.msisdn,
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/change-apn", { "apn": apn, "msisdn": object.msisdn })
        .then(res => {
            console.log(res.data)
            notification.close('loadingChangeApn')
            if (res.data.response.length === 0) {
                notification.error({
                    message: 'Erro no pedido!',
                    description:
                        'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                    duration: 10,
                    placement
                });
            }
            if (res.data.response[0].description === "OK" || res.data.response[0].description === "Ok") {
                notification.success({
                    message: 'Operação realizada com sucesso!',
                    description:
                        'A linha ' + object.msisdn + ' está com a APN ' + apn,
                    duration: 30,
                    placement
                });
            }
            else if (res.data.response[0][0].result === "OK") {
                notification.warning({
                    message: 'A linha está com falhas na Operadora!',
                    description:
                        'Caso o problema persista, entre em contato com o departamento de Engenharia',
                    duration: 30,
                    placement
                });
            }
            dispatch(fetchSuccess(actionType.CHANGE_APN_LINE_SUCCESS, res.data));
            dispatch(fetchStart(actionType.CHANGE_APN_LINE_CLEAR));
        })
        .catch(err => {
            notification.close('loadingChangeApn')
            notification.error({
                message: 'Erro no pedido!',
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.CHANGE_APN_LINE_ERROR, err));
        });
};
export const getManyChangeApn = (file, apn) => dispatch => {
    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    formData.append("apn", apn);

    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.CHANGE_APN_LINE_START));
    notification.open({
        key: 'loadingManyChangeApn',
        message: 'Efetuando operação massiva de Mudança de APN das linhas ',
        description:
            'Em instantes você receberá a resposta do pedido da linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/many-change-apn", formData, config)
        .then(res => {
            notification.close('loadingManyChangeApn')
            if (res.data.response.length === 0) {
                notification.error({
                    message: 'Erro no pedido!',
                    description:
                        'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                    duration: 60,
                    placement
                });
            }
            if (res.data.response.length !== 0) {
                info({
                    title: "",
                    width: 600,
                    content: <HeliosTableAtr
                        title="Resultado das linhas"
                        columns={columns}
                        dataSource={res.data.response[0] || []}
                        rowKey="line"
                    />,
                    okText: "Fechar",
                    onOk() {
                    }
                })
            }
            dispatch(fetchSuccess(actionType.CHANGE_APN_LINE_SUCCESS, res.data));
            dispatch(fetchStart(actionType.CHANGE_APN_LINE_CLEAR));

        })
        .catch(err => {
            notification.close('loadingManyChangeApn')

            notification.error({
                message: err.response.data.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.CHANGE_APN_LINE_ERROR, err));
        });
};

export const getManyChangeApnClient = (customerId,customerName,file, apn,motive,protocol,email) => dispatch => {
    const formData = new FormData();
    console.log(file)
    formData.append("upload", file);
    formData.append("apn", apn);
    formData.append("customerId", customerId);
    formData.append("customerName", customerName);
    formData.append("protocol", protocol);
    formData.append("email", email);
    formData.append("motive", motive);


    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    dispatch(fetchStart(actionType.CHANGE_APN_LINE_START));
    notification.open({
        key: 'loadingManyChangeApn',
        message: 'Efetuando operação massiva de Mudança de APN das linhas ',
        description:
            'Em instantes você receberá a resposta do pedido da linhas',
        icon: <LoadingOutlined />,
        duration: 0,
        placement

    });
    axios
        .post(API_ROOT + "lines/client/many-change-apn", formData, config)
        .then(res => {
            notification.close('loadingManyChangeApn')            
            dispatch(fetchSuccess(actionType.CHANGE_APN_LINE_SUCCESS, res.data));
            dispatch(fetchStart(actionType.CHANGE_APN_LINE_CLEAR));
        })
        .catch(err => {
            notification.close('loadingManyChangeApn')            
            notification.error({
                message: err.response.data.message,
                description:
                    'Caso o problema persista, entre em contato com o departamento de Engenharia!',
                duration: 60,
                placement
            });
            dispatch(fetchError(actionType.CHANGE_APN_LINE_ERROR, err));
        });
};


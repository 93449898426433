import axios from "axios";
import * as actionsType from "../types";
import { API_ROOT } from "../../config/api-config";
import { fetchStart, fetchSuccess, fetchError } from "./utils/defaultMethods";
import moment from "moment";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
const placement = "bottomRight"

//http://localhost:3333/api/customers?name=Li
export const getContracts = (customerId,page,size) => dispatch => {
    // dispatch(fetchStart());
    dispatch(fetchStart(actionsType.GET_CONTRACTS_START));

    axios
        .get(API_ROOT + `contracts?customerId=${customerId}&page=${page}&size=${size}`)
        .then(res => {
            dispatch(fetchSuccess(actionsType.GET_CONTRACTS_SUCCESS, res.data));
        })
        .catch(error => {
            dispatch(fetchError(actionsType.GET_CONTRACTS_FAIL, error));
        });
};

export const insertContracts = (contract) => dispatch => {
    // dispatch(fetchStart());
    contract.discount_inception = moment(contract.discount_inception).format("YYYY-MM-DD").toString()
    dispatch(fetchStart(actionsType.GET_CONTRACTS_START));
    notification.open({
        key: 'loadingInsertContract',
        message: 'Realizando a inserção do contrato!',
        description:
          'Em instantes você receberá a resposta',
        icon: <LoadingOutlined />,
        duration: 1,
        placement
    
      });
    axios
        .post(API_ROOT + `contract`,contract)
        .then(res => {
            notification.close('loadingInsertCustomer')
            console.log("Resultado:"+res.data)
            dispatch(fetchSuccess(actionsType.INSERT_CONTRACT_SUCCESS, res.data));
            dispatch(fetchStart(actionsType.INSERT_CONTRACT_CLEAR));
        })
        .catch(error => {
            notification.close('loadingInsertCustomer')
            dispatch(fetchError(actionsType.GET_CONTRACTS_FAIL, error));
        });
};

export const editContract = (id,contract) => dispatch => {
    // dispatch(fetchStart());
    contract.discount_inception = moment(contract.discount_inception).format("YYYY-MM-DD").toString()
    dispatch(fetchStart(actionsType.GET_CONTRACTS_START));
    notification.open({
        key: 'loadingEditContract',
        message: 'Realizando a edição do contrato!',
        description:
          'Em instantes você receberá a resposta',
        icon: <LoadingOutlined />,
        duration: 1,
        placement
    
      });
    axios
        .put(API_ROOT + `contract/${id}`,contract)
        .then(res => {
            notification.close('loadingEditContract')
            console.log("Resultado:"+res.data)
            dispatch(fetchSuccess(actionsType.INSERT_CONTRACT_SUCCESS, res.data));
            dispatch(fetchStart(actionsType.INSERT_CONTRACT_CLEAR));
        })
        .catch(error => {
            notification.close('loadingEditContract')
            dispatch(fetchError(actionsType.GET_CONTRACTS_FAIL, error));
        });
};

export const getContractV2 = ({customerId, page, limit}) => dispatch => {
    dispatch(fetchStart(actionsType.GET_CONTRACTS_START));

    axios
        .get(API_ROOT + `v2/contracts?customerId=${customerId}&page=${page}&size=${limit}`)
        .then(res => {
            dispatch(fetchSuccess(actionsType.GET_CONTRACTS_SUCCESS, res.data));
        })
        .catch(error => {
            dispatch(fetchError(actionsType.GET_CONTRACTS_FAIL, error));
        });
}